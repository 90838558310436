import {
  Avatar,
  Box,
  Checkbox,
  InputBase,
  List,
  ListItem,
  Popover,
  Typography,
} from "@mui/material";
import { GridRowId, useGridApiContext } from "@mui/x-data-grid-pro";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Talent_Entity } from "../../entities/talent";

export function SelectTalents({
  talents = [],
  open = true,
  single = false,
  anchorEl,
  children,
  onClose,
  value = [],
  onChange,
}: {
  talents: Talent_Entity[];
  open: boolean;
  single?: boolean;
  anchorEl?: Element;
  children?: ReactElement;
  onClose: () => void;
  value?: number[];
  onChange: (e: number[]) => void;
}) {
  const [text, setText] = useState("");
  const ref = useRef();
  const handleClick = (id: number) => () => {
    if (single) {
      onChange([id]);
      return;
    }
    const _value = [...value];
    const index = value.indexOf(id) ?? -1;
    if (index >= 0) {
      _value.splice(index, 1);
    } else {
      _value.push(id);
    }

    onChange(_value);
  };

  return (
    <Box ref={ref}>
      <Popover
        open={open}
        anchorEl={anchorEl ?? ref.current}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ width: 320 }}>
          <Box sx={{ p: 1 }}>
            <InputBase
              fullWidth
              autoFocus
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Search..."
              sx={{ background: "rgba(155,155,155,0.1)", p: 1 }}
            />
          </Box>
          <List sx={{ maxHeight: 400, overflow: "scroll" }}>
            {talents
              .filter(
                (t) =>
                  `${t.code} ${t.firstName} ${t.lastName}`
                    .toLowerCase()
                    .indexOf(text.toLowerCase()) >= 0
              )
              .map((t) => (
                <ListItem
                  selected={value.indexOf(t.id) >= 0}
                  key={t.id}
                  dense
                  button
                  onClick={handleClick(t.id)}
                >
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    {!single ? (
                      <Checkbox
                        color="secondary"
                        size="small"
                        checked={value.indexOf(t.id) >= 0}
                      />
                    ) : (
                      []
                    )}
                    <Avatar
                      sx={{
                        height: 22,
                        width: 22,
                        fontSize: 11,
                        color: "text.primary",
                        background: t.active
                          ? "rgba(33,150,243,0.3)"
                          : "rgba(155,155,155,0.3)",
                      }}
                    >
                      {t.code}
                    </Avatar>
                    <Typography>
                      {t.firstName} {t.lastName}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
          </List>
        </Box>
      </Popover>
      {children}
    </Box>
  );
}

export function SelectTalentInputCell({
  id,
  value,
  field,
  talents,
  single = false,
}: {
  id: GridRowId;
  value: number[];
  field: string;
  single?: boolean;
  talents: Talent_Entity[];
}) {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const apiRef = useGridApiContext();
  const [talentIDs, setTalentIDs] = useState<number[]>(value ?? []);

  useEffect(() => {
    if (ref.current) setOpen(true);
  }, [ref]);

  const handleClose = (ti?: number[]) => {
    const _talentIDs = ti ?? talentIDs;
    setOpen(false);
    apiRef.current.setEditCellValue({
      id,
      field,
      value: single ? _talentIDs[0] : _talentIDs,
    });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <>
      <Box ref={ref}></Box>
      <SelectTalents
        open={open}
        single={single}
        talents={talents}
        anchorEl={ref.current}
        value={talentIDs}
        onChange={(e) => {
          setTalentIDs(e);
          if (single) handleClose(e);
        }}
        onClose={() => handleClose()}
      />
    </>
  );
}
