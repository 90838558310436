import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Benefit, Benefit_Entity } from "../../entities/benefit";

export const setSelectedBenefitID = createAction(
  "setSelectedBenefitID",
  function prepare(benefitID?: number) {
    return {
      payload: {
        benefitID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getBenefits = createAction(
  "films/benefit/getBenefits",
  function prepare() {
    const request = new GetAction(`/films/benefits`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const benefits = Benefit.fromList(json);
      dispatch(getBenefitsSuccess(benefits));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getScaleBenefits = createAction(
  "films/benefit/getBenefits",
  function prepare() {
    const request = new GetAction(`/films/scalesBenefits`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const benefits = Benefit.fromList(json);
      dispatch(getBenefitsSuccess(benefits));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getBenefitsSuccess = createAction(
  "films/benefit/getBenefitSuccess",
  function prepare(benefits: Benefit[]) {
    return {
      payload: {
        benefits,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getBenefitsByFilter = createAction(
  "films/benefit/getBenefitsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/benefits`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const benefits = Benefit.fromList(json);
      dispatch(getBenefitsByFilterSuccess(benefits));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getBenefitsByFilterSuccess = createAction(
  "films/benefit/getBenefitsByFilterSuccess",
  function prepare(benefits: Benefit[]) {
    return {
      payload: {
        benefits,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getBenefitById = createAction(
  "films/benefit/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/benefits/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const benefit = new Benefit(json);
      dispatch(getBenefitByIdSuccess(benefit));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getBenefitByIdSuccess = createAction(
  "films/benefit/getBenefitByIdSuccess",
  function prepare(benefit?: Benefit_Entity) {
    return {
      payload: {
        benefit,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteBenefit = createAction(
  "films/removeBenefitById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/films/benefits/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteBenefitSuccess = createAction(
  "films/benefits/deleteBenefitSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateBenefit = createAction(
  "films/benefits/updateBenefit",
  function prepare(id: number, body: Benefit_Entity) {
    const request = new PutAction(`/films/benefits/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateBenefitSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateBenefitSuccess = createAction(
  "films/benefits/updateBenefitSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createBenefit = createAction(
  "films/benefits/createBenefit",
  function prepare(body: Partial<Benefit_Entity>) {
    const request = new PostAction(`/films/benefits`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const benefit = new Benefit(json);
      dispatch(createBenefitSuccess(benefit));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createBenefitSuccess = createAction(
  "films/benefits/createBenefitSuccess",
  function prepare(benefit: Benefit) {
    return {
      payload: {
        benefit,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getBenefitsSearchMetadata = createAction(
  "films/getBenefitsSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/benefits/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getBenefitsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getBenefitsSearchMetadataSuccess = createAction(
  "films/getBenefitsSearchMetadataSuccess",
  function prepare(benefitsSearchMetadata: unknown) {
    return {
      payload: {
        benefitsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
