import Position_Entity from "./position";
export default class Position extends Position_Entity {
  static fromList(positionsJSON: unknown): Array<Position> {
    const positions: Position[] = [];
    if (positionsJSON)
      Array.isArray(positionsJSON) &&
        positionsJSON.forEach((positionJSON) => {
          positions.push(new Position(positionJSON));
        });
    return positions;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
