import { Timer_Entity } from "@nerdjs/kronos-kit";
import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Project, Project_Entity } from "../../entities/project";
import { Reconciliation_Entity } from "../../entities/reconciliation";

export const setShowArchived = createAction(
  "setShowArchived",
  function prepare(showArchived: boolean) {
    return {
      payload: {
        showArchived,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setSelectedProjectID = createAction(
  "setSelectedProjectID",
  function prepare(projectID?: number) {
    return {
      payload: {
        projectID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getProjects = createAction(
  "films/project/getProjects",
  function prepare() {
    const request = new GetAction(`/films/projects`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const projects = Project.fromList(json);
      dispatch(getProjectsSuccess(projects));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getProjectsSuccess = createAction(
  "films/project/getProjectSuccess",
  function prepare(projects: Project[]) {
    return {
      payload: {
        projects,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getProjectsByFilter = createAction(
  "films/project/getProjectsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/projects`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const projects = Project.fromList(json);
      dispatch(getProjectsByFilterSuccess(projects));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getProjectsByFilterSuccess = createAction(
  "films/project/getProjectsByFilterSuccess",
  function prepare(projects: Project[]) {
    return {
      payload: {
        projects,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getProjectById = createAction(
  "films/project/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/projects/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const project = new Project(json);
      dispatch(getProjectByIdSuccess(project));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getProjectByIdSuccess = createAction(
  "films/project/getProjectByIdSuccess",
  function prepare(project?: Project_Entity) {
    return {
      payload: {
        project,
        projectID: project?.id,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getProjectTimers = createAction(
  "films/project/timers",
  function prepare(id: number, query?: { [key: string]: unknown }) {
    const request = new GetAction(`/films/projects/${id}/timers`, query);

    request.decodeResponse = (dispatch: Dispatch, timers: Timer_Entity[]) => {
      dispatch(getProjectTimersSuccess(id, timers));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getProjectTimersSuccess = createAction(
  "films/project/getProjectTimersSuccess",
  function prepare(projectID: number, timers?: Timer_Entity[]) {
    return {
      payload: {
        projectID,
        timers,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteProject = createAction(
  "films/removeProjectById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/films/projects/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteProjectSuccess = createAction(
  "films/projects/deleteProjectSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateProject = createAction(
  "films/projects/updateProject",
  function prepare(id: number, body: Partial<Project_Entity>) {
    const request = new PutAction(`/films/projects/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateProjectSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateProjectSuccess = createAction(
  "films/projects/updateProjectSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createProject = createAction(
  "films/projects/createProject",
  function prepare(body: Partial<Project_Entity>) {
    const request = new PostAction(`/films/projects`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const project = new Project(json);
      dispatch(createProjectSuccess(project));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createProjectSuccess = createAction(
  "films/projects/createProjectSuccess",
  function prepare(project: Project) {
    return {
      payload: {
        project,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getProjectsSearchMetadata = createAction(
  "films/getProjectsSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/projects/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getProjectsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getProjectsSearchMetadataSuccess = createAction(
  "films/getProjectsSearchMetadataSuccess",
  function prepare(projectsSearchMetadata: unknown) {
    return {
      payload: {
        projectsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const reconciliation = createAction(
  "films/reconciliation",
  function prepare(id: number, body: Reconciliation_Entity) {
    const request = new PutAction(`/films/reconciliations/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(reconciliationSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const reconciliationSuccess = createAction(
  "films/reconciliationSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
