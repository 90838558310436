/**
 * Budget_Entity type guard.
 *
 * @param {any} budgetJson Budget object from API
 * @returns {boolean} Return true if type is Budget_Entity
 */
function validator(budgetJson: unknown): budgetJson is Budget_Entity {
  if (typeof budgetJson === "object" && budgetJson != null) {
    Budget_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(budgetJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Budget_Entity {
  static requiredFields = [
    "id",
    "projectID",
    "name",
    "subTotalNoLibCopyist",
    "totalWages",
    "totalBenefits",
    "totalSupplies",
    "totalPayroll",
    "totalStipend",
    "grandTotal",
    "subTotalOrchestration",
    "vacationOrchestration",
    "subTotalNoBenOrchestration",
    "welfareOrchestration",
    "pensionOrchestration",
    "totalOrchestration",
    "createdAt",
    "updatedAt",
  ];

  constructor(budgetJson: unknown) {
    if (validator(budgetJson)) {
      this.id = budgetJson.id;
      this.projectID = budgetJson.projectID;
      this.name = budgetJson.name;
      this.subTotalNoLibCopyist = budgetJson.subTotalNoLibCopyist;
      this.totalWages = budgetJson.totalWages;
      this.totalBenefits = budgetJson.totalBenefits;
      this.totalSupplies = budgetJson.totalSupplies;
      this.totalPayroll = budgetJson.totalPayroll;
      this.totalStipend = budgetJson.totalStipend;
      this.grandTotal = budgetJson.grandTotal;
      this.subTotalOrchestration = budgetJson.subTotalOrchestration;
      this.vacationOrchestration = budgetJson.vacationOrchestration;
      this.subTotalNoBenOrchestration = budgetJson.subTotalNoBenOrchestration;
      this.welfareOrchestration = budgetJson.welfareOrchestration;
      this.pensionOrchestration = budgetJson.pensionOrchestration;
      this.totalOrchestration = budgetJson.totalOrchestration;
      this.createdAt = budgetJson.createdAt;
      this.updatedAt = budgetJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Budget_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  projectID: number;
  name: string;
  subTotalNoLibCopyist: number;
  totalWages: number;
  totalBenefits: number;
  totalSupplies: number;
  totalPayroll: number;
  totalStipend: number;
  grandTotal: number;
  subTotalOrchestration: number;
  vacationOrchestration: number;
  subTotalNoBenOrchestration: number;
  welfareOrchestration: number;
  pensionOrchestration: number;
  totalOrchestration: number;
  createdAt: string;
  updatedAt: string;
}
