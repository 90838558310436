/**
 * Expense_Entity type guard.
 *
 * @param {any} expenseJson Expense object from API
 * @returns {boolean} Return true if type is Expense_Entity
 */
function validator(expenseJson: unknown): expenseJson is Expense_Entity {
  if (typeof expenseJson === "object" && expenseJson != null) {
    Expense_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(expenseJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Expense_Entity {
  static requiredFields = [
    "id",
    "projectID",
    "description",
    "quantity",
    "unitPrice",
    "amount",
    "createdAt",
    "updatedAt",
  ];

  constructor(expenseJson: unknown) {
    if (validator(expenseJson)) {
      this.id = expenseJson.id;
      this.projectID = expenseJson.projectID;
      this.description = expenseJson.description;
      this.quantity = expenseJson.quantity;
      this.unitPrice = expenseJson.unitPrice;
      this.amount = expenseJson.amount;
      this.createdAt = expenseJson.createdAt;
      this.updatedAt = expenseJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Expense_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  projectID: number;
  description: string;
  quantity: number;
  unitPrice: number;
  amount: number;
  createdAt: string;
  updatedAt: string;
}
