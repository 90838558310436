import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Expense, Expense_Entity } from "../../entities/expense";

export const setSelectedExpenseID = createAction(
  "setSelectedExpenseID",
  function prepare(expenseID?: number) {
    return {
      payload: {
        expenseID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getExpenses = createAction(
  "films/expense/getExpenses",
  function prepare() {
    const request = new GetAction(`/films/expenses`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const expenses = Expense.fromList(json);
      dispatch(getExpensesSuccess(expenses));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getExpensesSuccess = createAction(
  "films/expense/getExpenseSuccess",
  function prepare(expenses: Expense[]) {
    return {
      payload: {
        expenses,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getExpensesByFilter = createAction(
  "films/expense/getExpensesByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/expenses`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const expenses = Expense.fromList(json);
      dispatch(getExpensesSuccess(expenses));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getExpensesByFilterSuccess = createAction(
  "films/expense/getExpensesByFilterSuccess",
  function prepare(expenses: Expense[]) {
    return {
      payload: {
        expenses,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getExpenseById = createAction(
  "films/expense/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/expenses/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const expense = new Expense(json);
      dispatch(getExpenseByIdSuccess(expense));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getExpenseByIdSuccess = createAction(
  "films/expense/getExpenseByIdSuccess",
  function prepare(expense?: Expense_Entity) {
    return {
      payload: {
        expense,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteExpense = createAction(
  "films/removeExpenseById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/films/expenses/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteExpenseSuccess = createAction(
  "films/expenses/deleteExpenseSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateExpense = createAction(
  "films/expenses/updateExpense",
  function prepare(id: number, body: Expense_Entity) {
    const request = new PutAction(`/films/expenses/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateExpenseSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateExpenseSuccess = createAction(
  "films/expenses/updateExpenseSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createExpense = createAction(
  "films/expenses/createExpense",
  function prepare(body: Expense_Entity) {
    const request = new PostAction(`/films/expenses`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const expense = new Expense(json);
      dispatch(createExpenseSuccess(expense));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createExpenseSuccess = createAction(
  "films/expenses/createExpenseSuccess",
  function prepare(expense: Expense) {
    return {
      payload: {
        expense,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getExpensesSearchMetadata = createAction(
  "films/getExpensesSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/expenses/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getExpensesSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getExpensesSearchMetadataSuccess = createAction(
  "films/getExpensesSearchMetadataSuccess",
  function prepare(expensesSearchMetadata: unknown) {
    return {
      payload: {
        expensesSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
