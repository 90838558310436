import { createSelector } from "@reduxjs/toolkit";
import { Expense_Entity } from "../../entities/expense";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectExpenses = (state: AppState) => state.expenseState.expenses;
const selectExpensesByProject = (state: AppState) =>
  state.expenseState.expensesByProject;

export const expensesSelector = createSelector([selectExpenses], (expenses) => {
  return mapToArray(expenses);
});

export const expensesMapSelector = createSelector(
  [selectExpenses],
  (expenses) => {
    return expenses;
  }
);

export const expenseSelector = (id?: number) => {
  return createSelector([selectExpenses], (expenses) => {
    if (id) return expenses[id];
    return undefined;
  });
};

export const expensesByProjectSelector = (projectID?: number) => {
  return createSelector([selectExpensesByProject], (expensesByProject) => {
    if (projectID) return [...(expensesByProject[projectID] ?? [])];
    return [];
  });
};
