import { Box, Typography } from "@mui/material";
import { PieChart } from "react-minimal-pie-chart";
import { currencyFormatter } from "../../../constants";
import { cuesByProjectSelector } from "../../../redux/cue/cueSelector";
import { jobsByProjectSelector } from "../../../redux/job/jobSelector";
import { selectedProjectSelector } from "../../../redux/project/projectSelector";
import { useAppSelector } from "../../hooks";

/**
 *
 * @returns {ReactElement} ProjectGraphs page
 */
export function ProjectGraphs() {
  const project = useAppSelector(selectedProjectSelector);
  const cues = useAppSelector(cuesByProjectSelector(project?.id));
  const jobs = useAppSelector(jobsByProjectSelector(project?.id));

  let manHours = 0;
  let avgHourlyRate = 0;
  jobs.forEach((j) => {
    manHours += j.hours;
    avgHourlyRate += j.hourlyRate / 10000;
  });

  avgHourlyRate = avgHourlyRate / jobs.length;

  return (
    <Box
      sx={{
        maxWidth: 600,
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: 2,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>Crew hours</Typography>
        <Typography>
          <b>{manHours / 100}</b>
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography>Average Crew Hourly Rate</Typography>
          {/* <Typography variant="caption">
            Not incuding Supervision, Librarian, Backroom, or Off-Split
            Adjusting/Adding bills (I.e. JZ bill) will not change this number.
            This is simply (Split Wage - Money Owed) / Man Hours by copyists
          </Typography> */}
        </Box>
        <Typography>
          <b>{currencyFormatter.format(avgHourlyRate)}</b>
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>Supply %</Typography>
        <Typography>59</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>Number of Cues</Typography>
        <Typography>
          <b>{cues.length}</b>
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>Total Cue Costs</Typography>
        <Typography color="primary"></Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>Cost Per Cue</Typography>
        <Typography>59</Typography>
      </Box>
      <Box sx={{ width: 300, height: 300, margin: "auto" }}>
        <PieChart
          lineWidth={15}
          paddingAngle={12}
          rounded
          startAngle={-90}
          data={[
            { title: "Orch 5%", value: 5, color: "#2196f3" },
            { title: "Prep 95%", value: 95, color: "#90caf9" },
          ]}
          animate
          label={({ dataEntry }) => dataEntry.title}
          labelStyle={{
            fontSize: "4px",
          }}
        />
      </Box>
    </Box>
  );
}
