import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Scale, Scale_Entity } from "../../entities/scale";

export const setSelectedScaleID = createAction(
  "setSelectedScaleID",
  function prepare(scaleID?: number) {
    return {
      payload: {
        scaleID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getScales = createAction(
  "films/scale/getScales",
  function prepare() {
    const request = new GetAction(`/films/scales`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const scales = Scale.fromList(json);
      dispatch(getScalesSuccess(scales));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getScalesSuccess = createAction(
  "films/scale/getScaleSuccess",
  function prepare(scales: Scale[]) {
    return {
      payload: {
        scales,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getScalesByFilter = createAction(
  "films/scale/getScalesByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/scales`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const scales = Scale.fromList(json);
      dispatch(getScalesByFilterSuccess(scales));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getScalesByFilterSuccess = createAction(
  "films/scale/getScalesByFilterSuccess",
  function prepare(scales: Scale[]) {
    return {
      payload: {
        scales,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getScaleById = createAction(
  "films/scale/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/scales/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const scale = new Scale(json);
      dispatch(getScaleByIdSuccess(scale));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getScaleByIdSuccess = createAction(
  "films/scale/getScaleByIdSuccess",
  function prepare(scale?: Scale_Entity) {
    return {
      payload: {
        scale,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteScale = createAction(
  "films/removeScaleById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/films/scales/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteScaleSuccess = createAction(
  "films/scales/deleteScaleSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateScale = createAction(
  "films/scales/updateScale",
  function prepare(id: number, body: Scale_Entity | Record<string, unknown>) {
    const request = new PutAction(`/films/scales/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateScaleSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateScaleSuccess = createAction(
  "films/scales/updateScaleSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createScale = createAction(
  "films/scales/createScale",
  function prepare(body: Scale_Entity) {
    const request = new PostAction(`/films/scales`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const scale = new Scale(json);
      dispatch(createScaleSuccess(scale));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createScaleSuccess = createAction(
  "films/scales/createScaleSuccess",
  function prepare(scale: Scale) {
    return {
      payload: {
        scale,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getScalesSearchMetadata = createAction(
  "films/getScalesSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/scales/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getScalesSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getScalesSearchMetadataSuccess = createAction(
  "films/getScalesSearchMetadataSuccess",
  function prepare(scalesSearchMetadata: unknown) {
    return {
      payload: {
        scalesSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
