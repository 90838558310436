import { Sheet } from "@mui/joy";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { getProjectById } from "@nerdjs/kronos-kit";
import { Dispatch } from "@reduxjs/toolkit";
import { ConfirmOptions, useConfirm } from "material-ui-confirm";
import { useContext, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  currencyFormatter,
  defaultDataGridProps,
  injectDefaultColumnProps,
} from "../../constants";
import { Job, Job_Entity } from "../../entities/job";
import { Position_Entity } from "../../entities/position";
import { Project } from "../../entities/project";
import { Talent_Entity } from "../../entities/talent";
import { AppConfig } from "../../environement";
import {
  createJob,
  deleteJob,
  deleteJobSuccess,
  getJobsByFilter,
  setSelectedJobID,
  updateJob,
} from "../../redux/job/jobActions";
import { jobsByProjectSelector } from "../../redux/job/jobSelector";
import { getPositions } from "../../redux/position/positionActions";
import { positionsSelector } from "../../redux/position/positionSelector";
import {
  projectSelector,
  selectedProjectIDSelector,
  selectedProjectSelector,
} from "../../redux/project/projectSelector";
import { projectTypesMapSelector } from "../../redux/projectType/projectTypeSelector";
import {
  talentsMapSelector,
  talentsSelector,
} from "../../redux/talent/talentSelector";
import { setSelectedVtransactionTalentID } from "../../redux/vtransaction/vtransactionActions";
import { ExcelContext } from "../excel";
import { useAppSelector } from "../hooks";
import {
  getterDivider,
  setterDivider,
} from "../jobCategory/jobCategoryRates/dataGridColDef";
import NoRowsOverlay from "../noRowsOverlay/noRowsOverlay";
import { Help } from "../projectBudget/projectBudget";
import {
  SelectTalentInputCell,
  SelectTalents,
} from "../selectTalents/selectTalents";

/**
 * Job
 *
 * @returns {null} Job
 */
export default function Jobs({
  orchestrator = false,
}: {
  orchestrator?: boolean;
}) {
  const projectID = useAppSelector(selectedProjectIDSelector);
  const project = useAppSelector(projectSelector(projectID));
  let jobs = useAppSelector(jobsByProjectSelector(projectID));
  const apiRef = useGridApiRef();
  const confirm = useConfirm();
  const talents = useAppSelector(talentsSelector);
  const talentsMap = useAppSelector(talentsMapSelector);
  const positions = useAppSelector(positionsSelector);
  const dispatch = useDispatch();
  const projectTypesMap = useAppSelector(projectTypesMapSelector);

  const minimumCopyist =
    projectTypesMap[project?.projectTypeID ?? 0]?.minimumCopyist;

  const excel = useContext(ExcelContext);

  if (orchestrator) {
    jobs = jobs.filter((j) => j.orchestrator);
  } else {
    jobs = jobs.filter((j) => !j.orchestrator);
  }

  useEffect(() => {
    dispatch(getPositions());
    if (projectID) {
      dispatch(
        getJobsByFilter({
          filters: JSON.stringify([
            {
              name: "jobs.projectID",
              value: projectID,
              comparison: "eq",
            },
          ]),
        })
      );
    }
  }, []);

  const onJobUpdate = async (_newJob: Job_Entity, _oldJob: Job_Entity) => {
    return new Promise<Job_Entity>((resolve) => {
      const body = { ..._newJob };

      const editedFields = [];

      for (const key in _newJob) {
        if (Object.prototype.hasOwnProperty.call(_newJob, key)) {
          if (_newJob[key] !== _oldJob[key]) {
            editedFields.push(key);
          }
        }
      }

      if (editedFields.indexOf("totalAdjusted") >= 0) {
        if (_newJob.totalAdjusted === 0) {
          body.totalAdjusted = -1;
        }
      } else {
        body.totalAdjusted = 0;
      }

      dispatch(updateJob(_newJob.id, body));
      setTimeout(() => {
        resolve({ ..._newJob });
      }, 50);
    });
  };

  const usedTalentID = new Map<number, boolean>();
  jobs.forEach((j) => {
    usedTalentID[j.talentID] = true;
  });

  let columnVisibility: GridColumnVisibilityModel = {
    hourlyRate: !orchestrator,
    subTotalNoLib: !orchestrator,
    kronosHours: !orchestrator,
    hours: !orchestrator,
    adjustment: !orchestrator,
    stipend: !orchestrator,
    effectiveHourly: !orchestrator,
    librarian: !orchestrator,
    supervisionPercent: !orchestrator,
    supervision: !orchestrator,
    stipendDescription: !orchestrator,
    totalAdjusted: !orchestrator,
    librarianSplit: !orchestrator,
    benefitsOnly: orchestrator,
  };

  if (!project?.union) {
    columnVisibility = {
      ...columnVisibility,
      flexPlanHours: false,
      flexPlan: false,
      vacation: false,
      pension: false,
      benefitsOnly: false,
    };
  }

  return (
    <Paper sx={{ flex: 1 }}>
      <DataGridPro
        {...defaultDataGridProps}
        disableColumnReorder={false}
        getCellClassName={(c) => {
          let className = "";
          const row = c.row;
          const talentID = row.talentID;
          const talent = talentsMap[talentID];
          const isPartner = talent?.positionID === 1;
          if (c.field === "totalAdjusted" && isPartner) {
            return "cellReadOnly";
          }
          className = !c.isEditable ? " cellReadOnly" : "";
          if (c.field === " librarianSplit" && !c.row.librarian) {
            className += " cellReadOnly";
          }
          if (
            !orchestrator &&
            (c.field === "total" || c.field === "subTotal") &&
            c.row.subTotal < minimumCopyist
          )
            className += " warning";

          if ((c.row.lock || project?.locked()) && c.field !== "actions")
            className += " lock";

          return className;
        }}
        isCellEditable={(params) => {
          const row = params.row;
          const talentID = row.talentID;
          const talent = talentsMap[talentID];
          const isPartner = talent?.positionID === 1;
          if (isPartner && params.field === "totalAdjusted") return false;
          return params.row.lock ? false : params.colDef.editable ?? false;
        }}
        apiRef={apiRef}
        processRowUpdate={onJobUpdate}
        rows={jobs}
        columns={jobColumns(
          excel,
          dispatch,
          project!,
          confirm,
          talents,
          talentsMap,
          positions,
          orchestrator
        )}
        experimentalFeatures={{ newEditingApi: true }}
        getDetailPanelHeight={() => 100}
        components={{
          Toolbar: JobToolbar,
          NoRowsOverlay: NoRowsOverlay,
          Footer: JobsFooter,
        }}
        componentsProps={{
          footer: { rows: jobs, orchestrator },
          toolbar: { usedTalentID, orchestrator },
          cell: { tabIndex: 1 },
        }}
        initialState={{
          columns: {
            columnVisibilityModel: columnVisibility,
          },
          pinnedColumns: {
            left: ["position", "talentID"],
            right: ["total", "totalAdjusted", "actions"],
          },
        }}
      />
    </Paper>
  );
}

function JobToolbar({
  usedTalentID,
  orchestrator,
}: {
  usedTalentID: Map<number, boolean>;
  orchestrator: boolean;
}) {
  const projectID = useAppSelector(selectedProjectIDSelector);
  const project = useAppSelector(selectedProjectSelector);
  const jobs = useAppSelector(jobsByProjectSelector(projectID));
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >();
  const [open, setOpen] = useState(false);
  const talents = useAppSelector(talentsSelector);
  const dispatch = useDispatch();
  const excel = useContext(ExcelContext);

  const handleNewJob = (talentID: number) => {
    if (projectID && !project?.locked()) {
      setOpen(false);
      const newJob = {
        projectID: projectID,
        id: -1,
        talentID,
        hours: 0,
        orchestrator,
        librarian: false,
        subTotal: 0,
        supervisionPercent: 0,
        supervision: 0,
        vacation: 0,
        benefitsOnly: false,
        total: 0,
        daysWorked: 0,
        pension: 0,
        flexPlanHours: 0,
        flexPlan: 0,
        supplies: 0,
        adjustment: 0,
        createdAt: "",
        updatedAt: "",
        hourlyRate: 0,
        stipend: 0,
        stipendDescription: "",
      };
      dispatch(createJob(newJob));
    }
  };

  useHotkeys(`ctrl+enter`, () => setOpen(true), {
    enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
  });

  return (
    <Box
      className="header"
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <Box sx={{ display: "flex" }}>
        <Tooltip title="ctrl + enter">
          <Button
            size="small"
            onClick={() => setOpen(true)}
            disabled={project?.locked()}
          >
            + New Job
          </Button>
        </Tooltip>
        <SelectTalents
          open={open}
          onChange={(e) => {
            if (e.length) handleNewJob(e[0]);
          }}
          talents={talents.filter((t) => usedTalentID[t.id] != true)}
          single
          onClose={() => setOpen(false)}
        />
        <Divider
          sx={{ margin: 1, width: 2 }}
          orientation="vertical"
          variant="middle"
          flexItem
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            excel.invoice(jobs);
            setAnchorEl(null);
          }}
        >
          <i className="fa-solid fa-file-excel pright"></i>Excel
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            window.open(
              `${AppConfig.api.endpoint}/films/projects/${projectID}/invoices`,
              "_blank"
            );
          }}
        >
          <i className="fa-solid fa-file-pdf pright"></i> PDF
        </MenuItem>
      </Menu>
    </Box>
  );
}

function JobsFooter({
  rows,
  orchestrator,
}: {
  rows: Job_Entity[];
  orchestrator: boolean;
}) {
  let total = 0;
  const params = useParams();
  const projectID = parseInt(params.id ?? "");
  const project = useAppSelector(projectSelector(projectID));
  const dispatch = useDispatch();

  for (const key in rows) {
    if (Object.prototype.hasOwnProperty.call(rows, key)) {
      const row = rows[key];
      total += row.total;
    }
  }
  return (
    <>
      <Divider />
      <Box
        sx={{
          p: 2,
          justifyContent: "space-between",
          gap: 2,
          display: "flex",
        }}
      >
        <Box>
          {!orchestrator ? (
            <Button
              onClick={() => dispatch(getProjectById(projectID))}
              variant="outlined"
              disabled={project?.locked()}
              size="small"
            >
              Calculate All Jobs
            </Button>
          ) : (
            []
          )}
        </Box>
        <Box />
        <Box>
          {!orchestrator ? (
            <>
              <Box sx={{ display: "flex", gap: 1, justifyContent: "end" }}>
                <Typography sx={{ fontWeight: 600 }} variant="body2">
                  <Help title="No benefits, No vacation, No lib" /> Subtotal (no
                  lib)
                </Typography>
                <Typography sx={{ fontWeight: 600 }} variant="body2">
                  {project?.laborSubTotalNoLibDollar()}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1, justifyContent: "end" }}>
                <Typography sx={{ fontWeight: 600 }} variant="body2">
                  <Help title="With Lib, Vacation, and Supervision" /> Total
                  (w/ben):
                </Typography>
                <Typography sx={{ fontWeight: 600 }} variant="body2">
                  {project?.laborTotalDollar()}
                </Typography>
              </Box>
            </>
          ) : (
            <Box sx={{ display: "flex", gap: 1, justifyContent: "end" }}>
              <Typography sx={{ fontWeight: 600 }} variant="body2">
                Subtotal (no ben):
              </Typography>
              <Typography sx={{ fontWeight: 600 }} variant="body2">
                {currencyFormatter.format(total / 10000)}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

const jobColumns = (
  excel: {
    invoice: (j: Job_Entity[]) => void;
  },
  dispatch: Dispatch,
  project: Project,
  confirm: (options?: ConfirmOptions | undefined) => Promise<void>,
  talents: Talent_Entity[],
  talentsMap: { [key: number]: Talent_Entity },
  positions: Position_Entity[],
  orchestrator: boolean
): GridColumns =>
  injectDefaultColumnProps([
    {
      field: "position",
      headerName: "Position",
      headerAlign: "center",
      type: "string",
      sortable: false,
      width: 100,
      renderCell: (p) => {
        const talent = talents.find((t) => t.id === p.row.talentID);
        const position = positions.find((p) => p.id === talent?.positionID);
        return (
          <span>
            {p.row.lock ? (
              <i className="fa-solid fa-lock" style={{ marginRight: 4 }}></i>
            ) : (
              []
            )}
            {position?.name ?? ""}
          </span>
        );
      },
      valueGetter: (p) => {
        const talent = talents.find((t) => t.id === p.row.talentID);
        const position = positions.find((p) => p.id === talent?.positionID);
        return position?.id ?? "";
      },
    },
    {
      field: "talentID",
      headerAlign: "center",
      headerName: "Talent",
      sortable: false,
      editable: true,
      width: 170,
      renderEditCell: (p) => {
        return (
          <SelectTalentInputCell
            id={p.id}
            single
            field={p.field}
            value={[p.row.talentID]}
            talents={talents}
          />
        );
      },
      renderCell: (p) => {
        const talent = talents.find((t) => t.id === p.row.talentID);
        return (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Avatar
              sx={{
                height: 22,
                width: 22,
                fontSize: 11,
                color: "text.primary",
                background: talent?.active
                  ? "rgba(33,150,243,0.3)"
                  : "rgba(155,155,155,0.3)",
              }}
            >
              {talent?.code}
            </Avatar>
            <Typography
              variant="body2"
              color={talent ? "textPrimary" : "textSecondary"}
            >
              {talent
                ? `${talent?.firstName} ${talent?.lastName}`
                : "Double Click to assign"}
            </Typography>
          </Box>
        );
      },
    },
    // {
    //   field: "orchestrator",
    //   headerAlign: "center",
    //   headerName: "Orch",
    //   sortable: false,
    //   editable: true,
    //   width: 60,
    //   type: "boolean",
    // },
    {
      field: "librarian",
      headerAlign: "center",
      headerName: "Lib",
      sortable: false,
      editable: true,
      width: 60,
      type: "boolean",
    },
    {
      field: "benefitsOnly",
      headerAlign: "center",
      headerName: "Ben Only",
      sortable: false,
      editable: true,
      width: 80,
      type: "boolean",
    },
    {
      field: "hourlyRate",
      headerAlign: "center",
      headerName: "Hourly Rate",
      sortable: false,
      editable: true,
      type: "number",
      valueGetter: getterDivider("hourlyRate", 10000),
      valueSetter: setterDivider("hourlyRate", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}/h`,
    },
    {
      field: "hours",
      headerAlign: "center",
      align: "right",
      headerName: "Hours",
      sortable: false,
      editable: true,
      type: "number",
      width: 70,
      valueGetter: getterDivider("hours", 100),
      valueSetter: setterDivider("hours", 100),
    },
    {
      field: "kronosHours",
      headerAlign: "center",
      align: "right",
      headerName: "Hours Tracked",
      width: 120,
      sortable: false,
      editable: false,
      type: "number",
      valueGetter: getterDivider("kronosHours", 100),
      valueSetter: setterDivider("kronosHours", 100),
      renderCell: (p) => <CopyOver job={p.row} />,
    },
    {
      field: "effectiveHourly",
      headerAlign: "center",
      headerName: "Hourly",
      sortable: false,
      editable: false,
      width: 90,
      type: "number",
      valueGetter: (p) => (p.row.hours > 0 ? p.row.total / p.row.hours : 0),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value / 100)}/h`,
    },
    {
      field: "adjustment",
      headerAlign: "center",
      headerName: "Adjustment",
      sortable: false,
      editable: true,
      type: "number",
      valueGetter: getterDivider("adjustment", 10000),
      valueSetter: setterDivider("adjustment", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`,
    },
    {
      field: "subTotalNoLib",
      headerAlign: "center",
      align: "right",
      headerName: "Subtotal (no lib)",
      editable: false,
      sortable: false,
      width: 125,
      type: "number",
      valueGetter: getterDivider("subTotalNoLib", 10000),
      valueSetter: setterDivider("subTotalNoLib", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`,
    },
    {
      field: "librarianSplit",
      headerAlign: "center",
      headerName: "Librarian",
      width: 80,
      sortable: false,
      editable: true,
      type: "number",
      valueGetter: getterDivider("librarianSplit", 10000),
      valueSetter: setterDivider("librarianSplit", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`,
    },
    {
      field: "supervisionPercent",
      headerAlign: "center",
      align: "center",
      headerName: "Supervision %",
      editable: false,
      sortable: false,
      type: "number",
      width: 110,
      valueGetter: getterDivider("supervisionPercent", 100),
      valueSetter: setterDivider("supervisionPercent", 100),
      valueFormatter: (p: GridValueFormatterParams) => `${p.value}%`,
    },
    {
      field: "supervision",
      headerAlign: "center",
      headerName: "Supervision",
      editable: false,
      sortable: false,
      type: "number",
      valueGetter: getterDivider("supervision", 10000),
      valueSetter: setterDivider("supervision", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`,
    },
    {
      field: "subTotal",
      headerAlign: "center",
      align: "right",
      headerName: "Subtotal",
      editable: false,
      sortable: false,
      type: "number",
      valueGetter: getterDivider("subTotal", 10000),
      valueSetter: setterDivider("subTotal", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`,
      renderCell: !orchestrator
        ? (p) => <RenderCopysitTotal {...p} />
        : undefined,
    },
    {
      field: "vacation",
      headerAlign: "center",
      headerName: "Vacation",
      editable: false,
      sortable: false,
      width: 80,
      type: "number",
      valueGetter: getterDivider("vacation", 10000),
      valueSetter: setterDivider("vacation", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`,
    },
    // {
    //   field: "daysWorked",
    //   align: "center",
    //   headerAlign: "center",
    //   headerName: "Days Wkd",
    //   sortable: false,
    //   editable: false,
    //   width: 90,
    //   type: "number",
    //   valueGetter: getterDivider("daysWorked", 1),
    //   valueSetter: setterDivider("daysWorked", 1),
    // },
    // {
    //   field: "flexPlanHours",
    //   align: "center",
    //   headerAlign: "center",
    //   headerName: "Flex Plan Hrs",
    //   editable: false,
    //   sortable: false,
    //   type: "number",
    //   width: 105,
    //   valueGetter: getterDivider("flexPlanHours", 100),
    //   valueSetter: setterDivider("flexPlanHours", 100),
    // },
    // {
    //   field: "flexPlan",
    //   headerAlign: "center",
    //   headerName: "Flex Plan",
    //   editable: false,
    //   sortable: false,
    //   width: 80,
    //   type: "number",
    //   valueGetter: getterDivider("flexPlan", 10000),
    //   valueSetter: setterDivider("flexPlan", 10000),
    //   valueFormatter: (p: GridValueFormatterParams) =>
    //     `${currencyFormatter.format(p.value)}`,
    // },
    // {
    //   field: "pension",
    //   headerAlign: "center",
    //   headerName: "Pension",
    //   editable: false,
    //   sortable: false,
    //   width: 80,
    //   type: "number",
    //   valueGetter: getterDivider("pension", 10000),
    //   valueSetter: setterDivider("pension", 10000),
    //   valueFormatter: (p: GridValueFormatterParams) =>
    //     `${currencyFormatter.format(p.value)}`,
    // },
    {
      field: "stipend",
      headerAlign: "center",
      headerName: "Stipend",
      width: 80,
      sortable: false,
      editable: true,
      type: "number",
      valueGetter: getterDivider("stipend", 10000),
      valueSetter: setterDivider("stipend", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`,
    },
    {
      field: "stipendDescription",
      headerAlign: "center",
      headerName: "Stipend Desc",
      editable: true,
      sortable: false,
      width: 150,
      type: "string",
    },
    {
      field: "total",
      align: "right",
      headerAlign: "center",
      headerName: orchestrator ? "Total" : "Total (no adj)",
      editable: false,
      sortable: false,
      width: orchestrator ? 100 : 120,
      type: "number",
      valueGetter: getterDivider("total", 10000),
      valueSetter: setterDivider("total", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`,
      renderCell: !orchestrator
        ? (p) => <RenderCopysitTotal {...p} />
        : undefined,
    },
    {
      field: "totalAdjusted",
      align: "right",
      headerAlign: "center",
      headerName: "Total Adjusted",
      editable: true,
      sortable: false,
      width: 120,
      type: "number",
      valueGetter: (p) => p.row.total / 10000,
      valueSetter: setterDivider("totalAdjusted", 10000),
      renderCell: (c) => {
        const talentID = c.row.talentID;
        const talent = talentsMap[talentID];
        const isPartner = talent?.positionID === 1;

        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: isPartner ? "space-between" : "end",
              flex: 1,
              alignItems: "center",
            }}
          >
            {isPartner ? (
              <Tooltip
                title={`Cannot calculate adjustment for partners. Must be manually adjusted.`}
              >
                <i className="fa-regular fa-circle-question"></i>
              </Tooltip>
            ) : (
              []
            )}
            <Typography variant="body2">
              {currencyFormatter.format(c.value)}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      sortable: false,
      headerName: "",
      width: 30,
      cellClassName: "actions",
      getActions: (props: { row: Job_Entity; id: any }) => {
        const actions = [
          <GridActionsCellItem
            key="delete"
            disabled={project.locked()}
            icon={<i className="fa-solid fa-trash"></i>}
            label={`Delete`}
            onClick={() => {
              confirm({
                description: `You are about to delete a job`,
              })
                .then(() => {
                  dispatch(deleteJob(props.id, deleteJobSuccess));
                })
                .catch(() => {
                  /* ... */
                });
            }}
            color="inherit"
            showInMenu
          />,
        ];

        if (props.row.talentID) {
          actions.push(
            <GridActionsCellItem
              key={"vtransactions"}
              icon={<i className="fa-solid fa-file-pdf"></i>}
              label="Download PDF"
              onClick={() => {
                window.open(
                  `${AppConfig.api.endpoint}/films/jobs/${props.row.id}/invoice`,
                  "_blank"
                );
              }}
              showInMenu
            />
          );
        }

        if (props.row.talentID) {
          actions.push(
            <GridActionsCellItem
              key={"vtransactions"}
              icon={<i className="fa-solid fa-scale-unbalanced"></i>}
              label="Ledger transactions"
              onClick={() => {
                dispatch(setSelectedVtransactionTalentID(props.row.talentID));
                dispatch(setSelectedJobID(props.id));
              }}
              showInMenu
            />
          );
        }

        if (props.row.talentID) {
          actions.push(
            <GridActionsCellItem
              key={props.row.lock ? "Unlock" : "lock"}
              disabled={project.locked()}
              icon={
                <i
                  className={
                    props.row.lock
                      ? "fa-regular fa-lock-open"
                      : "fa-solid fa-lock"
                  }
                ></i>
              }
              sx={{
                background: !props.row.lock
                  ? "rgba(76, 175, 80, 0.1)"
                  : undefined,
              }}
              label={props.row.lock ? "Unlock" : "Lock"}
              onClick={() => {
                dispatch(updateJob(props.row.id, { lock: !props.row.lock }));
              }}
              showInMenu
            />
          );
        }

        return actions;
      },
    },
  ]);

function RenderCopysitTotal(p: GridRenderCellParams) {
  const projectTypesMap = useAppSelector(projectTypesMapSelector);
  const projectID = useAppSelector(selectedProjectIDSelector);
  const project = useAppSelector(projectSelector(projectID));

  if (!project) return <Box>{currencyFormatter.format(p.value)}</Box>;

  const minimumCopyist = projectTypesMap[project.projectTypeID]?.minimumCopyist;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
        width: "100%",
      }}
    >
      {p.row.subTotal < minimumCopyist ? (
        <Tooltip
          title={`Min pay is ${currencyFormatter.format(
            minimumCopyist / 10000
          )}`}
        >
          <i className="fa-regular fa-triangle-exclamation"></i>
        </Tooltip>
      ) : (
        <Box />
      )}
      {p.field === "total"
        ? currencyFormatter.format((p.row.total - p.row.adjustment) / 10000)
        : currencyFormatter.format(p.value)}
    </Box>
  );
}

function CopyOver({ job }: { job: Job }) {
  const dispatch = useDispatch();

  const handleCopyOver = () => {
    dispatch(updateJob(job.id, { ...job, hours: job.kronosHours }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        justifyContent: "space-between",
        flex: 1,
        cursor: "pointer",
      }}
    >
      {job.kronosHours && job.hours != job.kronosHours ? (
        <Tooltip title="Copy over">
          <Sheet
            onClick={handleCopyOver}
            color="primary"
            variant="solid"
            sx={{
              height: 20,
              width: 20,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              borderRadius: 4,
            }}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </Sheet>
        </Tooltip>
      ) : (
        <div />
      )}
      {job.kronosHours ? (
        <Typography variant="body2">
          <i className="fa-solid fa-timer" style={{ paddingRight: 4 }}></i>
          {job.kronosHours / 100}
        </Typography>
      ) : (
        <Typography variant="caption" sx={{ opacity: 0.8 }}>
          <i>No time tracked</i>
        </Typography>
      )}
    </Box>
  );
}
