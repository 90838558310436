import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../store";

const selectDrawerOpen = (s: AppState) => s.appStatusState.drawerOpen;
const selectFormOpen = (s: AppState) => s.appStatusState.formOpen;
const selectTabIndexes = (s: AppState) => s.appStatusState.tabIndexes;
const selectConfetti = (s: AppState) => s.appStatusState.confetti;
const selectedNetworkCompletionStack = (s: AppState) =>
  s.networkState.networkCompletionStack;
const currentUserApps = (s: AppState) =>
  s.accountKitState.userState.currentUserApps;
const selectUserServices = (s: AppState) =>
  s.accountKitState.userState.currentUser?.services;

const selectImpersonateSuccess = (s: AppState) =>
  s.accountKitState.userState.impersonateSuccess;

export const impersonateSuccessSelector = createSelector(
  [selectImpersonateSuccess],
  (c) => c
);

export const drawerOpenSelector = createSelector([selectDrawerOpen], (c) => c);

export const userServicesSelector = createSelector(
  [selectUserServices],
  (c) => c
);

export const isAdminSelector = createSelector(
  [selectUserServices],
  (userServices) => {
    const isAdmin = userServices!["films"]?.indexOf("admin") >= 0;
    const isSuperAdmin = userServices!["films"]?.indexOf("superadmin") >= 0;

    return isAdmin || isSuperAdmin;
  }
);

export const currentUserAppsSelector = createSelector(
  [currentUserApps],
  (c) => c
);
export const confettiSelector = createSelector([selectConfetti], (c) => c);
export const networkCompletionStackSelector = createSelector(
  [selectedNetworkCompletionStack],
  (c) => c
);

export const tabIndexSelector = (tabID?: string) => {
  return createSelector([selectTabIndexes], (tabIndexes) => {
    if (tabID) return tabIndexes[tabID];
    return undefined;
  });
};

export const formOpenSelector = (formID?: string) => {
  return createSelector([selectFormOpen], (tabIndexes) => {
    if (formID) return tabIndexes[formID];
    return false;
  });
};
