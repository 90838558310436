import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { ratesTabID } from "../constants";
import { Budget_Entity } from "../entities/budget";
import { queryBuilder } from "../network";
import { setTabIndex, showConfetti } from "./appStatus/appStatusActions";
import {
  createBenefitSuccess,
  deleteBenefitSuccess,
  getBenefits,
  getScaleBenefits,
  setSelectedBenefitID,
  updateBenefitSuccess,
} from "./benefit/benefitActions";
import {
  createBudgetSuccess,
  getBudgetById,
  updateBudgetSuccess,
} from "./budget/budgetActions";
import {
  createCueSuccess,
  deleteCueSuccess,
  getCuesByFilter,
  updateBatchCuesSuccess,
  updateCueSuccess,
} from "./cue/cueActions";
import {
  createExpenseSuccess,
  deleteExpenseSuccess,
  getExpensesByFilter,
  updateExpenseSuccess,
} from "./expense/expenseActions";
import {
  createJobSuccess,
  deleteJobSuccess,
  getJobsByFilter,
  updateJobSuccess,
} from "./job/jobActions";
import {
  createJobCategorySuccess,
  deleteJobCategorySuccess,
  getJobCategories,
  setSelectedJobCategoryID,
} from "./jobCategory/jobCategoryActions";
import { updateJobTaskSuccess } from "./jobTask/jobTaskActions";
import {
  createPositionSuccess,
  deletePositionSuccess,
  getPositions,
  setSelectedPositionID,
} from "./position/positionActions";
import {
  createProjectSuccess,
  getProjectById,
  getProjectByIdSuccess,
  getProjects,
  reconciliationSuccess,
  setSelectedProjectID,
  updateProjectSuccess,
} from "./project/projectActions";
import { getProjectTasks } from "./projectTask/projectTaskActions";
import {
  createProjectTypeSuccess,
  deleteProjectTypeSuccess,
  getProjectTypes,
  setSelectedProjectTypeID,
  updateProjectTypeSuccess,
} from "./projectType/projectTypeActions";
import { projectTypesSelector } from "./projectType/projectTypeSelector";
import { getProjectTypeRates } from "./rate/rateActions";
import {
  createScaleSuccess,
  deleteScaleSuccess,
  getScales,
  setSelectedScaleID,
  updateScaleSuccess,
} from "./scale/scaleActions";
import { store } from "./store";
import {
  createTalentSuccess,
  deleteTalentSuccess,
  getTalentById,
  getTalents,
  setSelectedTalentID,
  updateTalentSuccess,
} from "./talent/talentActions";
import { updateBatchTasksSuccess, updateTaskSuccess } from "./task/taskActions";
import { getTaskTypes } from "./taskType/taskTypeActions";
import {
  createVendorSuccess,
  deleteVendorSuccess,
  getVendors,
  updateVendorSuccess,
} from "./vendor/vendorActions";
import {
  createVtransactionSuccess,
  deleteVtransactionSuccess,
  getVtransactions,
  updateVtransactionSuccess,
} from "./vtransaction/vtransactionActions";

export const filmsMiddleware: Middleware =
  (api: MiddlewareAPI<Dispatch<AnyAction>, unknown>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    const state = store.getState();

    const userServices =
      state.accountKitState.userState.currentUser?.services ?? {};
    const isAdmin = userServices["films"]?.indexOf("admin") >= 0;
    const isSuperAdmin = userServices["films"]?.indexOf("superadmin") >= 0;

    const projectID = state.projectState.selectedProjectID;

    const _getProjectTasks = () => {
      if (projectID && (isAdmin || isSuperAdmin))
        store.dispatch(getProjectTasks(projectID));
    };

    const _getJobs = () => {
      if (projectID && (isAdmin || isSuperAdmin))
        store.dispatch(
          getJobsByFilter({
            filters: JSON.stringify([
              {
                name: "jobs.projectID",
                value: action.payload.projectID,
                comparison: "eq",
              },
            ]),
          })
        );
    };

    if (action.type == updateProjectTypeSuccess) {
      store.dispatch(getProjectTypes());
    }

    if (action.type == getProjectByIdSuccess) {
      _getProjectTasks();
    }

    if (action.type == updateBatchTasksSuccess && projectID) {
      store.dispatch(getProjectById(projectID));
    }

    if (action.type == reconciliationSuccess) {
      _getProjectTasks();
    }

    if (action.type == updateJobTaskSuccess && projectID) {
      store.dispatch(
        getCuesByFilter({
          filters: JSON.stringify([
            {
              name: "cues.projectID",
              value: projectID,
              comparison: "eq",
            },
          ]),
        })
      );
      store.dispatch(getProjectById(projectID));
    }

    if (action.type == setSelectedTalentID) {
      const talentID = action.payload.talentID;
      if (talentID) {
        store.dispatch(getTalentById(talentID));
      }
    }

    if (action.type == updateTalentSuccess) {
      const talentID = action.payload.talentID;
      if (talentID) {
        store.dispatch(getTalentById(talentID));
      }
    }

    if (action.type == getProjectByIdSuccess) {
      _getJobs();
    }

    if (action.type == createProjectTypeSuccess) {
      store.dispatch(getProjectTypeRates(action.payload.projectType.id));
      store.dispatch(getScaleBenefits());
      store.dispatch(getProjectTypes());
      store.dispatch(setSelectedProjectTypeID(action.payload.talent.id));
    }

    if (action.type == updateBenefitSuccess) {
      store.dispatch(getBenefits());
    }

    if (action.type == deleteProjectTypeSuccess) {
      store.dispatch(setTabIndex(ratesTabID, 0));
    }

    if (action.type == updateScaleSuccess) {
      const tabIndex = state.appStatusState.tabIndexes[ratesTabID] ?? 0;
      const projectTypes = projectTypesSelector(state);
      const selectedProjectType = projectTypes[tabIndex];
      store.dispatch(getScales());
      if (selectedProjectType)
        store.dispatch(getProjectTypeRates(selectedProjectType.id));
    }

    if (action.type == updateBudgetSuccess) {
      store.dispatch(getBudgetById(action.payload.budgetID));
    }

    if (action.type == createTalentSuccess) {
      store.dispatch(getTalents());
      store.dispatch(setSelectedTalentID(action.payload.talent.id));
    }

    if (action.type == createProjectSuccess) {
      store.dispatch(showConfetti());
      store.dispatch(getProjects());
    }

    if (action.type == createBudgetSuccess && projectID) {
      store.dispatch(getProjectById(projectID));
    }

    if (action.type == deleteTalentSuccess) {
      store.dispatch(getTalents());
    }

    if (action.type == createPositionSuccess) {
      store.dispatch(getPositions());
      store.dispatch(setSelectedPositionID(action.payload.talent.id));
    }

    if (action.type == deletePositionSuccess) {
      store.dispatch(getPositions());
    }

    if (action.type == createScaleSuccess) {
      store.dispatch(getScales());
      store.dispatch(setSelectedScaleID(action.payload.talent.id));
    }

    if (action.type == deleteScaleSuccess) {
      store.dispatch(getScales());
    }

    if (action.type == createBenefitSuccess) {
      store.dispatch(getBenefits());
      store.dispatch(setSelectedBenefitID(action.payload.talent.id));
    }

    if (action.type == deleteBenefitSuccess) {
      store.dispatch(getBenefits());
    }

    if (action.type == deleteProjectTypeSuccess) {
      store.dispatch(getProjectTypes());
    }

    if (action.type == createJobCategorySuccess) {
      store.dispatch(getJobCategories());
      store.dispatch(setSelectedJobCategoryID(action.payload.talent.id));
    }

    if (action.type == deleteJobCategorySuccess) {
      store.dispatch(getJobCategories());
    }

    if (action.type == getProjectByIdSuccess) {
      const budgets = action.payload.project.budgets ?? [];
      if (isAdmin || isSuperAdmin) {
        budgets.forEach((b: Budget_Entity) => {
          store.dispatch(getBudgetById(b.id));
        });
      }
    }

    if (action.type == setSelectedProjectID) {
      const projectID = action.payload.projectID;
      if (projectID) {
        store.dispatch(getProjectById(projectID));
        store.dispatch(
          getCuesByFilter({
            filters: JSON.stringify([
              {
                name: "cues.projectID",
                value: projectID,
                comparison: "eq",
              },
            ]),
          })
        );
        store.dispatch(getTalents());

        if (isAdmin || isSuperAdmin) {
          store.dispatch(getJobCategories());
          _getProjectTasks();
          store.dispatch(getTaskTypes());
          store.dispatch(getPositions());
          store.dispatch(getVendors());
          store.dispatch(getScales());
          store.dispatch(getProjectTypes());
        }
      }
    }

    if (action.type == updateTaskSuccess) {
      _getProjectTasks();
    }

    if (action.type == createJobSuccess) {
      _getProjectTasks();
    }

    if (
      (action.type == createCueSuccess ||
        action.type == updateCueSuccess ||
        action.type == updateBatchCuesSuccess) &&
      projectID &&
      (isAdmin || isSuperAdmin)
    ) {
      store.dispatch(getProjectById(projectID));
    }

    if (action.type == updateJobTaskSuccess) {
      _getProjectTasks();
    }

    if (action.type == updateProjectSuccess && projectID) {
      store.dispatch(getProjectById(projectID));
    }

    if (
      action.type == createVtransactionSuccess ||
      action.type == updateVtransactionSuccess ||
      action.type == deleteVtransactionSuccess
    ) {
      store.dispatch(getVtransactions());
    }

    if (
      action.type == createCueSuccess ||
      action.type == updateCueSuccess ||
      action.type == updateBatchCuesSuccess ||
      action.type == deleteCueSuccess
    ) {
      store.dispatch(
        getCuesByFilter({
          filters: JSON.stringify([
            {
              name: "cues.projectID",
              value: projectID,
              comparison: "eq",
            },
          ]),
        })
      );
    }

    if (
      action.type == updateTaskSuccess ||
      action.type == updateJobSuccess ||
      action.type == createJobSuccess ||
      action.type == deleteJobSuccess
    ) {
      if (projectID) store.dispatch(getProjectById(projectID));
      const budgetPanelOpen = state.appStatusState.formOpen["budget"] ?? false;
      if (budgetPanelOpen && projectID) {
        const budget = state.projectState.projects[projectID].budgets[0];
        if (budget) store.dispatch(getBudgetById(budget.id));
      }
    }

    if (action.type == createJobSuccess || action.type == deleteJobSuccess) {
      _getJobs();
    }

    if (
      action.type == createVendorSuccess ||
      action.type == updateVendorSuccess ||
      action.type == deleteVendorSuccess
    ) {
      store.dispatch(getVendors());
    }

    if (
      action.type == createExpenseSuccess ||
      action.type == updateExpenseSuccess ||
      action.type == deleteExpenseSuccess
    ) {
      store.dispatch(
        getExpensesByFilter({
          filters: JSON.stringify([
            {
              name: "expenses.projectID",
              value: projectID,
              comparison: "eq",
            },
          ]),
        })
      );
    }

    if (action.type == setTabIndex) {
      queryBuilder.setUrlQuery({ tab: action.payload.tabIndex });
    }

    return next(action);
  };
