import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Checkbox } from "@mui/joy";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Paper,
  Popover,
  Tooltip,
} from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridRenderCellParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useConfirm } from "material-ui-confirm";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import {
  defaultDataGridProps,
  injectDefaultColumnProps,
} from "../../constants";
import { Cue, Cue_Entity } from "../../entities/cue";
import { Talent_Entity } from "../../entities/talent";
import {
  durationFormatter,
  getWidthFromLocalStorage,
  greyStyleForNonEditableCells,
  renderDurationInputCell,
} from "../../helpers/dataGridHelpers";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import {
  deleteCue,
  deleteCueSuccess,
  setBulkSelect,
  setSelectedGridCues,
  updateBatchCues,
  updateCue,
} from "../../redux/cue/cueActions";
import {
  cueBulkSelectSelector,
  cuesByProjectSelector,
  selectedGridCuesSelector,
} from "../../redux/cue/cueSelector";
import {
  projectSelector,
  selectedProjectIDSelector,
  selectedProjectSelector,
} from "../../redux/project/projectSelector";
import { talentsSelector } from "../../redux/talent/talentSelector";
import { useAppSelector } from "../hooks";
import NoRowsOverlay from "../noRowsOverlay/noRowsOverlay";
import { SelectTalentInputCell } from "../selectTalents/selectTalents";
import { CueBatch } from "./cueBatch";
import CueForm from "./cueForm/cueForm";

/**
 * Cue
 *
 * @returns {null} Cue
 */
export default function Cues() {
  const projectID = useAppSelector(selectedProjectIDSelector);
  const project = useAppSelector(projectSelector(projectID));
  const cues = useAppSelector(cuesByProjectSelector(projectID));
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const talents = useAppSelector(talentsSelector);
  const confirm = useConfirm();
  const selectedGridCues = useAppSelector(selectedGridCuesSelector);
  const bulkSelect = useAppSelector(cueBulkSelectSelector);

  const onCueUpdate = async (_newCue: Cue_Entity, _oldCue: Cue_Entity) => {
    return new Promise<Cue_Entity>((resolve) => {
      const body = {};
      const batch = [];
      const cuesMap = arrayToMap(cues);
      for (const key in _newCue) {
        if (Object.prototype.hasOwnProperty.call(_newCue, key)) {
          if (_oldCue[key] !== _newCue[key]) {
            body[key] = _newCue[key];
          }
        }
      }
      if (selectedGridCues?.length) {
        for (const key in selectedGridCues) {
          if (Object.prototype.hasOwnProperty.call(selectedGridCues, key)) {
            const element = selectedGridCues[key];
            batch.push({
              ...cuesMap[element.toString()],
              ...body,
            });
          }
        }
        dispatch(updateBatchCues(batch));
      } else {
        dispatch(updateCue(_newCue.id, _newCue));
      }
      setTimeout(() => {
        resolve({ ..._newCue });
      }, 50);
    });
  };

  const columns = injectDefaultColumnProps([
    {
      field: "id",
      headerName: "ID",
      type: "string",
      sortable: false,
      valueGetter: (p) => cues.indexOf(p.row) + 1,
      width: getWidthFromLocalStorage("cue", "id", 30),
    },
    {
      field: "code",
      headerName: "Cue",
      editable: true,
      sortable: false,
      type: "string",
      width: getWidthFromLocalStorage("cue", "code", 100),
      headerAlign: "center",
    },
    {
      field: "version",
      headerName: "Ver.",
      editable: true,
      sortable: false,
      width: getWidthFromLocalStorage("cue", "version", 80),
      type: "string",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "qualifier",
      headerName: "Qua.",
      editable: true,
      sortable: false,
      width: getWidthFromLocalStorage("cue", "qualifier", 60),
      type: "string",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "title",
      headerName: "Title",
      editable: true,
      sortable: false,
      width: getWidthFromLocalStorage("cue", "title", 200),
      type: "string",
      headerAlign: "center",
    },
    {
      field: "length",
      headerName: "Duration",
      editable: true,
      sortable: false,
      type: "number",
      width: getWidthFromLocalStorage("cue", "length", 80),
      headerAlign: "center",
      valueFormatter: (p) => durationFormatter(p.value),
      renderEditCell: renderDurationInputCell,
    },
    {
      field: "bars",
      headerName: "Bars",
      editable: true,
      sortable: false,
      type: "number",
      valueSetter: (p) => ({ ...p.row, bars: Number(p.value) }),
      width: getWidthFromLocalStorage("cue", "bars", 100),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lines",
      headerName: "Lines",
      editable: true,
      sortable: false,
      type: "number",
      valueSetter: (p) => ({ ...p.row, lines: Number(p.value) }),
      width: getWidthFromLocalStorage("cue", "lines", 100),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "talentIDs",
      headerName: "Orchestrators",
      editable: true,
      sortable: false,
      width: getWidthFromLocalStorage("cue", "talentIDs", 200),
      headerAlign: "center",
      renderEditCell: (p) => (
        <SelectTalentInputCell
          id={p.id}
          field={p.field}
          value={p.value}
          talents={talents}
        />
      ),
      renderCell: (p) => {
        const _talents: Talent_Entity[] = [];
        (p.value ?? []).forEach((t: number) => {
          const talent = talents.find((e) => e.id === t);
          if (talent) _talents.push(talent);
        });

        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            {_talents
              .sort((a, b) => a.lastName.localeCompare(b.lastName))
              .map((t: Talent_Entity) => {
                return (
                  <Tooltip key={t.id} title={`${t?.firstName} ${t?.lastName}`}>
                    <Avatar
                      sx={{
                        height: 22,
                        width: 22,
                        fontSize: 11,
                        color: "text.primary",
                        background: t.active
                          ? "rgba(33,150,243,0.3)"
                          : "rgba(155,155,155,0.3)",
                      }}
                    >
                      {t?.code}
                    </Avatar>
                  </Tooltip>
                );
              })}
          </Box>
        );
      },
    },
    {
      field: "notes",
      headerName: "Notes",
      editable: true,
      sortable: false,
      type: "string",
      flex: 1,
      width: getWidthFromLocalStorage("cue", "notes", 400),
      headerAlign: "center",
    },
    {
      field: "options",
      headerName: "Options",
      type: "string",
      editable: true,
      sortable: false,
      cellClassName: "actions",
      renderCell: (p: GridRenderCellParams) => (
        <CueOptions
          values={{
            showInContract: p.row.showInContract,
            showInInvoice: p.row.showInInvoice,
            showInMPS: p.row.showInMPS,
            includeInCalculation: p.row.includeInCalculation,
          }}
        />
      ),
      renderEditCell: (p) => <CueOptionsEdit p={p} onCueUpdate={onCueUpdate} />,
      width: 100,
      headerAlign: "center",
    },
    {
      field: "action",
      type: "actions",
      headerName: "Actions",
      width: getWidthFromLocalStorage("cue", "action", 50),
      cellClassName: "actions",
      getActions: ({ row }: { row: Cue_Entity }) => [
        <GridActionsCellItem
          key="delete"
          disabled={project?.locked()}
          icon={<DeleteIcon />}
          label={`Delete`}
          showInMenu
          onClick={() => {
            confirm({
              description: `You are about to delete a cue`,
            })
              .then(() => {
                dispatch(deleteCue(row.id, deleteCueSuccess));
              })
              .catch(() => {
                /* ... */
              });
          }}
          color="inherit"
        />,
      ],
    },
  ]);

  const grid = useMemo(() => {
    return (
      <Paper sx={{ flex: 1 }}>
        <DataGridPro
          {...defaultDataGridProps}
          isCellEditable={(params) =>
            project?.locked() ? false : params.colDef.editable ?? false
          }
          apiRef={apiRef}
          getCellClassName={(p) => {
            let classes = greyStyleForNonEditableCells(p);
            if (project?.locked()) classes += " lock";
            return classes;
          }}
          onColumnWidthChange={(p) =>
            localStorage.setItem(`cue_${p.colDef.field}_width`, `${p.width}`)
          }
          onSelectionModelChange={(p) => dispatch(setSelectedGridCues(p))}
          selectionModel={selectedGridCues ?? []}
          checkboxSelection={bulkSelect}
          processRowUpdate={onCueUpdate}
          rows={cues}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          componentsProps={{
            cell: { tabIndex: 1 },
            footer: { rows: cues },
          }}
          initialState={{
            pinnedColumns: {
              left: [
                GRID_CHECKBOX_SELECTION_COL_DEF.field,
                "id",
                "code",
                "version",
                "qualifier",
              ],
              right: ["options", "action"],
            },
          }}
          components={{
            Toolbar: CueToolbar,
            NoRowsOverlay: NoRowsOverlay,
            Footer: CueFooter,
          }}
        />
        <CueForm />
      </Paper>
    );
  }, [cues, bulkSelect, selectedGridCues]);

  return grid;
}

function CueToolbar() {
  const dispatch = useDispatch();
  const project = useAppSelector(selectedProjectSelector);
  const bulkSelect = useAppSelector(cueBulkSelectSelector);

  const handleNewCue = () => {
    if (!project?.locked()) dispatch(setFormOpen(true, "cueForm"));
  };

  useHotkeys(`ctrl+enter`, handleNewCue, {
    enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
  });

  return (
    <>
      <CueBatch />
      <Box
        className="header"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box>
          <Tooltip title="ctrl + enter">
            <Button
              size="small"
              onClick={handleNewCue}
              disabled={project?.locked()}
            >
              + New Cue
            </Button>
          </Tooltip>
          <Button
            size="small"
            onClick={() => dispatch(setFormOpen(true, "cueBatch"))}
            disabled={project?.locked()}
          >
            <i className="fa-solid fa-line-columns pright"></i> Batch Import
          </Button>
        </Box>
        <Button
          size="small"
          variant={bulkSelect ? "contained" : undefined}
          onClick={() => {
            dispatch(setSelectedGridCues([]));
            dispatch(setBulkSelect(!bulkSelect));
          }}
          disabled={project?.locked()}
        >
          {bulkSelect ? "Done" : "Bulk Edit"}
        </Button>
      </Box>
    </>
  );
}

function CueFooter({ rows }: { rows: Cue_Entity[] }) {
  let totalLength = 0;

  for (const key in rows) {
    if (Object.prototype.hasOwnProperty.call(rows, key)) {
      const row = rows[key];
      totalLength += row.length;
    }
  }
  return (
    <>
      <Divider />
      <Box
        sx={{
          p: 1,
          display: "flex",
          fontWeight: 600,
          gap: 1,
          alignItems: "center",
        }}
      >
        <span>{rows.length} Starts</span>
        <Divider
          sx={{ height: 14 }}
          orientation="vertical"
          flexItem
          variant="middle"
        />
        <span>Total: {durationFormatter(totalLength)}</span>
      </Box>
    </>
  );
}

const options = [
  "showInMPS",
  "showInInvoice",
  "showInContract",
  "includeInCalculation",
];
const labelForOptions = {
  showInMPS: "Show in Music Prep Summary",
  showInInvoice: "Show in individual Invoices",
  showInContract: "Show in the Contract",
  includeInCalculation: "Included in Calculation",
};
const negationLabelForOptions = {
  showInMPS: "NOT showing in Music Prep Summary",
  showInInvoice: "NOT showing in individual Invoices",
  showInContract: "NOT showing in the Contract",
  includeInCalculation: "Excluded from Calculation",
};
const letterForOption = {
  showInMPS: "M",
  showInInvoice: "I",
  showInContract: "C",
  includeInCalculation: "+",
};

function CueOptions({
  values,
}: {
  values: {
    showInMPS: boolean;
    showInInvoice: boolean;
    showInContract: boolean;
    includeInCalculation: boolean;
  };
}) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        width: 100,
        justifyContent: "center",
      }}
    >
      {options.map((o) => {
        const checked = values[o];
        return (
          <Tooltip
            key={o}
            title={!checked ? negationLabelForOptions[o] : labelForOptions[o]}
          >
            <Box
              sx={{
                background: checked ? "rgba(76, 175, 80, 0.3)" : "#ffe082",
                color: checked ? "rgba(76, 175, 80, 1)" : "#f57c00",
                width: 14,
                height: 14,
                borderRadius: 1,
                fontWeight: 800,
                fontSize: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {letterForOption[o]}
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
}

function CueOptionsEdit({
  p,
  onCueUpdate,
}: {
  p: GridRenderCellParams;
  onCueUpdate: (
    _newCue: Cue_Entity,
    _oldCue: Cue_Entity
  ) => Promise<Cue_Entity>;
}) {
  const ref = useRef();
  const cue: Cue = p.row;
  const apiRef = useGridApiContext();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (ref.current) setOpen(true);
  }, [ref]);

  const [values, setValues] = useState({
    showInMPS: cue.showInMPS,
    showInInvoice: cue.showInInvoice,
    showInContract: cue.showInContract,
    includeInCalculation: cue.includeInCalculation,
  });

  const onClose = () => {
    onCueUpdate({ ...p.row, ...values }, { ...p.row });
    apiRef.current.stopCellEditMode({
      id: p.id,
      field: p.field,
      ignoreModifications: true,
    });
  };

  return (
    <>
      <Box ref={ref}>
        <CueOptions values={values} />
      </Box>
      <Popover
        id={"simple-popover"}
        open={open}
        anchorEl={ref.current}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ p: 1, display: "flex", flexDirection: "column", gap: 1 }}>
          {options.map((o) => {
            const checked = values[o];
            return (
              <Checkbox
                sx={{
                  "&:hover": {
                    background: "rgba(155,155,155,0.1)",
                  },
                }}
                variant={checked ? "soft" : "solid"}
                color={checked ? "success" : "warning"}
                size="sm"
                key={o}
                onChange={(e) =>
                  setValues((v) => {
                    const _v = { ...v };
                    _v[o] = e.target.checked;
                    return _v;
                  })
                }
                checked={values[o]}
                label={`${labelForOptions[o]} (${letterForOption[o]})`}
              />
            );
          })}
        </Box>
      </Popover>
    </>
  );
}
