import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectBenefits = (state: AppState) => state.benefitState.benefits;
const selectBenefitSearchMetadata = (state: AppState) =>
  state.benefitState.searchMetadata;

export const benefitsSelector = createSelector([selectBenefits], (benefits) => {
  return mapToArray(benefits);
});

export const benefitsMapSelector = createSelector(
  [selectBenefits],
  (benefits) => {
    return benefits;
  }
);

export const benefitSelector = (id?: number) => {
  return createSelector([selectBenefits], (benefits) => {
    if (id) return benefits[id];
    return undefined;
  });
};

export const benefitSearchMetadataSelector = createSelector(
  [selectBenefitSearchMetadata],
  (s) => s
);
