import { createReducer } from "@reduxjs/toolkit";
import { Scale_Entity } from "../../entities/scale";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteScaleSuccess,
  getScaleByIdSuccess,
  getScalesSearchMetadataSuccess,
  getScalesSuccess,
  setSelectedScaleID,
} from "./scaleActions";
import { ScaleState } from "./scaleState";

function initState(): ScaleState {
  const { scale: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    scales: {},
    searchMetadata: undefined,
    selectedScaleID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const scaleReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedScaleID, (state, action) => {
    state.selectedScaleID = action.payload.scaleID;
    return state;
  });
  builder.addCase(getScalesSuccess, (state, action) => {
    const scales = action.payload.scales;
    state.scales = arrayToMap<Scale_Entity>(scales);
    return state;
  });
  builder.addCase(getScalesSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.scalesSearchMetadata;
    return state;
  });
  builder.addCase(deleteScaleSuccess, (state, action) => {
    const scaleID = action.payload.id;
    if (state.scales[scaleID]) {
      delete state.scales[scaleID];
    }
    return state;
  });
  builder.addCase(getScaleByIdSuccess, (state, action) => {
    const scale = action.payload.scale;
    if (scale) {
      const scales = { ...state.scales };
      scales[scale.id] = scale;
      state.scales = scales;
    }
    return state;
  });
});
