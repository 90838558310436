import { Paper, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { ReactElement, SyntheticEvent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { talentTab } from "../constants";
import { useAppSelector } from "../hooks/hooks";
import RouterBreadcrumbs from "../hooks/routerBreadcrumbs/routerBreadcrumbs";
import FormTalent from "../hooks/talent/talentsList/formTalent";
import TalentLedger from "../hooks/talentLedger/talentLedger";
import { TalentJobs } from "../hooks/talents/talentJobs";
import { TalentTimers } from "../hooks/talents/talentTimers";
import { queryBuilder } from "../network";
import { setTabIndex } from "../redux/appStatus/appStatusActions";
import { tabIndexSelector } from "../redux/appStatus/appStatusSelector";
import { setSelectedTalentID } from "../redux/talent/talentActions";
import { talentSelector } from "../redux/talent/talentSelector";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function TalentRoute(): ReactElement {
  const tabIndex = useAppSelector(tabIndexSelector(talentTab)) ?? 0;
  const dispatch = useDispatch();
  const params = useParams();
  const talentID = parseInt(params.id ?? "");
  const talent = useAppSelector(talentSelector(talentID));

  const handleTabIndexChange = (
    e: SyntheticEvent<Element, Event>,
    v: number
  ) => {
    dispatch(setTabIndex(talentTab, v));
  };

  useEffect(() => {
    dispatch(setSelectedTalentID(talentID));
    const query = queryBuilder.getQuery();
    if (query.tab) {
      dispatch(setTabIndex(talentTab, parseInt(query.tab)));
    }
  }, []);

  function getTabContent() {
    switch (tabIndex) {
      case 0:
        return (
          <Box sx={{ display: "flex", flexGrow: 1 }}>
            <Paper sx={{ maxWidth: 600 }}>
              <FormTalent talent={talent} header={false} />
            </Paper>
          </Box>
        );
      case 2:
        return (
          <Box sx={{ display: "flex", flexGrow: 1 }}>
            <TalentJobs />
          </Box>
        );
      case 3:
        return (
          <Box sx={{ display: "flex", flexGrow: 1 }}>
            <TalentTimers />
          </Box>
        );
      case 4:
        return (
          <Box sx={{ display: "flex", flexGrow: 1 }}>
            <TalentLedger />
          </Box>
        );
      default:
        return <div>Coming very soon 🙂</div>;
    }
  }

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Paper
        sx={(t) => ({
          position: "sticky",
          top: 48,
          borderRadius: 0,
          zIndex: 99,
        })}
      >
        <Box sx={{ p: 1 }}>
          <RouterBreadcrumbs
            onMoveBack={() => dispatch(setSelectedTalentID())}
            last={`${talent?.firstName} ${talent?.lastName}`}
          />
        </Box>
        <Tabs
          value={tabIndex}
          onChange={handleTabIndexChange}
          aria-label="basic tabs example"
        >
          <Tab value={0} label="Info" />
          <Tab value={2} label="Invoices" />
          <Tab value={3} label="Timers" />
          <Tab value={4} label="Virtual Ledger" />
        </Tabs>
      </Paper>
      <Box sx={{ p: 2, flexGrow: 1, display: "flex" }}>{getTabContent()}</Box>
    </Box>
  );
}
