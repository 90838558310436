/**
 * JobCategory_Entity type guard.
 *
 * @param {any} jobCategoryJson JobCategory object from API
 * @returns {boolean} Return true if type is JobCategory_Entity
 */
function validator(
  jobCategoryJson: unknown
): jobCategoryJson is JobCategory_Entity {
  if (typeof jobCategoryJson === "object" && jobCategoryJson != null) {
    JobCategory_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(jobCategoryJson).includes(element))
        throw new Error(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class JobCategory_Entity {
  static requiredFields = ["id", "name", "createdAt", "updatedAt"];

  constructor(jobCategoryJson: unknown) {
    if (validator(jobCategoryJson)) {
      this.id = jobCategoryJson.id;
      this.name = jobCategoryJson.name;
      this.createdAt = jobCategoryJson.createdAt;
      this.updatedAt = jobCategoryJson.updatedAt;
    } else
      throw new Error(
        `Failed to create new instance of ${JobCategory_Entity.name}`
      );
  }

  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}
