import { createReducer } from "@reduxjs/toolkit";
import { ProjectTask_Entity } from "../../entities/projectTask";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteProjectTaskSuccess,
  getProjectTaskByIdSuccess,
  getProjectTasksSearchMetadataSuccess,
  getProjectTasksSuccess,
  setSelectedProjectTaskID,
} from "./projectTaskActions";
import { ProjectTaskState } from "./projectTaskState";

function initState(): ProjectTaskState {
  const { projectTask: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    projectTasks: {},
    projectTasksByProject: {},
    searchMetadata: undefined,
    selectedProjectTaskID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const projectTaskReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedProjectTaskID, (state, action) => {
    state.selectedProjectTaskID = action.payload.projectTaskID;
    return state;
  });
  builder.addCase(getProjectTasksSuccess, (state, action) => {
    const projectTasks = action.payload.projectTasks;
    const projectID = action.payload.projectID;
    state.projectTasks = arrayToMap<ProjectTask_Entity>(projectTasks);

    const _projectTasksByProject = {};

    for (const key in projectTasks) {
      if (Object.prototype.hasOwnProperty.call(projectTasks, key)) {
        const projectTask = projectTasks[key];
        if (_projectTasksByProject[projectID]) {
          _projectTasksByProject[projectID].push(projectTask);
        } else {
          _projectTasksByProject[projectID] = [projectTask];
        }
      }
    }

    state.projectTasksByProject = {
      ...state.projectTasksByProject,
      ..._projectTasksByProject,
    };

    return state;
  });
  builder.addCase(getProjectTasksSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.projectTasksSearchMetadata;
    return state;
  });
  builder.addCase(deleteProjectTaskSuccess, (state, action) => {
    const projectTaskID = action.payload.id;
    if (state.projectTasks[projectTaskID]) {
      delete state.projectTasks[projectTaskID];
    }
    return state;
  });
  builder.addCase(getProjectTaskByIdSuccess, (state, action) => {
    const projectTask = action.payload.projectTask;
    if (projectTask) {
      const projectTasks = { ...state.projectTasks };
      projectTasks[projectTask.id] = projectTask;
      state.projectTasks = projectTasks;
    }
    return state;
  });
});
