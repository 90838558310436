import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import { ReactElement } from "react";
import Positions from "../hooks/positions/positions";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function PositionsRoute(): ReactElement {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        background: "none",
        p: 2,
        flex: 1,
        gap: 2,
      }}
    >
      <Paper sx={{ flex: 1, border: "none", display: "flex" }}>
        <Positions />
      </Paper>
    </Box>
  );
}
