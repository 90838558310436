import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { ProjectType, ProjectType_Entity } from "../../entities/projectType";

export const setSelectedProjectTypeID = createAction(
  "setSelectedProjectTypeID",
  function prepare(projectTypeID?: number) {
    return {
      payload: {
        projectTypeID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getProjectTypes = createAction(
  "films/projectType/getProjectTypes",
  function prepare() {
    const request = new GetAction(`/films/projectTypes`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const projectTypes = ProjectType.fromList(json);
      dispatch(getProjectTypesSuccess(projectTypes));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getProjectTypesSuccess = createAction(
  "films/projectType/getProjectTypeSuccess",
  function prepare(projectTypes: ProjectType[]) {
    return {
      payload: {
        projectTypes,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getProjectTypesByFilter = createAction(
  "films/projectType/getProjectTypesByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/projectTypes`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const projectTypes = ProjectType.fromList(json);
      dispatch(getProjectTypesByFilterSuccess(projectTypes));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getProjectTypesByFilterSuccess = createAction(
  "films/projectType/getProjectTypesByFilterSuccess",
  function prepare(projectTypes: ProjectType[]) {
    return {
      payload: {
        projectTypes,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getProjectTypeById = createAction(
  "films/projectType/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/projectTypes/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const projectType = new ProjectType(json);
      dispatch(getProjectTypeByIdSuccess(projectType));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getProjectTypeByIdSuccess = createAction(
  "films/projectType/getProjectTypeByIdSuccess",
  function prepare(projectType?: ProjectType_Entity) {
    return {
      payload: {
        projectType,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteProjectType = createAction(
  "films/removeProjectTypeById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/films/projectTypes/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteProjectTypeSuccess = createAction(
  "films/projectTypes/deleteProjectTypeSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateProjectType = createAction(
  "films/projectTypes/updateProjectType",
  function prepare(id: number, body: Partial<ProjectType_Entity>) {
    const request = new PutAction(`/films/projectTypes/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateProjectTypeSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateProjectTypeSuccess = createAction(
  "films/projectTypes/updateProjectTypeSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createProjectType = createAction(
  "films/projectTypes/createProjectType",
  function prepare(body: Partial<ProjectType_Entity>) {
    const request = new PostAction(`/films/projectTypes`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const projectType = new ProjectType(json);
      dispatch(createProjectTypeSuccess(projectType));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createProjectTypeSuccess = createAction(
  "films/projectTypes/createProjectTypeSuccess",
  function prepare(projectType: ProjectType) {
    return {
      payload: {
        projectType,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getProjectTypesSearchMetadata = createAction(
  "films/getProjectTypesSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/projectTypes/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getProjectTypesSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getProjectTypesSearchMetadataSuccess = createAction(
  "films/getProjectTypesSearchMetadataSuccess",
  function prepare(projectTypesSearchMetadata: unknown) {
    return {
      payload: {
        projectTypesSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
