import { currencyFormatter, formatCurrency } from "../../constants";
import { Budget_Entity } from "../budget";
import Project_Entity from "./project";
export default class Project extends Project_Entity {
  static fromList(projectsJSON: unknown): Array<Project> {
    const projects: Project[] = [];
    if (projectsJSON)
      Array.isArray(projectsJSON) &&
        projectsJSON.forEach((projectJSON) => {
          projects.push(new Project(projectJSON));
        });
    return projects;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  sentToClient(): boolean {
    return this.clientInvoiceDate !== "0001-01-01T00:00:00Z";
  }

  reversedDate(): string {
    return "0001-01-01T00:00:00Z";
  }

  paidByClient(): boolean {
    return this.clientPaymentDate !== "0001-01-01T00:00:00Z";
  }

  sentToEmployees(): boolean {
    return this.employeesInvoiceDate !== "0001-01-01T00:00:00Z";
  }

  employeesPaid(): boolean {
    return this.employeesPaymentDate !== "0001-01-01T00:00:00Z";
  }

  suppliesPaid(): boolean {
    return this.suppliesPaymentDate !== "0001-01-01T00:00:00Z";
  }

  locked(): boolean {
    // return true;
    return this.status != "Active";
  }

  // totalLibrarianDollar(): string {
  //   return formatCurrency((this.labor.librarian ?? 0) / 10000);
  // }

  // subTotalPrepDollar(): string {
  //   return formatCurrency((this.labor.subTotal ?? 0) / 10000);
  // }

  // subTotalDollar(): string {
  //   return formatCurrency((this.labor.subTotal ?? 0) / 10000);
  // }

  // totalVacationDollar(): string {
  //   return formatCurrency((this.labor.vacation ?? 0) / 10000);
  // }

  // totalSupervisionDollar(): string {
  //   return formatCurrency((this.labor.supervision ?? 0) / 10000);
  // }

  // totalPrepDollar(): string {
  //   return formatCurrency((this.labor.total ?? 0) / 10000);
  // }

  // copyistBenefitsDollar(): string {
  //   return formatCurrency((this.labor. ?? 0) / 10000);
  // }

  // totalWithBenefitsAmount(): string {
  //   return formatCurrency(
  //     ((this.copyistBenefits ?? 0) + (this.labor.wages ?? 0)) / 10000
  //   );
  // }

  // totalWithExpensesAmount(): string {
  //   return formatCurrency(
  //     ((this.copyistBenefits ?? 0) +
  //       (this.labor.wages ?? 0) +
  //       (this.totalExpenses ?? 0)) /
  //       10000
  //   );
  // }

  // copyistWagesDollar(): string {
  //   return formatCurrency((this.copyistWages ?? 0) / 10000);
  // }

  // copyistVacationsDollar(): string {
  //   return formatCurrency((this.copyistVacations ?? 0) / 10000);
  // }

  // totalWagesDollar(): string {
  //   return formatCurrency((this.totalWages ?? 0) / 10000);
  // }

  // copyistSubtotalDollar(): string {
  //   return formatCurrency(
  //     ((this.copyistWages ?? 0) + (this.totalLibrarian ?? 0)) / 10000
  //   );
  // }

  // totalOrchestratorDollar(): string {
  //   return formatCurrency((this.totalOrchestrator ?? 0) / 10000);
  // }

  // totalHWDollar(): string {
  //   return formatCurrency((this.totalHW ?? 0) / 10000);
  // }

  // totalPensionDollar(): string {
  //   return formatCurrency((this.totalPension ?? 0) / 10000);
  // }

  // totalExpensesDollar(): string {
  //   return formatCurrency((this.totalExpenses ?? 0) / 10000);
  // }

  // totalStipendDollar(): string {
  //   return formatCurrency((this.totalStipend ?? 0) / 10000);
  // }

  // laborDollar(): string {
  //   return formatCurrency((this.labor.wages ?? 0) / 10000);
  // }

  // grandTotalDollar(): string {
  //   return formatCurrency((this.grandTotal ?? 0) / 10000);
  // }

  // subTotalPrepBudgetDiff(budget: Budget_Entity): string {
  //   return formatCurrency(
  //     ((budget?.subTotalPrep ?? 0) - (this?.copyistWages ?? 0)) / 10000
  //   );
  // }

  // subTotalNoLibDiffDollar(): string {
  //   return formatCurrency(
  //     ((this?.copyistWages ?? 0) - (this?.subTotal ?? 0)) / 10000
  //   );
  // }

  // subTotalDiff(budget: Budget_Entity): string {
  //   return formatCurrency(
  //     ((budget?.subTotal ?? 0) - (this?.subTotal ?? 0)) / 10000
  //   );
  // }

  // supervisionDiff(budget: Budget_Entity): string {
  //   return formatCurrency(
  //     ((budget?.totalSupervision ?? 0) - (this?.totalSupervision ?? 0)) / 10000
  //   );
  // }

  // totalPrepDiff(budget: Budget_Entity): string {
  //   return formatCurrency(
  //     ((budget?.totalPrep ?? 0) - (this?.totalPrep ?? 0)) / 10000
  //   );
  // }

  // totalWagesDiff(budget: Budget_Entity): string {
  //   return formatCurrency(
  //     ((budget?.totalWages ?? 0) - (this?.totalWages ?? 0)) / 10000
  //   );
  // }

  // laborDiff(budget: Budget_Entity): string {
  //   return formatCurrency(
  //     ((budget?.totalWages ?? 0) - (this?.labor.wages ?? 0)) / 10000
  //   );
  // }

  // totalBudgetOrchestratorDiff(budget: Budget_Entity): string {
  //   return formatCurrency(
  //     ((budget?.totalOrchestrator ?? 0) - (this?.totalOrchestrator ?? 0)) /
  //       10000
  //   );
  // }

  // totalOrchestratorDiff(): string {
  //   return formatCurrency(
  //     ((this?.totalOrchestrator ?? 0) - (this?.totalOrchestrator ?? 0)) / 10000
  //   );
  // }

  // totalLibrarianDiff(): string {
  //   return "?";
  //   // return formatCurrency(
  //   //   ((this?.totalLibrarian ?? 0) - (this?.totalLibrarian ?? 0)) / 10000
  //   // );
  // }

  // grandTotalDiff(budget: Budget_Entity): string {
  //   return formatCurrency(
  //     ((budget?.grandTotal ?? 0) - (this?.grandTotal ?? 0)) / 10000
  //   );
  // }

  // librarian
  musicPrepLibrarianDollar(): string {
    return formatCurrency((this.musicPrep.librarian ?? 0) / 10000);
  }

  laborLibrarianDollar(): string {
    return formatCurrency((this.labor.librarian ?? 0) / 10000);
  }

  librarianDiffDollar(): string {
    return formatCurrency(
      ((this.musicPrep.librarian ?? 0) - (this.labor.librarian ?? 0)) / 10000
    );
  }

  // subTotalNoLib

  musicPrepSubTotalNoLibDollar(): string {
    return formatCurrency((this.musicPrep.subTotalNoLib ?? 0) / 10000);
  }

  laborSubTotalNoLibDollar(): string {
    return formatCurrency((this.labor.subTotalNoLib ?? 0) / 10000);
  }

  subTotalNoLibDiffDollar(): string {
    return formatCurrency(
      ((this.musicPrep.subTotalNoLib ?? 0) - (this.labor.subTotalNoLib ?? 0)) /
        10000
    );
  }

  // subTotalWithLib

  musicPrepSubTotalWithLibDollar(): string {
    return formatCurrency((this.musicPrep.subTotalWithLib ?? 0) / 10000);
  }

  laborSubTotalWithLibDollar(): string {
    return formatCurrency((this.labor.subTotalWithLib ?? 0) / 10000);
  }

  subTotalWithLibDiffDollar(): string {
    return formatCurrency(
      ((this.musicPrep.subTotalWithLib ?? 0) -
        (this.labor.subTotalWithLib ?? 0)) /
        10000
    );
  }

  subTotalPrepBudgetDiff(budget: Budget_Entity): string {
    return formatCurrency(
      ((budget?.subTotalNoLibCopyist ?? 0) - (this?.labor.subTotalNoLib ?? 0)) /
        10000
    );
  }

  // subTotal
  musicPrepSubTotalDollar(): string {
    return formatCurrency((this.musicPrep.subTotal ?? 0) / 10000);
  }

  laborSubTotalDollar(): string {
    return formatCurrency((this.labor.subTotal ?? 0) / 10000);
  }

  subTotalDiffDollar(): string {
    return formatCurrency(
      ((this.musicPrep.subTotal ?? 0) - (this.labor.subTotal ?? 0)) / 10000
    );
  }

  // supervision
  musicPrepSupervisionDollar(): string {
    return formatCurrency((this.musicPrep.supervision ?? 0) / 10000);
  }

  laborSupervisionDollar(): string {
    return formatCurrency((this.labor.supervision ?? 0) / 10000);
  }

  supervisionDiffDollar(): string {
    return formatCurrency(
      ((this.musicPrep.supervision ?? 0) - (this.labor.supervision ?? 0)) /
        10000
    );
  }

  // vacation
  musicPrepVacationDollar(): string {
    return formatCurrency((this.musicPrep.vacation ?? 0) / 10000);
  }

  laborVacationDollar(): string {
    return formatCurrency((this.labor.vacation ?? 0) / 10000);
  }

  vacationDiffDollar(): string {
    return formatCurrency(
      ((this.musicPrep.vacation ?? 0) - (this.labor.vacation ?? 0)) / 10000
    );
  }

  // subTotalNoBen
  musicPrepSubTotalNoBenDollar(): string {
    return formatCurrency((this.musicPrep.subTotalNoBen ?? 0) / 10000);
  }

  laborSubTotalNoBenDollar(): string {
    return formatCurrency((this.labor.subTotalNoBen ?? 0) / 10000);
  }

  subTotalNoBenDiffDollar(): string {
    return formatCurrency(
      ((this.musicPrep.subTotalNoBen ?? 0) - (this.labor.subTotalNoBen ?? 0)) /
        10000
    );
  }

  // welfare
  musicPrepWelfareDollar(): string {
    return formatCurrency((this.musicPrep.welfare ?? 0) / 10000);
  }

  laborWelfareDollar(): string {
    return formatCurrency((this.labor.welfare ?? 0) / 10000);
  }

  welfareDiffDollar(): string {
    return formatCurrency(
      ((this.musicPrep.welfare ?? 0) - (this.labor.welfare ?? 0)) / 10000
    );
  }

  // pension
  musicPrepPensionDollar(): string {
    return formatCurrency((this.musicPrep.pension ?? 0) / 10000);
  }

  laborPensionDollar(): string {
    return formatCurrency((this.labor.pension ?? 0) / 10000);
  }

  pensionDiffDollar(): string {
    return formatCurrency(
      ((this.musicPrep.pension ?? 0) - (this.labor.pension ?? 0)) / 10000
    );
  }

  // total
  musicPrepTotalDollar(): string {
    return formatCurrency((this.musicPrep.total ?? 0) / 10000);
  }

  laborTotalDollar(): string {
    return formatCurrency((this.labor.total ?? 0) / 10000);
  }

  totalDiffDollar(): string {
    return formatCurrency(
      ((this.musicPrep.total ?? 0) - (this.labor.total ?? 0)) / 10000
    );
  }

  // supplies
  musicPrepSuppliesDollar(): string {
    return formatCurrency((this.musicPrep.supplies ?? 0) / 10000);
  }

  laborSuppliesDollar(): string {
    return formatCurrency((this.labor.supplies ?? 0) / 10000);
  }

  suppliesDiffDollar(): string {
    return formatCurrency(
      ((this.musicPrep.supplies ?? 0) - (this.labor.supplies ?? 0)) / 10000
    );
  }

  // fox TV Copyist Wages/Supplies
  musicPrepCopysitWagesSuppliesDollar() {
    return formatCurrency(
      ((this.musicPrep.subTotalNoBen ?? 0) + (this.musicPrep.supplies ?? 0)) /
        10000
    );
  }

  laborCopysitWagesSuppliesDollar() {
    return formatCurrency(
      ((this.labor.subTotalNoBen ?? 0) + (this.labor.supplies ?? 0)) / 10000
    );
  }

  copysitWagesSuppliesDiffDollar(): string {
    return formatCurrency(
      ((this.musicPrep.subTotalNoBen ?? 0) + (this.musicPrep.supplies ?? 0)) /
        10000 -
        ((this.labor.subTotalNoBen ?? 0) + (this.labor.supplies ?? 0)) / 10000
    );
  }

  // orchestrationSubTotal
  orchestrationSubTotalDollar(): string {
    return formatCurrency((this.orchestration.subTotal ?? 0) / 10000);
  }

  orchestrationSubTotalDiffDollar(budget?: Budget_Entity): string {
    return formatCurrency(
      ((budget?.subTotalOrchestration ?? 0) -
        (this.orchestration.subTotal ?? 0)) /
        10000
    );
  }

  // orchestrationVacation
  orchestrationVacationDollar(): string {
    return formatCurrency((this.orchestration.vacation ?? 0) / 10000);
  }

  orchestrationVacationDiffDollar(budget?: Budget_Entity): string {
    return formatCurrency(
      ((budget?.vacationOrchestration ?? 0) -
        (this.orchestration.vacation ?? 0)) /
        10000
    );
  }

  // orchestrationSubTotalNoBen
  orchestrationSubTotalNoBenDollar(): string {
    return formatCurrency((this.orchestration.subTotalNoBen ?? 0) / 10000);
  }

  orchestrationSubTotalNoBenDiffDollar(budget?: Budget_Entity): string {
    return formatCurrency(
      ((budget?.subTotalNoBenOrchestration ?? 0) -
        (this.orchestration.subTotalNoBen ?? 0)) /
        10000
    );
  }

  // orchestrationWelfare
  orchestrationWelfareDollar(): string {
    return formatCurrency((this.orchestration.welfare ?? 0) / 10000);
  }

  orchestrationWelfareDiffDollar(budget?: Budget_Entity): string {
    return formatCurrency(
      ((budget?.welfareOrchestration ?? 0) -
        (this.orchestration.welfare ?? 0)) /
        10000
    );
  }

  // orchestrationPension
  orchestrationPensionDollar(): string {
    return formatCurrency((this.orchestration.pension ?? 0) / 10000);
  }

  orchestrationPensionDiffDollar(budget?: Budget_Entity): string {
    return formatCurrency(
      ((budget?.pensionOrchestration ?? 0) -
        (this.orchestration.pension ?? 0)) /
        10000
    );
  }

  // orchestrationTotal
  orchestrationTotalDollar(): string {
    return formatCurrency((this.orchestration.total ?? 0) / 10000);
  }

  orchestrationTotalDiffDollar(budget?: Budget_Entity): string {
    return formatCurrency(
      ((budget?.totalOrchestration ?? 0) - (this.orchestration.total ?? 0)) /
        10000
    );
  }

  // totalWages
  totalWagesDollar(): string {
    return formatCurrency((this.totalWages ?? 0) / 10000);
  }

  totalWagesDiffDollar(budget?: Budget_Entity): string {
    return formatCurrency(
      ((budget?.totalWages ?? 0) - (this.totalWages ?? 0)) / 10000
    );
  }

  // totalBenefits
  totalBenefitsDollar(): string {
    return formatCurrency(
      ((this.totalHW ?? 0) + (this.totalPension ?? 0)) / 10000
    );
  }

  totalBenefitsDiffDollar(budget?: Budget_Entity): string {
    return formatCurrency(
      ((budget?.totalBenefits ?? 0) -
        ((this.totalHW ?? 0) + (this.totalPension ?? 0))) /
        10000
    );
  }

  // totalSupplies
  totalSuppliesDollar(): string {
    return formatCurrency((this.totalExpenses ?? 0) / 10000);
  }

  totalSuppliesDiffDollar(budget?: Budget_Entity): string {
    return formatCurrency(
      ((budget?.totalSupplies ?? 0) - (this.totalExpenses ?? 0)) / 10000
    );
  }

  // totalStipend
  totalStipendDollar(): string {
    return formatCurrency((this.totalStipend ?? 0) / 10000);
  }

  totalStipendDiffDollar(budget?: Budget_Entity): string {
    return formatCurrency(
      ((budget?.totalStipend ?? 0) - (this.totalStipend ?? 0)) / 10000
    );
  }

  // grandTotal
  grandTotalDollar(): string {
    return formatCurrency((this.grandTotal ?? 0) / 10000);
  }

  grandTotalDiffDollar(budget?: Budget_Entity): string {
    return formatCurrency(
      ((budget?.grandTotal ?? 0) - (this.grandTotal ?? 0)) / 10000
    );
  }
}
