import { createReducer } from "@reduxjs/toolkit";
import { ProjectType_Entity } from "../../entities/projectType";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteProjectTypeSuccess,
  getProjectTypeByIdSuccess,
  getProjectTypesSearchMetadataSuccess,
  getProjectTypesSuccess,
  setSelectedProjectTypeID,
} from "./projectTypeActions";
import { ProjectTypeState } from "./projectTypeState";

function initState(): ProjectTypeState {
  const { projectType: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    projectTypes: {},
    searchMetadata: undefined,
    selectedProjectTypeID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const projectTypeReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedProjectTypeID, (state, action) => {
    state.selectedProjectTypeID = action.payload.projectTypeID;
    return state;
  });
  builder.addCase(getProjectTypesSuccess, (state, action) => {
    const projectTypes = action.payload.projectTypes;
    state.projectTypes = arrayToMap<ProjectType_Entity>(projectTypes);
    return state;
  });
  builder.addCase(getProjectTypesSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.projectTypesSearchMetadata;
    return state;
  });
  builder.addCase(deleteProjectTypeSuccess, (state, action) => {
    const projectTypeID = action.payload.id;
    if (state.projectTypes[projectTypeID]) {
      delete state.projectTypes[projectTypeID];
    }
    return state;
  });
  builder.addCase(getProjectTypeByIdSuccess, (state, action) => {
    const projectType = action.payload.projectType;
    if (projectType) {
      const projectTypes = { ...state.projectTypes };
      projectTypes[projectType.id] = projectType;
      state.projectTypes = projectTypes;
    }
    return state;
  });
});
