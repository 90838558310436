/**
 * Vtransaction_Entity type guard.
 *
 * @param {any} vtransactionJson Vtransaction object from API
 * @returns {boolean} Return true if type is Vtransaction_Entity
 */
function validator(
  vtransactionJson: unknown
): vtransactionJson is Vtransaction_Entity {
  if (typeof vtransactionJson === "object" && vtransactionJson != null) {
    Vtransaction_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(vtransactionJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Vtransaction_Entity {
  static requiredFields = [
    "id",
    "talentID",
    "projectID",
    "amount",
    "createdAt",
    "updatedAt",
    "projectName",
  ];

  constructor(vtransactionJson: unknown) {
    if (validator(vtransactionJson)) {
      this.id = vtransactionJson.id;
      this.talentID = vtransactionJson.talentID;
      this.projectID = vtransactionJson.projectID;
      this.amount = vtransactionJson.amount;
      this.notes = vtransactionJson.notes;
      this.createdAt = vtransactionJson.createdAt;
      this.updatedAt = vtransactionJson.updatedAt;
      this.projectName = vtransactionJson.projectName;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Vtransaction_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  talentID: number;
  projectID?: number;
  amount: number;
  notes: string;
  createdAt: string;
  updatedAt: string;
  projectName: string;
}
