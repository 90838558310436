import { fillWithColor, rectangleBorder } from "../../helpers/excelHelpers";

export class Indexes {
  worksheet: any;
  x: number;
  y: number;
  dx: number;
  dy: number;
  maxX: number;
  maxY: number;

  constructor(worksheet: any) {
    this.worksheet = worksheet;
    this.x = 0;
    this.y = 1;
    this.dx = 0;
    this.dy = 0;
    this.maxX = 0;
    this.maxY = 0;
  }

  setX(_x: number) {
    this.x = _x;
    if (_x > this.maxX) this.maxX = _x;
  }

  setY(_y: number) {
    this.y = _y;
    if (_y > this.maxY) this.maxY = _y;
  }

  color(value: string) {
    this.getCell().font = {
      ...this.getCell().font,
      color: value,
    };
  }

  printArea() {
    this.worksheet.pageSetup.printArea = `A1:${alphabet[this.maxX]}${
      this.maxY
    }`;
  }

  centerText() {
    this.getCell().alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: false,
    };
  }

  rightAlign() {
    this.getCell().alignment = {
      vertical: "middle",
      horizontal: "right",
      wrapText: true,
    };
  }

  leftAlign() {
    this.getCell().alignment = {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    };
  }

  getCell() {
    return this.worksheet.getCell(this.cellID());
  }

  get() {
    return this.worksheet.getCell(this.cellID()).value;
  }

  dollar() {
    this.getCell().numFmt = '"$"#,##0.00;[Red]-"$"#,##0.00';
  }

  percentage() {
    this.getCell().numFmt = "0.00%";
  }

  text(value: string | number) {
    this.getCell().value = value;
  }

  rotate() {
    this.getCell().alignment = {
      textRotation: 90,
      vertical: "left",
      horizontal: "center",
    };
  }

  formula(value: string) {
    this.getCell().value = { formula: value };
  }

  richText(value: any) {
    this.getCell().value = { richText: value };
  }

  getRow() {
    return this.y;
  }

  getColumn() {
    return alphabet[this.x];
  }

  nextRow() {
    this.setY(this.y + 1);
  }

  previousRow() {
    if (this.y > 0) this.setY(this.y - 1);
  }

  goToRow(row: number) {
    this.setY(row);
  }

  rowRelativeMove(value: number) {
    this.setY(this.y + value);
  }

  nextColumn() {
    this.setX(this.x + 1);
  }

  previousColumn() {
    if (this.x > 0) this.setX(this.x - 1);
  }

  goToColumn(column: number) {
    this.setX(column);
  }

  columnRelativeMove(value: number) {
    this.setX(this.x + value);
  }

  merge() {
    this.worksheet.mergeCells(this.selectionID());
  }

  height(value: number) {
    this.worksheet.getRow(this.y).height = value;
  }

  width(value: number) {
    this.worksheet.getColumn(alphabet[this.x]).width = value;
  }

  bold() {
    this.getCell().font = {
      ...this.getCell().font,
      bold: true,
    };
  }

  underline() {
    this.getCell().font = {
      ...this.getCell().font,
      underline: true,
    };
  }

  fontSize(value: number) {
    this.getCell().font = {
      ...this.getCell().font,
      size: value,
    };
  }

  fontFamily(name: string) {
    this.getCell().font = {
      ...this.getCell().font,
      name,
    };
  }

  columnFontFamily(name: string) {
    this.worksheet.getColumn(alphabet[this.x]).font = {
      ...this.worksheet.getColumn(alphabet[this.x]).font,
      name,
    };
  }

  columnColor(color: { argb: string }) {
    this.worksheet.getColumn(alphabet[this.x]).font = {
      ...this.worksheet.getColumn(alphabet[this.x]).font,
      color,
    };
  }

  columnFontSize(size: number) {
    this.worksheet.getColumn(alphabet[this.x]).font = {
      ...this.worksheet.getColumn(alphabet[this.x]).font,
      size,
    };
  }

  select(dx: number, dy: number) {
    this.dx = dx;
    this.dy = dy;
  }

  unselect() {
    this.dx = 0;
    this.dy = 0;
  }

  cellID() {
    return `${alphabet[this.x]}${this.y}`;
  }

  relativeCellID(dx: number, dy: number) {
    return `${alphabet[this.x + dx]}${this.y + dy}`;
  }

  selectionID() {
    return `${alphabet[this.x]}${this.y}:${alphabet[this.x + this.dx]}${
      this.y + this.dy
    }`;
  }

  fill(color: string) {
    fillWithColor(this.selectionID(), color, this.worksheet);
  }

  rectangle() {
    rectangleBorder(this.selectionID(), this.worksheet);
  }
}

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
