import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  InputBase,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { currencyFormatter } from "../../constants";
import { Budget_Entity } from "../../entities/budget";
import { JobTask } from "../../entities/jobTask";
import { Reconciliation_Entity } from "../../entities/reconciliation";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { formOpenSelector } from "../../redux/appStatus/appStatusSelector";
import { updateBudget } from "../../redux/budget/budgetActions";
import { budgetForProjectSelector } from "../../redux/budget/budgetSelector";
import { jobsMapSelector } from "../../redux/job/jobSelector";
import { reconciliation } from "../../redux/project/projectActions";
import { selectedProjectIDSelector } from "../../redux/project/projectSelector";
import { projectTasksByProjectSelector } from "../../redux/projectTask/projectTaskSelector";
import { talentsMapSelector } from "../../redux/talent/talentSelector";
import { useAppSelector } from "../hooks";
import { CurrencyTextField } from "../projectBudget/projectBudget";

export default function ReconcileOrchestrator() {
  const open = useAppSelector(formOpenSelector("ReconcileOrchestrator"));
  const projectID = useAppSelector(selectedProjectIDSelector);
  const projectTasks = useAppSelector(projectTasksByProjectSelector(projectID));
  const budget = useAppSelector(budgetForProjectSelector(projectID ?? 0));
  const projectTask = projectTasks.find((p) => p.id === 1);
  const dispatch = useDispatch();
  const jobTasksForFirstCue: JobTask[] =
    projectTask?.cues[0].tasks[0].jobTasks ?? [];
  const jobs = useAppSelector(jobsMapSelector);
  const [totalOrchestrator, setTotalOrchestrator] = useState("");
  const talents = useAppSelector(talentsMapSelector);
  const [mode, setMode] = useState<number | undefined>();
  const [orchestraors, setOrchestrators] = useState<
    {
      name: string;
      amount?: string;
      percentage?: string;
      id: number;
    }[]
  >();

  useEffect(() => {
    if (jobTasksForFirstCue && talents && jobs) {
      const o = [];
      for (const key in jobTasksForFirstCue) {
        if (Object.prototype.hasOwnProperty.call(jobTasksForFirstCue, key)) {
          const v = { name: "", amount: "0", percentage: "0", id: 0 };
          const jobTask = jobTasksForFirstCue[key];

          const job = jobs[jobTask.jobID];
          if (!job) continue;
          const talent = talents[job.talentID];
          v.name = `${talent.firstName} ${talent.lastName}`;
          (v.id = talent.id), o.push(v);
        }
      }
      setOrchestrators(o);
    }
  }, [jobTasksForFirstCue, talents, jobs]);

  useEffect(() => {
    if (budget) {
      setTotalOrchestrator(`${(budget?.totalOrchestration ?? 0) / 10000}`);
    }
  }, [budget]);

  const onClose = () => {
    dispatch(setFormOpen(false, "ReconcileOrchestrator"));
  };

  const save = () => {
    if (!budget) return;
    const body: Partial<Budget_Entity> = {
      totalOrchestration: Math.floor(parseFloat(totalOrchestrator) * 10000),
    };

    dispatch(updateBudget(budget?.id, body));
  };

  const getPercentageRemaining = () => {
    let t = 100;
    orchestraors?.forEach((o) => {
      t -= Math.floor(parseFloat(o.percentage ?? "0"));
    });
    return (
      <Typography
        variant="body2"
        sx={{ color: t === 0 ? "#4caf50" : "#f44336" }}
      >
        <b>{t}%</b>
      </Typography>
    );
  };

  const getAmountRemaining = () => {
    if (!budget) return;
    let t = budget?.totalOrchestration;
    orchestraors?.forEach((o) => {
      t -= Math.floor(parseFloat(o.amount ?? "0") * 10000);
    });
    return (
      <Typography
        variant="body2"
        sx={{ color: t === 0 ? "#4caf50" : "#f44336" }}
      >
        <b>{currencyFormatter.format(t / 10000)}</b>
      </Typography>
    );
  };

  const reconcile = () => {
    if (!projectID) return;
    const rec: Reconciliation_Entity = {
      totalOrchestrator: Math.floor(parseFloat(totalOrchestrator) * 10000),
      orchestratorSplit: mode === 0 ? "percentage" : "fixed",
      orchestratorValues: {},
    };

    orchestraors?.forEach((o) => {
      if (mode === 1) {
        rec.orchestratorValues[`${o.id}`] = Math.floor(
          parseFloat(o.amount ?? "0") * 10000
        );
      }
      if (mode === 0) {
        rec.orchestratorValues[`${o.id}`] = Math.floor(
          parseFloat(o.percentage ?? "0") * 100
        );
      }
    });

    dispatch(reconciliation(projectID, rec));
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <CurrencyTextField
            label="Total Orch"
            onCommit={save}
            value={totalOrchestrator}
            onChange={(e) => setTotalOrchestrator(e.target.value)}
          />
          <Divider />
          <Box>
            <Typography>How do you want to split the amount?</Typography>
            <ButtonGroup size="small">
              <Button
                color={"secondary"}
                variant={mode === 0 ? "contained" : undefined}
                onClick={() => setMode(0)}
                size="small"
              >
                Percentage
              </Button>
              <Button
                color={"secondary"}
                variant={mode === 1 ? "contained" : undefined}
                onClick={() => setMode(1)}
              >
                Fixed Amount
              </Button>
            </ButtonGroup>
          </Box>
          {mode != undefined ? (
            <>
              <Divider />
              <Typography>Set the value for each orchestrators:</Typography>
              {mode === 0 ? (
                <>
                  {orchestraors?.map((o, k) => (
                    <Box
                      key={o.name}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="body2">
                        <b>{o.name.toUpperCase()}</b>
                        <br />
                        <Typography variant="caption">
                          {currencyFormatter.format(
                            (parseInt(totalOrchestrator) *
                              parseFloat(o.percentage ?? "0")) /
                              100
                          )}
                        </Typography>
                      </Typography>
                      <InputBase
                        autoFocus
                        onFocus={(event) => {
                          event.target.select();
                        }}
                        sx={{
                          textAlign: "right",
                          width: 100,
                          fontSize: 14,
                          fontWeight: 600,
                          p: 0,
                          m: 0,
                          color: "#2196f3",
                        }}
                        size="small"
                        onChange={(e) => {
                          const _o = [...orchestraors];
                          _o[k].percentage = e.target.value;
                          setOrchestrators(_o);
                        }}
                        type="number"
                        value={o.percentage}
                        inputProps={{
                          style: {
                            textAlign: "right",
                            color: "#2196f3 !important",
                          },
                        }}
                        endAdornment="%"
                      />
                    </Box>
                  ))}
                </>
              ) : (
                []
              )}
              {mode === 1 ? (
                <>
                  {orchestraors?.map((o, k) => (
                    <CurrencyTextField
                      key={o.name}
                      label={o.name}
                      onCommit={save}
                      value={o.amount ?? "0"}
                      onChange={(e) => {
                        const _o = [...orchestraors];
                        _o[k].amount = e.target.value;
                        setOrchestrators(_o);
                      }}
                    />
                  ))}
                </>
              ) : (
                []
              )}

              <Divider />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body2">
                  <b>REMAINING:</b>
                </Typography>
                <Typography variant="body2">
                  {mode === 0 ? getPercentageRemaining() : getAmountRemaining()}
                </Typography>
              </Box>
            </>
          ) : (
            []
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!mode} onClick={reconcile} variant="contained">
          Reconcile
        </Button>
      </DialogActions>
    </Dialog>
  );
}
