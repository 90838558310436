import { createReducer } from "@reduxjs/toolkit";
import { Talent_Entity } from "../../entities/talent";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteTalentSuccess,
  getTalentByIdSuccess,
  getTalentsSearchMetadataSuccess,
  getTalentsSuccess,
  getTalentTimersSuccess,
  setSelectedTalentID,
  setShowArchived,
} from "./talentActions";
import { TalentState } from "./talentState";

function initState(): TalentState {
  const { talent: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    talents: {},
    searchMetadata: undefined,
    timersPerTalent: {},
    showArchived: false,
    selectedTalentID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const talentReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedTalentID, (state, action) => {
    state.selectedTalentID = action.payload.talentID;
    return state;
  });
  builder.addCase(setShowArchived, (state, action) => {
    state.showArchived = action.payload.showArchived;
    return state;
  });
  builder.addCase(getTalentTimersSuccess, (state, action) => {
    const talentID = action.payload.talentID;
    const timers = action.payload.timers;
    if (talentID && timers) state.timersPerTalent[talentID] = timers;
    return state;
  });
  builder.addCase(getTalentsSuccess, (state, action) => {
    const talents = action.payload.talents;
    state.talents = arrayToMap<Talent_Entity>(talents);
    return state;
  });
  builder.addCase(getTalentsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.talentsSearchMetadata;
    return state;
  });
  builder.addCase(deleteTalentSuccess, (state, action) => {
    const talentID = action.payload.id;
    if (state.talents[talentID]) {
      delete state.talents[talentID];
    }
    return state;
  });
  builder.addCase(getTalentByIdSuccess, (state, action) => {
    const talent = action.payload.talent;
    if (talent) {
      const talents = { ...state.talents };
      talents[talent.id] = talent;
      state.talents = talents;
    }
    return state;
  });
});
