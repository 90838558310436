import { GridSelectionModel } from "@mui/x-data-grid-pro";
import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Task, Task_Entity } from "../../entities/task";

export const getTasksSuccess = createAction(
  "films/task/getTaskSuccess",
  function prepare(tasks: Task[]) {
    return {
      payload: {
        tasks,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setSelectedGridTasks = createAction(
  "setSelectedGridTasks",
  function prepare(selectedGridTasks?: GridSelectionModel) {
    return {
      payload: {
        selectedGridTasks,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setTaskBulkSelect = createAction(
  "setTaskBulkSelect",
  function prepare(bulkSelect: boolean) {
    return {
      payload: {
        bulkSelect,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getTasksByFilterSuccess = createAction(
  "films/task/getTasksByFilterSuccess",
  function prepare(tasks: Task[]) {
    return {
      payload: {
        tasks,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getTaskByIdSuccess = createAction(
  "films/task/getTaskByIdSuccess",
  function prepare(task?: Task) {
    return {
      payload: {
        task,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getTaskById = createAction(
  "films/task/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/tasks/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const task = new Task(json);
      dispatch(getTaskByIdSuccess(task));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getTasks = createAction("films/task/getTasks", function prepare() {
  const request = new GetAction(`/films/tasks`);

  request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
    const tasks = Task.fromList(json);
    dispatch(getTasksSuccess(tasks));
  };

  return {
    payload: {
      id: nanoid(),
      createdAt: new Date().toISOString(),
      request,
    },
  };
});

export const getTasksByFilter = createAction(
  "films/task/getTasksByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/tasks`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const tasks = Task.fromList(json);
      dispatch(getTasksByFilterSuccess(tasks));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getTasksSearchMetadata = createAction(
  "films/getTasksSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/tasks/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getTasksSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getTasksSearchMetadataSuccess = createAction(
  "films/getTasksSearchMetadataSuccess",
  function prepare(tasksSearchMetadata: unknown) {
    return {
      payload: {
        tasksSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const removeTaskById = createAction(
  "films/removeTaskById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/films/tasks/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteTaskSuccess = createAction(
  "films/tasks/deleteTaskSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateTask = createAction(
  "films/tasks/updateTask",
  function prepare(id: number, body: Record<string, unknown>) {
    const request = new PutAction(`/films/tasks/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateTaskSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateBatchTasks = createAction(
  "films/tasks/updateBatchTasks",
  function prepare(body: Partial<Task_Entity>[]) {
    const request = new PutAction(`/films/tasks`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateBatchTasksSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateBatchTasksSuccess = createAction(
  "films/tasks/updateBatchTasksSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateTaskSuccess = createAction(
  "films/tasks/updateTaskSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createTask = createAction(
  "films/tasks/createTask",
  function prepare(body: Record<string, unknown>) {
    const request = new PostAction(`/films/tasks`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const task = new Task(json);
      dispatch(createTaskSuccess(task));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createTaskSuccess = createAction(
  "films/tasks/createTaskSuccess",
  function prepare(task: Task) {
    return {
      payload: {
        task,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
