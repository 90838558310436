import { Button, Checkbox } from "@mui/joy";
import { Box } from "@mui/material";
import {
  DataGridPro,
  GridColumns,
  GridToolbarQuickFilter,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  defaultDataGridProps,
  injectDefaultColumnProps,
} from "../../../constants";
import { Project } from "../../../entities/project";
import {
  setSelectedProjectID,
  setShowArchived,
} from "../../../redux/project/projectActions";
import {
  projectsSelector,
  showArchivedSelector,
} from "../../../redux/project/projectSelector";
import { getProjectTypes } from "../../../redux/projectType/projectTypeActions";
import { projectTypesSelector } from "../../../redux/projectType/projectTypeSelector";
import { useAppSelector } from "../../hooks";
import NoRowsOverlay from "../../noRowsOverlay/noRowsOverlay";

/**
 * Project list component
 *
 * @returns {ReactElement} Users List
 */
export default function ProjectsList(props: ProjectListProps) {
  const showArchived = useAppSelector(showArchivedSelector);
  const projects = useAppSelector(projectsSelector).filter((p) => {
    if (!showArchived) {
      return !p.archived;
    } else return true;
  });
  const projectTypes = useAppSelector(projectTypesSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (projectTypes.length === 0) dispatch(getProjectTypes());
  }, []);

  const apiRef = useGridApiRef();

  const handleClick = (id: number) => {
    navigate(`${id}`);
    dispatch(setSelectedProjectID(id));
  };

  const columns: GridColumns<Project> = [
    {
      field: "id",
      headerName: "ID",
      editable: false,
      type: "string",
      width: 40,
    },
    {
      field: "name",
      headerName: "Name",
      editable: false,
      type: "string",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      type: "string",
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice",
      editable: false,
      type: "string",
      valueFormatter: (p) => `FLMS${p.value}`,
    },
    {
      field: "referenceNumber",
      headerName: "Ref",
      editable: false,
      type: "string",
    },
    {
      field: "projectTypeID",
      headerName: "Type",
      editable: false,
      type: "string",
      width: 140,
      valueFormatter: (p) => projectTypes.find((pt) => pt.id === p.value)?.name,
    },
    {
      field: "createdAt",
      headerName: "Start Date",
      editable: false,
      type: "string",
      valueFormatter: (p: GridValueFormatterParams) =>
        moment(p.value).format("ll"),
    },
  ];

  return (
    <DataGridPro
      {...defaultDataGridProps}
      getCellClassName={(p) => {
        let classes = p.field === "id" ? "cellReadOnly" : "";
        if (p.row.archived) classes += " archived";
        if (p.row.status !== "Active" && p.row.status !== "Archived")
          classes += " lock";
        if (p.row.archived) classes += " archived";
        return classes;
      }}
      apiRef={apiRef}
      rows={projects}
      columns={injectDefaultColumnProps(columns)}
      onRowClick={(p) => handleClick(p.row.id)}
      experimentalFeatures={{ newEditingApi: true }}
      components={{
        Toolbar: ProjectToolbar,
        NoRowsOverlay: NoRowsOverlay,
      }}
      componentsProps={{
        toolbar: { onCreate: props.onCreate },
        cell: { tabIndex: 1 },
      }}
      initialState={{ pinnedColumns: { right: ["action"] } }}
    />
  );
}

function ProjectToolbar(props: { onCreate: () => void }) {
  const dispatch = useDispatch();
  const showArchived = useAppSelector(showArchivedSelector);
  return (
    <Box
      className="header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        gap: 2,
        alignItems: "center",
      }}
    >
      <Button variant="soft" size="sm" onClick={props.onCreate}>
        + New Project
      </Button>
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
      <Checkbox
        size="sm"
        variant="outlined"
        label="Show Archived"
        onChange={(e) => dispatch(setShowArchived(e.target.checked))}
        checked={showArchived}
      ></Checkbox>
    </Box>
  );
}

export type ProjectListProps = {
  onCreate?: () => void;
};
