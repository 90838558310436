import commonEn from "./en/common.json";
import entitiesEn from "./en/entities.json";
import commonFr from "./fr/common.json";
import entitiesFr from "./fr/entities.json";

const translations = {
  en: {
    common: commonEn,
    entities: entitiesEn,
  },
  fr: {
    common: commonFr,
    entities: entitiesFr,
  },
};

export default translations;
