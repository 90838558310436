import ProjectTask_Entity from "./projectTask";
export default class ProjectTask extends ProjectTask_Entity {
  static fromList(projectTasksJSON: unknown): Array<ProjectTask> {
    const projectTasks: ProjectTask[] = [];
    if (projectTasksJSON)
      Array.isArray(projectTasksJSON) &&
        projectTasksJSON.forEach((projectTaskJSON) => {
          projectTasks.push(new ProjectTask(projectTaskJSON));
        });
    return projectTasks;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
