import Rate_Entity from "./rate";
export default class Rate extends Rate_Entity {
  static fromList(ratesJSON: unknown): Array<Rate> {
    const rates: Rate[] = [];
    if (ratesJSON)
      Array.isArray(ratesJSON) &&
        ratesJSON.forEach((rateJSON) => {
          rates.push(new Rate(rateJSON));
        });
    return rates;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
