import { Paper, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { ReactElement, SyntheticEvent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ratesTabID } from "../constants";
import { useAppSelector } from "../hooks/hooks";
import JobCategoryRates from "../hooks/jobCategory/jobCategoryRates/jobCategoryRates";
import { queryBuilder } from "../network";
import { setTabIndex } from "../redux/appStatus/appStatusActions";
import { tabIndexSelector } from "../redux/appStatus/appStatusSelector";
import { getScaleBenefits } from "../redux/benefit/benefitActions";
import { getJobCategories } from "../redux/jobCategory/jobCategoryActions";
import { jobCategoriesSelector } from "../redux/jobCategory/jobCategorySelector";
import { getProjectTypes } from "../redux/projectType/projectTypeActions";
import { projectTypesSelector } from "../redux/projectType/projectTypeSelector";
import { getProjectTypeRates } from "../redux/rate/rateActions";
import { getScales } from "../redux/scale/scaleActions";
import { scalesSelector } from "../redux/scale/scaleSelector";
import { getTaskTypes } from "../redux/taskType/taskTypeActions";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function RatesRoute(): ReactElement {
  const tabIndex = useAppSelector(tabIndexSelector(ratesTabID)) ?? 0;
  const dispatch = useDispatch();
  const jobCategories = useAppSelector(jobCategoriesSelector);

  const projectTypes = useAppSelector(projectTypesSelector);
  const selectedProjectType = projectTypes[tabIndex];
  const scales = useAppSelector(scalesSelector);

  useEffect(() => {
    if (selectedProjectType)
      dispatch(getProjectTypeRates(selectedProjectType.id));
  }, [selectedProjectType]);

  useEffect(() => {
    dispatch(getProjectTypes());
    dispatch(getJobCategories());
    dispatch(getScaleBenefits());
    dispatch(getScales());
    dispatch(getTaskTypes());
    const query = queryBuilder.getQuery();
    if (query.tab) {
      dispatch(setTabIndex(ratesTabID, parseInt(query.tab)));
    }
  }, []);

  const handleTabIndexChange = (
    e: SyntheticEvent<Element, Event>,
    v: number
  ) => {
    dispatch(setTabIndex(ratesTabID, v));
  };

  if (!selectedProjectType) return <div />;

  return (
    <Box
      sx={{ flex: 1, height: "100%", display: "flex", flexDirection: "column" }}
    >
      <Paper
        sx={(t) => ({
          display: "flex",
          position: "sticky",
          top: 48,
          zIndex: 99,
        })}
      >
        <Tabs
          value={tabIndex}
          sx={{ flexGrow: 1 }}
          onChange={handleTabIndexChange}
          aria-label="basic tabs example"
        >
          {jobCategories.map((jc) => (
            <Tab label={jc.name} key={jc.id} />
          ))}
        </Tabs>
      </Paper>
      {projectTypes.length && jobCategories.length && scales.length ? (
        <Box sx={{ p: 2, flex: 1 }}>
          <JobCategoryRates jobCategoryID={jobCategories[tabIndex].id} />
        </Box>
      ) : (
        []
      )}
    </Box>
  );
}
