import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectPositions = (state: AppState) => state.positionState.positions;
const selectPositionSearchMetadata = (state: AppState) =>
  state.positionState.searchMetadata;

export const positionsSelector = createSelector(
  [selectPositions],
  (positions) => {
    return mapToArray(positions).sort((a, b) => a.pos - b.pos);
  }
);

export const positionsMapSelector = createSelector(
  [selectPositions],
  (positions) => {
    return positions;
  }
);

export const positionSelector = (id?: number) => {
  return createSelector([selectPositions], (positions) => {
    if (id) return positions[id];
    return undefined;
  });
};

export const positionSearchMetadataSelector = createSelector(
  [selectPositionSearchMetadata],
  (s) => s
);
