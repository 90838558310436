import { Box, Paper, Typography } from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro";
import {
  KronosDayLine,
  KronosTimerDialog,
  KronosTimerDuration,
  TimersDataGrid,
} from "@nerdjs/kronos-kit";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { getTalentTimers } from "../../redux/talent/talentActions";
import {
  selectedTalentSelector,
  timersForTalentSelector,
} from "../../redux/talent/talentSelector";
import { DateRangePicker } from "../dateRangePicker/dateRangePicker";
import { useAppSelector } from "../hooks";

/**
 *
 * @returns {ReactElement} TalentJobs page
 */
export function TalentTimers() {
  const dispatch = useDispatch();
  const talent = useAppSelector(selectedTalentSelector);
  const timers = useAppSelector(timersForTalentSelector(talent?.id));

  const [date, setDate] = React.useState<DateRange<Moment>>([
    moment().subtract(7, "day"),
    moment(),
  ]);

  useEffect(() => {
    if (talent) dispatch(getTalentTimers(talent.id));
  }, [talent]);

  const timersForRange = useCallback(() => {
    return timers.filter(
      (t) =>
        moment(t.start(date[0]?.format())).isSameOrAfter(date[0], "day") &&
        moment(t.start(date[1]?.format())).isSameOrBefore(date[1], "day")
    );
  }, [date, timers]);

  const duration = () => {
    const _timers = timersForRange();
    let duration = 0;
    _timers.forEach((t) => (duration += t.durationInSeconds()));

    return duration;
  };

  const daysWorkedCount = () => {
    const _timers = timersForRange();
    const days = {};
    _timers.forEach((t) => {
      days[moment(t.start()).format("YYYY/MM/DD")] = true;
    });

    return Object.keys(days).length;
  };

  const projectCount = () => {
    const _timers = timersForRange();
    const projectIDS = {};
    _timers.forEach((t) => {
      projectIDS[t.projectID] = true;
    });

    return Object.keys(projectIDS).length;
  };

  const timerReport = useMemo(() => {
    const reports = [];
    const s = date[0]?.clone().subtract(1, "day");
    const e = date[1]?.clone();
    let count = 0;
    const max = 50;
    if (!s || !e) return <div>Select date range first</div>;
    while (!e.isSame(s, "day") && count <= max) {
      const timersForDate = timers.filter((t) =>
        e.isBetween(moment(t.startDateUTC), moment(t.endDateUTC), "day", "[]")
      );

      reports.push(
        <Box>
          <Typography variant="h6">
            <b>{e.format("ll")}</b>
          </Typography>
          <KronosDayLine date={e.format()} timers={timers} />
          {timersForDate.length ? (
            <TimersDataGrid
              timers={timersForDate}
              searchEnabled={false}
              autoHeight
              header={false}
              forDate={e.format()}
            />
          ) : (
            []
          )}
        </Box>
      );
      e.subtract(1, "day");
      count += 1;
    }

    return (
      <Box sx={{ mt: 1, display: "flex", flexDirection: "column", gap: 4 }}>
        {reports}
      </Box>
    );
  }, [date, timers]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        flex: 1,
        alignItems: "stretch",
      }}
    >
      <DateRangePicker date={date} onChange={(e) => setDate(e)} />
      <Box sx={{ gap: 1, display: "flex" }}>
        {duration() ? (
          <Paper sx={{ p: 1 }}>
            <Typography variant="caption">Total:</Typography>
            <Typography variant="h6">
              <KronosTimerDuration
                color="inherit"
                durationInSeconds={duration()}
              />
            </Typography>
          </Paper>
        ) : (
          []
        )}
        {daysWorkedCount() ? (
          <Paper sx={{ p: 1 }}>
            <Typography variant="caption">
              Avg hours per working day:
            </Typography>
            <Typography variant="h6">
              <KronosTimerDuration
                color="inherit"
                durationInSeconds={duration() / daysWorkedCount()}
              />
            </Typography>
          </Paper>
        ) : (
          []
        )}
        {projectCount() ? (
          <Paper sx={{ p: 1 }}>
            <Typography variant="caption">Worked on:</Typography>
            <Typography variant="h6">
              <b>{projectCount()} projects</b>
            </Typography>
          </Paper>
        ) : (
          []
        )}
      </Box>
      {timerReport}
      <KronosTimerDialog />
    </Box>
  );
}
