import { createReducer } from "@reduxjs/toolkit";
import { Cue_Entity } from "../../entities/cue";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteCueSuccess,
  getCueByIdSuccess,
  getCuesSearchMetadataSuccess,
  getCuesSuccess,
  setBulkSelect,
  setSelectedCueID,
  setSelectedGridCues,
} from "./cueActions";
import { CueState } from "./cueState";

function initState(): CueState {
  const { cue: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    cues: {},
    cuesByProject: {},
    bulkSelect: false,
    selectedGridCues: undefined,
    searchMetadata: undefined,
    selectedCueID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const cueReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedCueID, (state, action) => {
    state.selectedCueID = action.payload.cueID;
    return state;
  });
  builder.addCase(setSelectedGridCues, (state, action) => {
    state.selectedGridCues = action.payload.selectedGridCues;
    return state;
  });
  builder.addCase(setBulkSelect, (state, action) => {
    const bulkSelect = action.payload.bulkSelect;

    state.bulkSelect = bulkSelect;
    if (!bulkSelect) state.selectedGridCues = undefined;
    return state;
  });
  builder.addCase(getCuesSuccess, (state, action) => {
    const cues = action.payload.cues;
    state.cues = arrayToMap<Cue_Entity>(cues);

    const _cuesByProject = {};

    for (const key in cues) {
      if (Object.prototype.hasOwnProperty.call(cues, key)) {
        const cue = cues[key];
        if (_cuesByProject[cue.projectID]) {
          _cuesByProject[cue.projectID].push(cue);
        } else {
          _cuesByProject[cue.projectID] = [cue];
        }
      }
    }

    state.cuesByProject = { ...state.cuesByProject, ..._cuesByProject };

    return state;
  });
  builder.addCase(getCuesSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.cuesSearchMetadata;
    return state;
  });
  builder.addCase(deleteCueSuccess, (state, action) => {
    const cueID = action.payload.id;
    if (state.cues[cueID]) {
      delete state.cues[cueID];
    }
    return state;
  });
  builder.addCase(getCueByIdSuccess, (state, action) => {
    const cue = action.payload.cue;
    if (cue) {
      const cues = { ...state.cues };
      cues[cue.id] = cue;
      state.cues = cues;
    }
    return state;
  });
});
