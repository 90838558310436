import ProjectType_Entity from "./projectType";
export default class ProjectType extends ProjectType_Entity {
  static fromList(projectTypesJSON: unknown): Array<ProjectType> {
    const projectTypes: ProjectType[] = [];
    if (projectTypesJSON)
      Array.isArray(projectTypesJSON) &&
        projectTypesJSON.forEach((projectTypeJSON) => {
          projectTypes.push(new ProjectType(projectTypeJSON));
        });
    return projectTypes;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
