import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Button } from "@mui/joy";
import { Box, Divider, Paper, Tooltip } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Dispatch } from "@reduxjs/toolkit";
import { ConfirmOptions, useConfirm } from "material-ui-confirm";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import {
  defaultDataGridProps,
  injectDefaultColumnProps,
} from "../../constants";
import { Position_Entity } from "../../entities/position";
import {
  createPosition,
  deletePosition,
  deletePositionSuccess,
  getPositions,
  updatePosition,
} from "../../redux/position/positionActions";
import { positionsSelector } from "../../redux/position/positionSelector";
import { selectedProjectIDSelector } from "../../redux/project/projectSelector";
import { useAppSelector } from "../hooks";
import {
  getterDivider,
  setterDivider,
} from "../jobCategory/jobCategoryRates/dataGridColDef";
import NoRowsOverlay from "../noRowsOverlay/noRowsOverlay";

/**
 * Position
 *
 * @returns {null} Position
 */
export default function Positions() {
  const positions = useAppSelector(positionsSelector);
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const confirm = useConfirm();

  useEffect(() => {
    if (positions.length === 0) {
      dispatch(getPositions());
    }
  }, []);

  const onPositionUpdate = async (_newPosition: Position_Entity) => {
    return new Promise<Position_Entity>((resolve) => {
      dispatch(updatePosition(_newPosition.id, _newPosition));
      setTimeout(() => {
        resolve({ ..._newPosition });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1 }}>
      <DataGridPro
        {...defaultDataGridProps}
        apiRef={apiRef}
        processRowUpdate={onPositionUpdate}
        rows={positions}
        columns={positionColumns(dispatch, confirm)}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: PositionToolbar,
          NoRowsOverlay: NoRowsOverlay,
        }}
        componentsProps={{
          cell: { tabIndex: 1 },
        }}
      />
    </Paper>
  );
}

function PositionToolbar() {
  const projectID = useAppSelector(selectedProjectIDSelector);
  const dispatch = useDispatch();

  const handleNewPosition = () => {
    const newPosition = {
      projectID: projectID,
      name: "",
      share: 0,
      id: -1,
    };
    dispatch(createPosition(newPosition));
  };

  useHotkeys(`ctrl+enter`, handleNewPosition, {
    enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
  });

  return (
    <Box className="header" sx={{ display: "flex", p: 1 }}>
      <Tooltip title="ctrl + enter">
        <Button variant="soft" size="sm" onClick={handleNewPosition}>
          + New Position
        </Button>
      </Tooltip>
    </Box>
  );
}

const positionColumns = (
  dispatch: Dispatch,
  confirm: (options?: ConfirmOptions | undefined) => Promise<void>
): GridColumns<any> =>
  injectDefaultColumnProps([
    {
      field: "id",
      headerName: "ID",
      editable: false,
      type: "string",
      width: 40,
    },
    {
      field: "name",
      headerName: "Name",
      editable: true,
      flex: 1,
      type: "string",
    },
    {
      field: "pos",
      headerName: "Order",
      editable: true,
      width: 90,
      type: "number",
    },
    {
      field: "share",
      headerName: "Share",
      editable: true,
      width: 90,
      type: "number",
      valueGetter: getterDivider("share", 100),
      valueSetter: setterDivider("share", 100),
      valueFormatter: (p: any) => `${p.value}%`,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (props: { id: any }) => [
        <GridActionsCellItem
          key="delete"
          icon={<DeleteIcon />}
          label={`Delete`}
          onClick={() => {
            confirm({
              description: `You are about to delete a position`,
            })
              .then(() => {
                dispatch(deletePosition(props.id, deletePositionSuccess));
              })
              .catch(() => {
                /* ... */
              });
          }}
          color="inherit"
        />,
      ],
    },
  ]);
