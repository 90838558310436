import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Job, Job_Entity } from "../../entities/job";

export const setSelectedJobID = createAction(
  "setSelectedJobID",
  function prepare(jobID?: number) {
    return {
      payload: {
        jobID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJobs = createAction("films/job/getJobs", function prepare() {
  const request = new GetAction(`/films/jobs`);

  request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
    const jobs = Job.fromList(json);
    dispatch(getJobsSuccess(jobs));
  };

  return {
    payload: {
      id: nanoid(),
      createdAt: new Date().toISOString(),
      request,
    },
  };
});

export const getJobsSuccess = createAction(
  "films/job/getJobSuccess",
  function prepare(jobs: Job[]) {
    return {
      payload: {
        jobs,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJobsByFilter = createAction(
  "films/job/getJobsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/jobs`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const jobs = Job.fromList(json);
      dispatch(getJobsSuccess(jobs));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJobsByFilterSuccess = createAction(
  "films/job/getJobsByFilterSuccess",
  function prepare(jobs: Job[]) {
    return {
      payload: {
        jobs,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJobById = createAction(
  "films/job/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/jobs/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const job = new Job(json);
      dispatch(getJobByIdSuccess(job));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJobByIdSuccess = createAction(
  "films/job/getJobByIdSuccess",
  function prepare(job?: Job_Entity) {
    return {
      payload: {
        job,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteJob = createAction(
  "films/removeJobById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/films/jobs/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteJobSuccess = createAction(
  "films/jobs/deleteJobSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateJob = createAction(
  "films/jobs/updateJob",
  function prepare(id: number, body: Partial<Job_Entity>) {
    const request = new PutAction(`/films/jobs/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateJobSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateJobSuccess = createAction(
  "films/jobs/updateJobSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createJob = createAction(
  "films/jobs/createJob",
  function prepare(body: Partial<Job_Entity>) {
    const request = new PostAction(`/films/jobs`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const job = new Job(json);
      dispatch(createJobSuccess(job));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createJobSuccess = createAction(
  "films/jobs/createJobSuccess",
  function prepare(job: Job) {
    return {
      payload: {
        job,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJobsSearchMetadata = createAction(
  "films/getJobsSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/jobs/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getJobsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJobsSearchMetadataSuccess = createAction(
  "films/getJobsSearchMetadataSuccess",
  function prepare(jobsSearchMetadata: unknown) {
    return {
      payload: {
        jobsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
