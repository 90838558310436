import { createReducer } from "@reduxjs/toolkit";
import { Project_Entity } from "../../entities/project";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteProjectSuccess,
  getProjectByIdSuccess,
  getProjectsSearchMetadataSuccess,
  getProjectsSuccess,
  getProjectTimersSuccess,
  setSelectedProjectID,
  setShowArchived,
} from "./projectActions";
import { ProjectState } from "./projectState";

function initState(): ProjectState {
  const { project: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    projects: {},
    searchMetadata: undefined,
    timersPerProject: {},
    showArchived: false,
    selectedProjectID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const projectReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedProjectID, (state, action) => {
    state.selectedProjectID = action.payload.projectID;
    return state;
  });
  builder.addCase(getProjectTimersSuccess, (state, action) => {
    const projectID = action.payload.projectID;
    const timers = action.payload.timers;
    if (projectID && timers) state.timersPerProject[projectID] = timers;
    return state;
  });
  builder.addCase(setShowArchived, (state, action) => {
    state.showArchived = action.payload.showArchived;
    return state;
  });
  builder.addCase(getProjectsSuccess, (state, action) => {
    const projects = action.payload.projects;
    state.projects = arrayToMap<Project_Entity>(projects);
    return state;
  });
  builder.addCase(getProjectsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.projectsSearchMetadata;
    return state;
  });
  builder.addCase(deleteProjectSuccess, (state, action) => {
    const projectID = action.payload.id;
    if (state.projects[projectID]) {
      delete state.projects[projectID];
    }
    return state;
  });
  builder.addCase(getProjectByIdSuccess, (state, action) => {
    const project = action.payload.project;
    if (project) {
      const projects = { ...state.projects };
      projects[project.id] = project;
      state.projects = projects;
    }
    return state;
  });
});
