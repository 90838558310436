export const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export function getInitial(name: string) {
  return name
    .split(" ")
    .map((n) => n[0])
    .join(".");
}

export function rectangleBorder(boundaries: string, worksheet: any) {
  const split = boundaries.split(":");
  const xs = alphabet.indexOf(split[0][0].toUpperCase());
  const xe = alphabet.indexOf(split[1][0].toUpperCase());
  const ys = parseInt(split[0].substring(1, split[0].length));
  const ye = parseInt(split[1].substring(1, split[0].length));

  for (let x = xs; x <= xe; x++) {
    worksheet.getCell(`${alphabet[x]}${ys}`).border = {
      ...worksheet.getCell(`${alphabet[x]}${ys}`).border,
      top: { style: "thin" },
    };
    worksheet.getCell(`${alphabet[x]}${ye}`).border = {
      ...worksheet.getCell(`${alphabet[x]}${ye}`).border,
      bottom: { style: "thin" },
    };
    if (x == xs || x == xe) {
      for (let y = ys; y <= ye; y++) {
        const cellBorders = worksheet.getCell(`${alphabet[x]}${y}`).border;
        worksheet.getCell(`${alphabet[x]}${y}`).border = {
          ...cellBorders,
          left: x === xs ? { style: "thin" } : undefined,
          right: x === xe ? { style: "thin" } : undefined,
        };
      }
    }
  }
}

export function fillWithColor(
  boundaries: string,
  color: string,
  worksheet: any
) {
  const split = boundaries.split(":");
  const xs = alphabet.indexOf(split[0][0].toUpperCase());
  const xe = alphabet.indexOf(split[1][0].toUpperCase());
  const ys = parseInt(split[0].substring(1, split[0].length));
  const ye = parseInt(split[1].substring(1, split[0].length));

  for (let x = xs; x <= xe; x++) {
    for (let y = ys; y <= ye; y++) {
      worksheet.getCell(`${alphabet[x]}${y}`).fill = {
        ...worksheet.getCell(`${alphabet[x]}${y}`).fill,
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color },
      };
    }
  }
}

export function setFontFor(
  boundaries: string,
  font: { size?: number; bold?: boolean; name?: string },
  worksheet: any
) {
  const split = boundaries.split(":");
  const xs = alphabet.indexOf(split[0][0].toUpperCase());
  const xe = alphabet.indexOf(split[1][0].toUpperCase());
  const ys = parseInt(split[0].substring(1, split[0].length));
  const ye = parseInt(split[1].substring(1, split[0].length));

  for (let x = xs; x <= xe; x++) {
    for (let y = ys; y <= ye; y++) {
      worksheet.getCell(`${alphabet[x]}${y}`).font = {
        ...worksheet.getCell(`${alphabet[x]}${y}`).font,
        ...font,
      };
    }
  }
}
