/**
 * Position_Entity type guard.
 *
 * @param {any} positionJson Position object from API
 * @returns {boolean} Return true if type is Position_Entity
 */
function validator(positionJson: unknown): positionJson is Position_Entity {
  if (typeof positionJson === "object" && positionJson != null) {
    Position_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(positionJson).includes(element))
        throw new Error(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class Position_Entity {
  static requiredFields = ["id"];

  constructor(positionJson: unknown) {
    if (validator(positionJson)) {
      this.id = positionJson.id;
      this.pos = positionJson.pos;
      this.name = positionJson.name;
      this.share = positionJson.share;
      this.createdAt = positionJson.createdAt;
      this.updatedAt = positionJson.updatedAt;
    } else
      throw new Error(
        `Failed to create new instance of ${Position_Entity.name}`
      );
  }

  id: number;
  pos: number;
  name: string;
  share: number;
  createdAt?: string;
  updatedAt?: string;
}
