import TaskType_Entity from "./taskType";
export default class TaskType extends TaskType_Entity {
  static fromList(taskTypesJSON: unknown): Array<TaskType> {
    const taskTypes: TaskType[] = [];
    if (taskTypesJSON)
      Array.isArray(taskTypesJSON) &&
        taskTypesJSON.forEach((taskTypeJSON) => {
          taskTypes.push(new TaskType(taskTypeJSON));
        });
    return taskTypes;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
