import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectVendors = (state: AppState) => state.vendorState.vendors;

export const vendorsSelector = createSelector([selectVendors], (s) =>
  mapToArray(s)
);

export const vendorsMapSelector = createSelector([selectVendors], (s) => s);

export const vendorSelector = (id?: number) => {
  return createSelector([selectVendors], (vendors) => {
    if (id) return vendors[id];
    return undefined;
  });
};
