import { createReducer } from "@reduxjs/toolkit";
import { Position_Entity } from "../../entities/position";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deletePositionSuccess,
  getPositionByIdSuccess,
  getPositionsSearchMetadataSuccess,
  getPositionsSuccess,
  setSelectedPositionID,
} from "./positionActions";
import { PositionState } from "./positionState";

function initState(): PositionState {
  const { position: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    positions: {},
    searchMetadata: undefined,
    selectedPositionID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const positionReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedPositionID, (state, action) => {
    state.selectedPositionID = action.payload.positionID;
    return state;
  });
  builder.addCase(getPositionsSuccess, (state, action) => {
    const positions = action.payload.positions;
    state.positions = arrayToMap<Position_Entity>(positions);
    return state;
  });
  builder.addCase(getPositionsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.positionsSearchMetadata;
    return state;
  });
  builder.addCase(deletePositionSuccess, (state, action) => {
    const positionID = action.payload.id;
    if (state.positions[positionID]) {
      delete state.positions[positionID];
    }
    return state;
  });
  builder.addCase(getPositionByIdSuccess, (state, action) => {
    const position = action.payload.position;
    if (position) {
      const positions = { ...state.positions };
      positions[position.id] = position;
      state.positions = positions;
    }
    return state;
  });
});
