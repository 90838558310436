import { RateScale } from "../rateScale";

/**
 * Rate_Entity type guard.
 *
 * @param {any} rateJson Rate object from API
 * @returns {boolean} Return true if type is Rate_Entity
 */
function validator(rateJson: unknown): rateJson is Rate_Entity {
  if (typeof rateJson === "object" && rateJson != null) {
    Rate_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(rateJson).includes(element))
        throw new Error(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class Rate_Entity {
  static requiredFields = ["id"];

  constructor(rateJson: unknown) {
    if (validator(rateJson)) {
      this.id = rateJson.id;
      this.name = rateJson.name;
      this.scales = RateScale.fromList(rateJson.scales);
    } else
      throw new Error(`Failed to create new instance of ${Rate_Entity.name}`);
  }

  id?: number;
  name: string;
  scales: RateScale[];
}
