import { Box } from "@mui/system";
import { ReactElement } from "react";
import { Outlet, useParams } from "react-router-dom";
import JobCategories from "../hooks/jobCategories/jobCategories";
import ProjectTypes from "../hooks/projectTypes/projectTypes";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function JobCategoryPage(): ReactElement {
  const params = useParams();

  if (params.id)
    return (
      <Box sx={{ flex: 1, display: "flex" }}>
        <Outlet />
      </Box>
    );
  return (
    <Box sx={{ p: 2, flex: 1, display: "flex" }}>
      <JobCategories />
    </Box>
  );
}
