/**
 * Scale_Entity type guard.
 *
 * @param {any} scaleJson Scale object from API
 * @returns {boolean} Return true if type is Scale_Entity
 */
function validator(scaleJson: unknown): scaleJson is Scale_Entity {
  if (typeof scaleJson === "object" && scaleJson != null) {
    Scale_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(scaleJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Scale_Entity {
  static requiredFields = [
    "id",
    "projectTypeID",
    "taskTypeID",
    "rate",
    "createdAt",
    "updatedAt",
  ];

  constructor(scaleJson: unknown) {
    if (validator(scaleJson)) {
      this.id = scaleJson.id;
      this.projectTypeID = scaleJson.projectTypeID;
      this.taskTypeID = scaleJson.taskTypeID;
      this.rate = scaleJson.rate;
      this.createdAt = scaleJson.createdAt;
      this.updatedAt = scaleJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Scale_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  projectTypeID: number;
  taskTypeID: number;
  rate: number;
  createdAt: string;
  updatedAt: string;
}
