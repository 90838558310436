import { GetAction } from "@nerdjs/nerd-network";
import { createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Rate } from "../../entities/rate";

export const getProjectTypeRates = createAction(
  "films/projectType/rates",
  function prepare(projectTypeID: number) {
    const request = new GetAction(`/films/projectTypes/${projectTypeID}/rates`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const projectTypes = Rate.fromList(json);
      dispatch(getProjectTypeRatesSuccess(projectTypes, projectTypeID));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getProjectTypeRatesSuccess = createAction(
  "films/projectType/ratesSuccess",
  function prepare(rates: Rate[], projectTypeID: number) {
    return {
      payload: {
        projectTypeID,
        rates,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
