import { extendTheme as extendJoyTheme } from "@mui/joy/styles";
import { blue, grey, red } from "@mui/material/colors";
import {
  experimental_extendTheme as extendMuiTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { deepmerge } from "@mui/utils";

export const getMuiTheme = (alpha: boolean) => {
  const muiTheme = extendMuiTheme({
    colorSchemes: {
      light: {
        palette: {
          primary: alpha ? { ...red } : { ...blue },
        },
      },
      dark: {
        palette: {
          primary: alpha ? { ...red } : { ...blue },
        },
      },
    },
    typography: {
      h4: {
        fontWeight: 600,
      },
      button: {
        textTransform: "none",
        fontSize: 14,
      },
      fontFamily:
        'asap, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
  });

  return responsiveFontSizes(muiTheme);
};

export const getJoyTheme = (alpha: boolean) => {
  const muiTheme = getMuiTheme(alpha);

  return extendJoyTheme({
    // This is required to point to `var(--mui-*)` because we are using `CssVarsProvider` from Material UI.
    cssVarPrefix: "mui",
    colorSchemes: {
      light: {
        palette: {
          primary: {
            ...blue,
          },
          neutral: {
            ...grey,
          },
          // Do the same for the `danger`, `info`, `success`, and `warning` palettes,
          divider: "var(--mui-palette-divider)",
          text: {
            tertiary: "rgba(0 0 0 / 0.56)",
          },
        },
      },
      dark: {
        palette: {
          primary: {
            ...blue,
          },
          neutral: {
            ...grey,
          },
          // Do the same for the `danger`, `info`, `success`, and `warning` palettes,
          divider: "var(--mui-palette-divider)",
          text: {
            tertiary: "rgba(0 0 0 / 0.56)",
          },
        },
      },
      // Do the same for dark mode
      // dark: { ... }
    },
    fontFamily: {
      display:
        'asap, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      body: 'asap, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    shadow: {
      xs: `var(--mui-shadowRing), ${muiTheme.shadows[1]}`,
      sm: `var(--mui-shadowRing), ${muiTheme.shadows[2]}`,
      md: `var(--mui-shadowRing), ${muiTheme.shadows[4]}`,
      lg: `var(--mui-shadowRing), ${muiTheme.shadows[8]}`,
      xl: `var(--mui-shadowRing), ${muiTheme.shadows[12]}`,
    },
  });
};

export const getTheme = (alpha: boolean) => {
  const muiTheme = getMuiTheme(alpha);
  const joyTheme = getJoyTheme(alpha);
  const theme = deepmerge(joyTheme, muiTheme);

  return theme;
};
