import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Position, Position_Entity } from "../../entities/position";

export const setSelectedPositionID = createAction(
  "setSelectedPositionID",
  function prepare(positionID?: number) {
    return {
      payload: {
        positionID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getPositions = createAction(
  "films/position/getPositions",
  function prepare() {
    const request = new GetAction(`/films/positions`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const positions = Position.fromList(json);
      dispatch(getPositionsSuccess(positions));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getPositionsSuccess = createAction(
  "films/position/getPositionSuccess",
  function prepare(positions: Position[]) {
    return {
      payload: {
        positions,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getPositionsByFilter = createAction(
  "films/position/getPositionsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/positions`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const positions = Position.fromList(json);
      dispatch(getPositionsByFilterSuccess(positions));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getPositionsByFilterSuccess = createAction(
  "films/position/getPositionsByFilterSuccess",
  function prepare(positions: Position[]) {
    return {
      payload: {
        positions,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getPositionById = createAction(
  "films/position/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/positions/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const position = new Position(json);
      dispatch(getPositionByIdSuccess(position));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getPositionByIdSuccess = createAction(
  "films/position/getPositionByIdSuccess",
  function prepare(position?: Position_Entity) {
    return {
      payload: {
        position,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deletePosition = createAction(
  "films/removePositionById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/films/positions/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deletePositionSuccess = createAction(
  "films/positions/deletePositionSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updatePosition = createAction(
  "films/positions/updatePosition",
  function prepare(id: number, body: Position_Entity) {
    const request = new PutAction(`/films/positions/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updatePositionSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updatePositionSuccess = createAction(
  "films/positions/updatePositionSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createPosition = createAction(
  "films/positions/createPosition",
  function prepare(body: Partial<Position_Entity>) {
    const request = new PostAction(`/films/positions`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const position = new Position(json);
      dispatch(createPositionSuccess(position));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createPositionSuccess = createAction(
  "films/positions/createPositionSuccess",
  function prepare(position: Position) {
    return {
      payload: {
        position,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getPositionsSearchMetadata = createAction(
  "films/getPositionsSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/positions/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getPositionsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getPositionsSearchMetadataSuccess = createAction(
  "films/getPositionsSearchMetadataSuccess",
  function prepare(positionsSearchMetadata: unknown) {
    return {
      payload: {
        positionsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
