import { accountKitReducer, AccountKitState } from "@nerdjs/account-kit";
import {
  kronosMiddleware,
  kronosReducer,
  KronosState,
} from "@nerdjs/kronos-kit";
import {
  networkMiddleware,
  networkReducer,
  NetworkStateExpanded,
} from "@nerdjs/nerd-network";
import { notificationReducer } from "@nerdjs/nerd-ui";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { appStatusReducer } from "./appStatus/appStatusReducer";
import { AppStatusState } from "./appStatus/appStatusState";
import { benefitReducer } from "./benefit/benefitReducer";
import { BenefitState } from "./benefit/benefitState";
import { budgetReducer } from "./budget/budgetReducer";
import { BudgetState } from "./budget/budgetState";
import { cueReducer } from "./cue/cueReducer";
import { CueState } from "./cue/cueState";
import { expenseReducer } from "./expense/expenseReducer";
import { ExpenseState } from "./expense/expenseState";
import { filmsMiddleware } from "./filmsMiddleware";
import { jobReducer } from "./job/jobReducer";
import { JobState } from "./job/jobState";
import { jobCategoryReducer } from "./jobCategory/jobCategoryReducer";
import { JobCategoryState } from "./jobCategory/jobCategoryState";
import { jobTaskReducer } from "./jobTask/jobTaskReducer";
import { JobTaskState } from "./jobTask/jobTaskState";
import { mainMiddleware } from "./mainMiddleware";
import { positionReducer } from "./position/positionReducer";
import { PositionState } from "./position/positionState";
import { projectReducer } from "./project/projectReducer";
import { ProjectState } from "./project/projectState";
import { projectTaskReducer } from "./projectTask/projectTaskReducer";
import { ProjectTaskState } from "./projectTask/projectTaskState";
import { projectTypeReducer } from "./projectType/projectTypeReducer";
import { ProjectTypeState } from "./projectType/projectTypeState";
import { rateReducer } from "./rate/rateReducer";
import { RateState } from "./rate/rateState";
import { scaleReducer } from "./scale/scaleReducer";
import { ScaleState } from "./scale/scaleState";
import { talentReducer } from "./talent/talentReducer";
import { TalentState } from "./talent/talentState";
import { taskReducer } from "./task/taskReducer";
import { TaskState } from "./task/taskState";
import { taskTypeReducer } from "./taskType/taskTypeReducer";
import { TaskTypeState } from "./taskType/taskTypeState";
import { vendorReducer } from "./vendor/vendorReducer";
import { VendorState } from "./vendor/vendorState";
import { vtransactionReducer } from "./vtransaction/vtransactionReducer";
import { VtransactionState } from "./vtransaction/vtransactionState";

export interface AppState {
  accountKitState: AccountKitState;
  networkState: NetworkStateExpanded;
  positionState: PositionState;
  talentState: TalentState;
  benefitState: BenefitState;
  cueState: CueState;
  jobState: JobState;
  jobCategoryState: JobCategoryState;
  projectState: ProjectState;
  projectTaskState: ProjectTaskState;
  projectTypeState: ProjectTypeState;
  scaleState: ScaleState;
  taskState: TaskState;
  vtransactionState: VtransactionState;
  expenseState: ExpenseState;
  taskTypeState: TaskTypeState;
  appStatusState: AppStatusState;
  rateState: RateState;
  vendorState: VendorState;
  budgetState: BudgetState;
  jobTaskState: JobTaskState;
  kronosState: KronosState;
}

export const store = createStore(
  combineReducers({
    // this defines the shape of our MainState
    accountKitState: accountKitReducer,
    notification: notificationReducer,
    appStatusState: appStatusReducer,
    networkState: networkReducer,
    positionState: positionReducer,
    talentState: talentReducer,
    benefitState: benefitReducer,
    projectTaskState: projectTaskReducer,
    cueState: cueReducer,
    jobState: jobReducer,
    jobCategoryState: jobCategoryReducer,
    projectState: projectReducer,
    projectTypeState: projectTypeReducer,
    scaleState: scaleReducer,
    taskState: taskReducer,
    vtransactionState: vtransactionReducer,
    expenseState: expenseReducer,
    taskTypeState: taskTypeReducer,
    rateState: rateReducer,
    vendorState: vendorReducer,
    budgetState: budgetReducer,
    jobTaskState: jobTaskReducer,
    kronosState: kronosReducer,
  }),
  applyMiddleware(
    filmsMiddleware,
    mainMiddleware,
    kronosMiddleware,
    networkMiddleware
  )
);

export type AppDispatch = typeof store.dispatch;
