import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { Cue } from "../../../entities/cue";
import { DurationTextField } from "../../../helpers/formHelpers";
import { setFormOpen } from "../../../redux/appStatus/appStatusActions";
import { formOpenSelector } from "../../../redux/appStatus/appStatusSelector";
import { createCue } from "../../../redux/cue/cueActions";
import { selectedProjectIDSelector } from "../../../redux/project/projectSelector";
import { talentsSelector } from "../../../redux/talent/talentSelector";
import { useAppSelector } from "../../hooks";
import { SelectTalents } from "../../selectTalents/selectTalents";

export default function CueForm() {
  const projectID = useAppSelector(selectedProjectIDSelector);
  const open = useAppSelector(formOpenSelector("cueForm"));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [newCue, setNewCue] = useState<Partial<Cue>>({ projectID });
  const [talentsOpen, setTalentsOpen] = useState(false);
  const dispatch = useDispatch();
  const talents = useAppSelector(talentsSelector);
  const codeRef = useRef<HTMLInputElement>();
  const titleRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!open) {
      setNewCue({
        projectID,
        showInContract: true,
        showInMPS: true,
        showInInvoice: true,
        includeInCalculation: true,
      });
    }
  }, [open]);

  const handleNewCue = () => {
    if (!newCue.code && !newCue.version && !newCue.qualifier) return;
    dispatch(createCue(newCue));
    setSnackbarOpen(true);
    setNewCue((c) => ({
      ...c,
      code: "",
      version: "",
      qualifier: "",
      title: "",
      bars: undefined,
      lines: undefined,
      length: undefined,
      notes: "",
      showInContract: true,
      showInMPS: true,
      showInInvoice: true,
      includeInCalculation: true,
    }));
    codeRef.current?.focus();
  };

  useHotkeys(`enter`, handleNewCue, {
    enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
  });

  const handleChange =
    (name: string, number?: boolean) => (e: ChangeEvent<HTMLInputElement>) => {
      setNewCue((c) => ({
        ...c,
        [name]: number ? Number(e.target.value) : e.target.value,
      }));
    };

  return (
    <Dialog onClose={() => dispatch(setFormOpen(false, "cueForm"))} open={open}>
      <DialogTitle>New Cue</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                autoComplete="off"
                onChange={handleChange("code")}
                label="Cue"
                autoFocus
                size="small"
                fullWidth
                onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                  const pastedValue = e.clipboardData.getData("Text");
                  const split = pastedValue.split(" ");
                  if (split.length >= 2) {
                    e.stopPropagation();
                    e.preventDefault();
                    setNewCue((c) => ({
                      ...c,
                      code: split[0],
                      version: split[1],
                      qualifier: split[2],
                    }));
                    titleRef.current?.focus();
                  }
                }}
                inputRef={codeRef}
                value={newCue.code ?? ""}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoComplete="off"
                onChange={handleChange("version")}
                label="Version"
                placeholder={"X.X.X"}
                size="small"
                fullWidth
                value={newCue.version ?? ""}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoComplete="off"
                onChange={handleChange("qualifier")}
                label="Qualifier"
                size="small"
                fullWidth
                value={newCue.qualifier ?? ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                onChange={handleChange("title")}
                label="Title"
                size="small"
                inputRef={titleRef}
                fullWidth
                value={newCue.title ?? ""}
              />
            </Grid>
            <Grid item xs={4}>
              <DurationTextField
                value={newCue.length ?? 0}
                onChange={(l) => setNewCue((c) => ({ ...c, length: l }))}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoComplete="off"
                onChange={handleChange("bars", true)}
                label="Bars"
                size="small"
                fullWidth
                value={newCue.bars ?? ""}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoComplete="off"
                onChange={handleChange("lines", true)}
                label="Lines"
                size="small"
                fullWidth
                value={newCue.lines ?? ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                onChange={handleChange("notes")}
                label="Notes"
                multiline
                size="small"
                variant="filled"
                fullWidth
                value={newCue.notes ?? ""}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectTalents
                talents={talents}
                open={talentsOpen}
                onChange={(e) => setNewCue((c) => ({ ...c, talentIDs: e }))}
                value={newCue.talentIDs ?? []}
                onClose={() => setTalentsOpen(false)}
              >
                <Button
                  fullWidth
                  onClick={() => setTalentsOpen(true)}
                  variant="outlined"
                >
                  Manage Orchestrators...
                </Button>
              </SelectTalents>
            </Grid>
            {newCue.talentIDs?.map((t) => {
              const talent = talents.find((e) => e.id === t);
              return (
                <Grid item key={t}>
                  <Chip
                    avatar={
                      talent?.code ? <Avatar>{talent?.code}</Avatar> : undefined
                    }
                    label={`${talent?.firstName} ${talent?.lastName}`}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(setFormOpen(false, "cueForm"))}>
          Cancel
        </Button>
        <Tooltip title="Keyboard shortcut: Enter">
          <Button
            disabled={!newCue.code && !newCue.version && !newCue.qualifier}
            variant="contained"
            onClick={handleNewCue}
          >
            Create & Next Cue
          </Button>
        </Tooltip>
      </DialogActions>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="success">Cue has been created</Alert>
      </Snackbar>
    </Dialog>
  );
}
