import { InputBase } from "@mui/material";
import { Box } from "@mui/system";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import moment from "moment";
import { useEffect, useState } from "react";

export function greyStyleForNonEditableCells(
  params: GridCellParams<number>
): string {
  return params.isEditable || params.colDef.type === "actions"
    ? "cell"
    : "cellReadOnly";
}

export function durationFormatter(value: number): string {
  const d = moment.duration(value, "seconds");
  return `${moment.utc(d.as("millisecond")).format("HH:mm:ss")}`;
}

export const durationRegex = /(\d{1,2}:){0,2}\d{1,2}/;

export function durationToSec(value: string): number {
  if (!durationRegex.test(value)) return -1;
  const split = value.split(":");
  const splitN = split
    .map((s) => {
      return parseInt(s);
    })
    .reverse();

  let sec = 0;
  let mutliplicator = 1;
  for (const key in splitN) {
    if (Object.prototype.hasOwnProperty.call(splitN, key)) {
      const v = splitN[key];
      sec += mutliplicator * v;
      mutliplicator = mutliplicator * 60;
    }
  }

  return sec;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const renderDurationInputCell: GridColDef["renderCell"] = (params) => {
  return <DurationInput {...params} />;
};

export const DurationInput = (props: GridRenderCellParams<number>) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const [text, setText] = useState(durationFormatter(value!));

  useEffect(() => {
    const _sec = durationToSec(text);
    if (_sec > -1) handleChange(_sec);
  }, [text]);

  const handleChange = (v: number) => {
    apiRef.current.setEditCellValue({ id, field, value: v });
  };

  const handleRef = (element: HTMLSpanElement) => {
    if (element) {
      const input = element.querySelector<HTMLInputElement>(
        `input[value="${value}"]`
      );
      input?.focus();
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <InputBase
        sx={{ fontSize: 14, textAlign: "right", flexGrow: 1 }}
        placeholder="00:00:00"
        onFocus={(event) => {
          event.target.select();
        }}
        value={text}
        autoFocus
        ref={handleRef}
        onChange={(e) => setText(e.target.value)}
        inputProps={{
          className: "rightAlign",
        }}
        onBlur={() => {
          if (durationToSec(text) == -1) {
            alert("Invalid duration format. Should be like 00:02:30");
          }
        }}
      />
    </Box>
  );
};

export function getWidthFromLocalStorage(
  entityName: string,
  field: string,
  fallback: number
): number {
  const match = localStorage.getItem(`${entityName}_${field}_width`);
  if (match) {
    try {
      return parseFloat(match);
    } catch (error) {
      return fallback;
    }
  }
  return fallback;
}
