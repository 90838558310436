import RateScale_Entity from "./rateScale";
export default class RateScale extends RateScale_Entity {
  static fromList(rateScalesJSON: unknown): Array<RateScale> {
    const rateScales: RateScale[] = [];
    if (rateScalesJSON)
      Array.isArray(rateScalesJSON) &&
        rateScalesJSON.forEach((rateScaleJSON) => {
          rateScales.push(new RateScale(rateScaleJSON));
        });
    return rateScales;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
