import { Button, Dialog, DialogActions, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { defaultButtonProps } from "../constants";
import { Project_Entity } from "../entities/project";
import FormProject from "../hooks/project/projectsForm/projectsForm";
import ProjectsList from "../hooks/project/projectsList/projectsList";
import { createProject, getProjects } from "../redux/project/projectActions";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function ProjectsRoute(): ReactElement {
  const [newProject, setNewProject] = useState<
    Partial<Project_Entity> | undefined
  >();
  const dispatch = useDispatch();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const params = useParams();

  useEffect(() => {
    if (!params.id) dispatch(getProjects());
  }, [params]);

  if (params.id)
    return (
      <Box sx={{ flex: 1, display: "flex" }}>
        <Outlet />
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        height: "100%",
        background: "none",
        p: 2,
        gap: 2,
      }}
    >
      <Paper sx={{ flexGrow: 1, border: "none" }}>
        <ProjectsList onCreate={() => setNewProject({ id: -1, union: true })} />
      </Paper>
      {newProject != undefined ? (
        <Dialog
          fullScreen={sm}
          open={newProject != undefined}
          onClose={() => setNewProject(undefined)}
        >
          <FormProject
            project={newProject}
            onClose={() => setNewProject(undefined)}
            onChange={(t) => {
              setNewProject(t);
            }}
            onSave={(t) => console.log(t)}
          />
          <DialogActions>
            <Button
              {...defaultButtonProps}
              onClick={() => {
                dispatch(createProject(newProject));
                setNewProject(undefined);
              }}
              disabled={
                !newProject.name ||
                !newProject.referenceNumber ||
                !newProject.projectTypeID
              }
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        []
      )}
    </Box>
  );
}
