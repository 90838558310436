export const downloadExcel = (workbook: any, fileName: string) => {
  workbook.xlsx
    .writeBuffer({
      base64: true,
    })
    .then(function (xls64: BlobPart) {
      // build anchor tag and attach file (works in chrome)
      const a = document.createElement("a");
      const data = new Blob([xls64], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(data);
      a.href = url;
      a.download = `${fileName}.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    })
    .catch(function (error: any) {
      console.log(error.message);
    });
};
