/**
 * Vendor_Entity type guard.
 *
 * @param {any} vendorJson Vendor object from API
 * @returns {boolean} Return true if type is Vendor_Entity
 */

/**
 * Vendor_Entity type guard.
 *
 * @param {any} vendorJson Vendor object from API
 * @returns {boolean} Return true if type is Vendor_Entity
 */
function validator(vendorJson: unknown): vendorJson is Vendor_Entity {
  if (typeof vendorJson === "object" && vendorJson != null) {
    Vendor_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(vendorJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Vendor_Entity {
  static requiredFields = [
    "id",
    "name",
    "contact",
    "address",
    "city",
    "state",
    "zipcode",
    "phone",
    "email",
    "kind",
    "createdAt",
    "updatedAt",
  ];

  constructor(vendorJson: unknown) {
    if (validator(vendorJson)) {
      this.id = vendorJson.id;
      this.name = vendorJson.name;
      this.local = vendorJson.local;
      this.contact = vendorJson.contact;
      this.address = vendorJson.address;
      this.city = vendorJson.city;
      this.state = vendorJson.state;
      this.zipcode = vendorJson.zipcode;
      this.phone = vendorJson.phone;
      this.email = vendorJson.email;
      this.kind = vendorJson.kind;
      this.createdAt = vendorJson.createdAt;
      this.updatedAt = vendorJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Vendor_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name: string;
  local: string;
  contact: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  phone: string;
  email: string;
  kind: string;
  createdAt: string;
  updatedAt: string;
}
