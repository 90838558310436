import { createReducer } from "@reduxjs/toolkit";
import { JobCategory_Entity } from "../../entities/jobCategory";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteJobCategorySuccess,
  getJobCategoryByIdSuccess,
  getJobCategoriesSearchMetadataSuccess,
  getJobCategoriesSuccess,
  setSelectedJobCategoryID,
} from "./jobCategoryActions";
import { JobCategoryState } from "./jobCategoryState";

function initState(): JobCategoryState {
  const { jobCategory: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    jobCategories: {},
    searchMetadata: undefined,
    selectedJobCategoryID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const jobCategoryReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedJobCategoryID, (state, action) => {
    state.selectedJobCategoryID = action.payload.jobCategoryID;
    return state;
  });
  builder.addCase(getJobCategoriesSuccess, (state, action) => {
    const jobCategories = action.payload.jobCategories;
    state.jobCategories = arrayToMap<JobCategory_Entity>(jobCategories);
    return state;
  });
  builder.addCase(getJobCategoriesSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.jobCategoriesSearchMetadata;
    return state;
  });
  builder.addCase(deleteJobCategorySuccess, (state, action) => {
    const jobCategoryID = action.payload.id;
    if (state.jobCategories[jobCategoryID]) {
      delete state.jobCategories[jobCategoryID];
    }
    return state;
  });
  builder.addCase(getJobCategoryByIdSuccess, (state, action) => {
    const jobCategory = action.payload.jobCategory;
    if (jobCategory) {
      const jobCategories = { ...state.jobCategories };
      jobCategories[jobCategory.id] = jobCategory;
      state.jobCategories = jobCategories;
    }
    return state;
  });
});
