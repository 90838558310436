import { Task } from "../task";

/**
 * ProjectCue_Entity type guard.
 *
 * @param {any} projectCueJson ProjectCue object from API
 * @returns {boolean} Return true if type is ProjectCue_Entity
 */
function validator(
  projectCueJson: unknown
): projectCueJson is ProjectCue_Entity {
  if (typeof projectCueJson === "object" && projectCueJson != null) {
    ProjectCue_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(projectCueJson).includes(element))
        throw new Error(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class ProjectCue_Entity {
  static requiredFields = ["id"];

  constructor(projectCueJson: unknown) {
    if (validator(projectCueJson)) {
      this.id = projectCueJson.id;
      this.name = projectCueJson.name;
      this.total = projectCueJson.total;
      this.showInMPS = projectCueJson.showInMPS;
      this.bars = projectCueJson.bars;
      this.tasks = Task.fromList(projectCueJson.tasks);
      this.showInMPS = projectCueJson.showInMPS;
      this.showInContract = projectCueJson.showInContract;
      this.showInInvoice = projectCueJson.showInInvoice;
      this.includeInCalculation = projectCueJson.includeInCalculation;
    } else
      throw new Error(
        `Failed to create new instance of ${ProjectCue_Entity.name}`
      );
  }

  id: number;
  name: string;
  bars: number;
  showInMPS: boolean;
  showInContract: boolean;
  showInInvoice: boolean;
  includeInCalculation: boolean;
  total: number;
  tasks: Task[];
}
