import { Button, Chip } from "@mui/joy";
import {
  Button as MUIButton,
  CircularProgress,
  Grow,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { selectedProjectIDSelector } from "@nerdjs/kronos-kit";
import { NerdSecurityGate } from "@nerdjs/nerd-core";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import { ReactElement, SyntheticEvent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { projectTab } from "../constants";
import { Task } from "../entities/task";
import Cues from "../hooks/cues/cues";
import Expenses from "../hooks/expenses/expenses";
import { useAppSelector } from "../hooks/hooks";
import Jobs from "../hooks/jobs/jobs";
import { MPSBatch } from "../hooks/musicPrepSummary/mpsBatch";
import MusicPrepSummary from "../hooks/musicPrepSummary/musicPrepSummary";
import OrchestratorMusicPrepSummary from "../hooks/musicPrepSummary/orchestratorMusicPrepSummary";
import { ProjectGraphs } from "../hooks/project/projectGraphs/projectGraphs";
import { ProjectTimers } from "../hooks/project/projectTimers/projectTimers";
import ProjectBudget from "../hooks/projectBudget/projectBudget";
import ProjectInfo from "../hooks/projectInfo/projectInfo";
import RouterBreadcrumbs from "../hooks/routerBreadcrumbs/routerBreadcrumbs";
import TalentLedger from "../hooks/talentLedger/talentLedger";
import { queryBuilder } from "../network";
import {
  setDrawerOpen,
  setFormOpen,
  setTabIndex,
} from "../redux/appStatus/appStatusActions";
import {
  drawerOpenSelector,
  formOpenSelector,
  isAdminSelector,
  tabIndexSelector,
  userServicesSelector,
} from "../redux/appStatus/appStatusSelector";
import { setBulkSelect, setSelectedGridCues } from "../redux/cue/cueActions";
import { cuesByProjectSelector } from "../redux/cue/cueSelector";
import { jobsByProjectSelector } from "../redux/job/jobSelector";
import { jobCategoriesSelector } from "../redux/jobCategory/jobCategorySelector";
import { positionsSelector } from "../redux/position/positionSelector";
import { setSelectedProjectID } from "../redux/project/projectActions";
import { projectSelector } from "../redux/project/projectSelector";
import { projectTasksByProjectSelector } from "../redux/projectTask/projectTaskSelector";
import {
  scalesMapSelector,
  scalesSelector,
} from "../redux/scale/scaleSelector";
import { talentsSelector } from "../redux/talent/talentSelector";
import {
  setSelectedGridTasks,
  setTaskBulkSelect,
  updateBatchTasks,
} from "../redux/task/taskActions";
import { taskTypesSelector } from "../redux/taskType/taskTypeSelector";
import { vTransactionSelectedTalentID } from "../redux/vtransaction/vtransactionSelector";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function ProjectRoute(): ReactElement {
  const tabIndex = useAppSelector(tabIndexSelector(projectTab)) ?? 0;
  const dispatch = useDispatch();
  const params = useParams();
  const talents = useAppSelector(talentsSelector);
  const projectID = parseInt(params.id ?? "");
  const jobs = useAppSelector(jobsByProjectSelector(projectID));
  const vtransactionTalentID = useAppSelector(vTransactionSelectedTalentID);
  const userServices = useAppSelector(userServicesSelector);
  const drawerOpen = useAppSelector(drawerOpenSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const cues = useAppSelector(cuesByProjectSelector(projectID));
  const budgetOpen = useAppSelector(formOpenSelector("budget"));
  const jobCategories = useAppSelector(jobCategoriesSelector);
  const taskTypes = useAppSelector(taskTypesSelector);
  const positions = useAppSelector(positionsSelector);
  const scales = useAppSelector(scalesSelector);
  const project = useAppSelector(projectSelector(projectID));
  const projectTasks = useAppSelector(projectTasksByProjectSelector(projectID));
  const allotmentJournalEntrySize = JSON.parse(
    localStorage.getItem("films_allotmentJournalEntrySize") ?? "[]"
  );
  const handleTabIndexChange = (
    e: SyntheticEvent<Element, Event>,
    v: number
  ) => {
    dispatch(setTabIndex(projectTab, v));
  };

  useEffect(() => {
    dispatch(setSelectedProjectID(projectID));
    const query = queryBuilder.getQuery();
    if (query.tab) {
      dispatch(setTabIndex(projectTab, parseInt(query.tab)));
    }

    return () => {
      dispatch(setTabIndex(projectTab, 0));
      dispatch(setSelectedGridCues([]));
      dispatch(setSelectedProjectID());
      dispatch(setBulkSelect(false));
      dispatch(setTaskBulkSelect(false));
      dispatch(setSelectedGridTasks([]));
    };
  }, []);

  function getTabContent() {
    switch (tabIndex) {
      case 0:
        return (
          <Box sx={{ display: "flex", flexGrow: 1, p: 2 }}>
            <Cues />
          </Box>
        );
      case 1:
        return (
          <Box
            sx={{ display: "flex", flexGrow: 1, gap: 1, p: 2 }}
            key="copyists"
          >
            <Jobs />
            {vtransactionTalentID ? (
              <Grow in>
                <Box sx={{ flex: 1, display: "flex" }}>
                  <TalentLedger talentID={vtransactionTalentID} />
                </Box>
              </Grow>
            ) : (
              []
            )}
          </Box>
        );

      case 2:
        return (
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              gap: 1,
              p: 2,
            }}
            key="orchestrator"
          >
            <Jobs orchestrator />
            {vtransactionTalentID ? (
              <Grow in>
                <Box sx={{ flex: 1, display: "flex" }}>
                  <TalentLedger talentID={vtransactionTalentID} />
                </Box>
              </Grow>
            ) : (
              []
            )}
          </Box>
        );

      case 3:
        return (
          <Box
            sx={{ display: "flex", flexGrow: 1, flexDirection: "column", p: 2 }}
          >
            <Box sx={{ height: 162, display: "flex", flexDirection: "column" }}>
              <Typography variant="overline">
                {jobCategories[2].name}
              </Typography>
              <MusicPrepSummary
                projectTask={projectTasks.find(
                  (p) => p.id === jobCategories[2].id
                )}
              />
            </Box>
            <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 1,
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Typography variant="overline">
                    {jobCategories[1].name}
                  </Typography>
                  <MUIButton
                    size="small"
                    onClick={() => dispatch(setFormOpen(true, "mpsBatch"))}
                    disabled={project?.locked()}
                  >
                    <i className="fa-solid fa-line-columns pright"></i> Batch
                    Import
                  </MUIButton>
                  <MPSBatch />
                </Box>
                <ReconcileRates />
              </Box>
              <MusicPrepSummary
                projectTask={projectTasks.find(
                  (p) => p.id === jobCategories[1].id
                )}
              />
            </Box>
          </Box>
        );
      case 4:
        return (
          <Box
            sx={{ display: "flex", flexGrow: 1, flexDirection: "column", p: 2 }}
          >
            <OrchestratorMusicPrepSummary
              projectTask={projectTasks.find(
                (p) => p.id === jobCategories[0].id
              )}
            />
          </Box>
        );

      case 5:
        return (
          <Box sx={{ display: "flex", flexGrow: 1, p: 2 }}>
            <Expenses />
          </Box>
        );
      case 6:
        return (
          <Box sx={{ display: "flex", flexGrow: 1, p: 2 }}>
            <ProjectTimers />
          </Box>
        );

      case 7:
        return (
          <Box
            sx={{
              overflow: "scroll",
              width: "100%",
              height: "calc(100vh - 136px)",
              position: "relative",
            }}
          >
            <ProjectInfo />
          </Box>
        );
      case 8:
        return (
          <Box
            sx={{
              overflow: "scroll",
              width: "100%",
              height: "calc(100vh - 136px)",
              position: "relative",
            }}
          >
            <ProjectGraphs />
          </Box>
        );

      default:
        return <div>Coming very soon 🙂</div>;
    }
  }

  const loadingProgress = (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );

  if (talents.length === 0 || !project) {
    return loadingProgress;
  }

  if (
    isAdmin &&
    (jobCategories.length === 0 ||
      scales.length === 0 ||
      positions.length === 0 ||
      projectTasks.length === 0 ||
      taskTypes.length === 0)
  ) {
    return loadingProgress;
  }

  const handleAllotmentChange = (e: number[]) => {
    if (e.length === 2)
      localStorage.setItem(
        "films_allotmentJournalEntrySize",
        JSON.stringify(e)
      );
  };

  const adminTabs = [
    <Tab value={0} key="cues" label="Cues" />,
    <Tab value={1} key="jobs" label="Copyists" />,
    <Tab value={2} key="jobs" label="Orchestrators" />,
    <Tab
      value={4}
      key="Orchestration"
      label="Orchestration"
      disabled={cues.length === 0 || jobs.length === 0}
    />,
    <Tab
      value={3}
      key="mps"
      label="Music Prep Summary"
      disabled={cues.length === 0 || jobs.length === 0}
    />,
    <Tab value={5} key="Expenses" label="Expenses" />,
    <Tab value={6} key="Timers" label="Timers" />,
    // <Tab value={8} key="Graphs" label="Graphs" />,
    <Tab value={7} key="Project" label="Project" />,
  ];
  const limitedTabs = [
    <Tab value={0} key="cues" label="Cues" />,
    <Tab value={5} key="Expenses" label="Expenses" />,
  ];

  return (
    <Allotment
      vertical={false}
      onChange={handleAllotmentChange}
      defaultSizes={allotmentJournalEntrySize}
    >
      <Allotment.Pane minSize={300}>
        <Box
          sx={{
            height: "calc(100vh - 48px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Paper
            sx={{
              position: "sticky",
              borderRadius: 0,
              zIndex: 99,
            }}
          >
            <Box sx={{ p: 1 }}>
              <RouterBreadcrumbs
                onMoveBack={() => dispatch(setSelectedProjectID())}
                last={project?.name}
              />
            </Box>
            <Tabs
              variant="scrollable"
              value={tabIndex}
              onChange={handleTabIndexChange}
              aria-label="basic tabs example"
            >
              {isAdmin ? adminTabs : limitedTabs}
            </Tabs>
            <NerdSecurityGate id="button-budget" userServices={userServices}>
              <Button
                variant="solid"
                color="neutral"
                onClick={() => {
                  dispatch(setFormOpen(!budgetOpen, "budget"));
                  if (!budgetOpen && drawerOpen) dispatch(setDrawerOpen(false));
                }}
                sx={{
                  position: "absolute",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  top: 10,
                  right: 0,
                  zIndex: 999,
                }}
                size="sm"
                endDecorator={
                  <i
                    className={`fa-sharp fa-solid fa-chevron-${
                      budgetOpen ? "left" : "right"
                    } pleft`}
                  ></i>
                }
              >
                Budget
              </Button>
            </NerdSecurityGate>
          </Paper>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
            }}
          >
            {getTabContent()}
          </Box>
        </Box>
      </Allotment.Pane>
      {budgetOpen ? (
        <Allotment.Pane
          minSize={320}
          preferredSize={
            allotmentJournalEntrySize.length === 2
              ? allotmentJournalEntrySize[1]
              : 420
          }
        >
          <Box
            sx={{
              height: "calc(100vh - 48px)",
              display: "flex",
              overflow: "scroll",
            }}
          >
            <ProjectBudget />
          </Box>
        </Allotment.Pane>
      ) : (
        []
      )}
    </Allotment>
  );
}

function ReconcileRates() {
  const scalesMap = useAppSelector(scalesMapSelector);
  const projectID = useAppSelector(selectedProjectIDSelector);
  const projectTasks = useAppSelector(projectTasksByProjectSelector(projectID));
  const copyists = projectTasks[1];
  const dispatch = useDispatch();

  const allTasks: Task[] = [];
  copyists.cues.forEach((c) => {
    c.tasks.forEach((t) => {
      allTasks.push(t);
    });
  });

  const handleClick = () => {
    const body: Partial<Task>[] = [];
    allTasks.forEach((t) => {
      const _t = { ...t };
      _t.rate = scalesMap[t.scaleID].rate;
      body.push(_t);
    });

    dispatch(updateBatchTasks(body));
  };

  return (
    <Tooltip title="This action will apply the latest rates to the Music Prep Summary.">
      <Chip
        startDecorator={<i className="fa-solid fa-arrows-rotate"></i>}
        onClick={handleClick}
        variant="soft"
        size="sm"
        color="neutral"
      >
        Use latest rates
      </Chip>
    </Tooltip>
  );
}
