import { createReducer } from "@reduxjs/toolkit";
import { TaskType_Entity } from "../../entities/taskType";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteTaskTypeSuccess,
  getTaskTypeByIdSuccess,
  getTaskTypesSearchMetadataSuccess,
  getTaskTypesSuccess,
  setSelectedTaskTypeID,
} from "./taskTypeActions";
import { TaskTypeState } from "./taskTypeState";

function initState(): TaskTypeState {
  const { taskType: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    taskTypes: {},
    searchMetadata: undefined,
    selectedTaskTypeID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const taskTypeReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedTaskTypeID, (state, action) => {
    state.selectedTaskTypeID = action.payload.taskTypeID;
    return state;
  });
  builder.addCase(getTaskTypesSuccess, (state, action) => {
    const taskTypes = action.payload.taskTypes;
    state.taskTypes = arrayToMap<TaskType_Entity>(taskTypes);
    return state;
  });
  builder.addCase(getTaskTypesSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.taskTypesSearchMetadata;
    return state;
  });
  builder.addCase(deleteTaskTypeSuccess, (state, action) => {
    const taskTypeID = action.payload.id;
    if (state.taskTypes[taskTypeID]) {
      delete state.taskTypes[taskTypeID];
    }
    return state;
  });
  builder.addCase(getTaskTypeByIdSuccess, (state, action) => {
    const taskType = action.payload.taskType;
    if (taskType) {
      const taskTypes = { ...state.taskTypes };
      taskTypes[taskType.id] = taskType;
      state.taskTypes = taskTypes;
    }
    return state;
  });
});
