import { createReducer } from "@reduxjs/toolkit";
import { Job_Entity } from "../../entities/job";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteJobSuccess,
  getJobByIdSuccess,
  getJobsSearchMetadataSuccess,
  getJobsSuccess,
  setSelectedJobID,
} from "./jobActions";
import { JobState } from "./jobState";

function initState(): JobState {
  const { job: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    jobs: {},
    jobsByProject: {},
    searchMetadata: undefined,
    selectedJobID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const jobReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedJobID, (state, action) => {
    state.selectedJobID = action.payload.jobID;
    return state;
  });
  builder.addCase(getJobsSuccess, (state, action) => {
    const jobs = action.payload.jobs;
    state.jobs = arrayToMap<Job_Entity>(jobs);

    const _jobsByProject = {};

    for (const key in jobs) {
      if (Object.prototype.hasOwnProperty.call(jobs, key)) {
        const job = jobs[key];
        if (_jobsByProject[job.projectID]) {
          _jobsByProject[job.projectID].push(job);
        } else {
          _jobsByProject[job.projectID] = [job];
        }
      }
    }

    state.jobsByProject = { ...state.jobsByProject, ..._jobsByProject };

    return state;
  });
  builder.addCase(getJobsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.jobsSearchMetadata;
    return state;
  });
  builder.addCase(deleteJobSuccess, (state, action) => {
    const jobID = action.payload.id;
    if (state.jobs[jobID]) {
      delete state.jobs[jobID];
    }
    return state;
  });
  builder.addCase(getJobByIdSuccess, (state, action) => {
    const job = action.payload.job;
    if (job) {
      const jobs = { ...state.jobs };
      jobs[job.id] = job;
      state.jobs = jobs;
    }
    return state;
  });
});
