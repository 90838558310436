import Cue_Entity from "./cue";
export default class Cue extends Cue_Entity {
  static fromList(cuesJSON: unknown): Array<Cue> {
    const cues: Cue[] = [];
    if (cuesJSON)
      Array.isArray(cuesJSON) &&
        cuesJSON.forEach((cueJSON) => {
          cues.push(new Cue(cueJSON));
        });
    return cues;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
