import { Box } from "@mui/material";
import alpha from "./alpha.png";

/**
 *
 * @returns {ReactElement} NerdAlpha page
 */
export function NerdAlpha() {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 9999,
        pointerEvents: "none",
        backgroundImage: `url(${alpha})`,
      }}
    ></Box>
  );
}
