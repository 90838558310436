import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link, { LinkProps } from "@mui/material/Link";
import ListItem, { ListItemProps } from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { breadcrumbNameMap } from "../config/routerConfig";

interface ListItemLinkProps extends ListItemProps {
  to: string;
  open?: boolean;
}

function ListItemLink(props: ListItemLinkProps) {
  const { to, open, ...other } = props;
  const primary = breadcrumbNameMap[to];

  let icon = null;
  if (open != null) {
    icon = open ? <ExpandLess /> : <ExpandMore />;
  }

  return (
    <li>
      <ListItem button component={RouterLink as any} to={to} {...other}>
        <ListItemText primary={primary} />
        {icon}
      </ListItem>
    </li>
  );
}

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

export default function RouterBreadcrumbs(props: {
  last?: string;
  onMoveBack?: () => void;
}) {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const handleOnClick = () => {
    if (props.onMoveBack) {
      props.onMoveBack();
    }
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <LinkRouter
        variant="body2"
        underline="hover"
        color="inherit"
        to="/"
        onClick={handleOnClick}
      >
        Home
      </LinkRouter>
      {pathnames.map((value, index) => {
        const end = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        return end ? (
          <Typography
            variant="body2"
            color="primary"
            key={to}
            sx={{ fontWeight: 400 }}
          >
            {props.last ?? ""}
          </Typography>
        ) : (
          <LinkRouter
            variant="body2"
            underline="hover"
            color="inherit"
            to={to}
            key={to}
            onClick={handleOnClick}
          >
            {breadcrumbNameMap[to]}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
}
