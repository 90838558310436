import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Box,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
  Tooltip,
  InputBase,
  InputLabelProps,
} from "@mui/material";
import moment from "moment";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { defaultTextFieldProps } from "../constants";
import { durationFormatter, durationToSec } from "./dataGridHelpers";

export function TextFieldHelper<T>(
  element: T,
  label: string,
  key: string,
  handleTextChange: (name: string) => (value: string | number) => void,
  save: () => void,
  additionalProps?: TextFieldProps,
  multiplier?: number,
  InputLabelProps?: InputLabelProps
): ReactElement {
  const _onChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
    if (additionalProps?.type == "number") {
      handleTextChange(name)(
        Math.round(parseFloat(e.target.value) * multiplier!)
      );
    } else if (additionalProps?.type == "date") {
      handleTextChange(name)(moment(e.target.value).utc().format());
    } else {
      handleTextChange(name)(e.target.value);
    }
  };

  let value = element && element[key] ? element[key] : "";
  if (multiplier != undefined) value = `${parseInt(element[key]) / multiplier}`;
  return (
    <TextField
      {...defaultTextFieldProps}
      InputLabelProps={InputLabelProps}
      onChange={_onChange(key)}
      onBlur={() => save()}
      label={label}
      value={
        additionalProps?.type === "date"
          ? moment(value).format("YYYY-MM-DD")
          : value ?? ""
      }
      inputProps={{
        autocomplete: false,
      }}
      {...additionalProps}
    />
  );
}

export function ConfidentialTextFieldHelper<T>(
  element: T,
  label: string,
  key: string,
  handleTextChange: (name: string) => (value: string | number) => void,
  save: () => void,
  additionalProps?: TextFieldProps,
  multiplier?: number,
  disabled?: boolean
): ReactElement {
  const [show, setShow] = useState(false);
  const _onChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
    if (additionalProps?.type == "number") {
      handleTextChange(name)(
        Math.round(parseFloat(e.target.value) * multiplier!)
      );
    } else {
      handleTextChange(name)(e.target.value);
    }
  };

  let value = element && element[key] ? element[key] : "";
  if (multiplier != undefined) value = `${parseInt(element[key]) / multiplier}`;
  return (
    <Box sx={{ display: "flex" }}>
      <TextField
        {...defaultTextFieldProps}
        disabled={disabled}
        onChange={_onChange(key)}
        onBlur={() => save()}
        label={label}
        value={value}
        {...additionalProps}
        type={show ? "text" : "password"}
      />
      <Tooltip title={show ? "Hide Value" : "Show Value"}>
        <IconButton onClick={() => setShow((s) => !s)}>
          {!show ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export function SelectFieldHelper<T>(
  element: T,
  label: string,
  key: string,
  data: Array<{ value: number | string; label: string }>,
  handleTextChange: (name: string) => (value: string | number) => void,
  save: () => void,
  disabled?: boolean
) {
  const value = element && element[key] ? element[key] : "";
  const _onChange = (name: string) => (e: SelectChangeEvent) => {
    handleTextChange(name)(e.target.value);
    save();
  };
  return (
    <FormControl fullWidth size="small" variant="filled" disabled={disabled}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={_onChange(key)}
      >
        {data.map((d) => (
          <MenuItem key={d.value} value={d.value}>
            {d.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function getOptions<T extends { id: number }>(
  array: Array<T>,
  key: string
): Array<{ value: number; label: string }> {
  const options = [];

  for (const j in array) {
    if (Object.prototype.hasOwnProperty.call(array, j)) {
      const element = array[j];
      options.push({
        value: element.id,
        label: element[key],
      });
    }
  }

  return options;
}

export const DurationTextField = ({
  value,
  onChange,
}: {
  value: number;
  onChange: (a: number) => void;
}) => {
  const [text, setText] = useState(durationFormatter(value!));

  useEffect(() => {
    const _sec = durationToSec(text);
    if (_sec > -1) onChange(_sec);
  }, [text]);

  const handleRef = (element: HTMLSpanElement) => {
    if (element) {
      const input = element.querySelector<HTMLInputElement>(
        `input[value="${value}"]`
      );
      input?.focus();
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <TextField
        sx={{ fontSize: 14, textAlign: "right", flexGrow: 1 }}
        placeholder="00:00:00"
        onFocus={(event) => {
          event.target.select();
        }}
        autoComplete="off"
        label="Duration"
        size="small"
        value={text}
        inputRef={handleRef}
        onChange={(e) => setText(e.target.value)}
        inputProps={{
          className: "rightAlign",
        }}
        onBlur={() => {
          if (durationToSec(text) == -1) {
            alert("Invalid duration format. Should be like 00:02:30");
          }
        }}
      />
    </Box>
  );
};
