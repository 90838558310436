/**
 * RateScale_Entity type guard.
 *
 * @param {any} rateScaleJson RateScale object from API
 * @returns {boolean} Return true if type is RateScale_Entity
 */
function validator(rateScaleJson: unknown): rateScaleJson is RateScale_Entity {
  if (typeof rateScaleJson === "object" && rateScaleJson != null) {
    RateScale_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(rateScaleJson).includes(element))
        throw new Error(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class RateScale_Entity {
  static requiredFields = ["id"];

  constructor(rateScaleJson: unknown) {
    if (validator(rateScaleJson)) {
      this.id = rateScaleJson.id;
      this.name = rateScaleJson.name;
      this.unit = rateScaleJson.unit;
      this.rate = rateScaleJson.rate;
    } else
      throw new Error(
        `Failed to create new instance of ${RateScale_Entity.name}`
      );
  }

  id?: number;
  name: string;
  unit: string;
  rate: number;
}
