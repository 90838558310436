import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Button } from "@mui/joy";
import { Box, Paper, Tooltip } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Dispatch } from "@reduxjs/toolkit";
import { ConfirmOptions, useConfirm } from "material-ui-confirm";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import {
  defaultDataGridProps,
  injectDefaultColumnProps,
} from "../../constants";
import { Vendor_Entity } from "../../entities/vendor";
import {
  createVendor,
  deleteVendor,
  deleteVendorSuccess,
  getVendors,
  updateVendor,
} from "../../redux/vendor/vendorActions";
import { vendorsSelector } from "../../redux/vendor/vendorSelector";
import { useAppSelector } from "../hooks";
import NoRowsOverlay from "../noRowsOverlay/noRowsOverlay";

/**
 * Vendor
 *
 * @returns {null} Vendor
 */
export default function Vendors() {
  const vendors = useAppSelector(vendorsSelector);

  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const confirm = useConfirm();

  useEffect(() => {
    dispatch(getVendors());
  }, []);

  const onVendorUpdate = async (
    _newVendor: Vendor_Entity,
    _oldVendor: Vendor_Entity
  ) => {
    return new Promise<Vendor_Entity>((resolve) => {
      dispatch(updateVendor(_newVendor.id, _newVendor));
      setTimeout(() => {
        resolve({ ..._newVendor });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1 }}>
      <DataGridPro
        {...defaultDataGridProps}
        apiRef={apiRef}
        processRowUpdate={onVendorUpdate}
        rows={vendors}
        columns={vendorColumns(dispatch, confirm)}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: VendorToolbar,
          NoRowsOverlay: NoRowsOverlay,
        }}
      />
    </Paper>
  );
}

function VendorToolbar() {
  const dispatch = useDispatch();

  const handleNewVendor = () => {
    const newVendor = {
      id: -1,
      name: "",
      contact: "",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      phone: "",
      email: "",
      kind: "",
      createdAt: "",
      updatedAt: "",
    };
    dispatch(createVendor(newVendor));
  };

  useHotkeys(`ctrl+enter`, handleNewVendor, {
    enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
  });

  return (
    <Box className="header" sx={{ display: "flex", p: 1 }}>
      <Tooltip title="ctrl + enter">
        <Button size="sm" variant="soft" onClick={handleNewVendor}>
          + New Vendor
        </Button>
      </Tooltip>
    </Box>
  );
}

const vendorColumns = (
  dispatch: Dispatch,
  confirm: (options?: ConfirmOptions | undefined) => Promise<void>
) =>
  injectDefaultColumnProps([
    {
      field: "id",
      headerName: "ID",
      editable: false,
      type: "number",
      width: 40,
    },
    {
      field: "name",
      headerName: "Name",
      editable: true,
      type: "string",
      width: 100,
      flex: 1,
    },
    {
      field: "contact",
      headerName: "Contact",
      editable: true,
      type: "string",
      width: 150,
    },
    {
      field: "address",
      headerName: "Address",
      editable: true,
      type: "string",
      width: 200,
    },
    {
      field: "city",
      headerName: "City",
      editable: true,
      type: "string",
      width: 100,
    },
    {
      field: "state",
      headerName: "State",
      editable: true,
      type: "string",
      width: 40,
    },
    {
      field: "zipcode",
      headerName: "Zipcode",
      editable: true,
      type: "string",
      width: 80,
    },
    {
      field: "email",
      headerName: "Email",
      editable: true,
      type: "string",
      width: 150,
    },
    {
      field: "phone",
      headerName: "Phone",
      editable: true,
      type: "string",
      width: 150,
    },
    {
      field: "kind",
      headerName: "Kind",
      editable: true,
      type: "string",
      width: 150,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (props: { id: any }) => [
        <GridActionsCellItem
          key="delete"
          showInMenu
          icon={<DeleteIcon />}
          label={`Delete`}
          onClick={() => {
            confirm({
              description: `You are about to delete a vendor`,
            })
              .then(() => {
                dispatch(deleteVendor(props.id, deleteVendorSuccess));
              })
              .catch(() => {
                /* ... */
              });
          }}
          color="inherit"
        />,
      ],
    },
  ]);
