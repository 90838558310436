import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactElement, useEffect } from "react";
import ReactConfetti from "react-confetti";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { RouterConfig } from "../hooks/config/routerConfig";
import { useAppSelector } from "../hooks/hooks";
import { confettiSelector } from "../redux/appStatus/appStatusSelector";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function RootRoute(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const confetti = useAppSelector(confettiSelector);

  useEffect(() => {
    if (pathnames.length === 0) return navigate(RouterConfig.projects);
  }, [pathnames]);

  return (
    <Box sx={{ display: "flex", flex: 1 }}>
      <Outlet />
      {confetti > 0 ? (
        <ReactConfetti
          key={confetti}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 99999,
          }}
          recycle={false}
          gravity={0.2}
          tweenDuration={4000}
          numberOfPieces={200}
        />
      ) : (
        []
      )}
    </Box>
  );
}
