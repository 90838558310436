import { Button } from "@mui/joy";
import { Box, Popover, TextField } from "@mui/material";
import {
  DateRange,
  LocalizationProvider,
  StaticDateRangePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import { useState } from "react";

/**
 *
 * @returns {ReactElement} DateRangePicker page
 */
export function DateRangePicker({
  onChange,
  date,
}: {
  date: DateRange<Moment>;
  onChange: (d: DateRange<Moment>) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box>
      <Button
        startDecorator={<i className="fa-duotone fa-calendar-days"></i>}
        size="sm"
        onClick={handleClick}
        variant="outlined"
        color="neutral"
        sx={{ background: "white" }}
      >
        {date[0] && date[1]
          ? `${date[0]?.format("ll")} - ${date[1]?.format("ll")} `
          : "Select date range"}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ p: 1 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              label="date range"
              value={date}
              onChange={(newValue) => onChange(newValue)}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField autoComplete="off" {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField autoComplete="off" {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={() => onChange([null, null])}
              size="sm"
              color="neutral"
              variant="soft"
            >
              Clear
            </Button>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                onClick={() => onChange([moment().startOf("year"), moment()])}
                size="sm"
                color="neutral"
                variant={"outlined"}
              >
                Year to date
              </Button>
              <Button
                onClick={() =>
                  onChange([
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "month").endOf("month"),
                  ])
                }
                size="sm"
                color="neutral"
                variant="outlined"
              >
                Last Month
              </Button>
              <Button
                onClick={() => onChange([moment().startOf("month"), moment()])}
                size="sm"
                color="neutral"
                variant="outlined"
              >
                This Month
              </Button>
              <Button
                onClick={() => onChange([moment().startOf("week"), moment()])}
                size="sm"
                color="neutral"
                variant="outlined"
              >
                This Week
              </Button>
              <Button
                onClick={() => onChange([moment(), moment()])}
                size="sm"
                color="neutral"
                variant="outlined"
              >
                Today
              </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
