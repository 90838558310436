import { Box, Divider, Paper, Typography } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { DateRange } from "@mui/x-date-pickers-pro";
import moment, { Moment } from "moment";
import { MutableRefObject, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { currencyFormatter } from "../../constants";
import { AppConfig } from "../../environement";
import { getJobsByFilter } from "../../redux/job/jobActions";
import { jobsForTalent } from "../../redux/job/jobSelector";
import { selectedTalentIDSelector } from "../../redux/talent/talentSelector";
import { DateRangePicker } from "../dateRangePicker/dateRangePicker";
import { useAppSelector } from "../hooks";
import {
  getterDivider,
  setterDivider,
} from "../jobCategory/jobCategoryRates/dataGridColDef";

/**
 *
 * @returns {ReactElement} TalentJobs page
 */
export function TalentJobs({
  withDashboard = true,
}: {
  withDashboard?: boolean;
}) {
  const dispatch = useDispatch();
  const talentID = useAppSelector(selectedTalentIDSelector);
  const jobs = useAppSelector(jobsForTalent(talentID));
  const apiRef = useGridApiRef();
  const [date, setDate] = useState<DateRange<Moment>>([
    moment().subtract(7, "day"),
    moment(),
  ]);

  useEffect(() => {
    dispatch(
      getJobsByFilter({
        filters: JSON.stringify([
          {
            name: "jobs.talentID",
            comparison: "eq",
            value: talentID,
          },
        ]),
      })
    );
  }, []);

  const jobsForRange = useCallback(() => {
    return jobs.filter(
      (t) =>
        moment(t.createdAt).isSameOrAfter(date[0], "day") &&
        moment(t.createdAt).isSameOrBefore(date[1], "day")
    );
  }, [jobs, date]);

  let totalHours = 0;
  let total = 0;
  let avgHourlyRate = 0;
  jobsForRange().forEach((j) => {
    totalHours += j.hours;
    total += j.total;
    avgHourlyRate += j.hourlyRate;
  });
  totalHours = totalHours / 100;
  total = total / 10000;
  avgHourlyRate = avgHourlyRate / jobsForRange().length / 10000;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, flex: 1 }}>
      <DateRangePicker date={date} onChange={(e) => setDate(e)} />
      <Box sx={{ display: "flex", gap: 1 }}>
        <Paper sx={{ p: 1 }}>
          <Typography variant="caption">Total</Typography>
          <Typography variant="h6">
            <b>{currencyFormatter.format(total)}</b>
          </Typography>
        </Paper>
        <Paper sx={{ p: 1 }}>
          <Typography variant="caption">Total Hours</Typography>
          <Typography variant="h6">
            <b>{totalHours}</b>
          </Typography>
        </Paper>
        <Paper sx={{ p: 1 }}>
          <Typography variant="caption">Avg Hourly Rate</Typography>
          <Typography variant="h6">
            <b>{currencyFormatter.format(avgHourlyRate)}</b>
          </Typography>
        </Paper>
      </Box>
      <DataGridPro
        sx={{ flexGrow: 1 }}
        rows={jobsForRange()}
        apiRef={apiRef}
        density="compact"
        // components={{
        //   Toolbar: CustomToolbar,
        //   Footer: CustomFooter,
        // }}
        componentsProps={{
          footer: { apiRef },
        }}
        columns={[
          { field: "projectName", headerName: "Project", width: 200 },
          {
            field: "createdAt",
            headerName: "Date",
            width: 200,
            type: "date",
            valueFormatter: (p) => moment(p.value).format("MM/DD/YYYY"),
          },
          {
            field: "hourlyRate",
            headerAlign: "center",
            headerName: "Hourly Rate",
            sortable: false,
            editable: true,
            type: "number",
            valueGetter: getterDivider("hourlyRate", 10000),
            valueSetter: setterDivider("hourlyRate", 10000),
            valueFormatter: (p: GridValueFormatterParams) =>
              `${currencyFormatter.format(p.value)}/h`,
          },
          {
            field: "hours",
            headerAlign: "center",
            align: "center",
            headerName: "Hours",
            sortable: false,
            editable: true,
            type: "number",
            valueGetter: getterDivider("hours", 100),
          },
          {
            field: "paymentDate",
            headerName: "Payment Date",
            flex: 1,
            type: "date",
            valueFormatter: (p) =>
              p.value !== "0001-01-01T00:00:00Z"
                ? moment(p.value).format("MM/DD/YYYY")
                : "No paid yet",
          },
          {
            field: "totalAdjusted",
            align: "right",
            headerAlign: "center",
            headerName: "Total",
            editable: false,
            sortable: false,
            width: 120,
            type: "number",
            valueGetter: (p) => p.row.total / 10000,
            valueSetter: setterDivider("totalAdjusted", 10000),
            valueFormatter: (p: GridValueFormatterParams) =>
              `${currencyFormatter.format(p.value)}`,
          },
          {
            field: "actions",
            type: "actions",
            sortable: false,
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: (p) => {
              const actions = [];

              if (p.row.talentID) {
                actions.push(
                  <GridActionsCellItem
                    key={"vtransactions"}
                    icon={<i className="fa-solid fa-file-pdf"></i>}
                    label="Download PDF"
                    onClick={() => {
                      window.open(
                        `${AppConfig.api.endpoint}/films/jobs/${p.row.id}/invoice`,
                        "_blank"
                      );
                    }}
                  />
                );
              }

              return actions;
            },
          },
        ]}
      />
    </Box>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function CustomFooter({ apiRef }: { apiRef: MutableRefObject<GridApiPro> }) {
  // const apiRef = useGridApiContext();
  const rows = apiRef.current?.getVisibleRowModels();

  let total = 0;
  rows.forEach((r) => (total += r.total ?? 0));

  return (
    <>
      <Divider />
      <Box
        sx={{ display: "flex", gap: 1, justifyContent: "end", p: 1, pr: 13 }}
      >
        Total: <b>{currencyFormatter.format(total / 10000)}</b>
      </Box>
    </>
  );
}
