import { createReducer } from "@reduxjs/toolkit";
import { Expense_Entity } from "../../entities/expense";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteExpenseSuccess,
  getExpenseByIdSuccess,
  getExpensesSearchMetadataSuccess,
  getExpensesSuccess,
  setSelectedExpenseID,
} from "./expenseActions";
import { ExpenseState } from "./expenseState";

function initState(): ExpenseState {
  const { expense: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    expenses: {},
    expensesByProject: {},
    searchMetadata: undefined,
    selectedExpenseID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const expenseReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedExpenseID, (state, action) => {
    state.selectedExpenseID = action.payload.expenseID;
    return state;
  });
  builder.addCase(getExpensesSuccess, (state, action) => {
    const expenses = action.payload.expenses;
    state.expenses = arrayToMap<Expense_Entity>(expenses);

    const _expensesByProject = {};

    for (const key in expenses) {
      if (Object.prototype.hasOwnProperty.call(expenses, key)) {
        const expense = expenses[key];
        if (_expensesByProject[expense.projectID]) {
          _expensesByProject[expense.projectID].push(expense);
        } else {
          _expensesByProject[expense.projectID] = [expense];
        }
      }
    }

    state.expensesByProject = {
      ...state.expensesByProject,
      ..._expensesByProject,
    };

    return state;
  });
  builder.addCase(getExpensesSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.expensesSearchMetadata;
    return state;
  });
  builder.addCase(deleteExpenseSuccess, (state, action) => {
    const expenseID = action.payload.id;
    if (state.expenses[expenseID]) {
      delete state.expenses[expenseID];
    }
    return state;
  });
  builder.addCase(getExpenseByIdSuccess, (state, action) => {
    const expense = action.payload.expense;
    if (expense) {
      const expenses = { ...state.expenses };
      expenses[expense.id] = expense;
      state.expenses = expenses;
    }
    return state;
  });
});
