import Task_Entity from "./task";
export default class Task extends Task_Entity {
  static fromList(tasksJSON: unknown): Array<Task> {
    const tasks: Task[] = [];
    if (tasksJSON)
      Array.isArray(tasksJSON) &&
        tasksJSON.forEach((taskJSON) => {
          tasks.push(new Task(taskJSON));
        });
    return tasks;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
