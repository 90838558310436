import { LicenseInfo } from "@mui/x-data-grid-pro";
import { NerdSecurityAgentWrapper } from "@nerdjs/nerd-core";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ConfirmProvider } from "material-ui-confirm";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { access } from "./access";
import { AppConfig } from "./environement";
import ExcelExport from "./hooks/excel/nerdExcel";
import { Helmet } from "react-helmet";
import { NerdAlpha } from "./hooks/nerdAlpha/nerdAlpha";
import NerdApp from "./hooks/nerdApp/nerdApp";
import { VersionChecker } from "./hooks/versionChecker/versionChecker";
import "./i18n";
import "./index.css";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: "https://cdf242cf4dc44566b6dc88237793a769@o1058683.ingest.sentry.io/6046515",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

LicenseInfo.setLicenseKey(
  "7908c976763f4c481913a58651a92565T1JERVI6MzkwMDIsRVhQSVJZPTE2Nzc5NTg1MjQwMDAsS0VZVkVSU0lPTj0x"
);

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>{AppConfig.app.alphaWatermark ? "FILMS (Alpha)" : "FILMS"}</title>
    </Helmet>
    {AppConfig.app.alphaWatermark ? <NerdAlpha /> : []}
    <NerdSecurityAgentWrapper access={access}>
      <BrowserRouter>
        <ConfirmProvider>
          <Provider store={store}>
            <VersionChecker
              versionCheckerEndpoint={AppConfig.api.versionCheckerEndpoint}
            >
              <ExcelExport>
                <NerdApp />
              </ExcelExport>
            </VersionChecker>
          </Provider>
        </ConfirmProvider>
      </BrowserRouter>
    </NerdSecurityAgentWrapper>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
