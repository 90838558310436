import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  Box,
  Button,
  Divider,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Dispatch } from "@reduxjs/toolkit";
import { ConfirmOptions, useConfirm } from "material-ui-confirm";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import {
  currencyFormatter,
  defaultDataGridProps,
  injectDefaultColumnProps,
} from "../../constants";
import { ProjectType_Entity } from "../../entities/projectType";
import { Scale_Entity } from "../../entities/scale";
import { TaskType_Entity } from "../../entities/taskType";
import { getProjectTypes } from "../../redux/projectType/projectTypeActions";
import { projectTypesSelector } from "../../redux/projectType/projectTypeSelector";
import {
  createScale,
  deleteScale,
  deleteScaleSuccess,
  getScales,
  updateScale,
} from "../../redux/scale/scaleActions";
import { scalesSelector } from "../../redux/scale/scaleSelector";
import { getTaskTypes } from "../../redux/taskType/taskTypeActions";
import { taskTypesSelector } from "../../redux/taskType/taskTypeSelector";
import { useAppSelector } from "../hooks";
import {
  getterDivider,
  setterDivider,
} from "../jobCategory/jobCategoryRates/dataGridColDef";
import NoRowsOverlay from "../noRowsOverlay/noRowsOverlay";

/**
 * Scale
 *
 * @returns {null} Scale
 */
export default function Scales() {
  const scales = useAppSelector(scalesSelector);
  const projectTypes = useAppSelector(projectTypesSelector);
  const taskTypes = useAppSelector(taskTypesSelector);

  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const confirm = useConfirm();

  useEffect(() => {
    dispatch(getScales());
    dispatch(getProjectTypes());
    dispatch(getTaskTypes());
  }, []);

  const onScaleUpdate = async (
    _newScale: Scale_Entity,
    _oldScale: Scale_Entity
  ) => {
    return new Promise<Scale_Entity>((resolve) => {
      dispatch(updateScale(_newScale.id, _newScale));
      setTimeout(() => {
        resolve({ ..._newScale });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1 }}>
      <DataGridPro
        {...defaultDataGridProps}
        apiRef={apiRef}
        processRowUpdate={onScaleUpdate}
        rows={scales}
        columns={scaleColumns(dispatch, confirm, projectTypes, taskTypes)}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: ScaleToolbar,
          NoRowsOverlay: NoRowsOverlay,
        }}
        initialState={{
          pinnedColumns: {
            left: ["id"],
            right: ["actions"],
          },
        }}
      />
    </Paper>
  );
}

function ScaleToolbar() {
  const dispatch = useDispatch();

  const handleNewScale = () => {
    const newScale = {
      id: -1,
      projectTypeID: 0,
      taskTypeID: 0,
      rate: 0,
      createdAt: "",
      updatedAt: "",
    };
    dispatch(createScale(newScale));
  };

  useHotkeys(`ctrl+enter`, handleNewScale, {
    enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
  });

  return (
    <Box className="header" sx={{ display: "flex" }}>
      <Tooltip title="ctrl + enter">
        <Button size="small" onClick={handleNewScale}>
          + New Scale
        </Button>
      </Tooltip>
      <Divider
        sx={{ margin: 1, width: 2 }}
        orientation="vertical"
        variant="middle"
        flexItem
      />
    </Box>
  );
}

const scaleColumns = (
  dispatch: Dispatch,
  confirm: (options?: ConfirmOptions | undefined) => Promise<void>,
  projectTypes: ProjectType_Entity[],
  taskTypes: TaskType_Entity[]
) =>
  injectDefaultColumnProps([
    {
      field: "id",
      headerName: "ID",
      editable: false,
      type: "number",
      width: 40,
    },
    {
      field: "projectTypeID",
      headerName: "Project Type",
      editable: true,
      width: 200,
      type: "singleSelect",
      valueOptions: getOptions(projectTypes),
      renderCell: (p: any) => {
        const projectType = projectTypes.find(
          (s) => s.id === p.row.projectTypeID
        );
        return <Typography variant="body2">{projectType?.name}</Typography>;
      },
    },
    {
      field: "taskTypeID",
      headerName: "Task Type",
      editable: true,
      width: 200,
      type: "singleSelect",
      valueOptions: getOptions(taskTypes),
      renderCell: (p: any) => {
        const taskType = taskTypes.find((s) => s.id === p.row.taskTypeID);
        return <Typography variant="body2">{taskType?.name}</Typography>;
      },
    },
    {
      field: "rate",
      headerName: "Rate",
      editable: true,
      type: "number",
      width: 150,
      valueGetter: getterDivider("rate", 10000),
      valueSetter: setterDivider("rate", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (props: { id: any }) => [
        <GridActionsCellItem
          key="delete"
          showInMenu
          icon={<DeleteIcon />}
          label={`Delete`}
          onClick={() => {
            confirm({
              description: `You are about to delete a scale`,
            })
              .then(() => {
                dispatch(deleteScale(props.id, deleteScaleSuccess));
              })
              .catch(() => {
                /* ... */
              });
          }}
          color="inherit"
        />,
      ],
    },
  ]);

function getOptions(items: { id: number; name?: string }[]) {
  const res = [];
  for (const key in items) {
    if (Object.prototype.hasOwnProperty.call(items, key)) {
      const item = items[key];
      res.push({
        value: item.id,
        label: `${item.id}: ${item.name}`,
      });
    }
  }

  return res;
}
