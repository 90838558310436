import { createReducer } from "@reduxjs/toolkit";
import { Rate_Entity } from "../../entities/rate";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import { getProjectTypeRatesSuccess } from "./rateActions";
import { RateState } from "./rateState";

function initState(): RateState {
  return {
    rates: {},
  };
}

export const rateReducer = createReducer(initState(), (builder) => {
  builder.addCase(getProjectTypeRatesSuccess, (state, action) => {
    const projectTypeID = action.payload.projectTypeID;
    state.rates[projectTypeID] = action.payload.rates;
    return state;
  });
});
