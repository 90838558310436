import Vendor_Entity from "./vendor";
export default class Vendor extends Vendor_Entity {
  static fromList(vendorsJSON: unknown): Array<Vendor> {
    const vendors: Vendor[] = [];
    if (vendorsJSON)
      Array.isArray(vendorsJSON) &&
        vendorsJSON.forEach((vendorJSON) => {
          vendors.push(new Vendor(vendorJSON));
        });
    return vendors;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
