import { Hidden, IconButton } from "@mui/material";
import { KronosTimer } from "@nerdjs/kronos-kit";
import { useDispatch } from "react-redux";
import { setDrawerOpen } from "../../redux/appStatus/appStatusActions";
import { drawerOpenSelector } from "../../redux/appStatus/appStatusSelector";
import { FineLineLogo } from "../fineLineLogo/fineLineLogo";
import { useAppSelector } from "../hooks";
import { NerdAppBarProps } from "../nerdAppBar/nerdAppBar";
import { NerdAppBarDarkModeButton } from "../nerdAppBarDarkModeButton/nerdAppBarDarkModeButton";
import { NerdAppBarStopImpersonate } from "../nerdAppBarStopImpersonate/nerdAppBarStopImpesonate";
import NerdAppsMenuWrapper from "../nerdAppsMenuWrapper/nerdAppsMenuWrapper";
import { NerdUserMenu } from "../nerdUserMenu/nerdUserMenu";

export const appBarConfig: NerdAppBarProps = {
  variant: "dense",
  content: [
    <Hidden smDown key="logo">
      <FineLineLogo />
    </Hidden>,
    <Hidden smUp key="Menu">
      <MenuOpenner />
    </Hidden>,
    <div style={{ flexGrow: 1 }} key={"divider1"} />,
    <KronosTimer key="kronos" />,
    <div style={{ flexGrow: 1 }} key={"nerdAppMenustylingDiv"} />,
    // <Hidden smDown key="crisp">
    //   <JoyButton
    //     onClick={() => {
    //       window.open(
    //         "https://go.crisp.chat/chat/embed/?website_id=53d14c32-6127-4d29-9c22-48a3df45523c",
    //         "Support",
    //         "width=400,height=650,left=150,top=200,toolbar=0,status=0"
    //       );
    //     }}
    //     variant="soft"
    //     size="sm"
    //   >
    //     <i className="fa-solid fa-comment pright"></i>
    //     Support
    //   </JoyButton>
    // </Hidden>,
    <NerdAppsMenuWrapper key={"NerdAppsMenuWrapper"} />,
    <NerdAppBarDarkModeButton key={"nerdAppBarDarkModeButton"} />,
    <NerdAppBarStopImpersonate key={"nerdAppBarStopImpesonate"} />,
    <NerdUserMenu key={"myAccountButton"} />,
  ],
};

export {};

declare global {
  interface Window {
    $crisp: {
      push: (a: any) => void;
    };
  }
}

function MenuOpenner() {
  const dispatch = useDispatch();
  const drawerOpen = useAppSelector(drawerOpenSelector);

  return (
    <IconButton
      size="small"
      color="primary"
      onClick={() => dispatch(setDrawerOpen(!drawerOpen))}
    >
      <i style={{ fontSize: "inherit" }} className="fa-solid fa-bars"></i>
    </IconButton>
  );
}
