import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectCues = (state: AppState) => state.cueState.cues;
const selectCuesByProject = (state: AppState) => state.cueState.cuesByProject;
const selectBulkSelect = (state: AppState) => state.cueState.bulkSelect;
const selectSelectedGridCues = (state: AppState) =>
  state.cueState.selectedGridCues;

export const cuesSelector = createSelector([selectCues], (cues) => {
  return mapToArray(cues);
});

export const cueBulkSelectSelector = createSelector(
  [selectBulkSelect],
  (s) => s
);

export const selectedGridCuesSelector = createSelector(
  [selectSelectedGridCues],
  (s) => s
);

export const cuesMapSelector = createSelector([selectCues], (cues) => {
  return cues;
});

export const cueSelector = (id?: number) => {
  return createSelector([selectCues], (cues) => {
    if (id) return cues[id];
    return undefined;
  });
};

export const cuesByProjectSelector = (projectID?: number) => {
  return createSelector([selectCuesByProject], (cuesByProject) => {
    if (projectID)
      return [...(cuesByProject[projectID] ?? [])].sort(
        (a, b) => a.order - b.order
      );
    return [];
  });
};
