import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectJobCategories = (state: AppState) =>
  state.jobCategoryState.jobCategories;

const selectJobCategorySearchMetadata = (state: AppState) =>
  state.jobCategoryState.searchMetadata;

export const jobCategoriesSelector = createSelector(
  [selectJobCategories],
  (jobCategories) => {
    return mapToArray(jobCategories);
  }
);

export const jobCategoriesMapSelector = createSelector(
  [selectJobCategories],
  (jobCategories) => {
    return jobCategories;
  }
);

export const jobCategorySelector = (id?: number) => {
  return createSelector([selectJobCategories], (jobCategories) => {
    if (id) return jobCategories[id];
    return undefined;
  });
};

export const jobCategorySearchMetadataSelector = createSelector(
  [selectJobCategorySearchMetadata],
  (s) => s
);
