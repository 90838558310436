import Job_Entity from "./job";
export default class Job extends Job_Entity {
  static fromList(jobsJSON: unknown): Array<Job> {
    const jobs: Job[] = [];
    if (jobsJSON)
      Array.isArray(jobsJSON) &&
        jobsJSON.forEach((jobJSON) => {
          jobs.push(new Job(jobJSON));
        });
    return jobs;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
