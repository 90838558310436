import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button, Divider, Paper, Tooltip } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Dispatch } from "@reduxjs/toolkit";
import { ConfirmOptions, useConfirm } from "material-ui-confirm";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import {
  defaultDataGridProps,
  injectDefaultColumnProps,
} from "../../constants";
import { JobCategory_Entity } from "../../entities/jobCategory";
import {
  createJobCategory,
  deleteJobCategory,
  deleteJobCategorySuccess,
  getJobCategories,
  updateJobCategory,
} from "../../redux/jobCategory/jobCategoryActions";
import { jobCategoriesSelector } from "../../redux/jobCategory/jobCategorySelector";
import { useAppSelector } from "../hooks";
import NoRowsOverlay from "../noRowsOverlay/noRowsOverlay";

/**
 * JobCategory
 *
 * @returns {null} JobCategory
 */
export default function JobCategories() {
  const jobCategories = useAppSelector(jobCategoriesSelector);

  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const confirm = useConfirm();

  useEffect(() => {
    dispatch(getJobCategories());
  }, []);

  const onJobCategoryUpdate = async (
    _newJobCategory: JobCategory_Entity,
    _oldJobCategory: JobCategory_Entity
  ) => {
    return new Promise<JobCategory_Entity>((resolve) => {
      dispatch(updateJobCategory(_newJobCategory.id, _newJobCategory));
      setTimeout(() => {
        resolve({ ..._newJobCategory });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1 }}>
      <DataGridPro
        {...defaultDataGridProps}
        apiRef={apiRef}
        processRowUpdate={onJobCategoryUpdate}
        rows={jobCategories}
        columns={jobCategoryColumns(dispatch, confirm)}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: JobCategoryToolbar,
          NoRowsOverlay: NoRowsOverlay,
        }}
      />
    </Paper>
  );
}

function JobCategoryToolbar() {
  const dispatch = useDispatch();

  const handleNewJobCategory = () => {
    const newJobCategory = {
      id: -1,
      name: "",
      createdAt: "",
      updatedAt: "",
    };
    dispatch(createJobCategory(newJobCategory));
  };

  useHotkeys(`ctrl+enter`, handleNewJobCategory, {
    enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
  });

  return (
    <Box className="header" sx={{ display: "flex" }}>
      <Tooltip title="ctrl + enter">
        <Button size="small" onClick={handleNewJobCategory}>
          + New Job Category
        </Button>
      </Tooltip>
      <Divider
        sx={{ margin: 1, width: 2 }}
        orientation="vertical"
        variant="middle"
        flexItem
      />
    </Box>
  );
}

const jobCategoryColumns = (
  dispatch: Dispatch,
  confirm: (options?: ConfirmOptions | undefined) => Promise<void>
) =>
  injectDefaultColumnProps([
    {
      field: "id",
      headerName: "ID",
      editable: false,
      type: "number",
      width: 40,
    },
    {
      field: "name",
      headerName: "Name",
      editable: true,
      type: "string",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (props: { id: any }) => [
        <GridActionsCellItem
          key="delete"
          showInMenu
          icon={<DeleteIcon />}
          label={`Delete ${props.id}`}
          onClick={() => {
            confirm({
              description: `You are about to delete a jobCategory`,
            })
              .then(() => {
                dispatch(deleteJobCategory(props.id, deleteJobCategorySuccess));
              })
              .catch(() => {
                /* ... */
              });
          }}
          color="inherit"
        />,
      ],
    },
  ]);
