import { createReducer } from "@reduxjs/toolkit";
import { Budget_Entity } from "../../entities/budget";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import { getProjectByIdSuccess } from "../project/projectActions";
import {
  deleteBudgetSuccess,
  getBudgetByIdSuccess,
  getBudgetsSearchMetadataSuccess,
  getBudgetsSuccess,
  setSelectedBudgetID,
} from "./budgetActions";
import { BudgetState } from "./budgetState";

function initState(): BudgetState {
  const { budget: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    budgets: {},
    searchMetadata: undefined,
    selectedBudgetID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const budgetReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedBudgetID, (state, action) => {
    state.selectedBudgetID = action.payload.budgetID;
    return state;
  });
  builder.addCase(getBudgetsSuccess, (state, action) => {
    const budgets = action.payload.budgets;
    state.budgets = arrayToMap<Budget_Entity>(budgets);
    return state;
  });
  builder.addCase(getProjectByIdSuccess, (state, action) => {
    const budgets = action.payload.project?.budgets ?? [];
    state.budgets = { ...state.budgets, ...arrayToMap<Budget_Entity>(budgets) };

    return state;
  });
  builder.addCase(getBudgetsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.budgetsSearchMetadata;
    return state;
  });
  builder.addCase(deleteBudgetSuccess, (state, action) => {
    const budgetID = action.payload.id;
    if (state.budgets[budgetID]) {
      delete state.budgets[budgetID];
    }
    return state;
  });
  builder.addCase(getBudgetByIdSuccess, (state, action) => {
    const budget = action.payload.budget;
    if (budget) {
      const budgets = { ...state.budgets };
      budgets[budget.id] = budget;
      state.budgets = budgets;
    }
    return state;
  });
});
