import { createReducer } from "@reduxjs/toolkit";
import { Vendor_Entity } from "../../entities/vendor";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteVendorSuccess,
  getVendorByIdSuccess,
  getVendorsSearchMetadataSuccess,
  getVendorsSuccess,
  setSelectedVendorID,
} from "./vendorActions";
import { VendorState } from "./vendorState";

function initState(): VendorState {
  const { vendor: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    vendors: {},
    searchMetadata: undefined,
    selectedVendorID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const vendorReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedVendorID, (state, action) => {
    state.selectedVendorID = action.payload.vendorID;
    return state;
  });
  builder.addCase(getVendorsSuccess, (state, action) => {
    const vendors = action.payload.vendors;
    state.vendors = arrayToMap<Vendor_Entity>(vendors);
    return state;
  });
  builder.addCase(getVendorsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.vendorsSearchMetadata;
    return state;
  });
  builder.addCase(deleteVendorSuccess, (state, action) => {
    const vendorID = action.payload.id;
    if (state.vendors[vendorID]) {
      delete state.vendors[vendorID];
    }
    return state;
  });
  builder.addCase(getVendorByIdSuccess, (state, action) => {
    const vendor = action.payload.vendor;
    if (vendor) {
      const vendors = { ...state.vendors };
      vendors[vendor.id] = vendor;
      state.vendors = vendors;
    }
    return state;
  });
});
