import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Cue_Entity } from "../../entities/cue";
import { Box } from "@mui/system";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { formOpenSelector } from "../../redux/appStatus/appStatusSelector";
import { useAppSelector } from "../hooks";
import { durationToSec } from "../../helpers/dataGridHelpers";
import { createCue } from "../../redux/cue/cueActions";
import { selectedProjectIDSelector } from "@nerdjs/kronos-kit";

/**
 *
 * @returns {ReactElement} CueBatch page
 */
export function CueBatch() {
  const open = useAppSelector(formOpenSelector("cueBatch"));
  const [text, setText] = useState("");
  const projectID = useAppSelector(selectedProjectIDSelector);
  const [mapping, setMapping] = useState<{ [i: number]: string }>({});
  const [data, setData] = useState<string[][]>([]);
  const headers = [
    { key: "code", name: "Cue", transformer: (t: string) => `${t}` },
    { key: "version", name: "Ver.", transformer: (t: string) => `${t}` },
    { key: "qualifier", name: "Qua.", transformer: (t: string) => `${t}` },
    { key: "title", name: "Title", transformer: (t: string) => `${t}` },
    {
      key: "length",
      name: "Length",
      transformer: (t: string) => durationToSec(t),
    },
    { key: "notes", name: "Notes", transformer: (t: string) => `${t}` },
    { key: "bars", name: "Bars", transformer: (t: string) => Number(t) },
    { key: "lines", name: "Lines", transformer: (t: string) => Number(t) },
  ];
  const dispatch = useDispatch();

  const parse = () => {
    const rows = text.split("\n");
    const data: string[][] = [];

    for (const i in rows) {
      const cells = rows[i].split("\t");
      const row: string[] = [];
      cells.forEach((c) => {
        row.push(`${c}`);
      });
      data.push(row);
    }

    setData(data);
    setText("");
  };

  const importCues = () => {
    data.forEach((d) => {
      const cue: Partial<Cue_Entity> = {
        projectID,
        showInContract: true,
        showInMPS: true,
        showInInvoice: true,
        includeInCalculation: true,
      };
      for (const key in mapping) {
        if (Object.prototype.hasOwnProperty.call(mapping, key)) {
          const m = mapping[key];
          const header = headers.find((h) => h.key === m);
          cue[m] = header?.transformer(d[key]);
        }
      }
      dispatch(createCue(cue));
      dispatch(setFormOpen(false, "cueBatch"));
      setData([]);
      setMapping({});
      setText("");
    });
  };

  return (
    <Dialog
      open={open}
      fullWidth={data.length > 0}
      maxWidth={data.length ? "lg" : undefined}
      onClose={() => {
        dispatch(setFormOpen(false, "cueBatch"));
      }}
    >
      <DialogTitle>Batch import cues</DialogTitle>
      {data.length ? (
        <DialogContent>
          <TableContainer component={Paper}>
            <Table
              size="small"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow sx={{ display: "flex" }}>
                  {data[0].map((d, i) => (
                    <TableCell key={d} sx={{ flex: 1 }}>
                      <TextField
                        select
                        autoComplete="off"
                        value={mapping[Number(i)]}
                        size="small"
                        onChange={(e) =>
                          setMapping((m) => ({
                            ...m,
                            [i]: e.target.value,
                          }))
                        }
                        fullWidth
                        placeholder="Select field..."
                      >
                        {headers.map((h) => (
                          <MenuItem key={h.key} value={h.key}>
                            {h.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((d, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      display: "flex",
                    }}
                  >
                    {d.map((r) => (
                      <TableCell
                        sx={{ flex: 1 }}
                        component="th"
                        scope="row"
                        key={r}
                      >
                        {r}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      ) : (
        <DialogContent>
          Instruction: copy and paste your cues from Google Sheets. Do not
          include headers. Then, bind each column to a field.
          <TextField
            autoComplete="off"
            placeholder="Paste here..."
            fullWidth
            multiline
            onChange={(e) => setText(e.target.value)}
            maxRows={10}
          />
        </DialogContent>
      )}
      <DialogActions>
        {data.length ? (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button onClick={() => setData([])}>Restart</Button>
            <Button variant="contained" onClick={importCues}>
              Import
            </Button>
          </Box>
        ) : (
          <Button variant="contained" onClick={parse}>
            Go!
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
