import Talent_Entity from "./talent";
export default class Talent extends Talent_Entity {
  static fromList(talentsJSON: unknown): Array<Talent> {
    const talents: Talent[] = [];
    if (talentsJSON)
      Array.isArray(talentsJSON) &&
        talentsJSON.forEach((talentJSON) => {
          talents.push(new Talent(talentJSON));
        });
    return talents;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
