import { Sheet, Tooltip } from "@mui/joy";
import { Box, Divider, IconButton, Paper, Typography } from "@mui/material";
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  GridValidRowModel,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Dispatch } from "@reduxjs/toolkit";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  currencyFormatter,
  defaultDataGridProps,
  injectDefaultColumnProps,
} from "../../constants";
import { ProjectCue_Entity } from "../../entities/projectCue";
import { ProjectTask_Entity } from "../../entities/projectTask";
import { Scale_Entity } from "../../entities/scale";
import { Talent_Entity } from "../../entities/talent";
import { TaskType_Entity } from "../../entities/taskType";
import { greyStyleForNonEditableCells } from "../../helpers/dataGridHelpers";
import {
  projectSelector,
  selectedProjectIDSelector,
  selectedProjectSelector,
} from "../../redux/project/projectSelector";
import {
  scalesForLibrarian,
  scalesSelector,
} from "../../redux/scale/scaleSelector";
import { talentsSelector } from "../../redux/talent/talentSelector";
import { updateTask } from "../../redux/task/taskActions";
import {
  taskTypesMapSelector,
  taskTypesSelector,
} from "../../redux/taskType/taskTypeSelector";
import { useAppSelector } from "../hooks";
import {
  getterDivider,
  setterDivider,
} from "../jobCategory/jobCategoryRates/dataGridColDef";
import NoRowsOverlay from "../noRowsOverlay/noRowsOverlay";

export default function MusicPrepSummary({
  projectTask,
}: {
  projectTask?: ProjectTask_Entity;
}) {
  const apiRef = useGridApiRef();
  const scales = useAppSelector(scalesSelector);
  const taskTypes = useAppSelector(taskTypesSelector);
  const taskTypeMap = useAppSelector(taskTypesMapSelector);
  const talents = useAppSelector(talentsSelector);
  const dispatch = useDispatch();
  const projectID = useAppSelector(selectedProjectIDSelector);
  const project = useAppSelector(projectSelector(projectID));
  const librarianScales = useAppSelector(
    scalesForLibrarian(project?.projectTypeID)
  );

  const onSingleCellTaskUpdate = async (_new: any, _old: any) => {
    for (const key in _new) {
      if (Object.prototype.hasOwnProperty.call(_new, key)) {
        if (_new[key] != _old[key]) {
          // task Update
          let body = {
            [key]: _new[key],
          };
          if (key === "scale") {
            body = {
              scale: _new[key],
              rate: _new[key],
            };
          }
          dispatch(updateTask(_new.taskID, body));
        }
      }
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ ..._new });
      }, 50);
    });
  };

  const onMultipleCellTaskUpdate = async (_new: any, _old: any) => {
    return new Promise((resolve) => {
      for (const taskTypeID in _new) {
        if (Object.prototype.hasOwnProperty.call(_new, taskTypeID)) {
          if (_new[taskTypeID].value != _old[taskTypeID].value) {
            dispatch(
              updateTask(_new[taskTypeID].taskID, {
                [_new[taskTypeID].field]: _new[taskTypeID].value,
              })
            );
          }
        }
      }
      setTimeout(() => {
        resolve({ ..._new });
      }, 50);
    });
  };

  if (!projectTask) return <div />;

  const multipleCell = projectTask.id === 2;

  const dataGrid = useMemo(() => {
    return (
      <DataGridPro
        {...defaultDataGridProps}
        getCellClassName={(p) => {
          if (project?.locked()) return "lock";
          const classes = greyStyleForNonEditableCells(p);
          return classes;
        }}
        isCellEditable={(params) =>
          project?.locked() ? false : params.colDef.editable ?? false
        }
        apiRef={apiRef}
        processRowUpdate={
          multipleCell ? onMultipleCellTaskUpdate : onSingleCellTaskUpdate
        }
        rows={
          multipleCell
            ? getRowsForMultipleCell(projectTask.cues, scales, taskTypes)
            : getRowsForSingleCell(projectTask.cues)
        }
        columns={
          multipleCell
            ? getColumnsForMultipleCell(
                projectTask?.cues[0],
                scales,
                taskTypes,
                dispatch
              )
            : getColumnsForSingleCell(talents, librarianScales, taskTypeMap)
        }
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          NoRowsOverlay: NoRowsOverlay,
          Footer: MusicPrepSummaryFooter,
        }}
        componentsProps={{
          footer: { projectTask },
          cell: { tabIndex: 1 },
        }}
        initialState={{ pinnedColumns: { left: ["name"], right: ["total"] } }}
      />
    );
  }, [projectTask]);

  return <Paper sx={{ display: "flex", flex: 1 }}>{dataGrid}</Paper>;
}

function MusicPrepSummaryFooter(props: { projectTask: ProjectTask_Entity }) {
  return (
    <>
      <Divider />
      <Box sx={{ p: 2, textAlign: "right", fontWeight: 600 }}>
        {props.projectTask.id === 3 ? "Librarian: " : ""}
        {props.projectTask.id === 2 ? "Subtotal (no lib): " : ""}
        {currencyFormatter.format(props.projectTask.subTotal / 10000)}
      </Box>
    </>
  );
}

const getRowsForSingleCell = (
  cues: ProjectCue_Entity[]
): GridValidRowModel[] => {
  const rows = [];

  for (const k in cues) {
    if (Object.prototype.hasOwnProperty.call(cues, k)) {
      const cue = cues[k];
      const task = cue.tasks[0];
      const row = {
        name: cue.name,
        bars: cue.bars,
        id: cue.id,
        quantity: task.quantity,
        rate: task.rate,
        scale: task.scale,
        taskID: task.id,
        total: cue.total,
      };

      rows.push(row);
    }
  }

  return rows;
};

const getRowsForMultipleCell = (
  cues: ProjectCue_Entity[],
  scales: Scale_Entity[],
  taskTypes: TaskType_Entity[]
): GridValidRowModel[] => {
  const rows = [];

  for (const k in cues) {
    if (Object.prototype.hasOwnProperty.call(cues, k)) {
      const cue = cues[k];
      if (!cue.showInMPS) continue;
      const row = {
        name: cue.name,
        id: cue.id,
        total: cue.total,
        bars: cue.bars,
      };

      for (const j in cue.tasks) {
        if (Object.prototype.hasOwnProperty.call(cue.tasks, j)) {
          const task = cue.tasks[j];
          const scale = scales.find((s) => s.id === task.scaleID);
          const taskType = taskTypes.find((t) => t.id === scale?.taskTypeID);
          row[`${taskType?.id}`] = {
            value: task.quantity,
            taskID: task.id,
            field: "quantity",
            rate: task.rate,
          };
        }
      }

      rows.push(row);
    }
  }

  return rows;
};

const getColumnsForSingleCell = (
  talents: Talent_Entity[],
  librarianScales: Scale_Entity[],
  taskTypes: {
    [key: number]: TaskType_Entity;
  }
): GridColumns => {
  const cols: GridColumns = [
    {
      field: "name",
      headerName: "Cue",
      headerAlign: "center",
      editable: false,
      sortable: false,
      type: "string",
      width: 150,
      renderCell: (p) => (
        <Box sx={{ display: "flex", alignItems: "space-between" }}>
          <Typography variant="body2">{p.value}</Typography>
        </Box>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerAlign: "center",
      sortable: false,
      editable: true,
      type: "number",
      valueGetter: getterDivider("quantity", 100),
      valueSetter: setterDivider("quantity", 100),
    },
    {
      field: "rate",
      headerName: "Rate",
      headerAlign: "center",
      sortable: false,
      editable: true,
      type: "number",
      valueGetter: getterDivider("rate", 10000),
      valueSetter: setterDivider("rate", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`.substring(1),
    },
    {
      field: "scale",
      headerName: "Scale",
      headerAlign: "center",
      align: "right",
      sortable: false,
      editable: true,
      type: "singleSelect",
      valueOptions: getOptions(librarianScales, taskTypes),
      valueGetter: getterDivider("scale", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`.substring(1),
    },
    {
      field: "total",
      headerAlign: "center",
      headerName: "Total",
      sortable: false,
      editable: false,
      type: "number",
      valueGetter: getterDivider("total", 10000),
      valueSetter: setterDivider("total", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`.substring(1),
    },
  ];

  return injectDefaultColumnProps(cols);
};

const getColumnsForMultipleCell = (
  cue: ProjectCue_Entity,
  scales: Scale_Entity[],
  taskTypes: TaskType_Entity[],
  dispatch: Dispatch
): GridColumns => {
  const cols: GridColumns = [
    {
      field: "name",
      headerName: "Cue",
      headerAlign: "center",
      sortable: false,
      editable: false,
      type: "string",
      width: 150,
      renderCell: (p) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flex: 1,
              width: "100%",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">{p.value}</Typography>
            <Tooltip size="sm" placement="right" title="Bars">
              <Sheet
                variant="solid"
                sx={{
                  p: 0.5,
                  background: "#9e9e9e",
                  borderRadius: 4,
                  height: 16,
                  fontSize: 12,
                  display: "flex",
                  alignItems: "center",
                  position: "sticky",
                  right: 0,
                }}
              >
                {p.row.bars}
              </Sheet>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  if (!cue) return [];

  for (const k in cue.tasks) {
    if (Object.prototype.hasOwnProperty.call(cue.tasks, k)) {
      const task = cue.tasks[k];
      const scale = scales.find((s) => s.id === task.scaleID);
      const taskType = taskTypes.find((t) => t.id === scale?.taskTypeID);

      cols.push({
        field: `${taskType?.id}`,
        editable: true,
        headerName: `${taskType?.name}`,
        type: "number",
        sortable: false,
        align: "center",
        headerAlign: "center",
        valueFormatter: (p: GridValueFormatterParams) => {
          return p.value.value;
        },
        valueSetter: (p) => {
          const n = { ...p.row[`${taskType?.id}`], value: p.value * 100 };
          return { ...p.row, [`${taskType?.id}`]: n };
        },
        renderCell: (p) => <RenderCell p={p} scale={scale} />,
        valueGetter: (p) => p.value.value / 100,
        renderHeader: () => (
          <Box
            sx={{
              lineHeight: 0.8,
              textAlign: "center",
            }}
          >
            <Typography variant="body2">
              {taskType?.name.toUpperCase()}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {`${currencyFormatter.format(
                (scale?.rate ?? 0) / 10000
              )}`.substring(1)}
            </Typography>
          </Box>
        ),
      });
    }
  }

  cols.push({
    field: "total",
    headerAlign: "center",
    headerName: "Total",
    editable: false,
    sortable: false,
    type: "number",
    valueGetter: getterDivider("total", 10000),
    valueSetter: setterDivider("total", 10000),
    valueFormatter: (p: GridValueFormatterParams) =>
      `${currencyFormatter.format(p.value)}`.substring(1),
  });

  return injectDefaultColumnProps(cols);
};

const RenderCell = ({
  p,
  scale,
}: {
  p: GridRenderCellParams;
  scale?: Scale_Entity;
}) => {
  const [spin, setSpin] = useState(false);
  const project = useAppSelector(selectedProjectSelector);
  const dispatch = useDispatch();

  const handleReconcileRate = (p: GridRenderCellParams, rate: number) => {
    setSpin(true);
    const taskID = p.row[p.field].taskID;
    dispatch(
      updateTask(taskID, {
        rate,
      })
    );
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {p.row[p.field].rate !== scale?.rate && !project?.locked() ? (
        <Tooltip
          title={
            <div style={{ textAlign: "center", fontSize: 12 }}>
              Rate is not up-to-date:{" "}
              {currencyFormatter.format(p.row[p.field].rate / 10000)} instead of{" "}
              {currencyFormatter.format((scale?.rate ?? 0) / 10000)}
              <br />
              Click to reconcile locally or click on the{" "}
              <b>
                <u>Use latest rates</u>
              </b>{" "}
              button.
            </div>
          }
        >
          <IconButton
            onClick={() => handleReconcileRate(p, scale?.rate ?? 0)}
            size="small"
          >
            <i
              style={{ fontSize: 16, color: "#ff9800" }}
              className={`fa-solid fa-arrows-rotate ${spin ? "fa-spin" : ""}`}
            ></i>
          </IconButton>
        </Tooltip>
      ) : (
        []
      )}
      <Typography variant="body2">{p.value > 0 ? p.value : ""}</Typography>
    </Box>
  );
};

export type MultipleCell = {
  taskID: number;
  value: number;
  field: string;
};

function getOptions(
  items: Scale_Entity[],
  taskTypes: { [key: number]: TaskType_Entity }
) {
  const res = [];
  for (const key in items) {
    if (Object.prototype.hasOwnProperty.call(items, key)) {
      const item = items[key];
      res.push({
        value: item.rate,
        label: `${taskTypes[item.taskTypeID].name}: ${currencyFormatter.format(
          item.rate / 10000
        )}`,
      });
    }
  }

  return res;
}
