import {
  KronosTimerDialog,
  selectedProjectIDSelector,
  TimersDataGrid,
  timersForProjectSelector,
} from "@nerdjs/kronos-kit";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProjectTimers } from "../../../redux/project/projectActions";
import { useAppSelector } from "../../hooks";

/**
 *
 * @returns {ReactElement} ProjectTimers page
 */
export function ProjectTimers() {
  const dispatch = useDispatch();
  const projectID = useAppSelector(selectedProjectIDSelector);
  const timers = useAppSelector(timersForProjectSelector(projectID));

  useEffect(() => {
    if (projectID) dispatch(getProjectTimers(projectID, undefined));
  }, [projectID]);

  return (
    <>
      <TimersDataGrid timers={timers} searchEnabled={false} />
      <KronosTimerDialog />
    </>
  );
}
