import Vtransaction_Entity from "./vtransaction";
export default class Vtransaction extends Vtransaction_Entity {
  static fromList(vtransactionsJSON: unknown): Array<Vtransaction> {
    const vtransactions: Vtransaction[] = [];
    if (vtransactionsJSON)
      Array.isArray(vtransactionsJSON) &&
        vtransactionsJSON.forEach((vtransactionJSON) => {
          vtransactions.push(new Vtransaction(vtransactionJSON));
        });
    return vtransactions;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
