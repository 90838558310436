import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Button } from "@mui/joy";
import { Box, Divider, Paper, Tooltip, Typography } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Dispatch } from "@reduxjs/toolkit";
import { ConfirmOptions, useConfirm } from "material-ui-confirm";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import {
  defaultDataGridProps,
  injectDefaultColumnProps,
} from "../../constants";
import { Benefit_Entity } from "../../entities/benefit";
import { JobCategory_Entity } from "../../entities/jobCategory";
import { ProjectType_Entity } from "../../entities/projectType";
import {
  createBenefit,
  deleteBenefit,
  deleteBenefitSuccess,
  getBenefits,
  updateBenefit,
} from "../../redux/benefit/benefitActions";
import { benefitsSelector } from "../../redux/benefit/benefitSelector";
import { getJobCategories } from "../../redux/jobCategory/jobCategoryActions";
import { jobCategoriesSelector } from "../../redux/jobCategory/jobCategorySelector";
import { getProjectTypes } from "../../redux/projectType/projectTypeActions";
import { projectTypesSelector } from "../../redux/projectType/projectTypeSelector";
import { useAppSelector } from "../hooks";
import {
  getterDivider,
  setterDivider,
} from "../jobCategory/jobCategoryRates/dataGridColDef";
import NoRowsOverlay from "../noRowsOverlay/noRowsOverlay";

/**
 * Benefit
 *
 * @returns {null} Benefit
 */
export default function Benefits() {
  const benefits = useAppSelector(benefitsSelector);
  const projectTypes = useAppSelector(projectTypesSelector);
  const jobCategories = useAppSelector(jobCategoriesSelector);

  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const confirm = useConfirm();

  useEffect(() => {
    dispatch(getBenefits());
    dispatch(getJobCategories());
    dispatch(getProjectTypes());
  }, []);

  const onBenefitUpdate = async (
    _newBenefit: Benefit_Entity,
    _oldBenefit: Benefit_Entity
  ) => {
    return new Promise<Benefit_Entity>((resolve) => {
      dispatch(updateBenefit(_newBenefit.id, _newBenefit));
      setTimeout(() => {
        resolve({ ..._newBenefit });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1 }}>
      <DataGridPro
        {...defaultDataGridProps}
        apiRef={apiRef}
        processRowUpdate={onBenefitUpdate}
        rows={benefits}
        columns={benefitColumns(dispatch, confirm, projectTypes, jobCategories)}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: BenefitToolbar,
          NoRowsOverlay: NoRowsOverlay,
        }}
        initialState={{
          pinnedColumns: {
            left: ["id"],
            right: ["actions"],
          },
        }}
      />
    </Paper>
  );
}

function BenefitToolbar() {
  const dispatch = useDispatch();

  const handleNewBenefit = () => {
    const newBenefit = {
      id: -1,
      projectTypeID: 0,
      jobCategoryID: 0,
      pension: 0,
      vacation: 0,
      flexDivisor: 0,
      flexMultiplier: 0,
      flexDayHours: 0,
      name: "",
      createdAt: "",
      updatedAt: "",
    };
    dispatch(createBenefit(newBenefit));
  };

  useHotkeys(`ctrl+enter`, handleNewBenefit, {
    enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
  });

  return (
    <Box className="header" sx={{ display: "flex", p: 1 }}>
      <Tooltip title="ctrl + enter">
        <Button size="sm" variant="soft" onClick={handleNewBenefit}>
          + New Benefit
        </Button>
      </Tooltip>
    </Box>
  );
}

const benefitColumns = (
  dispatch: Dispatch,
  confirm: (options?: ConfirmOptions | undefined) => Promise<void>,
  projectTypes: ProjectType_Entity[],
  jobCategories: JobCategory_Entity[]
) =>
  injectDefaultColumnProps([
    {
      field: "id",
      headerName: "ID",
      editable: false,
      type: "number",
      width: 40,
    },
    {
      field: "projectTypeID",
      headerName: "Project Type",
      editable: true,
      width: 200,
      type: "singleSelect",
      valueOptions: getOptions(projectTypes),
      renderCell: (p: any) => {
        const projectType = projectTypes.find(
          (s) => s.id === p.row.projectTypeID
        );
        return <Typography variant="body2">{projectType?.name}</Typography>;
      },
    },
    {
      field: "jobCategoryID",
      headerName: "Job Category",
      editable: true,
      width: 200,
      type: "singleSelect",
      valueOptions: getOptions(jobCategories),
      renderCell: (p: any) => {
        const jobCategory = jobCategories.find(
          (s) => s.id === p.row.jobCategoryID
        );
        return <Typography variant="body2">{jobCategory?.name}</Typography>;
      },
    },
    {
      field: "pension",
      headerName: "Pension",
      editable: true,
      type: "number",
      width: 150,
      valueGetter: getterDivider("pension", 10000),
      valueSetter: setterDivider("pension", 10000),
    },
    {
      field: "vacation",
      headerName: "Vacation",
      editable: true,
      type: "number",
      width: 150,
      valueGetter: getterDivider("vacation", 10000),
      valueSetter: setterDivider("vacation", 10000),
      valueFormatter: (p: GridValueFormatterParams) => `${p.value}%`,
    },
    {
      field: "welfare",
      headerName: "Welfare",
      editable: true,
      type: "number",
      width: 150,
      valueGetter: getterDivider("welfare", 10000),
      valueSetter: setterDivider("welfare", 10000),
      valueFormatter: (p: GridValueFormatterParams) => `${p.value}%`,
    },
    {
      field: "flexDivisor",
      headerName: "Flex Divisor",
      editable: true,
      type: "number",
      width: 150,
      valueGetter: getterDivider("flexDivisor", 10000),
      valueSetter: setterDivider("flexDivisor", 10000),
    },
    {
      field: "flexMultiplier",
      headerName: "Flex Multiplier",
      editable: true,
      type: "number",
      width: 150,
      valueGetter: getterDivider("flexMultiplier", 10000),
      valueSetter: setterDivider("flexMultiplier", 10000),
    },
    {
      field: "flexDayHours",
      headerName: "Flex Day Hours",
      editable: true,
      type: "number",
      width: 150,
      valueGetter: getterDivider("flexDayHours", 100),
      valueSetter: setterDivider("flexDayHours", 100),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (props: { id: any }) => [
        <GridActionsCellItem
          key="delete"
          showInMenu
          icon={<DeleteIcon />}
          label={`Delete ${props.id}`}
          onClick={() => {
            confirm({
              description: `You are about to delete a benefit`,
            })
              .then(() => {
                dispatch(deleteBenefit(props.id, deleteBenefitSuccess));
              })
              .catch(() => {
                /* ... */
              });
          }}
          color="inherit"
        />,
      ],
    },
  ]);

function getOptions(items: { id: number; name?: string }[]) {
  const res = [];
  for (const key in items) {
    if (Object.prototype.hasOwnProperty.call(items, key)) {
      const item = items[key];
      res.push({
        value: item.id,
        label: `${item.id}: ${item.name}`,
      });
    }
  }

  return res;
}
