import { Close } from "@mui/icons-material";
import { Button, Alert } from "@mui/joy";
import {
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { withNetworkCompletionDispatch } from "@nerdjs/nerd-network";
import { useConfirm } from "material-ui-confirm";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Position_Entity } from "../../../entities/position";
import { Talent_Entity } from "../../../entities/talent";
import {
  ConfidentialTextFieldHelper,
  getOptions,
  SelectFieldHelper,
  TextFieldHelper,
} from "../../../helpers/formHelpers";
import { networkCompletionStackSelector } from "../../../redux/appStatus/appStatusSelector";
import { getPositions } from "../../../redux/position/positionActions";
import { positionsSelector } from "../../../redux/position/positionSelector";
import {
  deleteTalent,
  deleteTalentSuccess,
  getTalentByIdSuccess,
  setSelectedTalentID,
  updateTalent,
} from "../../../redux/talent/talentActions";
import { talentsMapSelector } from "../../../redux/talent/talentSelector";
import { RouterConfig } from "../../config/routerConfig";
import { useAppSelector } from "../../hooks";

/**
 * TalentForm
 * @params onChange
 * @returns {ReactElement} Talent profile
 */
export default function FormTalent(props: FormTalentProps) {
  const networkCompletionStack: Record<string, boolean>[] = useAppSelector(
    networkCompletionStackSelector
  );
  const confirm = useConfirm();
  const [pending, setPending] = useState(true);
  const [requestUUID, setRequestUUID] = useState("");
  const talents = useAppSelector(talentsMapSelector);
  const navigate = useNavigate();
  let talent = { ...props.talent } as Talent_Entity;
  if (props.talentID) talent = talents[props.talentID];

  const positions = useAppSelector(positionsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (positions.length === 0) {
      dispatch(getPositions());
    }
  }, []);

  useEffect(() => {
    if (!networkCompletionStack.some((e) => e[requestUUID])) setPending(false);
  }, [requestUUID, networkCompletionStack]);

  const handleTextChange = (name: string) => (value: string | number) => {
    if (!talent) return;
    talent[name] = value;
    if (props.onChange != undefined) {
      props.onChange(talent);
    } else {
      dispatch(getTalentByIdSuccess(talent));
    }
  };

  function save() {
    if (!talent || talent.id < 0) return;
    if (props.onSave != undefined) {
      props.onSave(talent);
    } else {
      const uuid = withNetworkCompletionDispatch(
        dispatch,
        updateTalent(talent.id, talent)
      );
      setRequestUUID(uuid);
    }
  }

  function deleteTalentRequest() {
    confirm({
      description: `You are about to delete the talent ${talent.firstName} ${talent.lastName}`,
    })
      .then(() => {
        dispatch(deleteTalent(talent.id, deleteTalentSuccess));
        navigate(`/${RouterConfig.talents}`);
      })
      .catch(() => {
        /* ... */
      });
  }

  return (
    <Grid container sx={style.root} spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {props.header != false ? (
          <Typography variant="h5">
            {talent && talent.id
              ? `${talent?.firstName} ${talent?.lastName}`
              : "New Talent"}
          </Typography>
        ) : (
          []
        )}
        <>
          {pending ? <CircularProgress size={20} /> : []}
          {props.onClose ? (
            <Tooltip title="Close Panel">
              <IconButton
                onClick={() =>
                  props.onClose != undefined
                    ? props.onClose()
                    : dispatch(setSelectedTalentID())
                }
              >
                <Close />
              </IconButton>
            </Tooltip>
          ) : (
            []
          )}
        </>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">General</Typography>
      </Grid>
      <Grid item xs={12}>
        {!talent.active ? (
          <Alert
            variant="solid"
            endDecorator={
              <Button
                variant={talent.active ? "soft" : "solid"}
                onClick={() => {
                  dispatch(
                    updateTalent(talent.id, {
                      ...talent,
                      active: !talent.active,
                    })
                  );
                }}
              >
                {talent.active ? "Archive" : "Unarchive"}
              </Button>
            }
          >
            {talent.firstName} {talent.lastName} is archived.
          </Alert>
        ) : (
          <Button
            variant={talent.active ? "soft" : "solid"}
            onClick={() => {
              dispatch(
                updateTalent(talent.id, { ...talent, active: !talent.active })
              );
            }}
          >
            {talent.active ? "Archive" : "Unarchive"} {talent.firstName}{" "}
            {talent.lastName}
          </Button>
        )}
      </Grid>
      <Grid item xs={5}>
        {TextFieldHelper<Talent_Entity>(
          talent,
          "First Name",
          "firstName",
          handleTextChange,
          save
        )}
      </Grid>
      <Grid item xs={5}>
        {TextFieldHelper<Talent_Entity>(
          talent,
          "Last Name",
          "lastName",
          handleTextChange,
          save
        )}
      </Grid>
      <Grid item xs={2}>
        {TextFieldHelper<Talent_Entity>(
          talent,
          "Code",
          "code",
          handleTextChange,
          save
        )}
      </Grid>
      <Grid item xs={12}>
        {SelectFieldHelper(
          talent,
          "Position",
          "positionID",
          getOptions<Position_Entity>(positions, "name"),
          handleTextChange,
          save
        )}
      </Grid>
      <Grid item xs={12}>
        {TextFieldHelper<Talent_Entity>(
          talent,
          "Company",
          "company",
          handleTextChange,
          save
        )}
      </Grid>
      <Grid item xs={12}>
        {TextFieldHelper<Talent_Entity>(
          talent,
          "Email",
          "email",
          handleTextChange,
          save,
          { type: "email" }
        )}
      </Grid>
      <Grid item xs={12}>
        {TextFieldHelper<Talent_Entity>(
          talent,
          "Phone",
          "phone",
          handleTextChange,
          save,
          { type: "phone" }
        )}
      </Grid>
      <Grid item xs={12}>
        {TextFieldHelper<Talent_Entity>(
          talent,
          "Dropbox",
          "dropbox",
          handleTextChange,
          save
        )}
      </Grid>
      <Grid item xs={12}>
        {TextFieldHelper<Talent_Entity>(
          talent,
          "Google",
          "google",
          handleTextChange,
          save
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Personal Information</Typography>
      </Grid>
      <Grid item xs={12}>
        {TextFieldHelper<Talent_Entity>(
          talent,
          "Birthdate",
          "birthDate",
          handleTextChange,
          save,
          { type: "date" },
          undefined,
          { shrink: true }
        )}
      </Grid>
      <Grid item xs={12}>
        {TextFieldHelper<Talent_Entity>(
          talent,
          "Tax Status",
          "taxStatus",
          handleTextChange,
          save
        )}
      </Grid>
      <Grid item xs={12}>
        {TextFieldHelper<Talent_Entity>(
          talent,
          "Address",
          "address",
          handleTextChange,
          save
        )}
      </Grid>
      <Grid item xs={6}>
        {TextFieldHelper<Talent_Entity>(
          talent,
          "City",
          "city",
          handleTextChange,
          save
        )}
      </Grid>
      <Grid item xs={3}>
        {TextFieldHelper<Talent_Entity>(
          talent,
          "Zipcode",
          "zipcode",
          handleTextChange,
          save
        )}
      </Grid>
      <Grid item xs={3}>
        {TextFieldHelper<Talent_Entity>(
          talent,
          "State",
          "state",
          handleTextChange,
          save
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Identification</Typography>
      </Grid>
      <Grid item xs={12}>
        {ConfidentialTextFieldHelper<Talent_Entity>(
          talent,
          "SSN",
          "ssn",
          handleTextChange,
          save,
          { type: "password" }
        )}
      </Grid>
      <Grid item xs={12}>
        {ConfidentialTextFieldHelper<Talent_Entity>(
          talent,
          "EIN",
          "ein",
          handleTextChange,
          save,
          { type: "password" }
        )}
      </Grid>
      <Grid item xs={12}>
        {TextFieldHelper<Talent_Entity>(
          talent,
          "UUID",
          "userUUID",
          handleTextChange,
          save
        )}
      </Grid>
      {/* {talent.id > 0 ? (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Danger Zone</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="small"
              variant="outlined"
              color="error"
              onClick={deleteTalentRequest}
            >
              <i className="fa-duotone fa-trash-can pright"></i> Delete
            </Button>
          </Grid>
        </>
      ) : (
        []
      )} */}
    </Grid>
  );
}

const style = {
  root: {
    p: 2,
  },
};

export type FormTalentProps = {
  talentID?: number;
  header?: boolean;
  talent?: Talent_Entity;
  onChange?: (talent: Talent_Entity) => void;
  onSave?: (talent: Talent_Entity) => void;
  onClose?: () => void;
};
