import { createSelector } from "@reduxjs/toolkit";
import { Talent_Entity } from "../../entities/talent";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectJobs = (state: AppState) => state.jobState.jobs;
const selectTalents = (state: AppState) => state.talentState.talents;
const selectJobsByProject = (state: AppState) => state.jobState.jobsByProject;

export const jobsSelector = createSelector([selectJobs], (jobs) => {
  return mapToArray(jobs);
});

export const jobsMapSelector = createSelector([selectJobs], (jobs) => {
  return jobs;
});

export const jobSelector = (id?: number) => {
  return createSelector([selectJobs], (jobs) => {
    if (id) return jobs[id];
    return undefined;
  });
};

export const jobsByProjectSelector = (projectID?: number) => {
  return createSelector(
    [selectJobsByProject, selectTalents],
    (jobsByProject, talents) => {
      if (projectID === undefined) return [];
      let ret = [...(jobsByProject[projectID] ?? [])];

      ret = ret.sort((a, b) => {
        const aTalent: Talent_Entity = talents[a.talentID];
        const bTalent: Talent_Entity = talents[b.talentID];

        if (!aTalent) return -1;
        if (!bTalent) return 1;
        if (a.orchestrator && b.orchestrator) {
          return aTalent.lastName.localeCompare(bTalent.lastName);
        } else if (a.orchestrator) {
          return -1;
        } else if (b.orchestrator) {
          return 1;
        }

        return aTalent.lastName.localeCompare(bTalent.lastName);
      });

      return ret;
    }
  );
};

export const jobsForTalent = (id?: number) => {
  return createSelector([selectJobs], (jobs) => {
    const j = mapToArray(jobs);
    return j.filter((e) => e.talentID === id).sort((a, b) => b.id - a.id);
  });
};
