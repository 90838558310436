import { Box } from "@mui/material";
import {
  getTimers,
  KronosTimerDialog,
  TimersDataGrid,
} from "@nerdjs/kronos-kit";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

/**
 *
 * @returns {ReactElement} Timers page
 */
export function Timers() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTimers());
  }, []);

  return (
    <Box sx={{ p: 2, flex: 1, display: "flex" }}>
      <TimersDataGrid searchEnabled />
      <KronosTimerDialog />
    </Box>
  );
}
