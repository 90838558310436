import { ProjectCue } from "../projectCue";

/**
 * ProjectTask_Entity type guard.
 *
 * @param {any} projectTaskJson ProjectTask object from API
 * @returns {boolean} Return true if type is ProjectTask_Entity
 */
function validator(
  projectTaskJson: unknown
): projectTaskJson is ProjectTask_Entity {
  if (typeof projectTaskJson === "object" && projectTaskJson != null) {
    ProjectTask_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(projectTaskJson).includes(element))
        throw new Error(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class ProjectTask_Entity {
  static requiredFields = ["id"];

  constructor(projectTaskJson: unknown) {
    if (validator(projectTaskJson)) {
      this.id = projectTaskJson.id;
      this.name = projectTaskJson.name;
      this.subTotal = projectTaskJson.subTotal;
      this.cues = ProjectCue.fromList(projectTaskJson.cues);
    } else
      throw new Error(
        `Failed to create new instance of ${ProjectTask_Entity.name}`
      );
  }

  id: number;
  name: string;
  cues: ProjectCue[];
  subTotal: number;
}
