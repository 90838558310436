import { GridSelectionModel } from "@mui/x-data-grid-pro";
import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Cue, Cue_Entity } from "../../entities/cue";

export const setSelectedCueID = createAction(
  "setSelectedCueID",
  function prepare(cueID?: number) {
    return {
      payload: {
        cueID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setBulkSelect = createAction(
  "setBulkSelect",
  function prepare(bulkSelect: boolean) {
    return {
      payload: {
        bulkSelect,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setSelectedGridCues = createAction(
  "setSelectedGridCues",
  function prepare(selectedGridCues?: GridSelectionModel) {
    return {
      payload: {
        selectedGridCues,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getCues = createAction("films/cue/getCues", function prepare() {
  const request = new GetAction(`/films/cues`);

  request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
    const cues = Cue.fromList(json);
    dispatch(getCuesSuccess(cues));
  };

  return {
    payload: {
      id: nanoid(),
      createdAt: new Date().toISOString(),
      request,
    },
  };
});

export const getCuesSuccess = createAction(
  "films/cue/getCueSuccess",
  function prepare(cues: Cue[]) {
    return {
      payload: {
        cues,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getCuesByFilter = createAction(
  "films/cue/getCuesByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/cues`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const cues = Cue.fromList(json);
      dispatch(getCuesSuccess(cues));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getCuesByFilterSuccess = createAction(
  "films/cue/getCuesByFilterSuccess",
  function prepare(cues: Cue[]) {
    return {
      payload: {
        cues,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getCueById = createAction(
  "films/cue/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/cues/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const cue = new Cue(json);
      dispatch(getCueByIdSuccess(cue));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getCueByIdSuccess = createAction(
  "films/cue/getCueByIdSuccess",
  function prepare(cue?: Cue_Entity) {
    return {
      payload: {
        cue,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteCue = createAction(
  "films/removeCueById",
  function prepare(
    id: number,
    successAction?: AnyAction,
    callback?: () => void
  ) {
    const request = new DeleteAction(`/films/cues/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        if (callback) callback();
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteCueSuccess = createAction(
  "films/cues/deleteCueSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateBatchCues = createAction(
  "films/cues/updateBatchCues",
  function prepare(body: Partial<Cue_Entity>[]) {
    const request = new PutAction(`/films/cues`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateBatchCuesSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateBatchCuesSuccess = createAction(
  "films/cues/updateBatchCuesSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateCue = createAction(
  "films/cues/updateCue",
  function prepare(
    id: number,
    body: Partial<Cue_Entity>,
    callback?: () => void
  ) {
    const request = new PutAction(`/films/cues/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      if (callback) callback();
      dispatch(updateCueSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateCueSuccess = createAction(
  "films/cues/updateCueSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createCue = createAction(
  "films/cues/createCue",
  function prepare(body: any, callback?: () => void) {
    const request = new PostAction(`/films/cues`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      if (callback) callback();
      const cue = new Cue(json);
      dispatch(createCueSuccess(cue));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createCueSuccess = createAction(
  "films/cues/createCueSuccess",
  function prepare(cue: Cue) {
    return {
      payload: {
        cue,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getCuesSearchMetadata = createAction(
  "films/getCuesSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/cues/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getCuesSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getCuesSearchMetadataSuccess = createAction(
  "films/getCuesSearchMetadataSuccess",
  function prepare(cuesSearchMetadata: unknown) {
    return {
      payload: {
        cuesSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
