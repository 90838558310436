import { userSignout } from "@nerdjs/account-kit";
import { getCurrentTimer } from "@nerdjs/kronos-kit";
import { showNotification } from "@nerdjs/nerd-ui";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { AppConfig } from "../environement";
import { store } from "./store";

export const mainMiddleware: Middleware =
  (api: MiddlewareAPI<Dispatch<AnyAction>, unknown>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    if (action.type == userSignout) {
      setTimeout(() => {
        window.location.replace(AppConfig.front.baseHostname);
      }, 500);
    }

    if (action.type === "ApiErrorAction") {
      const {
        uuid,
        status,
        description = "",
        message = "",
      } = action.payload.error;

      if (status === 401) {
        window.location.replace(AppConfig.front.baseHostname);
      }

      if (
        action.payload.originAction.payload.request.url !==
        AppConfig.api.currentUser
      )
        if (
          action.payload.originAction.type != getCurrentTimer &&
          status === 403
        )
          store.dispatch(
            showNotification({
              notification: {
                uuid: uuid,
                title: `${status} - ${description}`,
                body: message,
                severity: status > 300 && status < 500 ? "warning" : "error",
                autohide: true,
              },
            })
          );
    }

    return next(action);
  };
