import { JobTask } from "../jobTask";

/**
 * Task_Entity type guard.
 *
 * @param {any} taskJson Task object from API
 * @returns {boolean} Return true if type is Task_Entity
 */
function validator(taskJson: unknown): taskJson is Task_Entity {
  if (typeof taskJson === "object" && taskJson != null) {
    Task_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(taskJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Task_Entity {
  static requiredFields = [
    "id",
    "cueID",
    "scaleID",
    "rate",
    "quantity",
    "scale",
    "total",
    "date",
    "jobTasks",
    "createdAt",
    "updatedAt",
  ];

  constructor(taskJson: unknown) {
    if (validator(taskJson)) {
      this.id = taskJson.id;
      this.cueID = taskJson.cueID;
      this.scaleID = taskJson.scaleID;
      this.rate = taskJson.rate;
      this.quantity = taskJson.quantity;
      this.scale = taskJson.scale;
      this.total = taskJson.total;
      this.date = taskJson.date;
      this.jobTasks = JobTask.fromList(taskJson.jobTasks);
      this.createdAt = taskJson.createdAt;
      this.updatedAt = taskJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Task_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  cueID: number;
  scaleID: number;
  rate: number;
  quantity: number;
  scale: number;
  total: number;
  date: string;
  jobTasks: Array<JobTask>;
  createdAt: string;
  updatedAt: string;
}
