import { Button, Checkbox } from "@mui/joy";
import { Avatar, Box, IconButton, Paper, Tooltip } from "@mui/material";
import {
  DataGridPro,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Dispatch } from "@reduxjs/toolkit";
import { ConfirmOptions, useConfirm } from "material-ui-confirm";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  defaultDataGridProps,
  injectDefaultColumnProps,
} from "../../constants";
import { Position_Entity } from "../../entities/position";
import { positionsSelector } from "../../redux/position/positionSelector";
import {
  getTalents,
  setSelectedTalentID,
  setShowArchived,
} from "../../redux/talent/talentActions";
import {
  showArchivedSelector,
  talentsSelector,
} from "../../redux/talent/talentSelector";
import { useAppSelector } from "../hooks";
import NoRowsOverlay from "../noRowsOverlay/noRowsOverlay";

/**
 * Talent
 *
 * @returns {null} Talent
 */
export default function Talents({ onCreate }: { onCreate: () => void }) {
  const showArchived = useAppSelector(showArchivedSelector);
  const talents = useAppSelector(talentsSelector).filter((p) => {
    if (!showArchived) {
      return p.active;
    } else return true;
  });
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const positions = useAppSelector(positionsSelector);

  useEffect(() => {
    dispatch(getTalents());
  }, []);

  const handleClick = (id: number) => {
    navigate(`${id}`);
    dispatch(setSelectedTalentID(id));
  };

  return (
    <Paper sx={{ flex: 1 }}>
      <DataGridPro
        {...defaultDataGridProps}
        getCellClassName={(c) => (c.field === "id" ? "cellReadOnly" : "")}
        apiRef={apiRef}
        onRowClick={(p) => handleClick(p.row.id)}
        rows={talents}
        columns={talentColumns(dispatch, positions, confirm)}
        experimentalFeatures={{ newEditingApi: true }}
        getDetailPanelHeight={() => 100}
        components={{
          Toolbar: TalentToolbar,
          NoRowsOverlay: NoRowsOverlay,
        }}
        componentsProps={{
          toolbar: { onCreate },
          cell: { tabIndex: 1 },
        }}
      />
    </Paper>
  );
}

function TalentToolbar({ onCreate }: { onCreate: () => void }) {
  const dispatch = useDispatch();
  const showArchived = useAppSelector(showArchivedSelector);
  return (
    <Box
      className="header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        gap: 2,
        alignItems: "center",
      }}
    >
      <Button variant="soft" size="sm" onClick={onCreate}>
        + New Talent
      </Button>
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
      <Checkbox
        size="sm"
        variant="outlined"
        label="Show Archived"
        onChange={(e) => dispatch(setShowArchived(e.target.checked))}
        checked={showArchived}
      ></Checkbox>
    </Box>
  );
}

const talentColumns = (
  dispatch: Dispatch,
  positions: Position_Entity[],
  confirm: (options?: ConfirmOptions | undefined) => Promise<void>
) =>
  injectDefaultColumnProps([
    {
      field: "id",
      headerName: "ID",
      type: "string",
      width: 40,
    },
    {
      field: "code",
      headerName: "",
      width: 30,
      type: "string",
      renderCell: (p) => (
        <Avatar
          sx={{
            height: 28,
            width: 28,
            fontSize: 14,
            color: "text.primary",
            background: p.row.active
              ? "rgba(33,150,243,0.3)"
              : "rgba(155,155,155,0.3)",
          }}
        >
          {(p.value ?? "-").toUpperCase()}
        </Avatar>
      ),
    },
    {
      field: "firstName",
      headerName: "First Name",
      type: "string",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      type: "string",
    },
    {
      field: "birthDate",
      headerName: "Birthdate",
      type: "string",
      width: 130,
      valueFormatter: (p) => {
        if (p.value === "0001-01-01T00:00:00Z") return "Not set";
        const now = moment();
        const date = moment(p.value);
        if (date.isSame(now, "d")) {
          return `🎉 ${date.format("MM/DD/YYYY")}`;
        }
        return `${date.format("MM/DD/YYYY")}`;
      },
    },
    {
      field: "positionID",
      headerName: "Position",
      type: "string",
      width: 200,
      valueFormatter: (p) =>
        positions.find((t) => t.id === p.value)?.name ?? "",
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      type: "string",
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 100,
      type: "string",
    },
    {
      field: "dropbox",
      headerName: " ",
      headerAlign: "center",
      align: "center",
      width: 50,
      type: "string",
      renderCell: (p) => {
        if (p.value) {
          return (
            <Tooltip
              title={
                <span>
                  Open Dropbox
                  <i className="fa-solid fa-up-right-from-square pleft"></i>
                </span>
              }
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  window.open(
                    p.value.indexOf("http") >= 0
                      ? p.value
                      : `https://${p.value}`,
                    "_blank"
                  );
                }}
              >
                <i className="fa-brands fa-dropbox"></i>
              </IconButton>
            </Tooltip>
          );
        }
        return <Box />;
      },
    },
    {
      field: "google",
      headerName: " ",
      headerAlign: "center",
      align: "center",
      width: 50,
      type: "string",
      renderCell: (p) => {
        if (p.value) {
          return (
            <Tooltip
              title={
                <span>
                  Open Google Drive
                  <i className="fa-solid fa-up-right-from-square pleft"></i>
                </span>
              }
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  window.open(
                    p.value.indexOf("http") >= 0
                      ? p.value
                      : `https://${p.value}`,
                    "_blank"
                  );
                }}
              >
                <i className="fa-brands fa-google-drive"></i>
              </IconButton>
            </Tooltip>
          );
        }
        return <Box />;
      },
    },
  ]);
