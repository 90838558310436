import { Paper } from "@mui/material";
import {
  DataGridPro,
  GridColumns,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useDispatch } from "react-redux";
import { currencyFormatter, defaultDataGridProps } from "../../../constants";
import { updateProjectType } from "../../../redux/projectType/projectTypeActions";
import { projectTypesSelector } from "../../../redux/projectType/projectTypeSelector";
import { updateScale } from "../../../redux/scale/scaleActions";
import { scalesSelector } from "../../../redux/scale/scaleSelector";
import { taskTypesSelector } from "../../../redux/taskType/taskTypeSelector";
import { useAppSelector } from "../../hooks";

export default function JobCategoryRates({
  jobCategoryID,
}: {
  jobCategoryID: number;
}) {
  const projectTypes = useAppSelector(projectTypesSelector);
  const apiRef = useGridApiRef();
  const taskTypes = useAppSelector(taskTypesSelector).filter(
    (tt) => tt.jobCategoryID === jobCategoryID
  );
  const scales = useAppSelector(scalesSelector);
  const dispatch = useDispatch();

  const processRowUpdate = async (_newRow: any, _oldRow: any) => {
    return new Promise<any>((resolve) => {
      projectTypes.forEach((pt) => {
        if (_newRow[pt.name].rate != _oldRow[pt.name].rate) {
          if (_newRow[pt.name].id === 0) {
            // Min pay for Copyists
            dispatch(
              updateProjectType(_newRow[pt.name].projectTypeID, {
                minimumCopyist: _newRow[pt.name].rate,
              })
            );
          } else {
            dispatch(updateScale(_newRow[pt.name].id, _newRow[pt.name]));
          }
        }
      });
      setTimeout(() => {
        resolve({ ..._newRow });
      }, 50);
    });
  };

  const getColumns = () => {
    const ret: GridColumns<any> = [
      {
        field: "taskType",
        headerName: "Task",
        width: 150,
      },
    ];

    projectTypes.forEach((pt) => {
      ret.push({
        field: pt.name,
        headerName: pt.name,
        width: 150,
        editable: true,
        type: "number",
        align: "right",
        headerAlign: "center",
        valueGetter: (p) => {
          return p.row[pt.name].rate / 10000;
        },
        valueSetter: (p) => {
          const ret = JSON.parse(JSON.stringify(p.row));
          ret[pt.name].rate = Math.round(p.value * 10000);

          return ret;
        },
        valueFormatter: (p: GridValueFormatterParams) =>
          `${currencyFormatter.format(p.value)}`.substring(1),
      });
    });

    return ret;
  };

  const getRows = () => {
    const ret: any[] = [];
    if (jobCategoryID === 2) {
      const row = {
        id: 0,
        taskType: "Minimum Pay",
      };
      projectTypes.forEach((pt) => {
        row[pt.name] = { id: 0, rate: pt.minimumCopyist, projectTypeID: pt.id };
      });

      ret.push(row);
    }
    taskTypes.forEach((tt) => {
      const row = {
        id: tt.id,
        taskType: tt.name,
      };
      projectTypes.forEach((pt) => {
        const scale = scales.find(
          (s) => s.projectTypeID === pt.id && s.taskTypeID === tt.id
        );
        row[pt.name] = scale;
      });
      ret.push(row);
    });

    return ret;
  };

  return (
    <Paper sx={{ flex: 1, height: "calc(100vh - 130px)" }}>
      <DataGridPro
        apiRef={apiRef}
        processRowUpdate={processRowUpdate}
        {...defaultDataGridProps}
        rows={getRows()}
        columns={getColumns()}
        experimentalFeatures={{ newEditingApi: true }}
        componentsProps={{
          cell: { tabIndex: 1 },
        }}
      />
    </Paper>
  );
}
