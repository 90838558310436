import { Close } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { withNetworkCompletionDispatch } from "@nerdjs/nerd-network";
import { useConfirm } from "material-ui-confirm";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Project_Entity } from "../../../entities/project";
import { ProjectType_Entity } from "../../../entities/projectType";
import {
  getOptions,
  SelectFieldHelper,
  TextFieldHelper,
} from "../../../helpers/formHelpers";
import { networkCompletionStackSelector } from "../../../redux/appStatus/appStatusSelector";
import {
  deleteProject,
  deleteProjectSuccess,
  getProjectByIdSuccess,
  setSelectedProjectID,
  updateProject,
} from "../../../redux/project/projectActions";
import { projectsMapSelector } from "../../../redux/project/projectSelector";
import { getProjectTypes } from "../../../redux/projectType/projectTypeActions";
import { projectTypesSelector } from "../../../redux/projectType/projectTypeSelector";
import { useAppSelector } from "../../hooks";

/**
 * ProjectForm
 * @params onChange
 * @returns {ReactElement} Project profile
 */
export default function FormProject(props: FormProjectProps) {
  const networkCompletionStack: Record<string, boolean>[] = useAppSelector(
    networkCompletionStackSelector
  );
  const confirm = useConfirm();
  const [pending, setPending] = useState(true);
  const [requestUUID, setRequestUUID] = useState("");
  const projects = useAppSelector(projectsMapSelector);
  let project = { ...props.project } as Project_Entity;
  if (props.projectID) project = projects[props.projectID];

  const projectTypes = useAppSelector(projectTypesSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (projectTypes.length === 0) {
      dispatch(getProjectTypes());
    }
  }, []);

  useEffect(() => {
    if (!networkCompletionStack.some((e) => e[requestUUID])) setPending(false);
  }, [requestUUID, networkCompletionStack]);

  const handleTextChange = (name: string) => (value: string | number) => {
    if (!project) return;
    project[name] = value;
    if (props.onChange != undefined) {
      props.onChange(project);
    } else {
      dispatch(getProjectByIdSuccess(project));
    }
  };

  const handleCheckboxChange = (name: string) => (checked: boolean) => {
    if (!project) return;
    project[name] = checked;
    if (props.onChange != undefined) {
      props.onChange(project);
    } else {
      dispatch(getProjectByIdSuccess(project));
    }
  };

  function save() {
    if (!project || project.id < 0) return;
    if (props.onSave != undefined) {
      props.onSave(project);
    } else {
      const uuid = withNetworkCompletionDispatch(
        dispatch,
        updateProject(project.id, project)
      );
      setRequestUUID(uuid);
      dispatch(updateProject(project.id, project));
    }
  }

  function deleteProjectRequest() {
    confirm({
      description: `You are about to delete the project ${project.name}`,
    })
      .then(() => {
        dispatch(deleteProject(project.id, deleteProjectSuccess));
      })
      .catch(() => {
        /* ... */
      });
  }

  return (
    <Grid container sx={style.root} spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">
          {project && project.name ? `${project?.name}` : "New Project"}
        </Typography>
        <>
          {pending ? <CircularProgress size={20} /> : []}
          <Tooltip title="Close Panel">
            <IconButton
              onClick={() =>
                props.onClose != undefined
                  ? props.onClose()
                  : dispatch(setSelectedProjectID())
              }
            >
              <Close />
            </IconButton>
          </Tooltip>
        </>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">General</Typography>
      </Grid>
      <Grid item xs={12}>
        {TextFieldHelper<Project_Entity>(
          project,
          "Name",
          "name",
          handleTextChange,
          save
        )}
      </Grid>
      <Grid item xs={6}>
        {TextFieldHelper<Project_Entity>(
          project,
          "Reference Number",
          "referenceNumber",
          handleTextChange,
          save
        )}
      </Grid>
      <Grid item xs={6}>
        {SelectFieldHelper(
          project,
          "Project Type",
          "projectTypeID",
          getOptions<ProjectType_Entity>(projectTypes, "name"),
          handleTextChange,
          save
        )}
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={project?.union}
              onChange={(e) => {
                handleCheckboxChange("union")(e.target.checked);
              }}
            />
          }
          label="Union Project"
        />
      </Grid>
      {/* <Grid item xs={12}>
        {TextFieldHelper<Project_Entity>(
          project,
          "Total Wages",
          "totalWages",
          handleTextChange,
          save,
          {
            type: "number",
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            },
          },
          10000
        )}
      </Grid>
      <Grid item xs={12}>
        {TextFieldHelper<Project_Entity>(
          project,
          "Total HW",
          "totalHW",
          handleTextChange,
          save,
          {
            type: "number",
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            },
          },
          10000
        )}
      </Grid>
      <Grid item xs={12}>
        {TextFieldHelper<Project_Entity>(
          project,
          "Total Pension",
          "totalPension",
          handleTextChange,
          save,
          {
            type: "number",
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            },
          },
          10000
        )}
      </Grid>
      <Grid item xs={12}>
        {TextFieldHelper<Project_Entity>(
          project,
          "Total Expenses",
          "totalExpenses",
          handleTextChange,
          save,
          {
            type: "number",
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            },
          },
          10000
        )}
      </Grid> */}
      {project.id > 0 ? (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Danger Zone</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="small"
              variant="outlined"
              color="error"
              onClick={deleteProjectRequest}
            >
              <i className="fa-duotone fa-trash-can pright"></i> Delete
            </Button>
          </Grid>
        </>
      ) : (
        []
      )}
    </Grid>
  );
}

const style = {
  root: {
    p: 2,
  },
};

export type FormProjectProps = {
  projectID?: number;
  project?: Partial<Project_Entity>;
  onChange?: (project: Project_Entity) => void;
  onSave?: (project: Project_Entity) => void;
  onClose?: () => void;
};
