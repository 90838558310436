/**
 * Benefit_Entity type guard.
 *
 * @param {any} benefitJson Benefit object from API
 * @returns {boolean} Return true if type is Benefit_Entity
 */
function validator(benefitJson: unknown): benefitJson is Benefit_Entity {
  if (typeof benefitJson === "object" && benefitJson != null) {
    Benefit_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(benefitJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Benefit_Entity {
  static requiredFields = [
    "id",
    "projectTypeID",
    "jobCategoryID",
    "pension",
    "vacation",
    "welfare",
    "flexDivisor",
    "flexMultiplier",
    "flexDayHours",
    "createdAt",
    "updatedAt",
  ];

  constructor(benefitJson: unknown) {
    if (validator(benefitJson)) {
      this.id = benefitJson.id;
      this.projectTypeID = benefitJson.projectTypeID;
      this.jobCategoryID = benefitJson.jobCategoryID;
      this.pension = benefitJson.pension;
      this.vacation = benefitJson.vacation;
      this.welfare = benefitJson.welfare;
      this.flexDivisor = benefitJson.flexDivisor;
      this.flexMultiplier = benefitJson.flexMultiplier;
      this.flexDayHours = benefitJson.flexDayHours;
      this.createdAt = benefitJson.createdAt;
      this.updatedAt = benefitJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Benefit_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  projectTypeID: number;
  jobCategoryID: number;
  pension: number;
  vacation: number;
  welfare: number;
  flexDivisor: number;
  flexMultiplier: number;
  flexDayHours: number;
  createdAt: string;
  updatedAt: string;
}
