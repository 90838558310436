import { Alert } from "@mui/joy";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ProjectCue_Entity } from "../../entities/projectCue";
import { Task, Task_Entity } from "../../entities/task";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { formOpenSelector } from "../../redux/appStatus/appStatusSelector";
import { projectTasksSelector } from "../../redux/projectTask/projectTaskSelector";
import { scalesMapSelector } from "../../redux/scale/scaleSelector";
import { updateBatchTasks } from "../../redux/task/taskActions";
import { taskTypesMapSelector } from "../../redux/taskType/taskTypeSelector";
import { useAppSelector } from "../hooks";

/**
 *
 * @returns {ReactElement} CueBatch page
 */
export function MPSBatch() {
  const open = useAppSelector(formOpenSelector("mpsBatch"));

  const [text, setText] = useState("");
  const projectTasks = useAppSelector(projectTasksSelector);
  const taskTypesMap = useAppSelector(taskTypesMapSelector);
  const scalesMap = useAppSelector(scalesMapSelector);
  const projectTask = projectTasks.length ? projectTasks[1] : undefined;
  const firstCue = projectTask?.cues[0];
  const [data, setData] = useState<string[][]>([]);

  const cuesLength = projectTask?.cues.filter((c) => c.showInMPS).length;

  const dispatch = useDispatch();

  const parse = () => {
    const rows = text.split("\n");
    const data: string[][] = [];

    for (const i in rows) {
      const cells = rows[i].split("\t");
      const row: string[] = [];
      cells.forEach((c) => {
        row.push(`${c}`);
      });
      data.push(row);
    }

    if (data.length != cuesLength) {
      return alert(
        `Your selection doesn't contain exacly ${cuesLength} cues. Make sure the format on Google Sheet matches the database.`
      );
    }

    if (data[0].length != firstCue?.tasks.length) {
      return alert(
        `Your selection doesn't contain exacly ${firstCue?.tasks.length} columns. Make sure the format on Google Sheet matches the database.`
      );
    }

    setData(data);
    setText("");
  };

  const importMPS = () => {
    const tasks: Partial<Task_Entity>[] = [];
    for (const k in projectTask?.cues) {
      if (Object.prototype.hasOwnProperty.call(projectTask?.cues, k)) {
        const cue: ProjectCue_Entity = projectTask?.cues[k];

        for (const j in cue.tasks) {
          if (Object.prototype.hasOwnProperty.call(cue.tasks, j)) {
            const task = cue.tasks[j];
            const quantity = Number(data[k][j] ?? 0) * 100;
            const body: Partial<Task> = {
              id: task.id,
              scaleID: task.scaleID,
              rate: task.rate,
              quantity,
            };
            tasks.push(body);
          }
        }
      }
    }
    dispatch(updateBatchTasks(tasks));
    dispatch(setFormOpen(false, "mpsBatch"));
  };

  return (
    <Dialog
      open={open}
      fullWidth={data.length > 0}
      maxWidth={data.length ? "lg" : undefined}
      onClose={() => {
        dispatch(setFormOpen(false, "mpsBatch"));
      }}
    >
      <DialogTitle>Import Music Prep Summary</DialogTitle>
      {data.length ? (
        <DialogContent
          sx={{ display: "flex", gap: 1, flexDirection: "column" }}
        >
          <Alert>
            <Box>
              The Music Prep Summary for Copyist will be replaced with the
              following data. <br />
              <b>Carefully review the content before importing</b>.
            </Box>
          </Alert>
          <TableContainer component={Paper}>
            <Table
              size="small"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead className="MuiDataGrid-columnHeaders">
                <TableRow>
                  <TableCell>Cue</TableCell>
                  {data[0].map((d, i) => {
                    const task = firstCue?.tasks[i];
                    const scale = scalesMap[task!.scaleID];
                    const taskType = taskTypesMap[scale!.taskTypeID];

                    return (
                      <TableCell className="cell" key={d}>
                        <Typography variant="body2">
                          {taskType.name.toUpperCase()}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((d, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell className="MuiDataGrid-columnHeaders">
                      <Typography variant="body2">
                        {projectTask?.cues[i].name}
                      </Typography>
                    </TableCell>
                    {d.map((r) => (
                      <TableCell
                        className="cell"
                        component="th"
                        scope="row"
                        key={r}
                        sx={{ textAlign: "center" }}
                      >
                        <Typography variant="body2">{r}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      ) : (
        <DialogContent>
          Copy the Music Prep Summary from Google Sheet:
          <br />
          <ul>
            <li>Do not include headers.</li>
            <li>Do not include cue names.</li>
            <li>Selection must contain {cuesLength} rows (cues).</li>
            <li>Selection must contain {firstCue?.tasks.length} columns.</li>
          </ul>
          <br />
          <TextField
            autoComplete="off"
            placeholder="Paste here..."
            fullWidth
            multiline
            onChange={(e) => setText(e.target.value)}
            maxRows={10}
          />
        </DialogContent>
      )}
      <DialogActions>
        {data.length ? (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button onClick={() => setData([])}>Restart</Button>
            <Button variant="contained" onClick={importMPS}>
              Import
            </Button>
          </Box>
        ) : (
          <Button variant="contained" onClick={parse}>
            Go!
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
