import { Sheet, Tooltip } from "@mui/joy";
import { Avatar, Box, Button, Divider, Paper, Typography } from "@mui/material";
import {
  DataGridPro,
  GridColumns,
  GridValidRowModel,
  GridValueFormatterParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { getProjectById } from "@nerdjs/kronos-kit";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { currencyFormatter, defaultDataGridProps } from "../../constants";
import { JobTask } from "../../entities/jobTask";
import { ProjectCue } from "../../entities/projectCue";
import { ProjectTask_Entity } from "../../entities/projectTask";
import { Scale_Entity } from "../../entities/scale";
import { Task } from "../../entities/task";
import { TaskType_Entity } from "../../entities/taskType";
import { budgetForProjectSelector } from "../../redux/budget/budgetSelector";
import { setSelectedGridCues } from "../../redux/cue/cueActions";
import { jobsMapSelector } from "../../redux/job/jobSelector";
import { updateJobTask } from "../../redux/jobTask/jobTaskActions";
import {
  projectSelector,
  selectedProjectIDSelector,
  selectedProjectSelector,
} from "../../redux/project/projectSelector";
import { scalesForOrchestrator } from "../../redux/scale/scaleSelector";
import { talentsMapSelector } from "../../redux/talent/talentSelector";
import {
  setSelectedGridTasks,
  setTaskBulkSelect,
  updateBatchTasks,
  updateTask,
} from "../../redux/task/taskActions";
import {
  selectedGridTasksSelector,
  taskBulkSelectSelector,
} from "../../redux/task/taskSelector";
import { taskTypesMapSelector } from "../../redux/taskType/taskTypeSelector";
import { useAppSelector } from "../hooks";
import {
  getterDivider,
  setterDivider,
} from "../jobCategory/jobCategoryRates/dataGridColDef";
import NoRowsOverlay from "../noRowsOverlay/noRowsOverlay";
import ReconcileOrchestrator from "../reconcile/reconcileOrchestrator";

export default function OrchestratorMusicPrepSummary({
  projectTask,
}: {
  projectTask?: ProjectTask_Entity;
}) {
  const apiRef = useGridApiRef();
  const projectID = useAppSelector(selectedProjectIDSelector);
  const project = useAppSelector(projectSelector(projectID));
  const scales = useAppSelector(scalesForOrchestrator(project?.projectTypeID));
  const taskTypes = useAppSelector(taskTypesMapSelector);
  const jobs = useAppSelector(jobsMapSelector);
  const talents = useAppSelector(talentsMapSelector);
  const dispatch = useDispatch();
  const selectedGridTasks = useAppSelector(selectedGridTasksSelector);
  const bulkSelect = useAppSelector(taskBulkSelectSelector);

  const allTasks = {};
  projectTask?.cues.forEach((pt) => {
    pt.tasks.forEach((t) => (allTasks[t.cueID] = t));
  });

  const getJobTask = (jobID: number, taskID: number): JobTask | undefined => {
    for (const key in projectTask?.cues) {
      if (Object.prototype.hasOwnProperty.call(projectTask?.cues, key)) {
        const c = projectTask?.cues[key];

        for (const key in c.tasks[0].jobTasks) {
          if (Object.prototype.hasOwnProperty.call(c.tasks[0].jobTasks, key)) {
            const j = c.tasks[0].jobTasks[key];
            if (j.jobID === jobID && j.taskID === taskID) return j;
          }
        }
      }
    }

    return undefined;
  };

  const jobTasksForFirstCue: JobTask[] =
    projectTask?.cues[0].tasks[0].jobTasks ?? [];

  const onTaskUpdate = async (_new: any, _old: any) => {
    return new Promise((resolve) => {
      for (const k in _new) {
        if (Object.prototype.hasOwnProperty.call(_new, k)) {
          if (/^\d+$/.test(k)) {
            // jobTask Update
            const jobID = parseInt(k);
            const taskID = _new.taskID;
            const jobTask = getJobTask(jobID, taskID);
            if (jobTask && jobTask.quantity != _new[k])
              dispatch(updateJobTask(jobTask.id, { quantity: _new[k] }));
          } else {
            if (_new[k] != _old[k]) {
              // task Update
              if (selectedGridTasks?.length) {
                const batch: Partial<Task>[] = [];

                for (const j in selectedGridTasks) {
                  if (
                    Object.prototype.hasOwnProperty.call(selectedGridTasks, j)
                  ) {
                    const cueID = selectedGridTasks[j] as number;
                    const _task = allTasks[cueID];
                    const _b: Partial<Task> = {
                      ..._task,
                      [k]: _new[k],
                    };
                    if (k === "scale" && _task.rate === 0) {
                      _b.rate = _new.scale;
                    }
                    batch.push(_b);
                  }
                }
                dispatch(updateBatchTasks(batch));
              } else {
                let body: Partial<Task> = {
                  [k]: _new[k],
                };
                if (k === "scale") {
                  body = {
                    scale: _new[k],
                    rate: _new["rate"] === 0 ? _new[k] : undefined,
                  };
                }
                dispatch(updateTask(_new.taskID, body));
              }
            }
          }
        }
      }
      setTimeout(() => {
        resolve({ ..._new });
      }, 50);
    });
  };

  const getRows = (): GridValidRowModel[] => {
    const rows = [];

    for (const k in projectTask?.cues) {
      if (Object.prototype.hasOwnProperty.call(projectTask?.cues, k)) {
        const cue: ProjectCue = projectTask?.cues[k];
        const task = cue.tasks[0];
        const row = {
          name: cue.name,
          bars: cue.bars,
          id: cue.id,
          quantity: task.quantity,
          rate: task.rate,
          scale: task.scale,
          taskID: task.id,
          total: cue.total,
        };

        for (const key in jobTasksForFirstCue) {
          if (Object.prototype.hasOwnProperty.call(jobTasksForFirstCue, key)) {
            const jobTask = jobTasksForFirstCue[key];
            const job = jobs[jobTask.jobID];
            if (!job) continue;

            row[job.id] = task.jobTasks[key].quantity;
          }
        }

        rows.push(row);
      }
    }

    return rows;
  };

  const getColumns = (bulkSelect: boolean): GridColumns<GridValidRowModel> => {
    const cols: GridColumns<GridValidRowModel> = [
      {
        field: "name",
        headerAlign: "center",
        align: "left",
        headerName: "Cue",
        editable: false,
        sortable: false,
        type: "string",
        width: 150,
        renderCell: (p) => {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flex: 1,
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography variant="body2">{p.value}</Typography>
              <Tooltip size="sm" placement="right" title="Bars">
                <Sheet
                  variant="solid"
                  sx={{
                    p: 0.5,
                    background: "#9e9e9e",
                    borderRadius: 4,
                    height: 16,
                    fontSize: 12,
                    display: "flex",
                    alignItems: "center",
                    position: "sticky",
                    right: 0,
                  }}
                >
                  {p.row.bars}
                </Sheet>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        field: "rate",
        headerName: "Rate",
        headerAlign: "center",
        sortable: false,
        editable: true,
        type: "number",
        valueGetter: getterDivider("rate", 10000),
        valueSetter: setterDivider("rate", 10000),
        renderCell: (p) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            {p.field === "rate" && p.row.rate < p.row.scale ? (
              <Tooltip title="Rate should be higher than the scale">
                <i className="fa-regular fa-triangle-exclamation"></i>
              </Tooltip>
            ) : (
              []
            )}
            {`${currencyFormatter.format(p.value)}`.substring(1)}
          </Box>
        ),
        valueFormatter: (p: GridValueFormatterParams) =>
          `${currencyFormatter.format(p.value)}`.substring(1),
      },
      {
        field: "scale",
        headerName: "Scale",
        align: "right",
        headerAlign: "center",
        sortable: false,
        editable: true,
        type: "singleSelect",
        valueOptions: getOptions(scales, taskTypes),
        valueGetter: getterDivider("scale", 10000),
        valueFormatter: (p: GridValueFormatterParams) =>
          `${currencyFormatter.format(p.value)}`.substring(1),
      },
      {
        field: "total",
        headerName: "Total",
        headerAlign: "center",
        align: "right",
        sortable: false,
        editable: false,
        type: "number",
        valueGetter: getterDivider("total", 10000),
        valueSetter: setterDivider("total", 10000),
        valueFormatter: (p: GridValueFormatterParams) =>
          `${currencyFormatter.format(p.value)}`.substring(1),
      },
    ];

    for (const key in jobTasksForFirstCue) {
      if (Object.prototype.hasOwnProperty.call(jobTasksForFirstCue, key)) {
        const jobTask = jobTasksForFirstCue[key];

        const job = jobs[jobTask.jobID];
        if (!job) continue;
        const talent = talents[job.talentID];

        cols.push({
          field: `${job.id}`,
          headerAlign: "center",
          headerName: `${talent.firstName} ${talent.lastName}`,
          renderHeader: (p) => {
            return (
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Avatar
                  sx={{
                    height: 22,
                    width: 22,
                    fontSize: 11,
                    color: "text.primary",
                    background: talent.active
                      ? "rgba(33,150,243,0.3)"
                      : "rgba(155,155,155,0.3)",
                  }}
                >
                  {talent?.code}
                </Avatar>
                <Typography
                  variant="body2"
                  color={talent ? "textPrimary" : "textSecondary"}
                >
                  {talent
                    ? `${talent?.firstName} ${talent?.lastName}`
                    : "Double Click to assign"}
                </Typography>
              </Box>
            );
          },
          sortable: false,
          editable: !bulkSelect,
          width: 150,
          type: "number",
          valueGetter: getterDivider(`${job.id}`, 100),
          valueSetter: setterDivider(`${job.id}`, 100),
        });
      }
    }

    return cols;
  };

  const dataGrid = useMemo(() => {
    return (
      <DataGridPro
        {...defaultDataGridProps}
        checkboxSelection={bulkSelect}
        onSelectionModelChange={(p) => dispatch(setSelectedGridTasks(p))}
        selectionModel={selectedGridTasks ?? []}
        isCellEditable={(params) =>
          project?.locked() ? false : params.colDef.editable ?? false
        }
        getCellClassName={(c) => {
          if (project?.locked()) return "lock";
          let className = !c.isEditable ? "cellReadOnly" : "";
          if (c.field === "rate" && c.row.rate < c.row.scale)
            className += " warning";

          return className;
        }}
        apiRef={apiRef}
        columns={getColumns(bulkSelect)}
        rows={getRows()}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={onTaskUpdate}
        components={{
          Toolbar: MusicPrepSummaryFooterToolbar,
          NoRowsOverlay: NoRowsOverlay,
          Footer: MusicPrepSummaryFooter,
        }}
        componentsProps={{
          footer: { projectTask },
          cell: { tabIndex: 1 },
        }}
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "name"],
            right: ["total"],
          },
        }}
      />
    );
  }, [projectTask, jobs, bulkSelect, selectedGridTasks]);

  return <Paper sx={{ display: "flex", flex: 1 }}>{dataGrid}</Paper>;
}

function getOptions(
  items: Scale_Entity[],
  taskTypes: { [key: number]: TaskType_Entity }
) {
  const res = [];
  for (const key in items) {
    if (Object.prototype.hasOwnProperty.call(items, key)) {
      const item = items[key];
      res.push({
        value: item.rate,
        label: `${taskTypes[item.taskTypeID].name}: ${currencyFormatter.format(
          item.rate / 10000
        )}`,
      });
    }
  }

  return res;
}

function MusicPrepSummaryFooterToolbar() {
  const dispatch = useDispatch();
  const project = useAppSelector(selectedProjectSelector);
  const bulkSelect = useAppSelector(taskBulkSelectSelector);

  return (
    <Box
      className="header"
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <Box />
      <Button
        size="small"
        variant={bulkSelect ? "contained" : undefined}
        onClick={() => {
          dispatch(setSelectedGridCues([]));
          dispatch(setTaskBulkSelect(!bulkSelect));
        }}
        disabled={project?.locked()}
      >
        {bulkSelect ? "Done" : "Bulk Edit"}
      </Button>
    </Box>
  );
}

function MusicPrepSummaryFooter(props: { projectTask: ProjectTask_Entity }) {
  const dispatch = useDispatch();
  const projectID = useAppSelector(selectedProjectIDSelector);
  const project = useAppSelector(selectedProjectSelector);
  const budget = useAppSelector(budgetForProjectSelector(projectID ?? 0));
  return (
    <>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pl: 2,
        }}
      >
        <Button
          onClick={() => dispatch(getProjectById(projectID!))}
          variant="outlined"
          disabled={project?.locked()}
          size="small"
        >
          Calculate All Orchestrators
        </Button>
        <Box sx={{ display: "flex", gap: 1 }}>
          <ReconcileOrchestrator />
          <Box
            sx={{
              p: 2,
              fontWeight: 600,
              display: "flex",
              gap: 1,
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {/* {budget ? (
              <Button
                sx={{
                  color: "#2196f3",
                  borderColor: "#2196f3",
                  "&:hover": {
                    borderColor: "#2196f3",
                  },
                }}
                disabled={project?.locked()}
                onClick={() =>
                  dispatch(setFormOpen(true, "ReconcileOrchestrator"))
                }
                variant="outlined"
                size="small"
              >
                Reconcile{" "}
                <i className="fa-solid fa-wand-magic-sparkles pleft"></i>
              </Button>
            ) : (
              []
            )} */}
            Subtotal:{" "}
            {currencyFormatter.format(props.projectTask.subTotal / 10000)}
          </Box>
        </Box>
      </Box>
    </>
  );
}
