import JobTask_Entity from "./jobTask";
export default class JobTask extends JobTask_Entity {
  static fromList(jobTasksJSON: unknown): Array<JobTask> {
    const jobTasks: JobTask[] = [];
    if (jobTasksJSON)
      Array.isArray(jobTasksJSON) &&
        jobTasksJSON.forEach((jobTaskJSON) => {
          jobTasks.push(new JobTask(jobTaskJSON));
        });
    return jobTasks;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
