import { createReducer } from "@reduxjs/toolkit";
import { Benefit_Entity } from "../../entities/benefit";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteBenefitSuccess,
  getBenefitByIdSuccess,
  getBenefitsSearchMetadataSuccess,
  getBenefitsSuccess,
  setSelectedBenefitID,
} from "./benefitActions";
import { BenefitState } from "./benefitState";

function initState(): BenefitState {
  const { benefit: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    benefits: {},
    searchMetadata: undefined,
    selectedBenefitID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const benefitReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedBenefitID, (state, action) => {
    state.selectedBenefitID = action.payload.benefitID;
    return state;
  });
  builder.addCase(getBenefitsSuccess, (state, action) => {
    const benefits = action.payload.benefits;
    state.benefits = arrayToMap<Benefit_Entity>(benefits);
    return state;
  });
  builder.addCase(getBenefitsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.benefitsSearchMetadata;
    return state;
  });
  builder.addCase(deleteBenefitSuccess, (state, action) => {
    const benefitID = action.payload.id;
    if (state.benefits[benefitID]) {
      delete state.benefits[benefitID];
    }
    return state;
  });
  builder.addCase(getBenefitByIdSuccess, (state, action) => {
    const benefit = action.payload.benefit;
    if (benefit) {
      const benefits = { ...state.benefits };
      benefits[benefit.id] = benefit;
      state.benefits = benefits;
    }
    return state;
  });
});
