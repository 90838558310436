import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { TaskType, TaskType_Entity } from "../../entities/taskType";

export const setSelectedTaskTypeID = createAction(
  "setSelectedTaskTypeID",
  function prepare(taskTypeID?: number) {
    return {
      payload: {
        taskTypeID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getTaskTypes = createAction(
  "films/taskType/getTaskTypes",
  function prepare() {
    const request = new GetAction(`/films/taskTypes`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const taskTypes = TaskType.fromList(json);
      dispatch(getTaskTypesSuccess(taskTypes));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getTaskTypesSuccess = createAction(
  "films/taskType/getTaskTypeSuccess",
  function prepare(taskTypes: TaskType[]) {
    return {
      payload: {
        taskTypes,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getTaskTypesByFilter = createAction(
  "films/taskType/getTaskTypesByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/taskTypes`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const taskTypes = TaskType.fromList(json);
      dispatch(getTaskTypesByFilterSuccess(taskTypes));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getTaskTypesByFilterSuccess = createAction(
  "films/taskType/getTaskTypesByFilterSuccess",
  function prepare(taskTypes: TaskType[]) {
    return {
      payload: {
        taskTypes,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getTaskTypeById = createAction(
  "films/taskType/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/taskTypes/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const taskType = new TaskType(json);
      dispatch(getTaskTypeByIdSuccess(taskType));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getTaskTypeByIdSuccess = createAction(
  "films/taskType/getTaskTypeByIdSuccess",
  function prepare(taskType?: TaskType_Entity) {
    return {
      payload: {
        taskType,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteTaskType = createAction(
  "films/removeTaskTypeById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/films/taskTypes/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteTaskTypeSuccess = createAction(
  "films/taskTypes/deleteTaskTypeSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateTaskType = createAction(
  "films/taskTypes/updateTaskType",
  function prepare(id: number, body: TaskType_Entity) {
    const request = new PutAction(`/films/taskTypes/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateTaskTypeSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateTaskTypeSuccess = createAction(
  "films/taskTypes/updateTaskTypeSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createTaskType = createAction(
  "films/taskTypes/createTaskType",
  function prepare(body: Partial<TaskType_Entity>) {
    const request = new PostAction(`/films/taskTypes`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const taskType = new TaskType(json);
      dispatch(createTaskTypeSuccess(taskType));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createTaskTypeSuccess = createAction(
  "films/taskTypes/createTaskTypeSuccess",
  function prepare(taskType: TaskType) {
    return {
      payload: {
        taskType,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getTaskTypesSearchMetadata = createAction(
  "films/getTaskTypesSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/taskTypes/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getTaskTypesSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getTaskTypesSearchMetadataSuccess = createAction(
  "films/getTaskTypesSearchMetadataSuccess",
  function prepare(taskTypesSearchMetadata: unknown) {
    return {
      payload: {
        taskTypesSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
