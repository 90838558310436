import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { JobTask, JobTask_Entity } from "../../entities/jobTask";

export const setSelectedJobTaskID = createAction(
  "setSelectedJobTaskID",
  function prepare(jobTaskID?: number) {
    return {
      payload: {
        jobTaskID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJobTasks = createAction(
  "films/jobTask/getJobTasks",
  function prepare() {
    const request = new GetAction(`/films/jobTasks`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const jobTasks = JobTask.fromList(json);
      dispatch(getJobTasksSuccess(jobTasks));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJobTasksSuccess = createAction(
  "films/jobTask/getJobTaskSuccess",
  function prepare(jobTasks: JobTask[]) {
    return {
      payload: {
        jobTasks,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJobTasksByFilter = createAction(
  "films/jobTask/getJobTasksByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/jobTasks`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const jobTasks = JobTask.fromList(json);
      dispatch(getJobTasksByFilterSuccess(jobTasks));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJobTasksByFilterSuccess = createAction(
  "films/jobTask/getJobTasksByFilterSuccess",
  function prepare(jobTasks: JobTask[]) {
    return {
      payload: {
        jobTasks,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJobTaskById = createAction(
  "films/jobTask/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/jobTasks/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const jobTask = new JobTask(json);
      dispatch(getJobTaskByIdSuccess(jobTask));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJobTaskByIdSuccess = createAction(
  "films/jobTask/getJobTaskByIdSuccess",
  function prepare(jobTask?: JobTask_Entity) {
    return {
      payload: {
        jobTask,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteJobTask = createAction(
  "films/removeJobTaskById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/films/jobTasks/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteJobTaskSuccess = createAction(
  "films/jobTasks/deleteJobTaskSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateJobTask = createAction(
  "films/jobTasks/updateJobTask",
  function prepare(id: number, body: Partial<JobTask_Entity>) {
    const request = new PutAction(`/films/jobTasks/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateJobTaskSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateJobTaskSuccess = createAction(
  "films/jobTasks/updateJobTaskSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createJobTask = createAction(
  "films/jobTasks/createJobTask",
  function prepare(body: JobTask_Entity) {
    const request = new PostAction(`/films/jobTasks`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const jobTask = new JobTask(json);
      dispatch(createJobTaskSuccess(jobTask));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createJobTaskSuccess = createAction(
  "films/jobTasks/createJobTaskSuccess",
  function prepare(jobTask: JobTask) {
    return {
      payload: {
        jobTask,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJobTasksSearchMetadata = createAction(
  "films/getJobTasksSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/jobTasks/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getJobTasksSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJobTasksSearchMetadataSuccess = createAction(
  "films/getJobTasksSearchMetadataSuccess",
  function prepare(jobTasksSearchMetadata: unknown) {
    return {
      payload: {
        jobTasksSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
