import ProjectCue_Entity from "./projectCue";
export default class ProjectCue extends ProjectCue_Entity {
  static fromList(projectCuesJSON: unknown): Array<ProjectCue> {
    const projectCues: ProjectCue[] = [];
    if (projectCuesJSON)
      Array.isArray(projectCuesJSON) &&
        projectCuesJSON.forEach((projectCueJSON) => {
          projectCues.push(new ProjectCue(projectCueJSON));
        });
    return projectCues;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
