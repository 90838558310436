import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  Box,
  Button,
  Divider,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Dispatch } from "@reduxjs/toolkit";
import { ConfirmOptions, useConfirm } from "material-ui-confirm";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import {
  defaultDataGridProps,
  injectDefaultColumnProps,
} from "../../constants";
import { JobCategory_Entity } from "../../entities/jobCategory";
import { TaskType_Entity } from "../../entities/taskType";
import { getJobCategories } from "../../redux/jobCategory/jobCategoryActions";
import { jobCategoriesSelector } from "../../redux/jobCategory/jobCategorySelector";
import {
  createTaskType,
  deleteTaskType,
  deleteTaskTypeSuccess,
  getTaskTypes,
  updateTaskType,
} from "../../redux/taskType/taskTypeActions";
import { taskTypesSelector } from "../../redux/taskType/taskTypeSelector";
import { useAppSelector } from "../hooks";
import NoRowsOverlay from "../noRowsOverlay/noRowsOverlay";

/**
 * TaskType
 *
 * @returns {null} TaskType
 */
export default function TaskTypes() {
  const taskTypes = useAppSelector(taskTypesSelector);

  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const confirm = useConfirm();
  const jobCategories = useAppSelector(jobCategoriesSelector);

  useEffect(() => {
    dispatch(getTaskTypes());
    dispatch(getJobCategories());
  }, []);

  const onTaskTypeUpdate = async (
    _newTaskType: TaskType_Entity,
    _oldTaskType: TaskType_Entity
  ) => {
    return new Promise<TaskType_Entity>((resolve) => {
      dispatch(updateTaskType(_newTaskType.id, _newTaskType));
      setTimeout(() => {
        resolve({ ..._newTaskType });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1 }}>
      <DataGridPro
        {...defaultDataGridProps}
        apiRef={apiRef}
        processRowUpdate={onTaskTypeUpdate}
        rows={taskTypes}
        columns={taskTypeColumns(dispatch, confirm, jobCategories)}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: TaskTypeToolbar,
          NoRowsOverlay: NoRowsOverlay,
        }}
      />
    </Paper>
  );
}

function TaskTypeToolbar() {
  const dispatch = useDispatch();

  const handleNewTaskType = () => {
    const newTaskType = {
      id: -1,
      jobCategoryID: -1,
      name: "",
      unit: "",
      createdAt: "",
      updatedAt: "",
    };
    dispatch(createTaskType(newTaskType));
  };

  useHotkeys(`ctrl+enter`, handleNewTaskType, {
    enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
  });

  return (
    <Box className="header" sx={{ display: "flex" }}>
      <Tooltip title="ctrl + enter">
        <Button size="small" onClick={handleNewTaskType}>
          + New Task Type
        </Button>
      </Tooltip>
      <Divider
        sx={{ margin: 1, width: 2 }}
        orientation="vertical"
        variant="middle"
        flexItem
      />
    </Box>
  );
}

const taskTypeColumns = (
  dispatch: Dispatch,
  confirm: (options?: ConfirmOptions | undefined) => Promise<void>,
  jobCategories: JobCategory_Entity[]
) =>
  injectDefaultColumnProps([
    {
      field: "id",
      headerName: "ID",
      editable: false,
      type: "number",
      width: 40,
    },
    {
      field: "name",
      headerName: "Name",
      editable: true,
      type: "string",
      flex: 1,
    },
    {
      field: "jobCategoryID",
      headerName: "Job Category",
      editable: true,
      flex: 1,
      type: "singleSelect",
      valueOptions: getOptions(jobCategories),
      renderCell: (p: any) => {
        const jobCategory = jobCategories.find(
          (s) => s.id === p.row.jobCategoryID
        );
        return <Typography variant="body2">{jobCategory?.name}</Typography>;
      },
    },
    {
      field: "unit",
      headerName: "Unit",
      editable: true,
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        { value: "page", label: "Page" },
        { value: "hour", label: "Hour" },
      ],
    },
    {
      field: "pos",
      headerName: "Order",
      editable: true,
      width: 90,
      type: "number",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (props: { id: any }) => [
        <GridActionsCellItem
          key="delete"
          showInMenu
          icon={<DeleteIcon />}
          label={`Delete ${props.id}`}
          onClick={() => {
            confirm({
              description: `You are about to delete a taskType`,
            })
              .then(() => {
                dispatch(deleteTaskType(props.id, deleteTaskTypeSuccess));
              })
              .catch(() => {
                /* ... */
              });
          }}
          color="inherit"
        />,
      ],
    },
  ]);

function getOptions(items: { id: number; name?: string }[]) {
  const res = [];
  for (const key in items) {
    if (Object.prototype.hasOwnProperty.call(items, key)) {
      const item = items[key];
      res.push({
        value: item.id,
        label: `${item.id}: ${item.name}`,
      });
    }
  }

  return res;
}
