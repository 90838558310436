import { Chip, useColorScheme, Typography, Tooltip } from "@mui/joy";
import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import { AppConfig } from "../../environement";

/**
 *
 * @returns {ReactElement} FineLineLogo page
 */
export function FineLineLogo() {
  const { mode } = useColorScheme();
  const isDark = mode === "dark";

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <img
        style={{ height: 35, filter: isDark ? "invert(100%)" : undefined }}
        src="https://storage.googleapis.com/films_assets/images/fineline_black_logo.png"
      />
      <Typography
        sx={{ fontFamily: "dharma-gothic-e, sans-serif", fontSize: 24 }}
      >
        FINE LINE MUSIC SERVICE
      </Typography>
      {AppConfig.app.alphaWatermark ? (
        <Tooltip
          color="danger"
          size="sm"
          title={
            <Box sx={{ textAlign: "center" }}>
              <b>
                DISCLAIMER: This is the alpha version of the app.
                <br /> It contains bugs and unstable features. <br />
                <u>DO NOT USE OR TRUST</u> any content generated by this version
                <br />
                of the app since it could be inaccurate.
              </b>
            </Box>
          }
        >
          <Chip size="sm">
            <b>ALPHA</b>
          </Chip>
        </Tooltip>
      ) : (
        <Typography
          sx={{
            border: "solid 1px rgba(155,155,155,0.5)",
            padding: "0px 2px 0px 2px",
            borderRadius: 16,
            color: "text.primary",
          }}
          level="body4"
        >
          {window.app_info.tag}
        </Typography>
      )}
    </Box>
  );
}
