import JobCategory_Entity from "./jobCategory";
export default class JobCategory extends JobCategory_Entity {
  static fromList(jobCategorysJSON: unknown): Array<JobCategory> {
    const jobCategorys: JobCategory[] = [];
    if (jobCategorysJSON)
      Array.isArray(jobCategorysJSON) &&
        jobCategorysJSON.forEach((jobCategoryJSON) => {
          jobCategorys.push(new JobCategory(jobCategoryJSON));
        });
    return jobCategorys;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
