import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { JobCategory, JobCategory_Entity } from "../../entities/jobCategory";

export const setSelectedJobCategoryID = createAction(
  "setSelectedJobCategoryID",
  function prepare(jobCategoryID?: number) {
    return {
      payload: {
        jobCategoryID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJobCategories = createAction(
  "films/jobCategory/getJobCategories",
  function prepare() {
    const request = new GetAction(`/films/jobCategories`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const jobCategories = JobCategory.fromList(json);
      dispatch(getJobCategoriesSuccess(jobCategories));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJobCategoriesSuccess = createAction(
  "films/jobCategory/getJobCategorySuccess",
  function prepare(jobCategories: JobCategory[]) {
    return {
      payload: {
        jobCategories,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJobCategoriesByFilter = createAction(
  "films/jobCategory/getJobCategoriesByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/jobCategories`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const jobCategories = JobCategory.fromList(json);
      dispatch(getJobCategoriesByFilterSuccess(jobCategories));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJobCategoriesByFilterSuccess = createAction(
  "films/jobCategory/getJobCategoriesByFilterSuccess",
  function prepare(jobCategories: JobCategory[]) {
    return {
      payload: {
        jobCategories,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJobCategoryById = createAction(
  "films/jobCategory/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/jobCategories/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const jobCategory = new JobCategory(json);
      dispatch(getJobCategoryByIdSuccess(jobCategory));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJobCategoryByIdSuccess = createAction(
  "films/jobCategory/getJobCategoryByIdSuccess",
  function prepare(jobCategory?: JobCategory_Entity) {
    return {
      payload: {
        jobCategory,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteJobCategory = createAction(
  "films/removeJobCategoryById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/films/jobCategories/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteJobCategorySuccess = createAction(
  "films/jobCategories/deleteJobCategorySuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateJobCategory = createAction(
  "films/jobCategories/updateJobCategory",
  function prepare(id: number, body: JobCategory_Entity) {
    const request = new PutAction(`/films/jobCategories/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateJobCategorySuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateJobCategorySuccess = createAction(
  "films/jobCategories/updateJobCategorySuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createJobCategory = createAction(
  "films/jobCategories/createJobCategory",
  function prepare(body: JobCategory_Entity) {
    const request = new PostAction(`/films/jobCategories`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const jobCategory = new JobCategory(json);
      dispatch(createJobCategorySuccess(jobCategory));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createJobCategorySuccess = createAction(
  "films/jobCategories/createJobCategorySuccess",
  function prepare(jobCategory: JobCategory) {
    return {
      payload: {
        jobCategory,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJobCategoriesSearchMetadata = createAction(
  "films/getJobCategoriesSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/jobCategories/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getJobCategoriesSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJobCategoriesSearchMetadataSuccess = createAction(
  "films/getJobCategoriesSearchMetadataSuccess",
  function prepare(jobCategoriesSearchMetadata: unknown) {
    return {
      payload: {
        jobCategoriesSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
