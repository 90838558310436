import {
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getCurrentUserApps } from "@nerdjs/account-kit";
import { Button } from "@mui/material";
import { useState, MouseEvent, useEffect, ReactElement } from "react";
import { Apps } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../hooks";
import { withNetworkCompletionDispatch } from "@nerdjs/nerd-network";
import { StyledMenu } from "../nerdAppBar/nerdAppBar";
import { RouterConfig } from "../config/routerConfig";
import { useTranslation } from "react-i18next";
import { NerdAppsMenu } from "@nerdjs/nerd-ui";
import {
  currentUserAppsSelector,
  networkCompletionStackSelector,
} from "../../redux/appStatus/appStatusSelector";

/**
 *
 * @returns {ReactElement} NerdAppsMenuWrapper
 */
export default function NerdAppsMenuWrapper(): ReactElement {
  const dispatch = useAppDispatch();
  const apps = useAppSelector(currentUserAppsSelector);
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const networkCompletionStack = useAppSelector(networkCompletionStackSelector);
  const [requestPending, setRequestPending] = useState(false);
  const [requestUUID, setRequestUUID] = useState("");
  const { t } = useTranslation();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (
      requestUUID !== "" &&
      !networkCompletionStack.some((e) => e[requestUUID])
    ) {
      setRequestPending(false);
    }
  }, [requestUUID, networkCompletionStack]);

  useEffect(() => {
    setRequestPending(true);
    const uuid = withNetworkCompletionDispatch(dispatch, getCurrentUserApps());
    setRequestUUID(uuid);
  }, []);

  return (
    <>
      {md ? (
        <Button size="small" onClick={handleClick} startIcon={<Apps />}>
          {t("entities:app.entity")}
        </Button>
      ) : (
        <Tooltip title={`${t("entities:app.entity")}`}>
          <IconButton color="primary" size="small" onClick={handleClick}>
            <Apps />
          </IconButton>
        </Tooltip>
      )}
      <StyledMenu
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "310px",
          }}
        >
          {requestPending ? (
            <Grid
              container
              sx={{ marginTop: 0, minHeight: "398px" }}
              justifyContent={"center"}
              direction={"column"}
            >
              <Grid item alignSelf={"center"}>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            apps && <NerdAppsMenu homePath={RouterConfig.root} apps={apps} />
          )}
        </div>
      </StyledMenu>
    </>
  );
}
