export const access = {
  "menu-projects": ["films:user", "films:admin", "films:limited"],
  "menu-positions": ["films:admin"],
  "menu-talents": ["films:admin"],
  "menu-rates": ["films:admin"],
  "menu-ledger": ["films:admin"],
  "menu-vendors": ["films:admin"],
  "menu-benefits": ["films:admin"],
  "button-budget": ["films:admin"],
  "tab-jobs": ["films:admin"],
  "tab-music-prep-summary": ["films:admin"],
  "tab-orchestrator": ["films:admin"],
  "tab-expenses": ["films:admin"],
  "tab-project": ["films:admin"],
  "menu-project-types": ["films:superadmin"],
  "menu-job-categories": ["films:superadmin"],
  "menu-task-types": ["films:superadmin"],
  "menu-scales": ["films:superadmin"],
};
