import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Vendor, Vendor_Entity } from "../../entities/vendor";

export const setSelectedVendorID = createAction(
  "setSelectedVendorID",
  function prepare(vendorID?: number) {
    return {
      payload: {
        vendorID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVendors = createAction(
  "films/vendor/getVendors",
  function prepare() {
    const request = new GetAction(`/films/vendors`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const vendors = Vendor.fromList(json);
      dispatch(getVendorsSuccess(vendors));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVendorsSuccess = createAction(
  "films/vendor/getVendorSuccess",
  function prepare(vendors: Vendor[]) {
    return {
      payload: {
        vendors,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVendorsByFilter = createAction(
  "films/vendor/getVendorsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/vendors`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const vendors = Vendor.fromList(json);
      dispatch(getVendorsByFilterSuccess(vendors));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVendorsByFilterSuccess = createAction(
  "films/vendor/getVendorsByFilterSuccess",
  function prepare(vendors: Vendor[]) {
    return {
      payload: {
        vendors,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVendorById = createAction(
  "films/vendor/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/vendors/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const vendor = new Vendor(json);
      dispatch(getVendorByIdSuccess(vendor));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVendorByIdSuccess = createAction(
  "films/vendor/getVendorByIdSuccess",
  function prepare(vendor?: Vendor_Entity) {
    return {
      payload: {
        vendor,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteVendor = createAction(
  "films/removeVendorById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/films/vendors/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteVendorSuccess = createAction(
  "films/vendors/deleteVendorSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateVendor = createAction(
  "films/vendors/updateVendor",
  function prepare(id: number, body: Vendor_Entity) {
    const request = new PutAction(`/films/vendors/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateVendorSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateVendorSuccess = createAction(
  "films/vendors/updateVendorSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createVendor = createAction(
  "films/vendors/createVendor",
  function prepare(body: Partial<Vendor_Entity>) {
    const request = new PostAction(`/films/vendors`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const vendor = new Vendor(json);
      dispatch(createVendorSuccess(vendor));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createVendorSuccess = createAction(
  "films/vendors/createVendorSuccess",
  function prepare(vendor: Vendor) {
    return {
      payload: {
        vendor,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVendorsSearchMetadata = createAction(
  "films/getVendorsSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/vendors/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getVendorsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVendorsSearchMetadataSuccess = createAction(
  "films/getVendorsSearchMetadataSuccess",
  function prepare(vendorsSearchMetadata: unknown) {
    return {
      payload: {
        vendorsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
