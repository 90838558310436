import { ButtonProps, TextFieldProps } from "@mui/material";
import { GridCellParams, GridColumns, GridDensity } from "@mui/x-data-grid-pro";
import { greyStyleForNonEditableCells } from "./helpers/dataGridHelpers";

export const defaultTextFieldProps: TextFieldProps = {
  size: "small",
  variant: "filled",
  autoComplete: "off",
  fullWidth: true,
};

export const injectDefaultColumnProps = (
  p: GridColumns<any>
): GridColumns<any> => {
  // for (const key in p) {
  //   if (Object.prototype.hasOwnProperty.call(p, key)) {
  //     const element = p[key];
  //     element.headerClassName = "header";
  //   }
  // }

  return p;
};

export const defaultDataGridProps: {
  density: GridDensity;
  disableSelectionOnClick: boolean;
  disableColumnFilter: boolean;
  disableColumnMenu: boolean;
  disableChildrenSorting: boolean;
  disableDensitySelector: boolean;
  disableColumnSelector: boolean;
  disableColumnReorder: boolean;
  getCellClassName: (params: GridCellParams<number, any, number>) => string;
} = {
  density: "compact",
  disableColumnFilter: true,
  disableColumnMenu: true,
  disableSelectionOnClick: true,
  disableChildrenSorting: true,
  disableColumnSelector: true,
  disableDensitySelector: true,
  disableColumnReorder: true,
  getCellClassName: greyStyleForNonEditableCells,
};

export const defaultButtonProps: ButtonProps = {
  size: "small",
  variant: "contained",
  fullWidth: true,
  sx: { color: "white" },
};

export const ratesTabID = "ratesTab";
export const projectTab = "projectTab";
export const talentTab = "talentTab";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

export const formatCurrency = (v: number): string => {
  let p = Math.round(v * 100) / 100;
  if (p === -0) p = 0; //eslint-disable-line
  const ret = currencyFormatter.format(p);
  return ret;
};

export const font = "dharma-gothic-e, sans-serif;";
