import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { Tooltip, useColorScheme } from "@mui/joy";
import {
  Button,
  CSSObject,
  Divider,
  IconButton,
  ListItemButton,
  styled,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CurrentUserSelector } from "@nerdjs/account-kit";
import { NerdSecurityGate } from "@nerdjs/nerd-core";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setDrawerOpen } from "../../redux/appStatus/appStatusActions";
import {
  drawerOpenSelector,
  userServicesSelector,
} from "../../redux/appStatus/appStatusSelector";
import { RouterConfig } from "../config/routerConfig";
import { useAppSelector } from "../hooks";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

/**
 *
 * @component
 * @example
 * <MainDrawer/>
 * @returns {ReactElement | null} MainDrawer component
 */
export function MainDrawer(): ReactElement | null {
  const [backDropOpen, setBackDropOpen] = useState(false);
  const theme = useTheme();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const userServices = useAppSelector(userServicesSelector);
  const { mode } = useColorScheme();
  const isDark = mode === "dark";
  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"), { noSsr: true });
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const drawerOpen = useAppSelector(drawerOpenSelector);
  const logged = useAppSelector(CurrentUserSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /**
   * Open or collapse the drawer.
   */
  function handleDrawerCollapse() {
    dispatch(setDrawerOpen(!drawerOpen));
    if (isDownSm) {
      setBackDropOpen(true);
    } else {
      localStorage.setItem("sideBarOpen", drawerOpen.toString());
    }
  }

  useEffect(
    function () {
      dispatch(setDrawerOpen(false));
      setBackDropOpen(false);
    },
    [isDownSm]
  );

  useEffect(
    function () {
      if (isDownLg) {
        dispatch(setDrawerOpen(false));
      } else {
        dispatch(
          setDrawerOpen(
            localStorage.getItem("sideBarOpen") === null ||
              localStorage.getItem("sideBarOpen") === "true"
              ? true
              : false
          )
        );
      }
    },
    [isDownLg]
  );

  /**
   * Close the drawer when the user click on the backDrop
   */
  function handleBackDropClick() {
    setBackDropOpen(false);
    handleDrawerCollapse();
  }

  /**
   * Close drawer menu when click on menu item.
   */
  function handleClickOnMenuItem() {
    if (isDownSm) dispatch(setDrawerOpen(false));
  }

  if (!logged) return null;

  return (
    <CustomDrawer
      open={drawerOpen}
      onClose={() => dispatch(setDrawerOpen(false))}
      variant={"permanent"}
    >
      <Box
        sx={{
          paddingTop: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          height: "100%",
        }}
      >
        <List sx={{ marginBottom: "auto", flex: 1, width: "100%" }}>
          <NerdSecurityGate id="menu-projects" userServices={userServices}>
            <Tooltip
              size="sm"
              title={!drawerOpen ? "Projects" : ""}
              placement="right"
            >
              <ListItem
                disablePadding
                selected={pathnames.indexOf(RouterConfig.projects) >= 0}
              >
                <ListItemButton
                  component={Link}
                  to={RouterConfig.projects}
                  onClick={handleClickOnMenuItem}
                >
                  <ListItemIcon
                    sx={{ fontSize: "20px", color: "text.primary" }}
                  >
                    <i className="fa fa-music"></i>
                  </ListItemIcon>
                  <ListItemText primary="Projects" />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </NerdSecurityGate>
          <NerdSecurityGate id="menu-timers" userServices={userServices}>
            <Tooltip
              size="sm"
              title={!drawerOpen ? "Timers" : ""}
              placement="right"
            >
              <ListItem
                disablePadding
                selected={pathnames.indexOf(RouterConfig.timers) >= 0}
              >
                <ListItemButton
                  component={Link}
                  to={RouterConfig.timers}
                  onClick={handleClickOnMenuItem}
                >
                  <ListItemIcon
                    sx={{ fontSize: "20px", color: "text.primary" }}
                  >
                    <i className="fa-solid fa-timer"></i>
                  </ListItemIcon>
                  <ListItemText primary="Timers" />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </NerdSecurityGate>
          <NerdSecurityGate id="menu-positions" userServices={userServices}>
            <Divider />
            <Tooltip
              size="sm"
              title={!drawerOpen ? "Positions" : ""}
              placement="right"
            >
              <ListItem
                disablePadding
                selected={pathnames.indexOf(RouterConfig.positions) >= 0}
              >
                <ListItemButton
                  component={Link}
                  to={RouterConfig.positions}
                  onClick={handleClickOnMenuItem}
                >
                  <ListItemIcon
                    sx={{ fontSize: "20px", color: "text.primary" }}
                  >
                    <i className="fa fa-briefcase"></i>
                  </ListItemIcon>
                  <ListItemText primary="Positions" />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </NerdSecurityGate>
          <NerdSecurityGate id="menu-talents" userServices={userServices}>
            <Tooltip
              size="sm"
              title={!drawerOpen ? "Talents" : ""}
              placement="right"
            >
              <ListItem
                disablePadding
                selected={pathnames.indexOf(RouterConfig.talents) >= 0}
              >
                <ListItemButton
                  component={Link}
                  to={RouterConfig.talents}
                  onClick={handleClickOnMenuItem}
                >
                  <ListItemIcon
                    sx={{ fontSize: "20px", color: "text.primary" }}
                  >
                    <i className="fa fa-user-music"></i>
                  </ListItemIcon>
                  <ListItemText primary="Talents" />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </NerdSecurityGate>
          <NerdSecurityGate id="menu-rates" userServices={userServices}>
            <Tooltip
              size="sm"
              title={!drawerOpen ? "Rates" : ""}
              placement="right"
            >
              <ListItem
                disablePadding
                selected={pathnames.indexOf(RouterConfig.rates) >= 0}
              >
                <ListItemButton
                  component={Link}
                  to={RouterConfig.rates}
                  onClick={handleClickOnMenuItem}
                >
                  <ListItemIcon
                    sx={{ fontSize: "20px", color: "text.primary" }}
                  >
                    <i className="fa fa-money-bill-wave"></i>
                  </ListItemIcon>
                  <ListItemText primary="Rates" />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </NerdSecurityGate>
          <NerdSecurityGate id="menu-benefits" userServices={userServices}>
            <Tooltip
              size="sm"
              title={!drawerOpen ? "Benefits" : ""}
              placement="right"
            >
              <ListItem
                disablePadding
                selected={pathnames.indexOf(RouterConfig.benefits) >= 0}
              >
                <ListItemButton
                  component={Link}
                  to={RouterConfig.benefits}
                  onClick={handleClickOnMenuItem}
                >
                  <ListItemIcon
                    sx={{ fontSize: "20px", color: "text.primary" }}
                  >
                    <i className="fa fa-hand-holding-dollar"></i>
                  </ListItemIcon>
                  <ListItemText primary="Benefits" />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </NerdSecurityGate>
          <NerdSecurityGate id="menu-ledger" userServices={userServices}>
            <Tooltip
              size="sm"
              title={!drawerOpen ? "Virtual Ledger" : ""}
              placement="right"
            >
              <ListItem
                disablePadding
                selected={pathnames.indexOf(RouterConfig.ledger) >= 0}
              >
                <ListItemButton
                  component={Link}
                  to={RouterConfig.ledger}
                  onClick={handleClickOnMenuItem}
                >
                  <ListItemIcon
                    sx={{ fontSize: "20px", color: "text.primary" }}
                  >
                    <i className="fa-solid fa-scale-unbalanced"></i>
                  </ListItemIcon>
                  <ListItemText primary="Virtual Ledger" />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </NerdSecurityGate>
          <NerdSecurityGate id="menu-vendors" userServices={userServices}>
            <Tooltip
              size="sm"
              title={!drawerOpen ? "Vendors" : ""}
              placement="right"
            >
              <ListItem
                disablePadding
                selected={pathnames.indexOf(RouterConfig.vendors) >= 0}
              >
                <ListItemButton
                  component={Link}
                  to={RouterConfig.vendors}
                  onClick={handleClickOnMenuItem}
                >
                  <ListItemIcon
                    sx={{ fontSize: "20px", color: "text.primary" }}
                  >
                    <i className="fa-solid fa-building"></i>
                  </ListItemIcon>
                  <ListItemText primary="Vendors" />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </NerdSecurityGate>
          <NerdSecurityGate id="menu-project-types" userServices={userServices}>
            <Divider />
            <Tooltip
              size="sm"
              title={!drawerOpen ? "Project Types" : ""}
              placement="right"
            >
              <ListItem
                disablePadding
                selected={pathnames.indexOf(RouterConfig.projectTypes) >= 0}
              >
                <ListItemButton
                  component={Link}
                  to={RouterConfig.projectTypes}
                  onClick={handleClickOnMenuItem}
                >
                  <ListItemIcon
                    sx={{ fontSize: "20px", color: "text.primary" }}
                  >
                    <i className="fa fa-tag"></i>
                  </ListItemIcon>
                  <ListItemText primary="Project Types" />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </NerdSecurityGate>
          <NerdSecurityGate
            id="menu-job-categories"
            userServices={userServices}
          >
            <Tooltip
              size="sm"
              title={!drawerOpen ? "Job Categories" : ""}
              placement="right"
            >
              <ListItem
                disablePadding
                selected={pathnames.indexOf(RouterConfig.jobCategories) >= 0}
              >
                <ListItemButton
                  component={Link}
                  to={RouterConfig.jobCategories}
                  onClick={handleClickOnMenuItem}
                >
                  <ListItemIcon
                    sx={{ fontSize: "20px", color: "text.primary" }}
                  >
                    <i className="fa fa-briefcase"></i>
                  </ListItemIcon>
                  <ListItemText primary="Job Categories" />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </NerdSecurityGate>
          <NerdSecurityGate id="menu-task-types" userServices={userServices}>
            <Tooltip
              size="sm"
              title={!drawerOpen ? "Task Types" : ""}
              placement="right"
            >
              <ListItem
                disablePadding
                selected={pathnames.indexOf(RouterConfig.taskTypes) >= 0}
              >
                <ListItemButton
                  component={Link}
                  to={RouterConfig.taskTypes}
                  onClick={handleClickOnMenuItem}
                >
                  <ListItemIcon
                    sx={{ fontSize: "20px", color: "text.primary" }}
                  >
                    <i className="fa fa-hammer"></i>
                  </ListItemIcon>
                  <ListItemText primary="Task Types" />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </NerdSecurityGate>
          <NerdSecurityGate id="menu-scales" userServices={userServices}>
            <Tooltip
              size="sm"
              title={!drawerOpen ? "Scales" : ""}
              placement="right"
            >
              <ListItem
                disablePadding
                selected={pathnames.indexOf(RouterConfig.scales) >= 0}
              >
                <ListItemButton
                  component={Link}
                  to={RouterConfig.scales}
                  onClick={handleClickOnMenuItem}
                >
                  <ListItemIcon
                    sx={{ fontSize: "20px", color: "text.primary" }}
                  >
                    <i className="fa-solid fa-money-bill"></i>
                  </ListItemIcon>
                  <ListItemText primary={"Scales"} />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </NerdSecurityGate>
        </List>
        {drawerOpen ? (
          <Button
            variant="text"
            fullWidth
            onClick={handleDrawerCollapse}
            sx={{
              justifySelf: "flex-end",
              alignSelf: "center",
              paddingBottom: "18px",
            }}
            startIcon={<KeyboardDoubleArrowLeft />}
          >
            {t("global.collapse")}
          </Button>
        ) : (
          <IconButton
            aria-label={t("global.open_sidebar")}
            color="primary"
            sx={{
              justifySelf: "flex-end",
              alignSelf: "center",
              marginBottom: "10px",
            }}
            onClick={handleDrawerCollapse}
          >
            <KeyboardDoubleArrowRight />
          </IconButton>
        )}
      </Box>
    </CustomDrawer>
  );
}
