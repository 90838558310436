/**
 * TaskType_Entity type guard.
 *
 * @param {any} taskTypeJson TaskType object from API
 * @returns {boolean} Return true if type is TaskType_Entity
 */
function validator(taskTypeJson: unknown): taskTypeJson is TaskType_Entity {
  if (typeof taskTypeJson === "object" && taskTypeJson != null) {
    TaskType_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(taskTypeJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class TaskType_Entity {
  static requiredFields = [
    "id",
    "jobCategoryID",
    "name",
    "unit",
    "createdAt",
    "updatedAt",
  ];

  constructor(taskTypeJson: unknown) {
    if (validator(taskTypeJson)) {
      this.id = taskTypeJson.id;
      this.jobCategoryID = taskTypeJson.jobCategoryID;
      this.name = taskTypeJson.name;
      this.pos = taskTypeJson.pos;
      this.unit = taskTypeJson.unit;
      this.createdAt = taskTypeJson.createdAt;
      this.updatedAt = taskTypeJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ TaskType_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  pos: number;
  jobCategoryID: number;
  name: string;
  unit: string;
  createdAt: string;
  updatedAt: string;
}
