import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectVtransactions = (state: AppState) =>
  state.vtransactionState.vtransactions;

const selectTalentVtransactions = (state: AppState) =>
  state.vtransactionState.talentVtransactions;

const selectSelectedTalentID = (state: AppState) =>
  state.vtransactionState.selectedTalentID;

export const vTransactionSelectedTalentID = createSelector(
  [selectSelectedTalentID],
  (s) => s
);

export const talentVtransactionsSelector = createSelector(
  [selectTalentVtransactions],
  (s) => s
);

export const vtransactionsSelector = createSelector(
  [selectVtransactions],
  (vtransactions) => mapToArray(vtransactions)
);

export const vtransactionSelector = (id?: number) => {
  return createSelector([selectVtransactions], (vtransactions) => {
    if (id) return vtransactions[id];
    return undefined;
  });
};
