import { ReactElement, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Ship } from "../../entities/ship";

/**
 *
 * @returns {ReactElement} This component ensure that the frontend is up-to-date.
 * it query the endpoint api.versionCheckerEndpoint (in env.js) every X seconds and compares the tags.
 * If they are not the same, a message is displayed.
 */

export function VersionChecker({
  children,
  versionCheckerEndpoint,
  checkRate = 10000,
}: {
  children: ReactElement;
  versionCheckerEndpoint: string;
  checkRate?: number;
}) {
  const [open, setOpen] = useState(false);
  const [skipped, setSkipped] = useState(false);
  const [newVersion, setNewVersion] = useState("0.0.0");
  let refreshInterval: ReturnType<typeof setInterval> | null;
  const tag = window.app_info?.tag;

  useEffect(() => {
    if (!refreshInterval && versionCheckerEndpoint) {
      refreshInterval = setInterval(checkNow, checkRate);
      checkNow();
    }
    return clear;
  }, []);

  const clear = () => {
    if (refreshInterval) clearInterval(refreshInterval);
    refreshInterval = null;
  };

  const checkNow = () => {
    fetch(versionCheckerEndpoint)
      .then((response) => response.json())
      .then((data: Ship) => {
        if (
          tag !== "alpha" &&
          tag !== "_COMMIT_REF_NAME" &&
          tag !== data.currentVersion &&
          !skipped
        ) {
          setOpen(true);
          setNewVersion(data.currentVersion ?? "");
        }
      });
  };

  return (
    <>
      {children}
      {!skipped ? (
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
            setSkipped(true);
          }}
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            <i
              style={{ fontSize: 48 }}
              className="fa-regular fa-circle-down"
            ></i>
            <br />
            <b>v{newVersion}</b>: A new update is available
          </DialogTitle>
          <DialogContent
            sx={{
              textAlign: "center",
              gap: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              You are currently using an outdated version of the app.
              <br />
              Refresh the page to get the latest features and bug fixes.
            </Box>
            <Divider />
            <Box sx={{ lineHeight: 0.4 }}>
              <Typography variant="caption">
                If you are currently filling a form, you may loose your content.
                <br />
                In this case you can temporarly skip this update and finish what
                you are currently doing.
                <br />
                We encourage you to refresh the page whenever you can to get the
                update.
              </Typography>
            </Box>
          </DialogContent>
          <DialogContent
            sx={{ justifyContent: "end", gap: 1, display: "flex" }}
          >
            <Button
              onClick={() => {
                setSkipped(true);
                setOpen(false);
              }}
              sx={{ textTransform: "none" }}
              size="small"
              variant="outlined"
            >
              I&apos;ll do it later
            </Button>
            <Button
              onClick={() => {
                window.location.reload();
              }}
              sx={{ textTransform: "none" }}
              size="small"
              variant="contained"
            >
              Refresh Now
            </Button>
          </DialogContent>
        </Dialog>
      ) : (
        []
      )}
    </>
  );
}

declare global {
  interface Window {
    app_info: { tag: string };
  }
}
