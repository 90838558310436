import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Vtransaction, Vtransaction_Entity } from "../../entities/vtransaction";

export const setSelectedVtransactionTalentID = createAction(
  "setSelectedVtransactionTalentID",
  function prepare(talentID?: number) {
    return {
      payload: {
        talentID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setSelectedVtransactionID = createAction(
  "setSelectedVtransactionID",
  function prepare(vtransactionID?: number) {
    return {
      payload: {
        vtransactionID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVtransactions = createAction(
  "films/vtransaction/getVtransactions",
  function prepare() {
    const request = new GetAction(`/films/vtransactions`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const vtransactions = Vtransaction.fromList(json);
      dispatch(getVtransactionsSuccess(vtransactions));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVtransactionsSuccess = createAction(
  "films/vtransaction/getVtransactionSuccess",
  function prepare(vtransactions: Vtransaction[]) {
    return {
      payload: {
        vtransactions,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVtransactionsByFilter = createAction(
  "films/vtransaction/getVtransactionsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/vtransactions`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const vtransactions = Vtransaction.fromList(json);
      dispatch(getVtransactionsByFilterSuccess(vtransactions));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVtransactionsByFilterSuccess = createAction(
  "films/vtransaction/getVtransactionsByFilterSuccess",
  function prepare(vtransactions: Vtransaction[]) {
    return {
      payload: {
        vtransactions,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVtransactionById = createAction(
  "films/vtransaction/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/vtransactions/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const vtransaction = new Vtransaction(json);
      dispatch(getVtransactionByIdSuccess(vtransaction));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVtransactionByIdSuccess = createAction(
  "films/vtransaction/getVtransactionByIdSuccess",
  function prepare(vtransaction?: Vtransaction_Entity) {
    return {
      payload: {
        vtransaction,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteVtransaction = createAction(
  "films/removeVtransactionById",
  function prepare(
    id: number,
    successAction?: AnyAction,
    callback?: () => void
  ) {
    const request = new DeleteAction(`/films/vtransactions/${id}`);

    if (successAction) {
      request.decodeResponse = (dispatch: Dispatch) => {
        if (callback) callback();
        dispatch(successAction);
      };
    }

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteVtransactionSuccess = createAction(
  "films/vtransactions/deleteVtransactionSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateVtransaction = createAction(
  "films/vtransactions/updateVtransaction",
  function prepare(
    id: number,
    body: Vtransaction_Entity,
    callback?: () => void
  ) {
    const request = new PutAction(`/films/vtransactions/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      if (callback) callback();
      dispatch(updateVtransactionSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateVtransactionSuccess = createAction(
  "films/vtransactions/updateVtransactionSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createVtransaction = createAction(
  "films/vtransactions/createVtransaction",
  function prepare(body: Vtransaction_Entity, callback?: () => void) {
    const request = new PostAction(`/films/vtransactions`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      if (callback) callback();
      const vtransaction = new Vtransaction(json);
      dispatch(createVtransactionSuccess(vtransaction));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createVtransactionSuccess = createAction(
  "films/vtransactions/createVtransactionSuccess",
  function prepare(vtransaction: Vtransaction) {
    return {
      payload: {
        vtransaction,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVtransactionsSearchMetadata = createAction(
  "films/getVtransactionsSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/vtransactions/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getVtransactionsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVtransactionsSearchMetadataSuccess = createAction(
  "films/getVtransactionsSearchMetadataSuccess",
  function prepare(vtransactionsSearchMetadata: unknown) {
    return {
      payload: {
        vtransactionsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
