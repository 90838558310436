import Budget_Entity from "./budget";
export default class Budget extends Budget_Entity {
  static fromList(budgetsJSON: unknown): Array<Budget> {
    const budgets: Budget[] = [];
    if (budgetsJSON)
      Array.isArray(budgetsJSON) &&
        budgetsJSON.forEach((budgetJSON) => {
          budgets.push(new Budget(budgetJSON));
        });
    return budgets;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
