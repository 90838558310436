import { Visibility } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { CurrentUserSelector, stopImpersonate } from "@nerdjs/account-kit";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../hooks";

/**
 * @component
 * @returns {ReactElement} Button to stop impersonate a user. Should be placed in the NerdAppBar configuration
 */
export function NerdAppBarStopImpersonate(): ReactElement | null {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(CurrentUserSelector);
  const { t } = useTranslation();

  /**
   * Stop impersonate
   */
  function handleStopImpersonate() {
    dispatch(stopImpersonate());
    window.location.reload();
  }

  return currentUser?.impersonate ? (
    <Chip
      icon={<Visibility />}
      sx={{
        mr: 1,
      }}
      onDelete={handleStopImpersonate}
      color={"error"}
      label={`Impersonating ${currentUser?.firstName} ${currentUser?.lastName}`}
    />
  ) : null;
}
