import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectScales = (state: AppState) => state.scaleState.scales;

const selectSelectedScaleID = (state: AppState) =>
  state.scaleState.selectedScaleID;

const selectScaleSearchMetadata = (state: AppState) =>
  state.scaleState.searchMetadata;

export const selectedScaleIDSelector = createSelector(
  [selectSelectedScaleID],
  (s) => s
);

const selectTaskTypes = (state: AppState) => state.taskTypeState.taskTypes;

export const scalesSelector = createSelector([selectScales], (scales) => {
  return mapToArray(scales);
});

export const scalesMapSelector = createSelector([selectScales], (scales) => {
  return scales;
});

export const scaleSelector = (id?: number) => {
  return createSelector([selectScales], (scales) => {
    if (id) return scales[id];
    return undefined;
  });
};

export const scalesForOrchestrator = (projectTypeID?: number) => {
  return createSelector(
    [selectScales, selectTaskTypes],
    (scales, taskTypes) => {
      return mapToArray(scales).filter(
        (s) =>
          s.projectTypeID === projectTypeID &&
          taskTypes[s.taskTypeID].jobCategoryID === 1
      );
    }
  );
};

export const scalesForLibrarian = (projectTypeID?: number) => {
  return createSelector(
    [selectScales, selectTaskTypes],
    (scales, taskTypes) => {
      return mapToArray(scales).filter(
        (s) =>
          s.projectTypeID === projectTypeID &&
          taskTypes[s.taskTypeID].jobCategoryID === 3
      );
    }
  );
};

export const scaleSearchMetadataSelector = createSelector(
  [selectScaleSearchMetadata],
  (s) => s
);
