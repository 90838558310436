import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectTaskTypes = (state: AppState) => state.taskTypeState.taskTypes;
const selectTaskTypeSearchMetadata = (state: AppState) =>
  state.taskTypeState.searchMetadata;

export const taskTypesSelector = createSelector(
  [selectTaskTypes],
  (taskTypes) => {
    return mapToArray(taskTypes);
  }
);

export const taskTypesMapSelector = createSelector(
  [selectTaskTypes],
  (taskTypes) => {
    return taskTypes;
  }
);

export const taskTypeSelector = (id?: number) => {
  return createSelector([selectTaskTypes], (taskTypes) => {
    if (id) return taskTypes[id];
    return undefined;
  });
};

export const taskTypeSearchMetadataSelector = createSelector(
  [selectTaskTypeSearchMetadata],
  (s) => s
);
