import { Timer } from "@nerdjs/kronos-kit";
import { createSelector } from "@reduxjs/toolkit";
import { Project } from "../../entities/project";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectShowArchived = (state: AppState) => state.projectState.showArchived;
export const selectTimersForProject = (state: AppState) =>
  state.projectState.timersPerProject;
const selectProjects = (state: AppState) => state.projectState.projects;
const selectSelectedProjectID = (state: AppState) =>
  state.projectState.selectedProjectID;

export const showArchivedSelector = createSelector(
  [selectShowArchived],
  (showArchived) => {
    return showArchived;
  }
);

export const projectsSelector = createSelector([selectProjects], (projects) => {
  return mapToArray(projects).sort((a, b) => b.id - a.id);
});

export const projectsMapSelector = createSelector(
  [selectProjects],
  (projects) => {
    return projects;
  }
);

export const selectedProjectSelector = createSelector(
  [selectProjects, selectSelectedProjectID],
  (projects, selectedProjectID) => {
    if (selectedProjectID) return new Project(projects[selectedProjectID]);
    return undefined;
  }
);

export const selectedProjectIDSelector = createSelector(
  [selectSelectedProjectID],
  (s) => s
);

export const projectSelector = (id?: number) => {
  return createSelector([selectProjects], (projects) => {
    if (id && projects[id]) return new Project(projects[id]);
    return undefined;
  });
};

export const timersForProjectSelector = (projectID?: number) => {
  return createSelector([selectTimersForProject], (timersPerProject) => {
    if (projectID) return Timer.fromList(timersPerProject[projectID]);
    return [];
  });
};
