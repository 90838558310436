import Expense_Entity from "./expense";
export default class Expense extends Expense_Entity {
  static fromList(expensesJSON: unknown): Array<Expense> {
    const expenses: Expense[] = [];
    if (expensesJSON)
      Array.isArray(expensesJSON) &&
        expensesJSON.forEach((expenseJSON) => {
          expenses.push(new Expense(expenseJSON));
        });
    return expenses;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
