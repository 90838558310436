import Benefit_Entity from "./benefit";
export default class Benefit extends Benefit_Entity {
  static fromList(benefitsJSON: unknown): Array<Benefit> {
    const benefits: Benefit[] = [];
    if (benefitsJSON)
      Array.isArray(benefitsJSON) &&
        benefitsJSON.forEach((benefitJSON) => {
          benefits.push(new Benefit(benefitJSON));
        });
    return benefits;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
