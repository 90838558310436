import { Button, Dialog, DialogActions, Hidden, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import { Talent, Talent_Entity } from "../entities/talent";
import { useAppSelector } from "../hooks/hooks";
import FormTalent from "../hooks/talent/talentsList/formTalent";
import TalentsList from "../hooks/talent/talentsList/talentsList";
import {
  createTalent,
  setSelectedTalentID,
} from "../redux/talent/talentActions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  selectedTalentIDSelector,
  talentSelector,
} from "../redux/talent/talentSelector";
import { defaultButtonProps } from "../constants";
import { Outlet, useParams } from "react-router-dom";
import Talents from "../hooks/talents/talents";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function TalentsRoute(): ReactElement {
  const selectedTalentID = useAppSelector(selectedTalentIDSelector);
  const [newTalent, setNewTalent] = useState<Talent_Entity | undefined>();
  const dispatch = useDispatch();
  const talent = useAppSelector(talentSelector(selectedTalentID));
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const params = useParams();

  if (params.id)
    return (
      <Box sx={{ flex: 1, display: "flex" }}>
        <Outlet />
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        background: "none",
        flex: 1,
        p: 2,
        gap: 2,
      }}
    >
      <Paper sx={{ flex: 1, border: "none", display: "flex" }}>
        <Talents onCreate={() => setNewTalent(new Talent('{"id": -1}'))} />
      </Paper>
      {sm && talent ? (
        <Dialog
          fullScreen={true}
          open={talent != undefined}
          onClose={() => dispatch(setSelectedTalentID())}
        >
          <FormTalent talentID={talent.id} />
        </Dialog>
      ) : (
        []
      )}
      {newTalent != undefined ? (
        <Dialog
          fullScreen={sm}
          open={newTalent != undefined}
          onClose={() => setNewTalent(undefined)}
        >
          <FormTalent
            talent={newTalent}
            onClose={() => setNewTalent(undefined)}
            onChange={(t) => {
              setNewTalent(t);
            }}
            onSave={(t) => console.log(t)}
          />
          <DialogActions>
            <Button
              {...defaultButtonProps}
              onClick={() => {
                dispatch(createTalent(newTalent));
                setNewTalent(undefined);
              }}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        []
      )}
    </Box>
  );
}
