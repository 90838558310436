import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button, Divider, Paper, Tooltip } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Dispatch } from "@reduxjs/toolkit";
import { ConfirmOptions, useConfirm } from "material-ui-confirm";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import {
  currencyFormatter,
  defaultDataGridProps,
  injectDefaultColumnProps,
} from "../../constants";
import { Expense_Entity } from "../../entities/expense";
import { Project } from "../../entities/project";
import { greyStyleForNonEditableCells } from "../../helpers/dataGridHelpers";
import { isAdminSelector } from "../../redux/appStatus/appStatusSelector";
import {
  createExpense,
  deleteExpense,
  deleteExpenseSuccess,
  getExpensesByFilter,
  updateExpense,
} from "../../redux/expense/expenseActions";
import { expensesByProjectSelector } from "../../redux/expense/expenseSelector";
import {
  projectSelector,
  selectedProjectIDSelector,
  selectedProjectSelector,
} from "../../redux/project/projectSelector";
import { useAppSelector } from "../hooks";
import {
  getterDivider,
  setterDivider,
} from "../jobCategory/jobCategoryRates/dataGridColDef";
import NoRowsOverlay from "../noRowsOverlay/noRowsOverlay";

/**
 * Expense
 *
 * @returns {null} Expense
 */
export default function Expenses() {
  const projectID = useAppSelector(selectedProjectIDSelector);
  const project = useAppSelector(projectSelector(projectID));
  const expenses = useAppSelector(expensesByProjectSelector(projectID));
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const confirm = useConfirm();

  const isAdmin = useAppSelector(isAdminSelector);

  useEffect(() => {
    if (projectID) {
      dispatch(
        getExpensesByFilter({
          filters: JSON.stringify([
            {
              name: "expenses.projectID",
              value: projectID,
              comparison: "eq",
            },
          ]),
        })
      );
    }
  }, []);

  // useEffect(() => {
  //   if (expenses.length) {
  //     if (expenses.length > count) scrollToBottom();
  //     setCount(expenses.length);
  //   }
  // }, [expenses]);

  // function scrollToBottom() {
  //   if (expenses?.length && apiRef && apiRef.current) {
  //     try {
  //       apiRef.current.scrollToIndexes({
  //         rowIndex: expenses.length - 1,
  //         colIndex: 0,
  //       });
  //     } catch (error) {
  //       console.log("Unable to scroll");
  //     }
  //   }
  // }

  const onExpenseUpdate = async (
    _newExpense: Expense_Entity,
    _oldExpense: Expense_Entity
  ) => {
    return new Promise<Expense_Entity>((resolve) => {
      dispatch(updateExpense(_newExpense.id, _newExpense));
      setTimeout(() => {
        resolve({ ..._newExpense });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1 }}>
      <DataGridPro
        {...defaultDataGridProps}
        isCellEditable={(params) =>
          project?.locked() ? false : params.colDef.editable ?? false
        }
        getCellClassName={(p) => {
          if (project?.locked()) return "lock";
          const classes = greyStyleForNonEditableCells(p);
          return classes;
        }}
        apiRef={apiRef}
        processRowUpdate={onExpenseUpdate}
        rows={expenses}
        columns={expenseColumns(dispatch, project!, confirm)}
        experimentalFeatures={{ newEditingApi: true }}
        getDetailPanelHeight={() => 100}
        components={{
          Toolbar: ExpenseToolbar,
          NoRowsOverlay: NoRowsOverlay,
          Footer: ExpensesFooter,
        }}
        componentsProps={{
          footer: { rows: expenses },
          cell: { tabIndex: 1 },
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              amount: isAdmin,
              unitPrice: isAdmin,
            },
          },
        }}
      />
    </Paper>
  );
}

function ExpenseToolbar() {
  const projectID = useAppSelector(selectedProjectIDSelector);
  const project = useAppSelector(selectedProjectSelector);
  const dispatch = useDispatch();

  const handleNewExpense = () => {
    if (projectID && !project?.locked()) {
      const newExpense = {
        projectID: projectID,
        description: "",
        quantity: 100,
        unitPrice: 0,
        amount: 0,
        createdAt: "",
        updatedAt: "",
        id: -1,
      };
      dispatch(createExpense(newExpense));
    }
  };

  useHotkeys(`ctrl+enter`, handleNewExpense, {
    enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
  });

  return (
    <Box className="header" sx={{ display: "flex" }}>
      <Tooltip title="ctrl + enter">
        <Button
          size="small"
          onClick={handleNewExpense}
          disabled={project?.locked()}
        >
          + New Expense
        </Button>
      </Tooltip>
      <Divider
        sx={{ margin: 1, width: 2 }}
        orientation="vertical"
        variant="middle"
        flexItem
      />
    </Box>
  );
}

function ExpensesFooter({ rows }: { rows: Expense_Entity[] }) {
  let total = 0;

  for (const key in rows) {
    if (Object.prototype.hasOwnProperty.call(rows, key)) {
      const row = rows[key];
      total += row.amount;
    }
  }
  return (
    <>
      <Divider />
      <Box sx={{ p: 2, textAlign: "right", fontWeight: 600 }}>
        Supplies: {currencyFormatter.format(total / 10000)}
      </Box>
    </>
  );
}

const expenseColumns = (
  dispatch: Dispatch,
  project: Project,
  confirm: (options?: ConfirmOptions | undefined) => Promise<void>
) =>
  injectDefaultColumnProps([
    {
      field: "id",
      headerName: "ID",
      type: "string",
      width: 40,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      editable: true,
      type: "string",
    },
    {
      field: "quantity",
      headerName: "Quantity",
      editable: true,
      type: "number",
      valueGetter: getterDivider("quantity", 100),
      valueSetter: setterDivider("quantity", 100),
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      editable: true,
      type: "number",
      valueGetter: getterDivider("unitPrice", 10000),
      valueSetter: setterDivider("unitPrice", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`,
    },
    {
      field: "amount",
      headerName: "Amount",
      editable: false,
      type: "number",
      valueGetter: getterDivider("amount", 10000),
      valueSetter: setterDivider("amount", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (props: { id: any }) => [
        <GridActionsCellItem
          key="delete"
          disabled={project.locked()}
          icon={<DeleteIcon />}
          label={`Delete ${props.id}`}
          onClick={() => {
            confirm({
              description: `You are about to delete an expense`,
            })
              .then(() => {
                dispatch(deleteExpense(props.id, deleteExpenseSuccess));
              })
              .catch(() => {
                /* ... */
              });
          }}
          color="inherit"
        />,
      ],
    },
  ]);
