import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button, Divider, Paper, Tooltip } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Dispatch } from "@reduxjs/toolkit";
import { ConfirmOptions, useConfirm } from "material-ui-confirm";
import moment from "moment";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import {
  currencyFormatter,
  defaultDataGridProps,
  injectDefaultColumnProps,
} from "../../constants";
import { Vtransaction_Entity } from "../../entities/vtransaction";
import { selectedProjectIDSelector } from "../../redux/project/projectSelector";
import { selectedTalentIDSelector } from "../../redux/talent/talentSelector";
import {
  createVtransaction,
  deleteVtransaction,
  deleteVtransactionSuccess,
  getVtransactionsByFilter,
  setSelectedVtransactionTalentID,
  updateVtransaction,
} from "../../redux/vtransaction/vtransactionActions";
import { talentVtransactionsSelector } from "../../redux/vtransaction/vtransactionSelector";
import { useAppSelector } from "../hooks";
import {
  getterDivider,
  setterDivider,
} from "../jobCategory/jobCategoryRates/dataGridColDef";
import NoRowsOverlay from "../noRowsOverlay/noRowsOverlay";

/**
 * TalentLedger
 *
 * @returns {null} TalentLedger
 */
export default function TalentLedger({
  talentID,
  header = false,
}: {
  talentID?: number;
  header?: boolean;
}) {
  const confirm = useConfirm();
  const _projectID = useAppSelector(selectedProjectIDSelector);
  const _talentID = talentID || useAppSelector(selectedTalentIDSelector);

  const apiRef = useGridApiRef();
  const talentVtransactions = useAppSelector(talentVtransactionsSelector);

  const onVtransactionUpdate = async (
    _newVtransaction: Vtransaction_Entity
  ) => {
    return new Promise<Vtransaction_Entity>((resolve) => {
      dispatch(
        updateVtransaction(_newVtransaction.id, _newVtransaction, refreshVtrans)
      );
      setTimeout(() => {
        resolve({ ..._newVtransaction });
      }, 50);
    });
  };

  const onVtransactionCreate = () => {
    if (_talentID) {
      const newVtransaction = {
        talentID: _talentID,
        projectID: _projectID,
        amount: 0,
        id: -1,
        notes: "",
        createdAt: "",
        projectName: "",
        updatedAt: "",
      };
      dispatch(createVtransaction(newVtransaction, refreshVtrans));
    }
  };

  useEffect(() => {
    if (_talentID) {
      refreshVtrans();
    }
  }, [_talentID]);

  const refreshVtrans = () => {
    dispatch(
      getVtransactionsByFilter({
        filters: JSON.stringify([
          {
            name: "vtransaction.talentID",
            value: _talentID,
            comparison: "eq",
          },
        ]),
      })
    );
  };

  const dispatch = useDispatch();
  return (
    <Paper
      sx={{
        p: header ? 2 : 0,
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DataGridPro
        {...defaultDataGridProps}
        apiRef={apiRef}
        processRowUpdate={onVtransactionUpdate}
        rows={talentVtransactions[_talentID!] ?? []}
        columns={vtransactionColumns(dispatch, confirm, refreshVtrans)}
        experimentalFeatures={{ newEditingApi: true }}
        initialState={{}}
        components={{
          Toolbar: VtransactionToolbar,
          NoRowsOverlay: NoRowsOverlay,
          Footer: TalentLedgerFooter,
        }}
        componentsProps={{
          footer: { rows: talentVtransactions[_talentID!] ?? [] },
          toolbar: {
            projectID: _projectID,
            talentID: _talentID,
            onCreate: onVtransactionCreate,
            onClose: () => dispatch(setSelectedVtransactionTalentID()),
          },
          cell: { tabIndex: 1 },
        }}
      />
    </Paper>
  );
}

function TalentLedgerFooter({ rows }: { rows: Vtransaction_Entity[] }) {
  let total = 0;

  for (const key in rows) {
    if (Object.prototype.hasOwnProperty.call(rows, key)) {
      const row = rows[key];
      total += row.amount;
    }
  }
  return (
    <>
      <Divider />
      <Box sx={{ p: 2, textAlign: "right", fontWeight: 600 }}>
        Total: {currencyFormatter.format(total / 10000)}
      </Box>
    </>
  );
}

function VtransactionToolbar({
  talentID,
  projectID,
  onCreate,
  onClose,
}: {
  talentID: number;
  projectID: number;
  onCreate: () => void;
  onClose: () => void;
}) {
  if (!talentID) return <div />;

  useHotkeys(`ctrl+enter`, onCreate, {
    enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
  });

  return (
    <Box className="header" sx={{ display: "flex" }}>
      <Tooltip title="ctrl + enter">
        <Button size="small" onClick={onCreate}>
          + New Vtransaction
        </Button>
      </Tooltip>
      {projectID ? (
        <Button size="small" onClick={onClose}>
          X Close
        </Button>
      ) : (
        []
      )}
    </Box>
  );
}

const vtransactionColumns = (
  dispatch: Dispatch,
  confirm: (options?: ConfirmOptions | undefined) => Promise<void>,
  refreshVtrans: () => void
) =>
  injectDefaultColumnProps([
    {
      field: "id",
      headerName: "ID",
      type: "string",
      width: 40,
    },
    {
      field: "createdAt",
      headerName: "Date",
      editable: false,
      flex: 1,
      type: "string",
      valueFormatter: (p: GridValueFormatterParams) =>
        moment(p.value).format("ll"),
    },
    {
      field: "projectName",
      headerName: "Project",
      editable: false,
      flex: 1,
      type: "string",
    },
    {
      field: "notes",
      headerName: "Notes",
      editable: true,
      flex: 1,
      type: "string",
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
      editable: true,
      type: "number",
      valueGetter: getterDivider("amount", 10000),
      valueSetter: setterDivider("amount", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (props: { id: any }) => [
        <GridActionsCellItem
          key="delete"
          icon={<DeleteIcon />}
          label={`Delete ${props.id}`}
          onClick={() => {
            confirm({
              description: `You are about to delete a virtual transaction`,
            })
              .then(() => {
                dispatch(
                  deleteVtransaction(
                    props.id,
                    deleteVtransactionSuccess,
                    refreshVtrans
                  )
                );
              })
              .catch(() => {
                /* ... */
              });
          }}
          color="inherit"
        />,
      ],
    },
  ]);
