import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { ProjectTask, ProjectTask_Entity } from "../../entities/projectTask";

export const setSelectedProjectTaskID = createAction(
  "setSelectedProjectTaskID",
  function prepare(projectTaskID?: number) {
    return {
      payload: {
        projectTaskID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getProjectTasks = createAction(
  "films/projectTask/getProjectTasks",
  function prepare(projectID: number) {
    const request = new GetAction(`/films/projects/${projectID}/tasks`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const projectTasks = ProjectTask.fromList(json);
      dispatch(getProjectTasksSuccess(projectTasks, projectID));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getProjectTasksSuccess = createAction(
  "films/projectTask/getProjectTaskSuccess",
  function prepare(projectTasks: ProjectTask[], projectID: number) {
    return {
      payload: {
        projectTasks,
        id: nanoid(),
        projectID,
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getProjectTasksByFilter = createAction(
  "films/projectTask/getProjectTasksByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/projectTasks`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const projectTasks = ProjectTask.fromList(json);
      dispatch(getProjectTasksByFilterSuccess(projectTasks));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getProjectTasksByFilterSuccess = createAction(
  "films/projectTask/getProjectTasksByFilterSuccess",
  function prepare(projectTasks: ProjectTask[]) {
    return {
      payload: {
        projectTasks,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getProjectTaskById = createAction(
  "films/projectTask/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/projectTasks/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const projectTask = new ProjectTask(json);
      dispatch(getProjectTaskByIdSuccess(projectTask));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getProjectTaskByIdSuccess = createAction(
  "films/projectTask/getProjectTaskByIdSuccess",
  function prepare(projectTask?: ProjectTask_Entity) {
    return {
      payload: {
        projectTask,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteProjectTask = createAction(
  "films/removeProjectTaskById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/films/projectTasks/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteProjectTaskSuccess = createAction(
  "films/projectTasks/deleteProjectTaskSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateProjectTask = createAction(
  "films/projectTasks/updateProjectTask",
  function prepare(id: number, body: ProjectTask_Entity) {
    const request = new PutAction(`/films/projectTasks/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateProjectTaskSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateProjectTaskSuccess = createAction(
  "films/projectTasks/updateProjectTaskSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createProjectTask = createAction(
  "films/projectTasks/createProjectTask",
  function prepare(body: ProjectTask_Entity) {
    const request = new PostAction(`/films/projectTasks`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const projectTask = new ProjectTask(json);
      dispatch(createProjectTaskSuccess(projectTask));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createProjectTaskSuccess = createAction(
  "films/projectTasks/createProjectTaskSuccess",
  function prepare(projectTask: ProjectTask) {
    return {
      payload: {
        projectTask,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getProjectTasksSearchMetadata = createAction(
  "films/getProjectTasksSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/projectTasks/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getProjectTasksSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getProjectTasksSearchMetadataSuccess = createAction(
  "films/getProjectTasksSearchMetadataSuccess",
  function prepare(projectTasksSearchMetadata: unknown) {
    return {
      payload: {
        projectTasksSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
