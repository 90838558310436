/**
 * ProjectType_Entity type guard.
 *
 * @param {any} projectTypeJson ProjectType object from API
 * @returns {boolean} Return true if type is ProjectType_Entity
 */
function validator(
  projectTypeJson: unknown
): projectTypeJson is ProjectType_Entity {
  if (typeof projectTypeJson === "object" && projectTypeJson != null) {
    ProjectType_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(projectTypeJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class ProjectType_Entity {
  static requiredFields = [
    "id",
    "name",
    "minimumCopyist",
    "flexPlan",
    "createdAt",
    "updatedAt",
  ];

  constructor(projectTypeJson: unknown) {
    if (validator(projectTypeJson)) {
      this.id = projectTypeJson.id;
      this.name = projectTypeJson.name;
      this.minimumCopyist = projectTypeJson.minimumCopyist;
      this.flexPlan = projectTypeJson.flexPlan;
      this.createdAt = projectTypeJson.createdAt;
      this.updatedAt = projectTypeJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ ProjectType_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name: string;
  minimumCopyist: number;
  flexPlan: boolean;
  createdAt: string;
  updatedAt: string;
}
