import { Box } from "@mui/system";
import { ReactElement, useEffect } from "react";
import { useDispatch } from "react-redux";
import Vtransactions from "../hooks/vtransactions/vtransactions";
import { getVtransactions } from "../redux/vtransaction/vtransactionActions";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function LedgerRoute(): ReactElement {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getVtransactions());
  }, []);

  return (
    <Box sx={{ p: 2, flex: 1, display: "flex" }}>
      <Vtransactions />
    </Box>
  );
}
