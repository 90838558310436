import { Box, CircularProgress, CssBaseline, Grid } from "@mui/material";
import { CurrentUserSelector, getCurrentUser } from "@nerdjs/account-kit";
import { getCurrentTimer } from "@nerdjs/kronos-kit";
import { initNetworkAction } from "@nerdjs/nerd-network";
import { clearNotification, NerdNotifier } from "@nerdjs/nerd-ui";
import {
  ReactChild,
  ReactChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
} from "react";

import { CssVarsProvider } from "@mui/joy";
import { AppRouter } from "../../appRouter";
import { AppConfig } from "../../environement";
import { appBarConfig } from "../config/appBarConfig";
import { getTheme } from "../config/themeConfig";
import { useAppDispatch, useAppSelector } from "../hooks";
import { MainDrawer } from "../mainDrawer/mainDrawer";
import { NerdAppBar } from "../nerdAppBar/nerdAppBar";
import styles from "./style.module.scss";

/**
 * App wrapper
 *
 * @returns {ReactElement} App wrapper
 */
export default function NerdApp(): ReactElement {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(CurrentUserSelector);

  useEffect(() => {
    dispatch(initNetworkAction(AppConfig.api));
    dispatch(getCurrentUser());
    dispatch(getCurrentTimer());

    document.addEventListener("wheel", function () {
      const activeElement = document.activeElement as HTMLInputElement;
      if (activeElement?.type === "number") {
        activeElement?.blur();
      }
    });
  }, []);

  const theme = useMemo(() => {
    return getTheme(AppConfig.app.alphaWatermark ?? false);
  }, []);

  return (
    <CssVarsProvider theme={theme}>
      <NotifierWrapper>
        <Box
          sx={{
            backgroundImage: `url("${AppConfig.app.rootBackground}") `,
            display: "flex",
            flex: 1,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <CssBaseline />
          {!currentUser ? (
            <Grid className={styles.circularProgressContainer} container>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              <NerdAppBar {...appBarConfig} />
              <MainDrawer />
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  display: "flex",
                }}
              >
                <AppRouter />
              </Box>
            </>
          )}
        </Box>
      </NotifierWrapper>
    </CssVarsProvider>
  );
}

const NotifierWrapper = ({
  children,
}: {
  children: (ReactChild | ReactChildren) & ReactNode;
}) => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector((s) => s.notification);
  return (
    notification && (
      <NerdNotifier
        {...notification}
        onClose={() => {
          dispatch(clearNotification());
          notification.onClose?.();
        }}
      >
        {children}
      </NerdNotifier>
    )
  );
};
