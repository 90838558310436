import { Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { CurrentUserSelector } from "@nerdjs/account-kit";
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { RouterConfig } from "./hooks/config/routerConfig";
import { useAppSelector } from "./hooks/hooks";
import BenefitsRoute from "./routes/benefitsPage";
import JobCategoriesRoute from "./routes/jobCategoriesPage";
import LedgerRoute from "./routes/ledgerPage";
import PositionsRoute from "./routes/positionsPage";
import ProjectRoute from "./routes/projectPage";
import ProjectsRoute from "./routes/projectsPage";
import ProjectTypesRoute from "./routes/projectTypesPage";
import RatesRoute from "./routes/rates";
import RootRoute from "./routes/rootPage";
import ScalesRoute from "./routes/scalesPage";
import TalentRoute from "./routes/talentPage";
import TalentsRoute from "./routes/talentsPage";
import TaskTypesRoute from "./routes/taskTypesPage";
import { Timer } from "./routes/timer";
import { Timers } from "./routes/timers";
import VendorsPage from "./routes/vendors";

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: useMediaQuery(theme.breakpoints.down("sm")) ? "inherit" : "48px",
  background:
    theme.palette.mode !== "light"
      ? "rgba(0,0,0,0.95)"
      : "rgba(255,255,255,0.95)",
  backdropFilter: "blur(10px)",
  flex: 1,
  display: "flex",
}));

/**
 *
 * @returns {React.ReactElement} the router
 */
export function AppRouter(): React.ReactElement {
  return (
    <Routes>
      <Route
        path={RouterConfig.root}
        element={
          <RequireAuth>
            <RootRoute />
          </RequireAuth>
        }
      >
        <Route
          path={RouterConfig.talents}
          element={
            <RequireAuth>
              <BoxWrapper>
                <TalentsRoute />
              </BoxWrapper>
            </RequireAuth>
          }
        >
          <Route path={":id"} element={<TalentRoute />} />
        </Route>
        <Route
          path={RouterConfig.taskTypes}
          element={
            <RequireAuth>
              <BoxWrapper>
                <TaskTypesRoute />
              </BoxWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={RouterConfig.timer}
          element={
            <RequireAuth>
              <BoxWrapper>
                <Timer />
              </BoxWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={RouterConfig.timers}
          element={
            <RequireAuth>
              <BoxWrapper>
                <Timers />
              </BoxWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={RouterConfig.positions}
          element={
            <RequireAuth>
              <BoxWrapper>
                <PositionsRoute />
              </BoxWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={RouterConfig.scales}
          element={
            <RequireAuth>
              <BoxWrapper>
                <ScalesRoute />
              </BoxWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={RouterConfig.benefits}
          element={
            <RequireAuth>
              <BoxWrapper>
                <BenefitsRoute />
              </BoxWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={RouterConfig.projectTypes}
          element={
            <RequireAuth>
              <BoxWrapper>
                <ProjectTypesRoute />
              </BoxWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={RouterConfig.ledger}
          element={
            <RequireAuth>
              <BoxWrapper>
                <LedgerRoute />
              </BoxWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={RouterConfig.vendors}
          element={
            <RequireAuth>
              <BoxWrapper>
                <VendorsPage />
              </BoxWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={RouterConfig.jobCategories}
          element={
            <RequireAuth>
              <BoxWrapper>
                <JobCategoriesRoute />
              </BoxWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={RouterConfig.taskTypes}
          element={
            <RequireAuth>
              <BoxWrapper>
                <TaskTypesRoute />
              </BoxWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={RouterConfig.rates}
          element={
            <RequireAuth>
              <BoxWrapper>
                <RatesRoute />
              </BoxWrapper>
            </RequireAuth>
          }
        />
        <Route
          path={RouterConfig.projects}
          element={
            <RequireAuth>
              <BoxWrapper>
                <ProjectsRoute />
              </BoxWrapper>
            </RequireAuth>
          }
        >
          <Route path={":id"} element={<ProjectRoute />} />
        </Route>
      </Route>
    </Routes>
  );
}

/**
 * Route wrapper that redirects logged off users to the signin route
 *
 * @param {{children: React.ReactElement}} param description
 * @returns {React.ReactElement} Return RequireAuth component
 */
function RequireAuth({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement {
  const logged = useAppSelector(CurrentUserSelector);
  const location = useLocation();

  if (!logged) {
    return (
      <Navigate to={RouterConfig.signin} state={{ from: location }} replace />
    );
  }

  return children;
}
