import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectBudgets = (state: AppState) => state.budgetState.budgets;

export const budgetForProjectSelector = (projectID?: number) => {
  return createSelector([selectBudgets], (budgetMap) => {
    if (projectID) {
      return mapToArray(budgetMap).filter((b) => b.projectID === projectID)[0];
    }
    return undefined;
  });
};

export const budgetSelector = (id?: number) => {
  return createSelector([selectBudgets], (budgets) => {
    if (id) return budgets[id];
    return undefined;
  });
};
