import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button, Divider, Paper, Tooltip } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Dispatch } from "@reduxjs/toolkit";
import { ConfirmOptions, useConfirm } from "material-ui-confirm";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import {
  currencyFormatter,
  defaultDataGridProps,
  injectDefaultColumnProps,
} from "../../constants";
import { ProjectType_Entity } from "../../entities/projectType";
import {
  createProjectType,
  deleteProjectType,
  deleteProjectTypeSuccess,
  getProjectTypes,
  updateProjectType,
} from "../../redux/projectType/projectTypeActions";
import { projectTypesSelector } from "../../redux/projectType/projectTypeSelector";
import { useAppSelector } from "../hooks";
import {
  getterDivider,
  setterDivider,
} from "../jobCategory/jobCategoryRates/dataGridColDef";
import NoRowsOverlay from "../noRowsOverlay/noRowsOverlay";

/**
 * ProjectType
 *
 * @returns {null} ProjectType
 */
export default function ProjectTypes() {
  const projectTypes = useAppSelector(projectTypesSelector);

  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const confirm = useConfirm();

  useEffect(() => {
    dispatch(getProjectTypes());
  }, []);

  const onProjectTypeUpdate = async (
    _newProjectType: ProjectType_Entity,
    _oldProjectType: ProjectType_Entity
  ) => {
    return new Promise<ProjectType_Entity>((resolve) => {
      dispatch(updateProjectType(_newProjectType.id, _newProjectType));
      setTimeout(() => {
        resolve({ ..._newProjectType });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1 }}>
      <DataGridPro
        {...defaultDataGridProps}
        apiRef={apiRef}
        processRowUpdate={onProjectTypeUpdate}
        rows={projectTypes}
        columns={projectTypeColumns(dispatch, confirm)}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: ProjectTypeToolbar,
          NoRowsOverlay: NoRowsOverlay,
        }}
      />
    </Paper>
  );
}

function ProjectTypeToolbar() {
  const dispatch = useDispatch();

  const handleNewProjectType = () => {
    const newProjectType = {
      id: -1,
      name: "",
      createdAt: "",
      updatedAt: "",
    };
    dispatch(createProjectType(newProjectType));
  };

  useHotkeys(`ctrl+enter`, handleNewProjectType, {
    enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
  });

  return (
    <Box className="header" sx={{ display: "flex" }}>
      <Tooltip title="ctrl + enter">
        <Button size="small" onClick={handleNewProjectType}>
          + New Project Type
        </Button>
      </Tooltip>
      <Divider
        sx={{ margin: 1, width: 2 }}
        orientation="vertical"
        variant="middle"
        flexItem
      />
    </Box>
  );
}

const projectTypeColumns = (
  dispatch: Dispatch,
  confirm: (options?: ConfirmOptions | undefined) => Promise<void>
) =>
  injectDefaultColumnProps([
    {
      field: "id",
      headerName: "ID",
      editable: false,
      type: "number",
      width: 40,
    },
    {
      field: "name",
      headerName: "Name",
      editable: true,
      type: "string",
      flex: 1,
    },
    {
      field: "flexPlan",
      headerName: "Flex Plan",
      editable: true,
      type: "boolean",
    },
    {
      field: "minimumCopyist",
      headerName: "Min Pay for Copyists",
      sortable: false,
      editable: true,
      width: 180,
      type: "number",
      valueGetter: getterDivider("minimumCopyist", 10000),
      valueSetter: setterDivider("minimumCopyist", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (props: { id: any }) => [
        <GridActionsCellItem
          key="delete"
          showInMenu
          icon={<DeleteIcon />}
          label={`Delete ${props.id}`}
          onClick={() => {
            confirm({
              description: `You are about to delete a projectType`,
            })
              .then(() => {
                dispatch(deleteProjectType(props.id, deleteProjectTypeSuccess));
              })
              .catch(() => {
                /* ... */
              });
          }}
          color="inherit"
        />,
      ],
    },
  ]);
