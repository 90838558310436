import { createSelector } from "@reduxjs/toolkit";
import { ProjectTask_Entity } from "../../entities/projectTask";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectProjectTasks = (state: AppState) =>
  state.projectTaskState.projectTasks;

const selectProjectTasksByProject = (state: AppState) =>
  state.projectTaskState.projectTasksByProject;

export const projectTasksSelector = createSelector(
  [selectProjectTasks],
  (projectTasks) => {
    return mapToArray(projectTasks);
  }
);

export const projectTasksMapSelector = createSelector(
  [selectProjectTasks],
  (projectTasks) => {
    return projectTasks;
  }
);

export const projectTaskSelector = (id?: number) => {
  return createSelector([selectProjectTasks], (projectTasks) => {
    if (id) return projectTasks[id];
    return undefined;
  });
};

export const projectTasksByProjectSelector = (projectID?: number) => {
  return createSelector(
    [selectProjectTasksByProject],
    (projectTasksByProject) => {
      if (projectID === undefined) return [];
      return projectTasksByProject[projectID] ?? [];
    }
  );
};
