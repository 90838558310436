import { createReducer } from "@reduxjs/toolkit";
import { Vtransaction_Entity } from "../../entities/vtransaction";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteVtransactionSuccess,
  getVtransactionByIdSuccess,
  getVtransactionsByFilterSuccess,
  getVtransactionsSearchMetadataSuccess,
  getVtransactionsSuccess,
  setSelectedVtransactionID,
  setSelectedVtransactionTalentID,
} from "./vtransactionActions";
import { VtransactionState } from "./vtransactionState";

function initState(): VtransactionState {
  const { vtransaction: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    vtransactions: {},
    talentVtransactions: {},
    searchMetadata: undefined,
    selectedTalentID: undefined,
    selectedVtransactionID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const vtransactionReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedVtransactionTalentID, (state, action) => {
    state.selectedTalentID = action.payload.talentID;
    return state;
  });
  builder.addCase(setSelectedVtransactionID, (state, action) => {
    state.selectedVtransactionID = action.payload.vtransactionID;
    return state;
  });
  builder.addCase(getVtransactionsSuccess, (state, action) => {
    const vtransactions = action.payload.vtransactions;
    state.vtransactions = arrayToMap<Vtransaction_Entity>(vtransactions);
    return state;
  });
  builder.addCase(getVtransactionsByFilterSuccess, (state, action) => {
    const vtransactions = action.payload.vtransactions;
    const _s = {};

    for (const key in vtransactions) {
      if (Object.prototype.hasOwnProperty.call(vtransactions, key)) {
        const vtransaction = vtransactions[key];
        if (_s[vtransaction.talentID]) {
          _s[vtransaction.talentID].push(vtransaction);
        } else {
          _s[vtransaction.talentID] = [vtransaction];
        }
      }
    }
    for (const key in _s) {
      if (Object.prototype.hasOwnProperty.call(_s, key)) {
        _s[key] = _s[key].sort(
          (a: Vtransaction_Entity, b: Vtransaction_Entity) => b.id - a.id
        );
      }
    }
    state.talentVtransactions = _s;
    return state;
  });
  builder.addCase(getVtransactionsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.vtransactionsSearchMetadata;
    return state;
  });
  builder.addCase(deleteVtransactionSuccess, (state, action) => {
    const vtransactionID = action.payload.id;
    if (state.vtransactions[vtransactionID]) {
      delete state.vtransactions[vtransactionID];
    }
    return state;
  });
  builder.addCase(getVtransactionByIdSuccess, (state, action) => {
    const vtransaction = action.payload.vtransaction;
    if (vtransaction) {
      const vtransactions = { ...state.vtransactions };
      vtransactions[vtransaction.id] = vtransaction;
      state.vtransactions = vtransactions;
    }
    return state;
  });
});
