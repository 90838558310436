import { createReducer } from "@reduxjs/toolkit";
import {
  createTaskSuccess,
  deleteTaskSuccess,
  getTaskByIdSuccess,
  getTasksByFilterSuccess,
  getTasksSearchMetadataSuccess,
  getTasksSuccess,
  setSelectedGridTasks,
  setTaskBulkSelect,
  updateTaskSuccess,
} from "./taskActions";
import { TaskState } from "./taskState";

const initState: TaskState = {
  tasks: [],
  bulkSelect: false,
  searchMetadata: undefined,
  selectedGridTasks: undefined,
  updateTaskSuccess: false,
  deleteTaskSuccess: false,
};

export const taskReducer = createReducer(initState, (builder) => {
  builder.addCase(getTasksSuccess, (state, action) => {
    state.tasks = action.payload.tasks;
    return state;
  });
  builder.addCase(setSelectedGridTasks, (state, action) => {
    state.selectedGridTasks = action.payload.selectedGridTasks;
    return state;
  });
  builder.addCase(setTaskBulkSelect, (state, action) => {
    const bulkSelect = action.payload.bulkSelect;

    state.bulkSelect = bulkSelect;
    if (!bulkSelect) state.selectedGridTasks = undefined;
    return state;
  });
  builder.addCase(getTasksByFilterSuccess, (state, action) => {
    state.tasksByFilter = action.payload.tasks;
    return state;
  });
  builder.addCase(getTasksSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.tasksSearchMetadata;
    return state;
  });
  builder.addCase(updateTaskSuccess, (state, action) => {
    state.updateTaskSuccess = action.payload.success;
    return state;
  });
  builder.addCase(deleteTaskSuccess, (state, action) => {
    state.deleteTaskSuccess = action.payload.success;
    return state;
  });
  builder.addCase(getTaskByIdSuccess, (state, action) => {
    state.task = action.payload.task;
    return state;
  });
  builder.addCase(createTaskSuccess, (state, action) => {
    state.createTaskSuccess = action.payload.task;
    return state;
  });
});
