import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import {
  DataGridPro,
  GridRowParams,
  GridToolbar,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Dispatch } from "@reduxjs/toolkit";
import { ConfirmOptions, useConfirm } from "material-ui-confirm";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  currencyFormatter,
  defaultDataGridProps,
  injectDefaultColumnProps,
} from "../../constants";
import { Talent_Entity } from "../../entities/talent";
import { Vtransaction_Entity } from "../../entities/vtransaction";
import { mapToArray } from "../../helpers/reducerHelpers";
import { store } from "../../redux/store";
import { getTalents } from "../../redux/talent/talentActions";
import {
  talentsMapSelector,
  talentsSelector,
} from "../../redux/talent/talentSelector";
import {
  createVtransaction,
  getVtransactions,
  updateVtransaction,
} from "../../redux/vtransaction/vtransactionActions";
import { vtransactionsSelector } from "../../redux/vtransaction/vtransactionSelector";
import { useAppSelector } from "../hooks";
import {
  getterDivider,
  setterDivider,
} from "../jobCategory/jobCategoryRates/dataGridColDef";
import NoRowsOverlay from "../noRowsOverlay/noRowsOverlay";
import TalentLedger from "../talentLedger/talentLedger";

/**
 * Vtransaction
 *
 * @returns {null} Vtransaction
 */
export default function Vtransactions() {
  const vtransactions = useAppSelector(vtransactionsSelector);
  const talentsMap = useAppSelector(talentsMapSelector);
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const confirm = useConfirm();
  const [count, setCount] = useState(0);
  const talents = useAppSelector(talentsSelector);

  useEffect(() => {
    dispatch(getVtransactions());
    if (talents.length === 0) {
      store.dispatch(getTalents());
    }
  }, []);

  useEffect(() => {
    if (vtransactions.length) {
      if (vtransactions.length > count) scrollToBottom();
      setCount(vtransactions.length);
    }
  }, [vtransactions]);

  function scrollToBottom() {
    if (vtransactions?.length && apiRef && apiRef.current) {
      try {
        apiRef.current.scrollToIndexes({
          rowIndex: vtransactions.length - 1,
          colIndex: 0,
        });
      } catch (error) {
        console.log("Unable to scroll");
      }
    }
  }

  const getData = (): any => {
    const _ret = {};
    for (const key in vtransactions) {
      if (Object.prototype.hasOwnProperty.call(vtransactions, key)) {
        const vtransaction = vtransactions[key];
        if (_ret[vtransaction.talentID]) {
          _ret[vtransaction.talentID].balance += vtransaction.amount;
          _ret[vtransaction.talentID].vtransactions.push(vtransaction);
        } else {
          _ret[vtransaction.talentID] = {
            id: vtransaction.talentID,
            talentID: vtransaction.talentID,
            balance: vtransaction.amount,
            vtransactions: [vtransaction],
          };
        }
      }
    }

    return mapToArray(_ret).sort((a, b) => {
      const aLastName = talentsMap[a?.id].lastName ?? "";
      const bLastName = talentsMap[b?.id].lastName ?? "";

      return aLastName.localeCompare(bLastName);
    });
  };

  const getDetailPanelContent = useCallback(
    (
      p: GridRowParams<{
        id: number;
        talentID: number;
        vtransactions: Vtransaction_Entity[];
        balance: number;
      }>
    ) => {
      return (
        <Box
          sx={{
            flex: 1,
            height: "100%",
            p: 1,
            display: "flex",
            background: "rgba(33,150,243, 0.08)",
          }}
        >
          <TalentLedger talentID={p.row.talentID} />
        </Box>
      );
    },
    [vtransactions]
  );

  return (
    <Paper sx={{ flex: 1 }}>
      <DataGridPro
        {...defaultDataGridProps}
        getCellClassName={(c) => (c.field === "id" ? "cellReadOnly" : "")}
        apiRef={apiRef}
        rows={getData()}
        columns={vtransactionColumns(dispatch, confirm, talents)}
        experimentalFeatures={{ newEditingApi: true }}
        getDetailPanelHeight={(p) => {
          return 400;
        }}
        components={{
          Toolbar: GridToolbar,
          Footer: VtransactionFooter,
          NoRowsOverlay: NoRowsOverlay,
        }}
        componentsProps={{
          footer: { vtransactions },
          cell: { tabIndex: 1 },
        }}
        getDetailPanelContent={getDetailPanelContent}
      />
    </Paper>
  );
}

function VtransactionFooter({
  vtransactions,
}: {
  vtransactions: Vtransaction_Entity[];
}) {
  let due = 0;
  let owed = 0;

  for (const key in vtransactions) {
    if (Object.prototype.hasOwnProperty.call(vtransactions, key)) {
      const vtransaction = vtransactions[key];
      if (vtransaction.amount > 0) {
        due += vtransaction.amount;
      } else {
        owed += vtransaction.amount;
      }
    }
  }

  return (
    <>
      <Divider />
      <Box sx={{ p: 2, textAlign: "right", fontWeight: 600 }}>
        Credited: {currencyFormatter.format(due / 10000)}
        <br />
        Debited: {currencyFormatter.format(owed / 10000)}
        <br />
        <Typography variant="body1">
          Balance: {currencyFormatter.format((due + owed) / 10000)}
        </Typography>
      </Box>
    </>
  );
}

function VtransactionToolbar() {
  const dispatch = useDispatch();

  const handleNewVtransaction = () => {
    const newVtransaction = {
      talentID: 0,
      projectID: 0,
      amount: 0,
      id: -1,
      createdAt: "",
      projectName: "",
      updatedAt: "",
      notes: "",
    };
    dispatch(createVtransaction(newVtransaction));
  };

  return (
    <Box className="header" sx={{ display: "flex" }}>
      <Button size="small" onClick={handleNewVtransaction}>
        + New vTransaction
      </Button>
      <Divider
        sx={{ margin: 1, width: 2 }}
        orientation="vertical"
        variant="middle"
        flexItem
      />
    </Box>
  );
}

const vtransactionColumns = (
  dispatch: Dispatch,
  confirm: (options?: ConfirmOptions | undefined) => Promise<void>,
  talents: Talent_Entity[]
) =>
  injectDefaultColumnProps([
    {
      field: "id",
      headerName: "ID",
      type: "string",
      width: 40,
    },
    {
      field: "talentID",
      headerName: "Talent",
      type: "string",
      renderCell: (p: any) => {
        const talent = talents.find((t) => t.id === p.row.talentID);
        return (
          <Typography variant="body2">
            {talent?.firstName} {talent?.lastName}
          </Typography>
        );
      },
      flex: 1,
    },
    {
      field: "balance",
      headerName: "Balance",
      editable: false,
      type: "number",
      valueGetter: getterDivider("balance", 10000),
      valueSetter: setterDivider("balance", 10000),
      valueFormatter: (p: GridValueFormatterParams) =>
        `${currencyFormatter.format(p.value)}`,
    },
  ]);
