/**
 * Talent_Entity type guard.
 *
 * @param {any} talentJson Talent object from API
 * @returns {boolean} Return true if type is Talent_Entity
 */
function validator(talentJson: unknown): talentJson is Talent_Entity {
  if (typeof talentJson === "object" && talentJson != null) {
    Talent_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(talentJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Talent_Entity {
  static requiredFields = [
    "id",
    "userUUID",
    "positionID",
    "company",
    "firstName",
    "lastName",
    "code",
    "email",
    "address",
    "city",
    "state",
    "zipcode",
    "phone",
    "archived",
    "birthDate",
    "taxStatus",
    "dropbox",
    "google",
    "createdAt",
    "updatedAt",
  ];

  constructor(talentJson: unknown) {
    if (validator(talentJson)) {
      this.id = talentJson.id;
      this.userUUID = talentJson.userUUID;
      this.positionID = talentJson.positionID;
      this.company = talentJson.company;
      this.firstName = talentJson.firstName;
      this.lastName = talentJson.lastName;
      this.code = talentJson.code;
      this.email = talentJson.email;
      this.address = talentJson.address;
      this.city = talentJson.city;
      this.state = talentJson.state;
      this.zipcode = talentJson.zipcode;
      this.phone = talentJson.phone;
      this.ssn = talentJson.ssn;
      this.ein = talentJson.ein;
      this.archived = talentJson.archived;
      this.active = talentJson.active;
      this.birthDate = talentJson.birthDate;
      this.taxStatus = talentJson.taxStatus;
      this.dropbox = talentJson.dropbox;
      this.google = talentJson.google;
      this.createdAt = talentJson.createdAt;
      this.updatedAt = talentJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Talent_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  userUUID: string;
  positionID: number;
  company: string;
  firstName: string;
  lastName: string;
  code: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  phone: string;
  ssn?: string;
  ein?: string;
  archived: boolean;
  active: boolean;
  birthDate: string;
  taxStatus: string;
  dropbox: string;
  google: string;
  createdAt: string;
  updatedAt: string;
}
