import {
  GridColDef,
  GridValueGetterParams,
  GridValueSetterParams,
} from "@mui/x-data-grid-pro";
import { JobCategory_Entity } from "../../../entities/jobCategory";

export const getBenefitsColumnDef = (
  jobCategories: JobCategory_Entity[]
): GridColDef[] => {
  return [
    {
      field: "jobCategoryID",
      headerName: "Job Category",
      width: 120,
      editable: false,
      type: "string",
      valueFormatter: (p) =>
        jobCategories.find((jc) => jc.id === p.value)?.name,
    },
    {
      field: "pension",
      headerName: "Pension",
      editable: true,
      type: "number",
      valueGetter: getterDivider("pension", 10000),
      valueSetter: setterDivider("pension", 10000),
      valueFormatter: (p) => `${p.value}%`,
    },
    {
      field: "vacation",
      headerName: "Vacation",
      editable: true,
      type: "number",
      valueGetter: getterDivider("vacation", 10000),
      valueSetter: setterDivider("vacation", 10000),
      valueFormatter: (p) => `${p.value}%`,
    },
    {
      field: "flexDivisor",
      headerName: "F Divisor",
      editable: true,
      type: "number",
      valueGetter: getterDivider("flexDivisor", 10000),
      valueSetter: setterDivider("flexDivisor", 10000),
      valueFormatter: (p) => `${p.value}`,
    },
    {
      field: "flexMultiplier",
      headerName: "F Multiplier",
      editable: true,
      type: "number",
      valueGetter: getterDivider("flexMultiplier", 10000),
      valueSetter: setterDivider("flexMultiplier", 10000),
      valueFormatter: (p) => `${p.value}`,
    },
    {
      field: "flexDayHours",
      headerName: "F Day Hours",
      editable: true,
      type: "number",
      valueGetter: getterDivider("flexDayHours", 100),
      valueSetter: setterDivider("flexDayHours", 100),
      valueFormatter: (p) => `${p.value}`,
    },
  ];
};

export const getterDivider =
  (key: string, multipler: number) => (e: GridValueGetterParams) => {
    return e.row[key] / multipler;
  };

export const setterDivider =
  (key: string, multipler: number) => (p: GridValueSetterParams) => {
    return { ...p.row, [key]: Math.round(p.value * multipler) };
  };
