export const RouterConfig = {
  root: "/",
  talents: "talents",
  positions: "positions",
  signin: "signin",
  scales: "scales",
  timers: "timers",
  timer: "timer",
  projects: "projects",
  project: "project",
  benefits: "benefits",
  projectTypes: "projectTypes",
  jobCategories: "jobCategories",
  taskTypes: "taskTypes",
  rates: "rates",
  ledger: "ledger",
  vendors: "Vendors",
};

export const breadcrumbNameMap: { [key: string]: string } = {
  "/": "Home",
  "/talents": "Talents",
  "/positions": "Positions",
  "/signin": "Signin",
  "/scales": "Scales",
  "/timers": "Timers",
  "/projects": "Projects",
  "/project": "Project",
  "/benefits": "Benefits",
  "/projectTypes": "Project Types",
  "/jobCategories": "Job Categories",
  "/taskTypes": "Task Types",
  "/rates": "Rates",
  "/ledger": "Ledger",
  "/vendors": "Vendors",
};
