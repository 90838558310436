import { createReducer } from "@reduxjs/toolkit";
import { JobTask_Entity } from "../../entities/jobTask";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteJobTaskSuccess,
  getJobTaskByIdSuccess,
  getJobTasksSearchMetadataSuccess,
  getJobTasksSuccess,
  setSelectedJobTaskID,
} from "./jobTaskActions";
import { JobTaskState } from "./jobTaskState";

function initState(): JobTaskState {
  const { jobTask: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    jobTasks: {},
    searchMetadata: undefined,
    selectedJobTaskID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const jobTaskReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedJobTaskID, (state, action) => {
    state.selectedJobTaskID = action.payload.jobTaskID;
    return state;
  });
  builder.addCase(getJobTasksSuccess, (state, action) => {
    const jobTasks = action.payload.jobTasks;
    state.jobTasks = arrayToMap<JobTask_Entity>(jobTasks);
    return state;
  });
  builder.addCase(getJobTasksSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.jobTasksSearchMetadata;
    return state;
  });
  builder.addCase(deleteJobTaskSuccess, (state, action) => {
    const jobTaskID = action.payload.id;
    if (state.jobTasks[jobTaskID]) {
      delete state.jobTasks[jobTaskID];
    }
    return state;
  });
  builder.addCase(getJobTaskByIdSuccess, (state, action) => {
    const jobTask = action.payload.jobTask;
    if (jobTask) {
      const jobTasks = { ...state.jobTasks };
      jobTasks[jobTask.id] = jobTask;
      state.jobTasks = jobTasks;
    }
    return state;
  });
});
