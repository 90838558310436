/**
 * Job_Entity type guard.
 *
 * @param {any} jobJson Job object from API
 * @returns {boolean} Return true if type is Job_Entity
 */
function validator(jobJson: unknown): jobJson is Job_Entity {
  if (typeof jobJson === "object" && jobJson != null) {
    Job_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(jobJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Job_Entity {
  static requiredFields = [
    "id",
    "projectID",
    "talentID",
    "hours",
    "orchestrator",
    "librarian",
    "benefitsOnly",
    "subTotal",
    "scaleAmount",
    "supervisionPercent",
    "supervision",
    "vacation",
    "total",
    "daysWorked",
    "pension",
    "flexPlanHours",
    "flexPlan",
    "adjustment",
    "hourlyRate",
    "stipend",
    "stipendDescription",
    "paymentDate",
    "lock",
    "librarianSplit",
    "totalAdjusted",
    "kronosHours",
    "projectName",
    "createdAt",
    "updatedAt",
  ];

  constructor(jobJson: unknown) {
    if (validator(jobJson)) {
      this.id = jobJson.id;
      this.projectID = jobJson.projectID;
      this.talentID = jobJson.talentID;
      this.hours = jobJson.hours;
      this.orchestrator = jobJson.orchestrator;
      this.librarian = jobJson.librarian;
      this.benefitsOnly = jobJson.benefitsOnly;
      this.subTotal = jobJson.subTotal;
      this.scaleAmount = jobJson.scaleAmount;
      this.supervisionPercent = jobJson.supervisionPercent;
      this.supervision = jobJson.supervision;
      this.vacation = jobJson.vacation;
      this.total = jobJson.total;
      this.daysWorked = jobJson.daysWorked;
      this.pension = jobJson.pension;
      this.flexPlanHours = jobJson.flexPlanHours;
      this.flexPlan = jobJson.flexPlan;
      this.adjustment = jobJson.adjustment;
      this.hourlyRate = jobJson.hourlyRate;
      this.wagesEarned = jobJson.wagesEarned;
      this.stipend = jobJson.stipend;
      this.stipendDescription = jobJson.stipendDescription;
      this.paymentDate = jobJson.paymentDate;
      this.lock = jobJson.lock;
      this.librarianSplit = jobJson.librarianSplit;
      this.totalAdjusted = jobJson.totalAdjusted;
      this.kronosHours = jobJson.kronosHours;
      this.subTotalNoLib = jobJson.subTotalNoLib;
      this.projectName = jobJson.projectName;
      this.createdAt = jobJson.createdAt;
      this.updatedAt = jobJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Job_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  projectID: number;
  talentID: number;
  hours: number;
  orchestrator: boolean;
  librarian: boolean;
  benefitsOnly: boolean;
  subTotal: number;
  scaleAmount: number;
  supervisionPercent: number;
  supervision: number;
  vacation: number;
  total: number;
  daysWorked: number;
  pension: number;
  flexPlanHours: number;
  flexPlan: number;
  adjustment: number;
  hourlyRate: number;
  subTotalNoLib: number;
  wagesEarned: number;
  stipend: number;
  stipendDescription: string;
  paymentDate: string;
  lock: boolean;
  librarianSplit: number;
  totalAdjusted: number;
  kronosHours: number;
  projectName: string;
  createdAt: string;
  updatedAt: string;
}
