import { Timer } from "@nerdjs/kronos-kit";
import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

export const selectTalents = (state: AppState) => state.talentState.talents;
const selectShowArchived = (state: AppState) => state.projectState.showArchived;
export const selectTimersForTalent = (state: AppState) =>
  state.talentState.timersPerTalent;
export const selectSelectedTalentID = (state: AppState) =>
  state.talentState.selectedTalentID;
const selectTalentSearchMetadata = (state: AppState) =>
  state.talentState.searchMetadata;

export const showArchivedSelector = createSelector(
  [selectShowArchived],
  (showArchived) => {
    return showArchived;
  }
);

export const selectedTalentIDSelector = createSelector(
  [selectSelectedTalentID],
  (s) => s
);

export const selectedTalentSelector = createSelector(
  [selectTalents, selectSelectedTalentID],
  (talents, talentID) => {
    if (talentID) return talents[talentID];
    return undefined;
  }
);

export const talentSearchMetadataSelector = createSelector(
  [selectTalentSearchMetadata],
  (s) => s
);

export const talentsSelector = createSelector([selectTalents], (talents) => {
  return mapToArray(talents).sort((a, b) =>
    a.lastName.localeCompare(b.lastName)
  );
});

export const orchestratorsSelector = createSelector(
  [selectTalents],
  (talents) => {
    const orchestratorsID = [8, 7];
    return mapToArray(talents)
      .filter((t) => orchestratorsID.indexOf(t.positionID) >= 0)
      .sort((a, b) => a.lastName.localeCompare(b.lastName));
  }
);

export const talentsMapSelector = createSelector([selectTalents], (talents) => {
  return talents;
});

export const talentSelector = (id?: number) => {
  return createSelector([selectTalents], (talents) => {
    if (id) return talents[id];
    return undefined;
  });
};

export const timersForTalentSelector = (talentID?: number) => {
  return createSelector([selectTimersForTalent], (timersForTalent) => {
    if (talentID) return Timer.fromList(timersForTalent[talentID]);
    return [];
  });
};
