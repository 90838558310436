import { Timer_Entity } from "@nerdjs/kronos-kit";
import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Talent, Talent_Entity } from "../../entities/talent";

export const setShowArchived = createAction(
  "setShowArchived",
  function prepare(showArchived: boolean) {
    return {
      payload: {
        showArchived,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setSelectedTalentID = createAction(
  "setSelectedTalentID",
  function prepare(talentID?: number) {
    return {
      payload: {
        talentID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getTalents = createAction(
  "films/talent/getTalents",
  function prepare() {
    const request = new GetAction(`/films/talents`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const talents = Talent.fromList(json);
      dispatch(getTalentsSuccess(talents));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getTalentsSuccess = createAction(
  "films/talent/getTalentSuccess",
  function prepare(talents: Talent[]) {
    return {
      payload: {
        talents,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getTalentsByFilter = createAction(
  "films/talent/getTalentsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/talents`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const talents = Talent.fromList(json);
      dispatch(getTalentsByFilterSuccess(talents));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getTalentsByFilterSuccess = createAction(
  "films/talent/getTalentsByFilterSuccess",
  function prepare(talents: Talent[]) {
    return {
      payload: {
        talents,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getTalentById = createAction(
  "films/talent/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/talents/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const talent = new Talent(json);
      dispatch(getTalentByIdSuccess(talent));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getTalentByIdSuccess = createAction(
  "films/talent/getTalentByIdSuccess",
  function prepare(talent?: Talent_Entity) {
    return {
      payload: {
        talent,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getTalentTimers = createAction(
  "films/talent/getTalentTimers",
  function prepare(id: number, query?: { [key: string]: unknown }) {
    const request = new GetAction(`/films/talents/${id}/timers`, query);

    request.decodeResponse = (dispatch: Dispatch, timers: Timer_Entity[]) => {
      dispatch(getTalentTimersSuccess(id, timers));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getTalentTimersSuccess = createAction(
  "films/talent/getTalentTimersSuccess",
  function prepare(talentID: number, timers?: Timer_Entity[]) {
    return {
      payload: {
        talentID,
        timers,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteTalent = createAction(
  "films/removeTalentById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/films/talents/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteTalentSuccess = createAction(
  "films/talents/deleteTalentSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateTalent = createAction(
  "films/talents/updateTalent",
  function prepare(id: number, body: Talent_Entity) {
    const request = new PutAction(`/films/talents/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateTalentSuccess(true, id));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateTalentSuccess = createAction(
  "films/talents/updateTalentSuccess",
  function prepare(success: boolean, talentID: number) {
    return {
      payload: {
        success,
        talentID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createTalent = createAction(
  "films/talents/createTalent",
  function prepare(body: Talent_Entity) {
    const request = new PostAction(`/films/talents`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const talent = new Talent(json);
      dispatch(createTalentSuccess(talent));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createTalentSuccess = createAction(
  "films/talents/createTalentSuccess",
  function prepare(talent: Talent) {
    return {
      payload: {
        talent,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getTalentsSearchMetadata = createAction(
  "films/getTalentsSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/talents/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getTalentsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getTalentsSearchMetadataSuccess = createAction(
  "films/getTalentsSearchMetadataSuccess",
  function prepare(talentsSearchMetadata: unknown) {
    return {
      payload: {
        talentsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
