import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectProjectTypes = (state: AppState) =>
  state.projectTypeState.projectTypes;

const selectProjectTypeSearchMetadata = (state: AppState) =>
  state.projectTypeState.searchMetadata;

export const projectTypesSelector = createSelector(
  [selectProjectTypes],
  (projectTypes) => {
    return mapToArray(projectTypes);
  }
);

export const projectTypesMapSelector = createSelector(
  [selectProjectTypes],
  (projectTypes) => {
    return projectTypes;
  }
);

export const projectTypeSelector = (id?: number) => {
  return createSelector([selectProjectTypes], (projectTypes) => {
    if (id) return projectTypes[id];
    return undefined;
  });
};

export const projectTypeSearchMetadataSelector = createSelector(
  [selectProjectTypeSearchMetadata],
  (s) => s
);
