import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Budget, Budget_Entity } from "../../entities/budget";

export const setSelectedBudgetID = createAction(
  "setSelectedBudgetID",
  function prepare(budgetID?: number) {
    return {
      payload: {
        budgetID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getBudgets = createAction(
  "films/budget/getBudgets",
  function prepare() {
    const request = new GetAction(`/films/budgets`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const budgets = Budget.fromList(json);
      dispatch(getBudgetsSuccess(budgets));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getBudgetsSuccess = createAction(
  "films/budget/getBudgetSuccess",
  function prepare(budgets: Budget[]) {
    return {
      payload: {
        budgets,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getBudgetsByFilter = createAction(
  "films/budget/getBudgetsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/films/budgets`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const budgets = Budget.fromList(json);
      dispatch(getBudgetsByFilterSuccess(budgets));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getBudgetsByFilterSuccess = createAction(
  "films/budget/getBudgetsByFilterSuccess",
  function prepare(budgets: Budget[]) {
    return {
      payload: {
        budgets,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getBudgetById = createAction(
  "films/budget/getById",
  function prepare(id: number) {
    const request = new GetAction(`/films/budgets/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const budget = new Budget(json);
      dispatch(getBudgetByIdSuccess(budget));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getBudgetByIdSuccess = createAction(
  "films/budget/getBudgetByIdSuccess",
  function prepare(budget?: Budget_Entity) {
    return {
      payload: {
        budget,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteBudget = createAction(
  "films/removeBudgetById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/films/budgets/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteBudgetSuccess = createAction(
  "films/budgets/deleteBudgetSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateBudget = createAction(
  "films/budgets/updateBudget",
  function prepare(id: number, body: Partial<Budget_Entity>) {
    const request = new PutAction(`/films/budgets/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateBudgetSuccess(true, id));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateBudgetSuccess = createAction(
  "films/budgets/updateBudgetSuccess",
  function prepare(success: boolean, budgetID: number) {
    return {
      payload: {
        budgetID,
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createBudget = createAction(
  "films/budgets/createBudget",
  function prepare(body: any) {
    const request = new PostAction(`/films/budgets`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const budget = new Budget(json);
      dispatch(createBudgetSuccess(budget));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createBudgetSuccess = createAction(
  "films/budgets/createBudgetSuccess",
  function prepare(budget: Budget) {
    return {
      payload: {
        budget,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getBudgetsSearchMetadata = createAction(
  "films/getBudgetsSearchMetadata",
  function prepare() {
    const request = new GetAction(`/films/budgets/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getBudgetsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getBudgetsSearchMetadataSuccess = createAction(
  "films/getBudgetsSearchMetadataSuccess",
  function prepare(budgetsSearchMetadata: unknown) {
    return {
      payload: {
        budgetsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
