const ExcelJS = require("exceljs"); //eslint-disable-line
import moment from "moment";
import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Job_Entity } from "../../entities/job";
import { Talent_Entity } from "../../entities/talent";
import { AppConfig } from "../../environement";
import {
  fillWithColor,
  getInitial,
  rectangleBorder,
} from "../../helpers/excelHelpers";
import { getCuesByFilter } from "../../redux/cue/cueActions";
import { cuesByProjectSelector } from "../../redux/cue/cueSelector";
import { getJobsByFilter } from "../../redux/job/jobActions";
import { jobsByProjectSelector } from "../../redux/job/jobSelector";
import {
  projectSelector,
  selectedProjectIDSelector,
} from "../../redux/project/projectSelector";
import { projectTasksByProjectSelector } from "../../redux/projectTask/projectTaskSelector";
import {
  projectTypesMapSelector,
  projectTypesSelector,
} from "../../redux/projectType/projectTypeSelector";
import { scalesMapSelector } from "../../redux/scale/scaleSelector";
import { talentsMapSelector } from "../../redux/talent/talentSelector";
import { taskTypesMapSelector } from "../../redux/taskType/taskTypeSelector";
import { vendorsMapSelector } from "../../redux/vendor/vendorSelector";
import { useAppSelector } from "../hooks";
import { AFMLogoBase64 } from "./afm";
import { downloadExcel } from "./helpers";
import { Indexes } from "./indexes";

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const ExcelContext = createContext({
  invoice: (j: Job_Entity[]) => console.log("invoice"),
  contract: () => console.log("contract"),
  musicPrepSummary: () => console.log("musicPrepSummary"),
});

export default function ExcelExport({
  children,
}: {
  children: React.ReactElement;
}) {
  const [jobs, setJobs] = useState<Job_Entity[]>();
  const [c, setC] = useState(0);
  const dispatch = useDispatch();
  const projectTypes = useAppSelector(projectTypesSelector);
  const projectTypesMap = useAppSelector(projectTypesMapSelector);
  const talents = useAppSelector(talentsMapSelector);
  const vendors = useAppSelector(vendorsMapSelector);
  const projectID = useAppSelector(selectedProjectIDSelector);
  const projectJobs = useAppSelector(jobsByProjectSelector(projectID));
  const taskTypes = useAppSelector(taskTypesMapSelector);
  const projectTasks = useAppSelector(projectTasksByProjectSelector(projectID));
  const scales = useAppSelector(scalesMapSelector);
  const cues = useAppSelector(cuesByProjectSelector(projectID));
  const project = useAppSelector(projectSelector(projectID));
  let processing = false;

  useEffect(() => {
    if (c > 0) {
      dispatch(
        getJobsByFilter({
          filters: JSON.stringify([
            {
              name: "jobs.projectID",
              value: projectID,
              comparison: "eq",
            },
          ]),
        })
      );
      dispatch(
        getCuesByFilter({
          filters: JSON.stringify([
            {
              name: "cues.projectID",
              value: projectID,
              comparison: "eq",
            },
          ]),
        })
      );
    }
  }, [c]);

  useEffect(() => {
    if (projectJobs.length && c) {
      downloadContract();
      setC(0);
    }
  }, [projectJobs, cues, c]);

  useEffect(() => {
    if (
      jobs?.length &&
      project?.id &&
      Object.keys(talents).length &&
      projectTypes.length &&
      !processing
    ) {
      processing = true;
      downloadInvoices();
      setJobs(undefined);
    }
  }, [jobs, projectTypes, talents, project]);

  const invoice = (jobs: Job_Entity[]) => {
    setJobs(jobs);
  };

  const musicPrepSummary = async () => {
    if (!project) return;
    const workbook = createWorkbook();
    const fileName = `${project?.name} (${project?.referenceNumber}) - Music Prep Summary`;
    const worksheet = workbook.addWorksheet("Music Prep Summary", {
      pageSetup: {
        paperSize: undefined,
        fitToPage: true,
        fitToWidth: 1,
        blackAndWhite: true,
        horizontalCentered: true,
        fitToHeight: 2,
      },
    });

    worksheet.headerFooter.oddFooter = "Page &P of &N";
    const indexes = new Indexes(worksheet);

    for (let index = 0; index < 20; index++) {
      indexes.width(15);
      indexes.columnFontFamily("Times");
      if (AppConfig.app.alphaWatermark) {
        indexes.columnColor({ argb: `FFf44336` });
      }
      indexes.fontSize(14);
      indexes.nextColumn();
    }

    // left margin
    indexes.goToColumn(0);
    indexes.width(8);

    // right margin
    indexes.goToColumn(3 + projectTasks[1].cues[0].tasks.length);
    indexes.width(8);

    // Fine Line + Doc Title
    indexes.goToColumn(1);
    indexes.goToRow(1);
    indexes.select(1 + projectTasks[1].cues[0].tasks.length, 0);
    indexes.merge();
    indexes.unselect();
    indexes.text("Fine Line Music Service");
    indexes.centerText();
    indexes.nextRow();
    indexes.select(1 + projectTasks[1].cues[0].tasks.length, 0);
    indexes.merge();
    indexes.unselect();
    indexes.text("MUSIC PREPARATION SUMMARY");
    indexes.centerText();
    indexes.nextRow();
    indexes.select(1 + projectTasks[1].cues[0].tasks.length, 0);
    indexes.merge();
    indexes.unselect();
    indexes.text(projectTypesMap[project.projectTypeID]?.name);
    indexes.centerText();
    indexes.nextRow();

    // date
    indexes.text(moment(project.date).format("MM/DD/YYYY"));

    // projectName
    indexes.nextRow();
    indexes.text(`${project.name}`);
    indexes.bold();

    // invoice #
    indexes.columnRelativeMove(1 + projectTasks[1].cues[0].tasks.length);
    indexes.text(`FiLMS ${project?.invoiceNumber}`);
    indexes.rightAlign();
    indexes.goToColumn(1);

    // Music Prep Summary Column Headers
    indexes.nextRow();
    indexes.height(70);
    indexes.rectangle();
    indexes.text("CUES");
    indexes.bold();

    projectTasks[1].cues[0].tasks.forEach((task) => {
      indexes.nextColumn();
      const scale = scales[task.scaleID];
      const taskType = taskTypes[scale.taskTypeID];
      indexes.rectangle();
      indexes.text(taskType?.name.toUpperCase());
      indexes.rotate();
      indexes.bold();
      indexes.nextRow();
      indexes.rectangle();
      indexes.text(scale.rate / 10000);
      indexes.centerText();
      indexes.previousRow();
    });

    indexes.nextColumn();
    indexes.rectangle();
    indexes.text("AMOUNT");
    indexes.rotate();
    indexes.bold();

    if (projectTasks[2].cues[0].total) {
      // Librarian
      indexes.rowRelativeMove(3);
      indexes.goToColumn(1);
      indexes.text("Librarian");
      indexes.select(1 + projectTasks[1].cues[0].tasks.length, 0);
      indexes.rectangle();
      indexes.unselect();
      indexes.columnRelativeMove(1 + projectTasks[1].cues[0].tasks.length);
      indexes.text(`${projectTasks[2].cues[0].total / 10000}`);
      indexes.rightAlign();
      indexes.rectangle();
    } else {
      indexes.rowRelativeMove(2);
    }

    // Cues
    projectTasks[1].cues
      .filter((c) => c.showInMPS)
      .forEach((cue) => {
        indexes.goToColumn(1);
        indexes.nextRow();
        indexes.text(cue?.name);
        indexes.rectangle();

        cue.tasks.forEach((task) => {
          indexes.nextColumn();
          indexes.rectangle();
          indexes.text(task.quantity > 0 ? task.quantity / 100 : "");
          indexes.centerText();
        });

        // Cue total
        indexes.nextColumn();
        indexes.rectangle();
        indexes.rightAlign();
        indexes.text((cue.total / 10000).toFixed(2));
      });

    // SUB TOTAL (No Lib)
    indexes.nextRow();
    indexes.goToColumn(1);
    indexes.columnRelativeMove(projectTasks[1].cues[0].tasks.length - 2);
    indexes.select(2, 0);
    indexes.merge();
    indexes.unselect();
    indexes.text("SUB TOTAL (No Lib)");
    indexes.columnRelativeMove(3);
    indexes.text((project?.musicPrep.subTotalNoLib ?? 0) / 10000);
    indexes.dollar();

    // SubTotal
    indexes.nextRow();
    indexes.columnRelativeMove(-3);
    indexes.select(2, 0);
    indexes.merge();
    indexes.unselect();
    indexes.text("SUB TOTAL");
    indexes.columnRelativeMove(3);
    indexes.text((project?.musicPrep.subTotalWithLib ?? 0) / 10000);
    indexes.dollar();

    // Supervision
    indexes.nextRow();
    indexes.columnRelativeMove(-3);
    indexes.select(2, 0);
    indexes.merge();
    indexes.unselect();
    indexes.text(
      `SUPERVISION 25% of ${project?.musicPrepSubTotalNoLibDollar()}`
    );
    indexes.columnRelativeMove(3);
    indexes.text((project?.musicPrep.supervision ?? 0) / 10000);
    indexes.dollar();

    // Vacation
    indexes.nextRow();
    indexes.columnRelativeMove(-3);
    indexes.select(2, 0);
    indexes.merge();
    indexes.unselect();
    indexes.text("VACATION");
    indexes.columnRelativeMove(3);
    indexes.text((project?.musicPrep.vacation ?? 0) / 10000);
    indexes.dollar();

    // Total
    indexes.nextRow();
    indexes.columnRelativeMove(-3);
    indexes.select(2, 0);
    indexes.merge();
    indexes.unselect();
    indexes.select(3, 0);
    indexes.rectangle();
    indexes.unselect();
    indexes.text("TOTAL");
    indexes.bold();
    indexes.columnRelativeMove(3);
    indexes.text((project?.musicPrep.subTotalNoBen ?? 0) / 10000);
    indexes.bold();
    indexes.dollar();

    indexes.rowRelativeMove(-1);
    indexes.goToColumn(1);
    indexes.select(6, 0);
    indexes.fill("D4D4D4");
    indexes.unselect();
    indexes.select(6, 1);
    indexes.rectangle();
    indexes.text("Flex Plan Hours");
    indexes.centerText();
    indexes.bold();
    indexes.nextRow();
    indexes.text(project.musicPrep.flexPlanHours / 100);
    indexes.previousRow();
    indexes.columnRelativeMove(2);
    indexes.text("Flex Plan $");
    indexes.centerText();
    indexes.bold();
    indexes.nextRow();
    indexes.text(project.musicPrep.welfare / 10000);
    indexes.dollar();
    indexes.previousRow();
    indexes.columnRelativeMove(2);
    indexes.text("AFM Local H&W");
    indexes.centerText();
    indexes.bold();
    indexes.columnRelativeMove(2);
    indexes.text("Pension $");
    indexes.centerText();
    indexes.nextRow();
    indexes.text(project.musicPrep.pension / 10000);
    indexes.dollar();
    indexes.previousRow();
    indexes.bold();
    indexes.columnRelativeMove(2);

    downloadExcel(workbook, fileName);
  };

  const downloadInvoices = async () => {
    if (!jobs || jobs.length === 0) return;
    const workbook = createWorkbook();

    jobs.forEach((j) => {
      const t = talents[j?.talentID];
      addJobWorksheet(workbook, j, t);
    });

    let fileName = `${project?.name} (${project?.referenceNumber}) - Invoices`;
    if (jobs.length === 1) {
      const t = talents[jobs[0]?.talentID];
      fileName = `${t?.firstName} ${t?.lastName} - ${project?.name} (${project?.referenceNumber})`;
    }

    downloadExcel(workbook, fileName);
  };

  const createWorkbook = () => {
    const workbook = new ExcelJS.Workbook();
    return workbook;
  };

  const addJobWorksheet = async (
    workbook: any,
    j: Job_Entity,
    t: Talent_Entity
  ) => {
    if (!j) return;
    // Page settings
    const worksheet = workbook.addWorksheet(
      getInitial(`${t?.firstName} ${t?.lastName}`),
      {
        pageSetup: { paperSize: undefined, orientation: "portrait" },
      }
    );
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };
    worksheet.pageSetup.printArea = "A1:K61";

    // Invoice Title
    worksheet.mergeCells("B2:J2");
    worksheet.getCell("B2").value = "COPYIST, LIBRARIANS, and PROOFREADERS";
    worksheet.getCell("B2").font = {
      size: 24,
      bold: true,
    };
    worksheet.getCell("B2").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // AFM infos
    worksheet.mergeCells("B3:J3");
    worksheet.getCell("B3").value = "American Federation of Musicians AFL-CIO";
    worksheet.getCell("B3").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.mergeCells("B4:J4");
    worksheet.getCell("B4").value =
      "3220 WINONA AVE • BURBANK, CALIFORNIA 91504 • (323) 993-3170";
    worksheet.getCell("B4").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    //Date
    worksheet.getCell("B6").value = "Date";
    worksheet.getCell("B6").font = {
      bold: true,
    };
    worksheet.getCell("B7").value = moment().format("MM/DD/YYYY");

    //CONTRACT AND INVOICE
    worksheet.mergeCells("H6:J6");
    worksheet.getCell("J6").value = "CONTRACT AND INVOICE";
    worksheet.getCell("J6").font = {
      bold: true,
    };
    worksheet.getCell("J6").alignment = {
      vertical: "middle",
      horizontal: "right",
    };
    worksheet.getCell("J7").value = moment().format("MM/DD/2022");

    //Talent name
    worksheet.mergeCells("B10:J10");
    worksheet.getCell("B10").value =
      `${t?.firstName} ${t?.lastName}`.toUpperCase();
    worksheet.getCell("B10").font = {
      size: 24,
    };
    worksheet.getCell("B10").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    //Member
    worksheet.getCell("B11").value = "MEMBER";
    worksheet.getCell("B11").font = {
      bold: true,
    };

    //Local
    worksheet.getCell("J11").value = "Local 47";
    worksheet.getCell("J11").font = {
      bold: true,
    };

    //Line between Member & Local
    for (let index = 1; index <= 9; index++) {
      worksheet.getCell(`${alphabet[index]}11`).border = {
        bottom: { style: "thin" },
      };
    }

    // Employer / Purchaser
    worksheet.getCell("B13").value = "Employer / Purchaser";
    worksheet.getCell("B13").font = {
      bold: true,
    };
    worksheet.getCell("B14").value = "ENTERTAINMENT PARTNERS";
    worksheet.getCell("B15").value = "2950 N HOLLYWOOD WAY";
    worksheet.getCell("B16").value = "BURBANK, CA 91505";
    worksheet.getCell("B17").value = "(818) 955-6065";

    // Pay
    worksheet.getCell("G13").value = "Pay";
    worksheet.getCell("G13").font = {
      bold: true,
    };
    worksheet.getCell("H13").value = t?.company.toUpperCase();
    worksheet.getCell("H14").value = t?.phone;
    worksheet.getCell("H15").value = t?.address;
    worksheet.getCell("H16").value = `${t?.city}, ${t?.state} ${t?.zipcode}`;
    worksheet.getCell("H17").value = t?.email;

    // Project Info
    rectangleBorder("B21:J24", worksheet);
    rectangleBorder("B21:J21", worksheet);
    fillWithColor("B21:J21", "f5f5f5", worksheet);
    worksheet.getCell("B21").value = "Category";
    worksheet.getCell("B21").font = {
      bold: true,
    };
    worksheet.getCell("B22").value = projectTypes?.find(
      (pt) => pt.id === project?.projectTypeID
    )?.name;
    worksheet.getCell("E21").value = "Production";
    worksheet.getCell("E21").font = {
      bold: true,
    };
    worksheet.getCell("E22").value = project?.name;
    worksheet.getCell("G21").value = "Prep Code";
    worksheet.getCell("G21").font = {
      bold: true,
    };
    worksheet.getCell("I21").value = "Billing #";
    worksheet.getCell("I21").font = {
      bold: true,
    };
    worksheet.getCell("J21").value = "New Use";
    worksheet.getCell("J21").font = {
      bold: true,
    };
    rectangleBorder("B23:J23", worksheet);
    fillWithColor("B23:J23", "f5f5f5", worksheet);

    worksheet.getCell("B23").value = "Leader ";
    worksheet.getCell("B23").font = {
      bold: true,
    };
    worksheet.getCell("E23").value = "Surpervising Copyist ";
    worksheet.getCell("E23").font = {
      bold: true,
    };

    // Accounting values
    rectangleBorder("B54:F60", worksheet);
    rectangleBorder("H54:J60", worksheet);
    rectangleBorder("B59:F59", worksheet);
    fillWithColor("B59:F59", "f5f5f5", worksheet);
    worksheet.getCell("E54").value = "Earnings";
    worksheet.getCell("F54").value = j.total / 10000;
    worksheet.getCell("F54").numFmt = '"$"#,##0.00;[Red]-"$"#,##0.00';

    worksheet.getCell("E55").value = "Less Overscale";
    worksheet.getCell("F55").value = (j.total - j.subTotal) / 10000;
    worksheet.getCell("F55").numFmt = '"$"#,##0.00;[Red]-"$"#,##0.00';

    worksheet.getCell("E56").value = "Scale Amount ";
    worksheet.getCell("F56").value = j.subTotal / 10000;
    worksheet.getCell("F56").numFmt = '"$"#,##0.00;[Red]-"$"#,##0.00';

    worksheet.getCell("B59").value = "Flex Plan Hours";
    worksheet.getCell("B59").font = {
      bold: true,
      size: 9,
    };
    worksheet.getCell("B60").value = j.flexPlanHours / 100;
    worksheet.getCell("C59").value = "Flex Plan";
    worksheet.getCell("C59").font = {
      bold: true,
      size: 9,
    };
    worksheet.getCell("C60").value = j.flexPlan / 10000;
    worksheet.getCell("C60").numFmt = '"$"#,##0.00;[Red]-"$"#,##0.00';
    worksheet.getCell("D59").value = "Local HW";
    worksheet.getCell("D59").font = {
      bold: true,
      size: 9,
    };
    worksheet.getCell("E59").value = "Pension";
    worksheet.getCell("E59").font = {
      bold: true,
      size: 9,
    };
    worksheet.getCell("E60").value = j.pension / 10000;
    worksheet.getCell("E60").numFmt = '"$"#,##0.00;[Red]-"$"#,##0.00';
    worksheet.getCell("F59").value = "Days Worked";
    worksheet.getCell("F59").font = {
      bold: true,
      size: 9,
    };
    worksheet.getCell("F60").value = j.daysWorked;
    worksheet.getCell("H54").value = "Sub Total ";
    worksheet.getCell("J54").value = j.subTotal / 10000;
    worksheet.getCell("J54").numFmt = '"$"#,##0.00;[Red]-"$"#,##0.00';
    worksheet.getCell("H56").value = "Vacation";
    worksheet.getCell("J56").value = j.vacation / 10000;
    worksheet.getCell("J56").numFmt = '"$"#,##0.00;[Red]-"$"#,##0.00';
    worksheet.getCell("H57").value = "Wages Earned";
    worksheet.getCell("J57").value = j.total / 10000;
    worksheet.getCell("J57").numFmt = '"$"#,##0.00;[Red]-"$"#,##0.00';
    worksheet.getCell("H58").value = "Supplies";

    worksheet.getCell("H60").value = "TOTAL ";
    worksheet.getCell("H60").font = {
      bold: true,
      size: 14,
    };
    worksheet.getCell("J60").value = j.total / 10000;
    worksheet.getCell("J60").numFmt = '"$"#,##0.00;[Red]-"$"#,##0.00';
    worksheet.getCell("J60").font = {
      bold: true,
      size: 14,
    };
  };

  const contract = () => {
    setC((e) => e + 1);
  };

  const downloadContract = () => {
    const workbook = createWorkbook();
    const fileName = `${project?.name} (${project?.referenceNumber}) - Contract`;
    let _jobs = [...projectJobs].filter((j) => j.total > 0 || j.orchestrator);
    const worksheet = workbook.addWorksheet("Contract", {
      pageSetup: {
        paperSize: undefined,
        fitToPage: true,
        fitToWidth: 1,
        blackAndWhite: true,
        horizontalCentered: true,
        fitToHeight: 2,
      },
    });

    worksheet.headerFooter.oddFooter = "Page &P of &N";
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0,
      bottom: 0.75,
      header: 0,
      footer: 0.3,
    };
    const indexes = new Indexes(worksheet);
    const ratios = [
      6.5, 31.3, 8.33, 41, 7.83, 20.5, 4, 5, 12.33, 8.83, 8.5, 7.83,
    ];
    const multipler = 6.5 / 5.67;
    const signatory = project && vendors[project.signatoryID];
    const payroll = project && vendors[project.payrollID];

    ratios.forEach((r) => {
      indexes.width(r * multipler);
      indexes.columnFontFamily("Bookman Old Style");
      indexes.columnFontSize(14);
      if (AppConfig.app.alphaWatermark) {
        indexes.columnColor({ argb: `FFf44336` });
      }
      indexes.nextColumn();
    });
    indexes.goToColumn(0);

    // AFM Image
    const afmImage = workbook.addImage({
      base64: AFMLogoBase64,
      extension: "jpeg",
    });

    worksheet.addImage(afmImage, {
      tl: { col: 1, row: 1 },
      ext: { width: 160, height: 160 },
    });

    // Header
    indexes.goToColumn(2);
    indexes.goToRow(3);
    indexes.select(6, 0);
    indexes.merge();
    indexes.unselect();
    indexes.text("Personal Service Contract");
    indexes.fontSize(24);
    indexes.centerText();

    indexes.nextRow();
    indexes.select(6, 0);
    indexes.merge();
    indexes.unselect();
    indexes.text("American Federation of Musicians");
    indexes.fontSize(24);
    indexes.bold();
    indexes.centerText();

    indexes.columnRelativeMove(7);
    indexes.select(2, 0);
    indexes.merge();
    indexes.unselect();
    indexes.text(`FLMS${project?.invoiceNumber}`);
    indexes.rightAlign();
    indexes.bold();
    indexes.underline();

    indexes.rowRelativeMove(2);
    indexes.select(2, 0);
    indexes.merge();
    indexes.unselect();
    indexes.text(project?.local ?? "");
    indexes.rightAlign();
    indexes.rowRelativeMove(-2);

    indexes.goToColumn(2);

    indexes.nextRow();
    indexes.select(6, 0);
    indexes.merge();
    indexes.unselect();
    indexes.text("of the United States and Canada");
    indexes.centerText();

    indexes.nextRow();
    indexes.select(6, 0);
    indexes.merge();
    indexes.unselect();
    indexes.text(
      "For all Motion Picture Films, Television Films, Industrial, Documentary, Newsreel and Miscellaneous"
    );
    indexes.centerText();

    indexes.rowRelativeMove(2);
    indexes.goToColumn(0);
    indexes.richText([
      {
        text: "THIS CONTRACT for the personal services of musicians, recorded on this date: ",
      },
      {
        font: { ...indexes.getCell().font, bold: true, underline: true },
        text: moment(project?.date).format("MM/DD/YYYY"),
      },
    ]);

    indexes.rowRelativeMove(2);
    indexes.richText([
      {
        text: "Between the undersigned producer (hereinafter called the Producer) and ",
      },
      {
        font: { ...indexes.getCell().font, bold: true, underline: true },
        text: `${_jobs.length} musician${_jobs.length > 1 ? "s" : ""}`,
      },
    ]);
    indexes.nextRow();
    indexes.text(
      "(hereinafter called Employees), represented by the undersigned representative (leader, composer, or orchestra manager)."
    );

    indexes.rowRelativeMove(2);
    indexes.text(
      "WITNESSETH, That the Producer employs the personal services of the Employees, as musicians severally and the Employees severally,"
    );
    indexes.nextRow();
    indexes.text(
      "through their representative, agree to render collectively to the Producer services as musicians in the orchestra under the leadership"
    );
    indexes.nextRow();
    indexes.richText([
      {
        text: "of ",
      },
      {
        font: { ...indexes.getCell().font, bold: true, underline: true },
        text: project?.leader ?? "",
      },
    ]);

    indexes.rowRelativeMove(2);
    indexes.richText([
      {
        text: "Name and Address of Place of Engagement: ",
      },
      {
        font: { ...indexes.getCell().font, bold: true, underline: true },
        text: project?.placeOfEngagement ?? "",
      },
    ]);

    indexes.goToColumn(3);
    indexes.rowRelativeMove(5);
    indexes.text(project?.name ?? "");
    indexes.bold();
    indexes.underline();
    indexes.fontSize(18);

    indexes.rowRelativeMove(2);
    indexes.columnRelativeMove(-2);
    indexes.text("Rehearsal");
    indexes.rightAlign();
    indexes.nextColumn();
    indexes.text("☐");
    indexes.rightAlign();
    indexes.previousColumn();

    indexes.nextRow();
    indexes.text("Recording");
    indexes.rightAlign();
    indexes.nextColumn();
    indexes.text("✔️");
    indexes.rightAlign();
    indexes.previousColumn();

    indexes.nextRow();
    indexes.text("Sideline");
    indexes.rightAlign();
    indexes.nextColumn();
    indexes.text("☐");
    indexes.rightAlign();
    indexes.previousColumn();

    // Outlined box
    indexes.goToColumn(7);
    indexes.goToRow(16);
    indexes.select(4, 11);
    indexes.rectangle();
    indexes.unselect();
    indexes.nextRow();

    indexes.text("• Motion Picture (Theatrical)");
    indexes.columnRelativeMove(4);
    indexes.text(project?.projectTypeID === 1 ? "✔️" : "☐");
    indexes.rightAlign();
    indexes.columnRelativeMove(-4);
    indexes.nextRow();
    indexes.text("• TV Film");
    indexes.columnRelativeMove(4);
    indexes.text(project?.projectTypeID === 2 ? "✔️" : "☐");
    indexes.rightAlign();
    indexes.columnRelativeMove(-4);
    indexes.nextRow();
    indexes.text("• Documentary & Industry Film");
    indexes.columnRelativeMove(4);
    indexes.text("☐");
    indexes.rightAlign();
    indexes.columnRelativeMove(-4);
    indexes.nextRow();
    indexes.text("(non-theatrical, Non TVFilm)");
    indexes.columnRelativeMove(4);
    indexes.text("☐");
    indexes.rightAlign();
    indexes.columnRelativeMove(-4);
    indexes.nextRow();
    if (project?.projectTypeID !== 1 && project?.projectTypeID !== 2) {
      indexes.text(`• ${projectTypesMap[project!.projectTypeID].name}`);
      indexes.columnRelativeMove(4);
      indexes.text("✔️");
    } else {
      indexes.text("• ");
      indexes.columnRelativeMove(4);
      indexes.text("☐");
    }
    indexes.rightAlign();
    indexes.columnRelativeMove(-4);
    indexes.nextRow();
    indexes.text("MUSIC SOURCE");
    indexes.nextRow();
    indexes.text("• Original Session ");
    indexes.columnRelativeMove(4);
    indexes.text("✔️");
    indexes.rightAlign();
    indexes.columnRelativeMove(-4);
    indexes.nextRow();
    indexes.text("• New Use ");
    indexes.columnRelativeMove(4);
    indexes.text("☐");
    indexes.rightAlign();
    indexes.columnRelativeMove(-4);
    indexes.nextRow();
    indexes.text("• Clip Use ");
    indexes.columnRelativeMove(4);
    indexes.text("☐");
    indexes.rightAlign();
    indexes.columnRelativeMove(-4);
    indexes.nextRow();
    indexes.text("• Tracking ");
    indexes.columnRelativeMove(4);
    indexes.text("☐");
    indexes.rightAlign();
    indexes.columnRelativeMove(-4);

    indexes.goToColumn(0);
    indexes.goToRow(29);
    indexes.text(
      "Conditions of Employment shall be in accordance with the provision contained in the Scale Wages, Hours of Employment and Working "
    );
    indexes.nextRow();
    indexes.text(
      "Conditions in the basic agreement executed between the A.F. of M. and the Producer."
    );

    indexes.rowRelativeMove(2);
    indexes.text("WAGE AGREED UPON");
    indexes.columnRelativeMove(4);
    indexes.text("UNION SCALES");
    indexes.bold();
    indexes.underline();
    indexes.columnRelativeMove(-4);

    indexes.rowRelativeMove(2);
    indexes.text("To be Paid");
    indexes.columnRelativeMove(4);
    indexes.text("WITHIN FIFTEEN WORKING DAYS ");
    indexes.bold();
    indexes.underline();
    indexes.columnRelativeMove(-4);

    indexes.rowRelativeMove(2);
    indexes.text("EMPLOYER OF RECORD (e.g. Payroll Services)");
    indexes.columnRelativeMove(4);
    indexes.text(payroll?.name ?? "");
    indexes.bold();
    indexes.underline();
    indexes.nextRow();
    indexes.text(payroll?.address ?? "");
    indexes.nextRow();
    indexes.text(
      payroll ? `${payroll.city}, ${payroll.state} ${payroll.zipcode}` : ""
    );
    indexes.columnRelativeMove(-4);

    indexes.rowRelativeMove(2);
    indexes.text("SIGNATORY OF RECORD");
    indexes.bold();
    indexes.nextRow();
    indexes.text("ADDRESS");
    indexes.nextRow();
    indexes.text("CITY, STATE");
    indexes.rowRelativeMove(-2);
    indexes.columnRelativeMove(2);
    indexes.text(signatory?.name ?? "");
    indexes.bold();
    indexes.underline();
    indexes.nextRow();
    indexes.text(signatory?.address ?? "");
    indexes.nextRow();
    indexes.text(
      signatory
        ? `${signatory?.city ?? ""} ${signatory?.state ?? ""} ${
            signatory?.zipcode ?? ""
          } `
        : ""
    );
    indexes.rowRelativeMove(-2);
    indexes.goToColumn(7);
    indexes.text("MEMO");
    indexes.bold();
    indexes.nextRow();
    indexes.text(project?.memo ?? project?.name ?? "");

    indexes.goToColumn(0);
    indexes.rowRelativeMove(3);

    drawLineForMusicianLineItem(indexes, true);
    fillWithHeader(indexes);

    _jobs = _jobs.sort((a, b) => {
      const aTalent: Talent_Entity = talents[a.talentID];
      const bTalent: Talent_Entity = talents[b.talentID];
      if (a.orchestrator && b.orchestrator) {
        return aTalent.lastName.localeCompare(bTalent.lastName);
      } else if (a.orchestrator) {
        return -1;
      } else if (b.orchestrator) {
        return 1;
      }

      return aTalent.lastName.localeCompare(bTalent.lastName);
    });

    indexes.rowRelativeMove(2);
    _jobs?.forEach((j, i) => {
      drawLineForMusicianLineItem(indexes, false);
      fillLineForMusicianLineItem(indexes, i, j);
      indexes.rowRelativeMove(2);
    });

    const offset = _jobs.length * 2 + 2; // 2 rows per musician + 2rows for header

    // next lines are font 10
    for (let index = 46 + offset; index < 300 + offset; index++) {
      worksheet.getRow(index).font = {
        ...worksheet.getRow(index).font,
        size: 10,
        name: "Bookman Old Style",
        color: AppConfig.app.alphaWatermark ? { argb: `FFf44336` } : undefined,
      };
    }

    indexes.rowRelativeMove(2);
    indexes.nextColumn();

    indexes.text("PLEASE PAY TAX EXEMPT:");
    indexes.nextRow();
    indexes.rectangle();
    indexes.text("Fine Line Music Service, LLC");
    indexes.bold();
    indexes.nextColumn();
    indexes.rectangle();
    indexes.bold();
    indexes.dollar();
    indexes.nextColumn();
    indexes.rectangle();
    indexes.bold();
    indexes.text("Supplies / Messengers");
    indexes.nextColumn();
    indexes.dollar();
    indexes.text(project ? project.totalExpenses / 10000 : 0);
    indexes.goToColumn(1);
    indexes.nextRow();
    indexes.text(
      "==================================================================================="
    );
    indexes.nextRow();
    indexes.text(
      "All Arrangers, Orchestrators, Copyists and Librarians must be on this report form."
    );
    indexes.nextRow();
    indexes.text("TOTAL WAGES");
    indexes.rightAlign();
    indexes.columnRelativeMove(2);
    indexes.text(project ? project.totalWages / 10000 : 0);
    indexes.dollar();
    indexes.underline();
    indexes.bold();
    indexes.rightAlign();
    indexes.nextColumn();
    indexes.text("Make checks payable as listed above");
    indexes.columnRelativeMove(-3);

    indexes.nextRow();
    indexes.text("HEALTH and WELFARE");
    indexes.rightAlign();
    indexes.columnRelativeMove(2);
    indexes.text(project ? project.totalHW / 10000 : 0);
    indexes.dollar();
    indexes.underline();
    indexes.bold();
    indexes.rightAlign();
    indexes.nextColumn();
    indexes.richText([
      {
        text: "Make check payable to ",
      },
      {
        text: project?.hwCompany ?? "Entertainment Industry Flex Plan",
        font: { ...indexes.getCell().font, bold: true },
      },
    ]);
    indexes.columnRelativeMove(-3);

    indexes.nextRow();
    indexes.text("TOTAL PENSION CONTRIBUTIONS");
    indexes.rightAlign();
    indexes.columnRelativeMove(2);
    indexes.text(project ? project.totalPension / 10000 : 0);
    indexes.dollar();
    indexes.underline();
    indexes.bold();
    indexes.rightAlign();
    indexes.nextColumn();
    indexes.richText([
      {
        text: "Make check payable to ",
      },
      {
        text: "AFM & EP FUND ATT: Transmittals PO Box 2673 New York, NY 10117-0262",
        font: { ...indexes.getCell().font, bold: true },
      },
    ]);
    indexes.columnRelativeMove(-3);

    indexes.nextRow();
    indexes.text("REIMBURSABLE EXPENSES");
    indexes.rightAlign();
    indexes.columnRelativeMove(2);
    indexes.text(project ? project.totalExpenses / 10000 : 0);
    indexes.dollar();
    indexes.underline();
    indexes.bold();
    indexes.rightAlign();
    indexes.nextColumn();
    indexes.richText([
      {
        text: "Make check payable to ",
      },
      {
        text: "Fine Line Music Service, LLC",
        font: { ...indexes.getCell().font, bold: true },
      },
    ]);
    indexes.columnRelativeMove(-3);

    const jobsWithStipend = projectJobs?.filter((j) => j.stipend > 0);

    jobsWithStipend.forEach((j) => {
      const talent = talents[j.talentID];
      if (talent) {
        indexes.nextRow();
        indexes.text("STIPEND");
        indexes.rightAlign();
        indexes.columnRelativeMove(2);
        indexes.text(j.stipend / 10000);
        indexes.dollar();
        indexes.underline();
        indexes.bold();
        indexes.rightAlign();
        indexes.nextColumn();
        indexes.richText([
          {
            text: "Make check payable to ",
          },
          {
            text: talent.company || `${talent.firstName} ${talent.lastName}`,
            font: { ...indexes.getCell().font, bold: true },
          },
        ]);
        indexes.goToColumn(1);
      }
    });

    indexes.rowRelativeMove(2);
    indexes.text("FOR FUND USE ONLY:");
    indexes.nextRow();
    indexes.text("Date pay't rec'd__________________________");
    indexes.columnRelativeMove(2);
    indexes.text("Amt Paid____________________________");
    indexes.nextColumn();
    indexes.text("Date_______________");

    indexes.goToColumn(1);
    indexes.nextRow();
    indexes.text(
      "Employer / Purchaser_____________________________________________________________________"
    );
    indexes.columnRelativeMove(3);
    indexes.text("Date_______________");

    indexes.goToColumn(1);
    indexes.nextRow();
    indexes.text(
      "Invoice Submitted by:______________________________________________________________________"
    );
    indexes.columnRelativeMove(3);
    indexes.text("Date_______________");

    indexes.goToColumn(2);
    indexes.rowRelativeMove(2);
    indexes.text("Cue No.");
    indexes.bold();
    indexes.nextColumn();
    indexes.text("Cue Subtitle");
    indexes.bold();
    indexes.previousColumn();

    cues
      .filter((c) => c.showInContract)
      .forEach((cue) => {
        indexes.nextRow();
        indexes.text(cue.code);
        indexes.rightAlign();
        indexes.nextColumn();
        indexes.text(cue.title);
        indexes.previousColumn();
      });

    worksheet.pageSetup.printArea = indexes.printArea();

    downloadExcel(workbook, fileName);
  };

  const fillLineForMusicianLineItem = (
    indexes: Indexes,
    i: number,
    job: Job_Entity
  ) => {
    const talent = talents[job.talentID];
    const initialColumn = indexes.getColumn();
    const initialRow = indexes.getRow();

    indexes.text(job.daysWorked ?? "0");
    indexes.fontSize(10);
    indexes.leftAlign();
    indexes.nextRow();
    indexes.text(`${i + 1})`);
    indexes.fontSize(10);
    indexes.bold();
    indexes.previousRow();

    indexes.nextColumn();
    if (talent.company) {
      indexes.text(`${talent.company}`.toUpperCase());
      indexes.fontSize(10);
      indexes.nextRow();
      indexes.text(
        `(FSO ${talent.firstName} ${talent.lastName})`.toUpperCase()
      );
      indexes.fontSize(10);
      indexes.previousRow();
    } else {
      indexes.text(`${talent.firstName} ${talent.lastName}`.toUpperCase());
      indexes.fontSize(10);
    }

    indexes.nextColumn();
    indexes.text(job.orchestrator ? "(Orch)" : "(Prep)");
    indexes.fontSize(10);
    indexes.centerText();

    indexes.nextColumn();
    indexes.text(talent.address ?? "");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.nextRow();
    indexes.text(
      talent ? `${talent.city}, ${talent.state} ${talent.zipcode}` : ""
    );
    indexes.fontSize(10);
    indexes.centerText();
    indexes.previousRow();

    indexes.nextColumn();
    indexes.nextRow();
    indexes.text(47);
    indexes.fontSize(10);
    indexes.centerText();
    indexes.previousRow();

    indexes.nextColumn();
    if (talent.company) {
      indexes.text(talent.ein ?? "");
      indexes.fontSize(10);
      indexes.centerText();
      indexes.nextRow();
      indexes.text(talent.ssn ?? "");
      indexes.fontSize(10);
      indexes.centerText();
      indexes.previousRow();
    } else {
      indexes.text(talent.taxStatus);
      indexes.fontSize(10);
      indexes.centerText();
      indexes.nextRow();
      indexes.text(talent.ssn ?? "");
      indexes.fontSize(10);
      indexes.centerText();
      indexes.previousRow();
    }

    indexes.columnRelativeMove(3);
    if (job.benefitsOnly) {
      indexes.text("BENEFITS");
      indexes.fontSize(10);
      indexes.centerText();
      indexes.nextRow();
      indexes.text("ONLY");
      indexes.fontSize(10);
      indexes.centerText();
      indexes.previousRow();
    } else {
      if (job.orchestrator) {
        indexes.text(job.scaleAmount / 10000);
      } else {
        indexes.text(job.subTotal / 10000);
      }
      indexes.fontSize(10);
      indexes.rightAlign();
      indexes.dollar();
      indexes.nextRow();
      indexes.text(job.total / 10000);
      indexes.fontSize(10);
      indexes.rightAlign();
      indexes.dollar();
      indexes.previousRow();
    }

    indexes.nextColumn();
    if (job.stipend) {
      indexes.text(job.stipendDescription);
      indexes.fontSize(10);
      indexes.nextRow();
      indexes.text(job.stipend / 10000);
      indexes.fontSize(10);
      indexes.dollar();
      indexes.rightAlign();
      indexes.previousRow();
    }

    indexes.nextColumn();
    indexes.nextRow();
    indexes.text(job.pension / 10000);
    indexes.fontSize(10);
    indexes.rightAlign();
    indexes.dollar();
    indexes.previousRow();

    indexes.nextColumn();
    indexes.text(job.flexPlanHours / 100);
    indexes.fontSize(10);
    indexes.rightAlign();
    indexes.nextRow();
    indexes.text(job.flexPlan / 10000);
    indexes.fontSize(10);
    indexes.rightAlign();
    indexes.dollar();
    indexes.previousRow();

    indexes.goToColumn(alphabet.indexOf(initialColumn));
    indexes.goToRow(initialRow);
  };

  const fillWithHeader = (indexes: Indexes) => {
    const initialColumn = indexes.getColumn();
    const initialRow = indexes.getRow();

    indexes.text("Days");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.nextRow();
    indexes.text("Worked");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.previousRow();

    indexes.nextColumn();
    indexes.text("EMPLOYEE'S NAME");
    indexes.fontSize(10);
    indexes.centerText();

    indexes.nextColumn();
    indexes.text("(Orch)");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.nextRow();
    indexes.text("(Prep)");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.previousRow();

    indexes.nextColumn();
    indexes.text("HOME ADDRESS");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.nextRow();
    indexes.text("(STREET, CITY AND STATE)");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.previousRow();

    indexes.nextColumn();
    indexes.text("Local");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.nextRow();
    indexes.text("Union No.");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.previousRow();

    indexes.nextColumn();
    indexes.text("SS");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.nextRow();
    indexes.text("NUMBER");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.previousRow();

    indexes.nextColumn();
    indexes.text("Hrs");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.nextRow();
    indexes.text("Guar");
    indexes.fontSize(9);
    indexes.centerText();
    indexes.previousRow();

    indexes.nextColumn();
    indexes.text("Hrs");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.nextRow();
    indexes.text("Wkd");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.previousRow();

    indexes.nextColumn();
    indexes.text("UNION SCALE");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.nextRow();
    indexes.text("TOTAL WAGES");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.previousRow();

    indexes.nextColumn();
    indexes.text("");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.nextRow();
    indexes.text("CARTAGE");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.previousRow();

    indexes.nextColumn();
    indexes.text("PENSION");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.nextRow();
    indexes.text("Contribution");
    indexes.fontSize(8);
    indexes.centerText();
    indexes.previousRow();

    indexes.nextColumn();
    indexes.text("H&W Hrs");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.nextRow();
    indexes.text("Flex $");
    indexes.fontSize(10);
    indexes.centerText();
    indexes.previousRow();

    indexes.goToColumn(alphabet.indexOf(initialColumn));
    indexes.goToRow(initialRow);
  };

  const drawLineForMusicianLineItem = (indexes: Indexes, header: boolean) => {
    const initialColumn = indexes.getColumn();
    const initialRow = indexes.getRow();

    indexes.select(0, 1);
    indexes.rectangle();
    indexes.unselect();

    indexes.nextColumn();
    indexes.select(0, 1);
    indexes.rectangle();
    indexes.unselect();

    indexes.nextColumn();
    indexes.select(0, 1);
    indexes.rectangle();
    indexes.unselect();

    indexes.nextColumn();
    indexes.select(0, 1);
    indexes.rectangle();
    indexes.unselect();

    indexes.nextColumn();
    indexes.select(0, 1);
    indexes.rectangle();
    indexes.unselect();

    indexes.nextColumn();
    indexes.select(0, 1);
    indexes.rectangle();
    indexes.unselect();

    indexes.nextColumn();
    indexes.select(0, 1);
    indexes.rectangle();
    indexes.unselect();

    indexes.nextColumn();
    indexes.select(0, 1);
    indexes.rectangle();
    indexes.unselect();

    if (header) {
      indexes.nextColumn();
      indexes.rectangle();
      indexes.nextRow();
      indexes.rectangle();
      indexes.previousRow();
    } else {
      indexes.nextColumn();
      indexes.select(0, 1);
      indexes.rectangle();
      indexes.unselect();
    }

    indexes.nextColumn();
    indexes.select(0, 1);
    indexes.rectangle();
    indexes.unselect();

    indexes.nextColumn();
    indexes.select(0, 1);
    indexes.rectangle();
    indexes.unselect();

    indexes.nextColumn();
    indexes.select(0, 1);
    indexes.rectangle();
    indexes.unselect();

    indexes.goToColumn(alphabet.indexOf(initialColumn));
    indexes.goToRow(initialRow);
  };

  return (
    <ExcelContext.Provider
      value={{
        invoice,
        contract,
        musicPrepSummary,
      }}
    >
      {children}
    </ExcelContext.Provider>
  );
}
