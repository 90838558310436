import { IconButton } from "@mui/joy";
import {
  alpha,
  Box,
  Divider,
  InputBase,
  Tooltip,
  Typography,
} from "@mui/material";
import { amber } from "@mui/material/colors";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { currencyFormatter } from "../../constants";
import { updateBudget } from "../../redux/budget/budgetActions";
import { budgetForProjectSelector } from "../../redux/budget/budgetSelector";
import { getProjectById } from "../../redux/project/projectActions";
import { selectedProjectSelector } from "../../redux/project/projectSelector";
import { useAppSelector } from "../hooks";

const budgetFields = {
  grandTotal: true,
  pensionOrchestration: true,
  subTotalNoBenOrchestration: true,
  subTotalNoLibCopyist: true,
  subTotalOrchestration: true,
  totalBenefits: true,
  totalOrchestration: true,
  totalPayroll: true,
  totalStipend: true,
  totalSupplies: true,
  totalWages: true,
  vacationOrchestration: true,
  welfareOrchestration: true,
};

/**
 *
 *
 * @returns {null}
 */
export default function ProjectBudget() {
  const project = useAppSelector(selectedProjectSelector);
  const budget = useAppSelector(budgetForProjectSelector(project?.id));
  const [_budget, setBudget] = useState<{ [k: string]: string }>({});
  const projectID = project?.id ?? 0;

  const dispatch = useDispatch();

  useEffect(() => {
    if (projectID && budget) {
      const _b = {};
      for (const key in budget) {
        if (Object.prototype.hasOwnProperty.call(budget, key)) {
          if (budgetFields[key]) _b[key] = `${(budget[key] ?? 0) / 10000}`;
        }
      }
      setBudget(_b);
    }
  }, [budget, projectID]);

  const save = () => {
    if (!budget) return;
    const _b = {};
    for (const key in _budget) {
      if (Object.prototype.hasOwnProperty.call(_budget, key)) {
        _b[key] = Math.floor(parseFloat(_budget[key]) * 10000);
      }
    }
    dispatch(updateBudget(budget.id, _b));
  };

  if (!project || !budget) return <div />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        p: 2,
        flex: 1,
        gap: 1,
        width: "100%",
        position: "relative",
      }}
    >
      <Box sx={{ textAlign: "right", position: "fixed", top: 8, right: 8 }}>
        <Tooltip title="Refresh Budget">
          <IconButton
            size="sm"
            color="neutral"
            variant="solid"
            onClick={() => dispatch(getProjectById(projectID))}
          >
            <i className="fa-solid fa-arrows-rotate"></i>
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ border: "solid 1px black", p: 1, borderRadius: 2 }}>
        <Box
          sx={{ display: "flex", textAlign: "center", alignItems: "center" }}
        >
          <Typography variant="body1" sx={{ flex: 2 }}>
            <b>Copyists</b>
          </Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>
            <b>Music Prep Summary</b>
          </Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>
            <b>Actual</b>
          </Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>
            <b>Difference</b>
          </Typography>
        </Box>
        <Divider />
        <Line2
          title="Librarian"
          c1={project?.musicPrepLibrarianDollar()}
          c2={project?.laborLibrarianDollar()}
          c3={project?.librarianDiffDollar()}
        />
        <Line2
          title="Subtotal (no lib)"
          bold
          c1={project?.musicPrepSubTotalNoLibDollar()}
          c2={project?.laborSubTotalNoLibDollar()}
          c3={project?.subTotalNoLibDiffDollar()}
        />
        <Line2
          title="Subtotal (w/lib)"
          bold
          c1={project?.musicPrepSubTotalWithLibDollar()}
          c2={project?.laborSubTotalWithLibDollar()}
          c3={project?.subTotalWithLibDiffDollar()}
        />
        <Divider />
        <Line2
          title="Supervision"
          c1={project?.musicPrepSupervisionDollar()}
          c2={project?.laborSupervisionDollar()}
          c3={project?.supervisionDiffDollar()}
        />
        <Line2
          title="Subtotal"
          bold
          c1={project?.musicPrepSubTotalDollar()}
          c2={project?.laborSubTotalDollar()}
          c3={project?.subTotalDiffDollar()}
        />
        <Line2
          title="Vacation"
          c1={project?.musicPrepVacationDollar()}
          c2={project?.laborVacationDollar()}
          c3={project?.vacationDiffDollar()}
        />
        <Line2
          title="Subtotal (no ben)"
          bold
          c1={project?.musicPrepSubTotalNoBenDollar()}
          c2={project?.laborSubTotalNoBenDollar()}
          c3={project?.subTotalNoBenDiffDollar()}
        />
        <Line2
          title="HW"
          c1={project?.musicPrepWelfareDollar()}
          c2={project?.laborWelfareDollar()}
          c3={project?.welfareDiffDollar()}
        />
        <Line2
          title="Pension"
          c1={project?.musicPrepPensionDollar()}
          c2={project?.laborPensionDollar()}
          c3={project?.pensionDiffDollar()}
        />
        <Line2
          title="Total (w/ben)"
          bold
          c1={project?.musicPrepTotalDollar()}
          c2={project?.laborTotalDollar()}
          c3={project?.totalDiffDollar()}
        />
        <Line2
          title="Supplies"
          c1={project?.musicPrepSuppliesDollar()}
          c2={project?.laborSuppliesDollar()}
          c3={project?.suppliesDiffDollar()}
        />
        <Line2 title="PAYROLL" c1={""} c2={""} c3={""} />
        <Divider />
        <Box
          sx={{ display: "flex", textAlign: "center", alignItems: "center" }}
        >
          <Typography variant="body1" sx={{ flex: 2 }}>
            <b>Fox TV</b>
          </Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>
            <b>Estimate</b>
          </Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>
            <b>Actual</b>
          </Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>
            <b>Difference</b>
          </Typography>
        </Box>
        <Line2
          title="Copyist Wages/Supplies"
          c1={project?.musicPrepCopysitWagesSuppliesDollar()}
          c2={project?.laborCopysitWagesSuppliesDollar()}
          c3={project?.copysitWagesSuppliesDiffDollar()}
        />
      </Box>
      <Box sx={{ border: "solid 1px black", p: 1, borderRadius: 2 }}>
        <Box
          sx={{ display: "flex", textAlign: "center", alignItems: "center" }}
        >
          <Typography variant="body1" sx={{ flex: 2 }}>
            <b>Orchestration</b>
          </Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>
            <b>Estimate</b>
          </Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>
            <b>Actual</b>
          </Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>
            <b>Difference</b>
          </Typography>
        </Box>
        <Divider />
        <Line2
          title="Subtotal"
          bold
          c2={project?.orchestrationSubTotalDollar()}
          c3={
            budget?.subTotalOrchestration != 0
              ? project?.orchestrationSubTotalDiffDollar(budget)
              : ""
          }
          budget={_budget.subTotalOrchestration}
          disabled={project?.locked()}
          onChange={(e) =>
            setBudget((b) => ({
              ...b,
              subTotalOrchestration: e.target.value,
            }))
          }
          save={save}
        />
        <Line2
          title="Vacation"
          c2={project?.orchestrationVacationDollar()}
          budget={_budget.vacationOrchestration}
          c3={
            budget?.vacationOrchestration != 0
              ? project?.orchestrationVacationDiffDollar(budget)
              : ""
          }
          disabled={project?.locked()}
          onChange={(e) =>
            setBudget((b) => ({
              ...b,
              vacationOrchestration: e.target.value,
            }))
          }
          save={save}
        />
        <Line2
          title="Subtotal (no ben)"
          bold
          c2={project?.orchestrationSubTotalNoBenDollar()}
          c3={
            budget?.subTotalNoBenOrchestration != 0
              ? project?.orchestrationSubTotalNoBenDiffDollar(budget)
              : ""
          }
          budget={_budget.subTotalNoBenOrchestration}
          disabled={project?.locked()}
          onChange={(e) =>
            setBudget((b) => ({
              ...b,
              subTotalNoBenOrchestration: e.target.value,
            }))
          }
          save={save}
        />
        <Line2
          title="HW"
          c2={project?.orchestrationWelfareDollar()}
          c3={
            budget?.welfareOrchestration != 0
              ? project?.orchestrationWelfareDiffDollar(budget)
              : ""
          }
          budget={_budget.welfareOrchestration}
          disabled={project?.locked()}
          onChange={(e) =>
            setBudget((b) => ({ ...b, welfareOrchestration: e.target.value }))
          }
          save={save}
        />
        <Line2
          title="Pension"
          c2={project?.orchestrationPensionDollar()}
          c3={
            budget?.pensionOrchestration != 0
              ? project?.orchestrationPensionDiffDollar(budget)
              : ""
          }
          budget={_budget.pensionOrchestration}
          disabled={project?.locked()}
          onChange={(e) =>
            setBudget((b) => ({ ...b, pensionOrchestration: e.target.value }))
          }
          save={save}
        />
        <Divider />
        <Line2
          title="Total Orch"
          bold
          c2={project?.orchestrationTotalDollar()}
          c3={
            budget?.totalOrchestration != 0
              ? project?.orchestrationTotalDiffDollar(budget)
              : ""
          }
          budget={_budget.totalOrchestration}
          disabled={project?.locked()}
          onChange={(e) =>
            setBudget((b) => ({ ...b, totalOrchestration: e.target.value }))
          }
          save={save}
        />
      </Box>
      <Box sx={{ border: "solid 1px black", p: 1, borderRadius: 2 }}>
        <Box
          sx={{ display: "flex", textAlign: "center", alignItems: "center" }}
        >
          <Typography variant="body1" sx={{ flex: 2 }}>
            <b>TTL Copy/Orch</b>
          </Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>
            <b>Estimate</b>
          </Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>
            <b>Actual</b>
          </Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>
            <b>Difference</b>
          </Typography>
        </Box>
        <Divider />
        <Line2
          title="Wages (w/vac)"
          bold
          c2={project?.totalWagesDollar()}
          c3={
            budget?.totalWages != 0 ? project?.totalWagesDiffDollar(budget) : ""
          }
          budget={_budget.totalWages}
          disabled={project?.locked()}
          onChange={(e) =>
            setBudget((b) => ({ ...b, totalWages: e.target.value }))
          }
          save={save}
        />
        <Line2
          title="Benefits"
          c1={""}
          c2={project?.totalBenefitsDollar()}
          c3={
            budget?.totalBenefits != 0
              ? project?.totalBenefitsDiffDollar(budget)
              : ""
          }
          budget={_budget.totalBenefits}
          disabled={project?.locked()}
          onChange={(e) =>
            setBudget((b) => ({ ...b, totalBenefits: e.target.value }))
          }
          save={save}
        />
        <Line2
          title="Supplies"
          c2={project?.totalSuppliesDollar()}
          c3={
            budget?.totalSupplies != 0
              ? project?.totalSuppliesDiffDollar(budget)
              : ""
          }
          budget={_budget.totalSupplies}
          disabled={project?.locked()}
          onChange={(e) =>
            setBudget((b) => ({ ...b, totalSupplies: e.target.value }))
          }
          save={save}
        />
        <Line2
          title="Payroll"
          c1={""}
          c2={""}
          budget={_budget.totalPayroll}
          disabled={project?.locked()}
          onChange={(e) =>
            setBudget((b) => ({ ...b, totalPayroll: e.target.value }))
          }
          save={save}
        />
        <Line2
          title="Stipend/Cartage"
          c1={""}
          c2={project?.totalStipendDollar()}
          c3={
            budget?.totalStipend != 0
              ? project?.totalStipendDiffDollar(budget)
              : ""
          }
          budget={_budget.totalStipend}
          disabled={project?.locked()}
          onChange={(e) =>
            setBudget((b) => ({ ...b, totalStipend: e.target.value }))
          }
          save={save}
        />
        <Divider />
        <Line2
          title="Grand Total"
          bold
          c1={""}
          c2={project?.grandTotalDollar()}
          c3={
            budget?.grandTotal != 0 ? project?.grandTotalDiffDollar(budget) : ""
          }
          budget={_budget.grandTotal}
          disabled={project?.locked()}
          onChange={(e) =>
            setBudget((b) => ({ ...b, grandTotal: e.target.value }))
          }
          save={save}
        />
      </Box>
    </Box>
  );
}

export function CurrencyTextField({
  value,
  onChange,
  onCommit,
  label,
  disabled,
}: {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onCommit: () => void;
  label?: string;
  disabled?: boolean;
}) {
  const [active, setActive] = useState(false);

  useHotkeys(
    `enter`,
    () => {
      if (active) {
        setActive(false);
        onCommit();
      }
    },
    {
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    }
  );

  const field =
    active && !disabled ? (
      <InputBase
        autoFocus
        onBlur={() => {
          setActive(false);
          onCommit();
        }}
        onFocus={(event) => {
          event.target.select();
        }}
        sx={{
          textAlign: label ? "right" : "left",

          fontSize: 14,
          fontWeight: 600,
          p: 0,
          m: 0,
          color: "#2196f3",
        }}
        size="small"
        onChange={onChange}
        value={value}
        inputProps={{
          style: {
            textAlign: label ? "right" : "left",
            color: "#2196f3 !important",
          },
        }}
      />
    ) : (
      <Tooltip title="Budget">
        <Typography
          onClick={() => setActive((a) => !a)}
          sx={{
            fontWeight: 600,
            color: "#2196f3",
            border: `solid 1px ${alpha("#2196f3", 0.3)}`,
            borderRadius: 1,
            cursor: disabled ? "not-allowed" : undefined,
          }}
          variant="body2"
        >
          {currencyFormatter.format(parseFloat(value))}
        </Typography>
      </Tooltip>
    );

  if (label)
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
          height: 22,
          borderRadius: 2,
        }}
      >
        <Typography sx={{ fontWeight: 600 }} variant="body2">
          {label.toUpperCase()}
        </Typography>
        {field}
      </Box>
    );

  return field;
}

export function Help({ title }: { title: string }) {
  return (
    <Tooltip title={title}>
      <i
        style={{ color: amber[600] }}
        className="fa-regular fa-square-question"
      ></i>
    </Tooltip>
  );
}

function Line2({
  title,
  c1,
  c2,
  c3,
  budget,
  onChange,
  save,
  disabled,
  help,
  bold,
}: {
  title: string;
  c1?: string;
  c2?: string;
  c3?: string;
  help?: string;
  budget?: string;
  save?: () => void;
  disabled?: boolean;
  difference?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  bold?: boolean;
}) {
  return (
    <Box sx={{ mt: 0.2 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography
          variant="body2"
          sx={{
            flex: 1.5,
            display: "flex",
            gap: 1,
            alignItems: "center",
            fontWeight: bold ? 600 : undefined,
          }}
        >
          {title} {help ? <Help title={help} /> : []}
        </Typography>
        {budget && save && onChange ? (
          <Box sx={{ flex: 1, textAlign: "right" }}>
            <CurrencyTextField
              onCommit={save}
              disabled={disabled}
              value={budget}
              onChange={onChange}
              label={" "}
            />
          </Box>
        ) : (
          <Typography
            variant="body2"
            sx={{
              flex: 1,
              textAlign: "right",
              fontWeight: bold ? 600 : undefined,
            }}
          >
            {c1}
          </Typography>
        )}
        <Typography
          variant="body2"
          sx={{
            flex: 1,
            textAlign: "right",
            fontWeight: bold ? 600 : undefined,
          }}
        >
          {c2}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            flex: 1,
            textAlign: "right",
            color: c3?.length && c3[0] === "-" ? "#f44336" : "#4caf50",
            fontWeight: bold ? 600 : undefined,
          }}
        >
          {c3}
        </Typography>
      </Box>
    </Box>
  );
}
