import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Menu,
  MenuItem,
  Paper,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import { MouseEvent, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Project_Entity } from "../../entities/project";
import { AppConfig } from "../../environement";
import { TextFieldHelper } from "../../helpers/formHelpers";
import { updateProject } from "../../redux/project/projectActions";
import { selectedProjectSelector } from "../../redux/project/projectSelector";
import { projectTypesSelector } from "../../redux/projectType/projectTypeSelector";
import { talentsSelector } from "../../redux/talent/talentSelector";
import { vendorsSelector } from "../../redux/vendor/vendorSelector";
import { RouterConfig } from "../config/routerConfig";
import { ExcelContext } from "../excel";
import { useAppSelector } from "../hooks";

/**
 *
 *
 * @returns {null}
 */
export default function ProjectInfo() {
  const project = useAppSelector(selectedProjectSelector);
  const talents = useAppSelector(talentsSelector);
  const vendors = useAppSelector(vendorsSelector);
  const projectTypes = useAppSelector(projectTypesSelector);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLElement) | null
  >();

  const [_project, setProject] = useState<Project_Entity | undefined>();

  const dispatch = useDispatch();
  const confirm = useConfirm();

  const excel = useContext(ExcelContext);

  useEffect(() => {
    setProject(project);
  }, [project]);

  if (!_project) return <div />;

  const handleArchive = () => {
    confirm({
      description: `You are about to archive a project. You will still have access to it.`,
    })
      .then(() => {
        dispatch(updateProject(project!.id, { archived: !project!.archived }));
      })
      .catch(() => {
        /* ... */
      });
  };

  const handleDelete = () => {
    confirm({
      description: `IMPORTANT MESSAGE: YOU ARE ABOUT TO DELETE A PROJECT. YOU WON'T BE ABLE TO RECOVER ANYTHING FROM THIS PROJECT. PLEASE CONFIRM YOUR ACTION`,
    })
      .then(() => {
        dispatch(updateProject(project!.id, { deleted: true }));
        navigate(`/${RouterConfig.projects}`);
      })
      .catch(() => {
        /* ... */
      });
  };

  const handleTextChange = (name: string) => (value: string | number) => {
    if (!_project) return;
    _project[name] = value;
    setProject({ ..._project });
  };

  function save() {
    if (!_project) return;
    dispatch(updateProject(_project.id, _project));
  }

  const steps = ["Active", "Invoiced", "Paid"];
  const chips = [
    {
      label: "Sent to Client",
      active: project?.sentToClient(),
      field: "clientInvoiceDate",
    },
    {
      label: "Paid by Client",
      active: project?.paidByClient(),
      field: "clientPaymentDate",
      disabled: !project?.sentToClient(),
    },
    {
      label: project?.union ? "Sent to employees" : "Sent to vendors",
      active: project?.sentToEmployees(),
      field: "employeesInvoiceDate",
    },
    {
      label: project?.union ? "Paid to employees" : "Paid to vendors",
      active: project?.employeesPaid(),
      field: "employeesPaymentDate",
      disabled: !project?.sentToEmployees(),
    },
  ];

  if (project?.union) {
    chips.push({
      label: "Supplies paid",
      active: project?.suppliesPaid(),
      field: "suppliesPaymentDate",
    });
  }

  if (!project) return <Box />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch",
        flex: 1,
      }}
    >
      <Box
        sx={{
          background: "black",
          flex: 1,
          color: "white",
          p: 1,
          display: "flex",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <ActionButton
          faIcon="fa-duotone fa-file-contract"
          // onClick={excel.contract}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          title={project.union ? "AFM Contract" : "Client Invoice"}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              excel.contract();
              setAnchorEl(null);
            }}
          >
            <i className="fa-duotone fa-file-excel pright"></i> Excel
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.open(
                `${AppConfig.api.endpoint}/films/projects/${project.id}/contract`,
                "_blank"
              );
              setAnchorEl(null);
            }}
          >
            <i className="fa-duotone fa-file-pdf pright"></i>PDF
          </MenuItem>
        </Menu>
        <ActionButton
          faIcon="fa-duotone fa-file-spreadsheet"
          onClick={excel.musicPrepSummary}
          title={"Music Prep Summary"}
        />
        <ActionButton
          faIcon="fa-duotone fa-file-invoice"
          onClick={() => {
            window.open(
              `${AppConfig.api.endpoint}/films/projects/${project.id}/invoices`,
              "_blank"
            );
          }}
          title={project.union ? "Copyists Invoices" : "Vendor Invoices"}
        />
        <ActionButton
          faIcon="fa-duotone fa-file-invoice"
          onClick={() => {
            window.open(
              `${AppConfig.api.endpoint}/films/projects/${project.id}/orchInvoices`,
              "_blank"
            );
          }}
          title={"Orchestrators Invoices"}
        />
        <ActionButton
          faIcon="fa-duotone fa-receipt"
          onClick={() => {
            window.open(
              `${AppConfig.api.endpoint}/films/projects/${project.id}/expensesInvoices`,
              "_blank"
            );
          }}
          title={"Expenses Invoice"}
        />
      </Box>
      <Box
        sx={{
          maxWidth: 600,
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 2,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Stepper
            nonLinear
            activeStep={steps.indexOf(project.status)}
            alternativeLabel
          >
            {steps.map((label, i) => (
              <Step key={label} color="success">
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
          {chips.map((c) => (
            <Tooltip
              title={
                c.active
                  ? `${moment(project[c.field]).format(
                      "lll"
                    )}. Click on the crossmark to undo.`
                  : ""
              }
              key={c.label}
            >
              <Chip
                size="small"
                variant={c.active ? "filled" : "outlined"}
                color={c.active ? "success" : undefined}
                disabled={c.disabled}
                onDelete={
                  c.active
                    ? () =>
                        dispatch(
                          updateProject(project!.id, {
                            [c.field]: project.reversedDate(),
                          })
                        )
                    : undefined
                }
                label={c.label}
                onClick={() =>
                  dispatch(
                    updateProject(project!.id, {
                      [c.field]: moment().utc().format(),
                    })
                  )
                }
              />
            </Tooltip>
          ))}
        </Box>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
          {TextFieldHelper<Project_Entity>(
            _project,
            "Name",
            "name",
            handleTextChange,
            save,
            { disabled: project?.locked() }
          )}
          {TextFieldHelper<Project_Entity>(
            _project,
            "Reference Number",
            "referenceNumber",
            handleTextChange,
            save,
            { disabled: project?.locked() }
          )}
          {TextFieldHelper<Project_Entity>(
            _project,
            "Invoice Number",
            "invoiceNumber",
            handleTextChange,
            save,
            { disabled: project?.locked() }
          )}
          <TextField
            autoComplete="off"
            select
            disabled={project?.locked()}
            size="small"
            variant="filled"
            value={_project.projectTypeID}
            label="Type"
            onChange={(e) => {
              _project.projectTypeID = parseInt(e.target.value);
              setProject({ ..._project });
              dispatch(updateProject(project!.id, _project));
            }}
          >
            {projectTypes.map((t) => (
              <MenuItem key={t.id} value={t.id}>
                {t.name}
              </MenuItem>
            ))}
          </TextField>
          <FormControlLabel
            disabled={project?.locked()}
            control={
              <Checkbox
                checked={project?.newMedia}
                onChange={(e) => {
                  _project.newMedia = e.target.checked;
                  setProject({ ..._project });
                  dispatch(
                    updateProject(project!.id, { newMedia: e.target.checked })
                  );
                }}
              />
            }
            label="New Media"
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              disabled={project?.locked()}
              label="Date"
              value={moment(_project.date)}
              onChange={(newValue) => {
                _project.date = newValue?.utc().format() ?? "";
                setProject({ ..._project });
                dispatch(updateProject(project!.id, _project));
              }}
              renderInput={(params) => (
                <TextField size="small" variant="filled" {...params} />
              )}
            />
          </LocalizationProvider>
          <TextField
            select
            autoComplete="off"
            disabled={project?.locked()}
            size="small"
            variant="filled"
            value={_project.principalID}
            label="Project Principal"
            onChange={(e) => {
              _project.principalID = parseInt(e.target.value);
              setProject({ ..._project });
              dispatch(updateProject(project!.id, _project));
            }}
          >
            {talents.map((t) => (
              <MenuItem key={t.id} value={t.id}>
                {t.firstName} {t.lastName}
              </MenuItem>
            ))}
          </TextField>
          {TextFieldHelper<Project_Entity>(
            _project,
            "Leader",
            "leader",
            handleTextChange,
            save,
            { disabled: project?.locked() }
          )}
          <TextField
            select
            autoComplete="off"
            size="small"
            disabled={project?.locked()}
            variant="filled"
            value={_project.payrollID}
            label="Payroll"
            onChange={(e) => {
              _project.payrollID = parseInt(e.target.value);
              setProject({ ..._project });
              dispatch(updateProject(project!.id, _project));
            }}
          >
            {vendors.map((t) => (
              <MenuItem key={t.id} value={t.id}>
                {t.name}
              </MenuItem>
            ))}
          </TextField>
          <FormControlLabel
            disabled={project?.locked()}
            control={
              <Checkbox
                checked={project?.union}
                onChange={(e) => {
                  _project.union = e.target.checked;
                  setProject({ ..._project });
                  dispatch(
                    updateProject(project!.id, { union: e.target.checked })
                  );
                }}
              />
            }
            label="Union Project"
          />
          <TextField
            select
            autoComplete="off"
            size="small"
            variant="filled"
            value={_project.unionID}
            disabled={!_project.union || project?.locked()}
            label="Union"
            onChange={(e) => {
              _project.unionID = parseInt(e.target.value);
              setProject({ ..._project });
              dispatch(updateProject(project!.id, _project));
            }}
          >
            {vendors.map((t) => (
              <MenuItem key={t.id} value={t.id}>
                {t.name}
              </MenuItem>
            ))}
          </TextField>
          {TextFieldHelper<Project_Entity>(
            _project,
            "Local",
            "local",
            handleTextChange,
            save,
            { disabled: project?.locked() }
          )}
          <TextField
            select
            autoComplete="off"
            disabled={project?.locked()}
            size="small"
            variant="filled"
            value={_project.signatoryID}
            label="Signatory"
            onChange={(e) => {
              _project.signatoryID = parseInt(e.target.value);
              setProject({ ..._project });
              dispatch(updateProject(project!.id, _project));
            }}
          >
            {vendors.map((t) => (
              <MenuItem key={t.id} value={t.id}>
                {t.name}
              </MenuItem>
            ))}
          </TextField>
          {TextFieldHelper<Project_Entity>(
            _project,
            "Place of Engagement",
            "placeOfEngagement",
            handleTextChange,
            save,
            { disabled: project?.locked() }
          )}
          {TextFieldHelper<Project_Entity>(
            _project,
            "Memo",
            "memo",
            handleTextChange,
            save,
            { disabled: project?.locked() }
          )}
          {TextFieldHelper<Project_Entity>(
            _project,
            "Health & Welfare Company",
            "hwCompany",
            handleTextChange,
            save,
            { disabled: project?.locked() }
          )}
        </Paper>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" color="error">
            Danger Zone
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              disabled={project?.locked()}
              variant="outlined"
              color="error"
              onClick={handleArchive}
            >
              {project?.archived ? "Unarchive Project" : "Archive Project"}
            </Button>
            <Tooltip
              title={
                project?.archived
                  ? "Delete for ever"
                  : "Only archived projects can be deleted"
              }
            >
              <Box>
                <Button
                  disabled={!project?.archived}
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                >
                  Delete Project
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

function ActionButton({
  faIcon,
  title,
  onClick,
}: {
  faIcon?: string;
  title?: string;
  onClick: (e: MouseEvent<HTMLElement>) => void;
}) {
  return (
    <Box
      sx={{
        width: 150,
        background: "rgba(255,255,255,0.2)",
        border: "solid 1px rgba(255,255,255,0.3)",
        borderRadius: 2,
        textAlign: "center",
        cursor: "pointer",
        p: 1,
        transition: "background .2s",
        "&:hover": {
          background: "rgba(255,255,255,0.5)",
        },
      }}
      onClick={(e) => onClick(e)}
    >
      <Box sx={{ fontSize: 32 }}>
        <i className={faIcon}></i>
      </Box>
      <Typography variant="caption">{title}</Typography>
    </Box>
  );
}
