/**
 * Cue_Entity type guard.
 *
 * @param {any} cueJson Cue object from API
 * @returns {boolean} Return true if type is Cue_Entity
 */
function validator(cueJson: unknown): cueJson is Cue_Entity {
  if (typeof cueJson === "object" && cueJson != null) {
    Cue_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(cueJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Cue_Entity {
  static requiredFields = [
    "id",
    "projectID",
    "code",
    "version",
    "qualifier",
    "title",
    "length",
    "notes",
    "bars",
    "lines",
    "hidden",
    "createdAt",
    "updatedAt",
    "talentIDs",
  ];

  constructor(cueJson: unknown) {
    if (validator(cueJson)) {
      this.id = cueJson.id;
      this.order = cueJson.order;
      this.projectID = cueJson.projectID;
      this.code = cueJson.code;
      this.version = cueJson.version;
      this.qualifier = cueJson.qualifier;
      this.title = cueJson.title;
      this.length = cueJson.length;
      this.notes = cueJson.notes;
      this.bars = cueJson.bars;
      this.lines = cueJson.lines;
      this.createdAt = cueJson.createdAt;
      this.updatedAt = cueJson.updatedAt;
      this.talentIDs = cueJson.talentIDs;
      this.showInMPS = cueJson.showInMPS;
      this.showInContract = cueJson.showInContract;
      this.showInInvoice = cueJson.showInInvoice;
      this.includeInCalculation = cueJson.includeInCalculation;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Cue_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  order: number;
  projectID: number;
  code: string;
  version: string;
  qualifier: string;
  title: string;
  length: number;
  notes: string;
  bars: number;
  lines: number;
  createdAt: string;
  updatedAt: string;
  talentIDs: number[];
  showInMPS: boolean;
  showInContract: boolean;
  showInInvoice: boolean;
  includeInCalculation: boolean;
}
