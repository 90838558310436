import { Budget } from "../budget";
import { Labor } from "../labor";
import { MusicPrep } from "../musicPrep";
import { Orchestration } from "../orchestration";

/**
 * Project_Entity type guard.
 *
 * @param {any} projectJson Project object from API
 * @returns {boolean} Return true if type is Project_Entity
 */
function validator(projectJson: unknown): projectJson is Project_Entity {
  if (typeof projectJson === "object" && projectJson != null) {
    Project_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(projectJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Project_Entity {
  static requiredFields = [
    "id",
    "name",
    "date",
    "referenceNumber",
    "projectTypeID",
    "totalWages",
    "totalHW",
    "totalPension",
    "totalExpenses",
    "totalStipend",
    "invoiceNumber",
    "principalID",
    "leader",
    "payrollID",
    "unionID",
    "signatoryID",
    "local",
    "memo",
    "hwCompany",
    "placeOfEngagement",
    "clientInvoiceDate",
    "clientPaymentDate",
    "employeesInvoiceDate",
    "employeesPaymentDate",
    "suppliesPaymentDate",
    "newMedia",
    "archived",
    "deleted",
    "union",
    "grandTotal",
    "budgets",
    "musicPrep",
    "labor",
    "orchestration",
    "status",
    "createdAt",
    "updatedAt",
  ];

  constructor(projectJson: unknown) {
    if (validator(projectJson)) {
      this.id = projectJson.id;
      this.name = projectJson.name;
      this.date = projectJson.date;
      this.referenceNumber = projectJson.referenceNumber;
      this.projectTypeID = projectJson.projectTypeID;
      this.totalWages = projectJson.totalWages;
      this.totalHW = projectJson.totalHW;
      this.totalPension = projectJson.totalPension;
      this.totalExpenses = projectJson.totalExpenses;
      this.totalStipend = projectJson.totalStipend;
      this.invoiceNumber = projectJson.invoiceNumber;
      this.principalID = projectJson.principalID;
      this.leader = projectJson.leader;
      this.payrollID = projectJson.payrollID;
      this.unionID = projectJson.unionID;
      this.signatoryID = projectJson.signatoryID;
      this.local = projectJson.local;
      this.memo = projectJson.memo;
      this.hwCompany = projectJson.hwCompany;
      this.placeOfEngagement = projectJson.placeOfEngagement;
      this.clientInvoiceDate = projectJson.clientInvoiceDate;
      this.clientPaymentDate = projectJson.clientPaymentDate;
      this.employeesInvoiceDate = projectJson.employeesInvoiceDate;
      this.employeesPaymentDate = projectJson.employeesPaymentDate;
      this.suppliesPaymentDate = projectJson.suppliesPaymentDate;
      this.newMedia = projectJson.newMedia;
      this.archived = projectJson.archived;
      this.deleted = projectJson.deleted;
      this.union = projectJson.union;
      this.grandTotal = projectJson.grandTotal;
      this.budgets = projectJson.budgets;
      this.musicPrep = projectJson.musicPrep;
      this.labor = projectJson.labor;
      this.orchestration = projectJson.orchestration;
      this.status = projectJson.status;
      this.createdAt = projectJson.createdAt;
      this.updatedAt = projectJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Project_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name: string;
  date: string;
  referenceNumber: string;
  projectTypeID: number;
  totalWages: number;
  totalHW: number;
  totalPension: number;
  totalExpenses: number;
  totalStipend: number;
  invoiceNumber: string;
  principalID: number;
  leader: string;
  payrollID: number;
  unionID: number;
  signatoryID: number;
  local: string;
  memo: string;
  hwCompany: string;
  placeOfEngagement: string;
  clientInvoiceDate: string;
  clientPaymentDate: string;
  employeesInvoiceDate: string;
  employeesPaymentDate: string;
  suppliesPaymentDate: string;
  newMedia: boolean;
  archived: boolean;
  deleted: boolean;
  union: boolean;
  grandTotal: number;
  budgets: Budget[];
  musicPrep: MusicPrep;
  labor: Labor;
  orchestration: Orchestration;
  status: string;
  createdAt: string;
  updatedAt: string;
}
