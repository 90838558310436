import { DarkMode, LightMode } from "@mui/icons-material";
import { useColorScheme } from "@mui/joy";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { NerdAppBarMenuItem } from "../nerdAppBar/nerdAppBar";
import "./style.css";

/**
 * @component
 * @returns {React.ReactElement} Button to trigger the dark/light mode. Should be placed in the NerdAppBar configuration
 */
export function NerdAppBarDarkModeButton(): React.ReactElement {
  const { mode, setMode } = useColorScheme();
  const { t } = useTranslation();
  const isDark = mode === "dark";

  /**
   * Handle click on DarkMode/LightMode button
   */
  function handleClick() {
    isDark ? setMode("light") : setMode("dark");
  }

  return (
    <NerdAppBarMenuItem
      callback={handleClick}
      sx={{ mr: 1 }}
      startIcon={
        isDark ? (
          <LightMode fontSize="inherit" />
        ) : (
          <DarkMode fontSize="inherit" />
        )
      }
      title={!isDark ? t("global.dark_mode") : t("global.light_mode")}
    />
  );
}
