import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectTasks = (state: AppState) => state.taskState.tasks;
const selectBulkSelect = (state: AppState) => state.taskState.bulkSelect;
const selectSelectedGridTasks = (state: AppState) =>
  state.taskState.selectedGridTasks;

export const tasksSelector = createSelector([selectTasks], (tasks) => {
  return mapToArray(tasks);
});

export const taskBulkSelectSelector = createSelector(
  [selectBulkSelect],
  (s) => s
);

export const selectedGridTasksSelector = createSelector(
  [selectSelectedGridTasks],
  (s) => s
);

export const tasksMapSelector = createSelector([selectTasks], (tasks) => {
  return tasks;
});

export const taskSelector = (id?: number) => {
  return createSelector([selectTasks], (tasks) => {
    if (id) return tasks[id];
    return undefined;
  });
};
