/**
 * JobTask_Entity type guard.
 *
 * @param {any} jobTaskJson JobTask object from API
 * @returns {boolean} Return true if type is JobTask_Entity
 */
function validator(jobTaskJson: unknown): jobTaskJson is JobTask_Entity {
  if (typeof jobTaskJson === "object" && jobTaskJson != null) {
    JobTask_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(jobTaskJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class JobTask_Entity {
  static requiredFields = [
    "id",
    "jobID",
    "taskID",
    "quantity",
    "createdAt",
    "updatedAt",
  ];

  constructor(jobTaskJson: unknown) {
    if (validator(jobTaskJson)) {
      this.id = jobTaskJson.id;
      this.jobID = jobTaskJson.jobID;
      this.taskID = jobTaskJson.taskID;
      this.quantity = jobTaskJson.quantity;
      this.createdAt = jobTaskJson.createdAt;
      this.updatedAt = jobTaskJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ JobTask_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  jobID: number;
  taskID: number;
  quantity: number;
  createdAt: string;
  updatedAt: string;
}
